import React, { useEffect, useState } from "react";
import Card from "../admin/Card";
import { useDispatch, useSelector } from "react-redux";
import { FiMenu } from "react-icons/fi";
import { getResellerDashboard } from "../../features/dashboard/dashboardSlice";
import Sidebar from "../common/Sidebar";
import ProfileContent from "../users/ProfileContent";
import VideoCourses from "./VideoCourses";
import LoaderComponent from "../LoaderComponent";
import PaymentSettings from "../common/PaymentSettings";
import {
  createWithdrawalMethodByReseller,
  getWithdrawalMethodsByReseller,
  deleteWithdrawalMethodByReseller
} from "../../features/withdrawalMethod/withdrawalSlice";
import { getSingleSubscriptionOrder } from "../../features/subscriptionOrder/subscriptionOrderSlice";

import Withdraw from "../common/Withdraw";
import StoreDetails from "./StoreDetails";
import { Link, useLocation } from "react-router-dom";
import SubscriptionDetails from "./SubscriptionDetails";
import ProductRequestList from "../common/ProductRequestList";
import ResellerReferral from "./ResellerReferral";
import ResellerOrders from "./ResellerOrders";
import OrderFromResellerStore from "./OrderFromResellerStore";
import { fetchResellerProfile } from "../../features/user/userSlice";
import OrderBonus from "./OrderBonus";
import ChangePassword from "../common/ChangePassword";

// menu items

const menuItems = [
  { id: "dashboard", label: "Dashboard" },
  { id: "profile", label: "Profile" },
  { id: "change-password", label: "Change Password" },
  { id: "orders", label: "Orders" },
  { id: "store-orders", label: "Store Orders" },
  { id: "withdraw", label: "Withdraw" },
  { id: "my-store", label: "My Store" },
  { id: "payment-settings", label: "Payment Settings" },
  { id: "video-course", label: "Video Tutorials" },
  { id: "package", label: "Package Details" },  
  { id: "referrals", label: "Referrals" },
];

const menuItems2 = [
  { id: "product-request", label: "Product Request" },
  { id: "order-bonus", label: "Order Bonus Notice" },
];

const ResellerDashboard = () => {
  const { userInfo } = useSelector((state) => state.auth);

  const { dashboard, loading, error } = useSelector((state) => state.dashboard);
  const { subscriptionOrderByReseller } = useSelector(
    (state) => state.subscriptionOrders
  );

  // check state from checkout
  const location = useLocation();
  const { active } = location.state || { active: "dashboard" };

  const initialTab = ()=>localStorage.getItem("activeTab") || active;

  // default active sections
  const [selectedSection, setSelectedSection] = useState(initialTab());
  const dispatch = useDispatch();

  // toggle sidebar
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const isPaymentPending =
    userInfo && !userInfo.subscription && userInfo.subscriptionStatus === false;
  const resellerId = userInfo._id;

    useEffect(() => {
      localStorage.setItem("activeTab", selectedSection);
    }, [selectedSection]);

  useEffect(() => {
    if (!isPaymentPending) {
      dispatch(
        getResellerDashboard({
          resellerId,
          token: JSON.parse(localStorage.getItem("access_token")),
        })
      );

      dispatch(fetchResellerProfile());

    }
  }, [dispatch, isPaymentPending, resellerId]);

  useEffect(() => {
    if (isPaymentPending) {
      dispatch(
        getSingleSubscriptionOrder({
          resellerId,
          token: JSON.parse(localStorage.getItem("access_token")),
        })
      );
    }
  }, [dispatch, resellerId, isPaymentPending]);

 

  if (loading) return <LoaderComponent />;

  if (error) {
    return (
      <div className="max-w-2xl mx-auto text-center mt-20">
        <h1 className="text-3xl text-center text-red-600 font-semibold mb-6">
          Error Loading Dashboard
        </h1>
        <p className="text-xl text-gray-600">
          There was an issue fetching your dashboard data. Please try again
          later.
        </p>
        <button onClick={() => window.location.reload()} className="mt-4 py-2 px-6 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors">
          Retry
        </button>
      </div>
    );
  }

  return (
    <div className="flex min-h-screen bg-gray-100">
      {isPaymentPending ? (
        <div className="max-w-2xl mx-auto text-center mt-20">
          {subscriptionOrderByReseller &&
            subscriptionOrderByReseller.manualPaymentId &&
            subscriptionOrderByReseller.provider && (
              <>
                <h1 className="text-3xl text-center bg-red-600 text-white font-semibold mb-6">
                  Payment Pending
                </h1>
                <p>
                  {" "}
                  your package name is:{" "}
                  {
                    subscriptionOrderByReseller?.subscriptionPackageId?.name
                  }{" "}
                </p>
                <p>
                  {" "}
                  your reference id is: {
                    subscriptionOrderByReseller.paymentId
                  }{" "}
                </p>
                <p>
                  {" "}
                  your payment provider is:{" "}
                  {subscriptionOrderByReseller.provider}{" "}
                </p>
                <p>
                  {" "}
                  your payment id is:{" "}
                  {subscriptionOrderByReseller.manualPaymentId}{" "}
                </p>
                <p className="text-xl italic text-red-600 font-semibold mb-6">
                  Your package payment is pending. If you believe this is an
                  error, please contact our support team.
                </p>
              </>
            )}
          {!subscriptionOrderByReseller && (
            <>
              <p className="text-xl italic text-red-600 font-semibold mb-6">
                If you don't have chosen any package, please choose one.
              </p>
              <Link
                to="/reseller/packages"
                className="mt-4 py-2 px-6 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors"
              >
                Go to packages and choose
              </Link>
            </>
          )}

          {subscriptionOrderByReseller &&
            !subscriptionOrderByReseller.manualPaymentId && (
              <div className="mt-4 py-2 px-6 bg-green-600 text-white rounded-lg hover:bg-green-700 transition-colors">
                <p> You chose a package but You have not made any payment.</p>
                <p className="text-2xl font-semibold">
                  Please make a payment. your package name is :
                  <span className="text-red-600">
                    {subscriptionOrderByReseller?.subscriptionPackageId?.name}
                  </span>
                </p>
                <p> You have to pay : {subscriptionOrderByReseller.amount}</p>
                <Link
                  to={`/reseller/package/${subscriptionOrderByReseller?.subscriptionPackageId?.name}/order/manual-payment`}
                  state={{
                    singleSubscriptionOrder: subscriptionOrderByReseller,
                  }}
                  className="text-white font-semibold"
                >
                  Go to Payment
                </Link>
              </div>
            )}
        </div>
      ) : (
        <>
          {/* Sidebar */}
          <Sidebar
            selectedSection={selectedSection}
            setSelectedSection={setSelectedSection}
            menuItems={ userInfo?.subscription === 'standard' ? menuItems : [...menuItems, ...menuItems2] }
            toggleSidebar={toggleSidebar}
            isOpen={isSidebarOpen}
          />

          {/* Main content */}
          <div className="flex-1 overflow-y-auto">
            {/* Toggle Button for Mobile View */}
            <div className="flex items-center justify-center w-full bg-blue-600 p-4">
              <button
                onClick={toggleSidebar}
                className="inline-flex lg:hidden text-white mr-4"
              >
                <FiMenu size={24} />
              </button>
              <h1 className="text-sm md:text-3xl text-white font-semibold">
                Reseller Dashboard
              </h1>
            </div>

            {/* Conditionally render based on selected section */}
            {selectedSection === "dashboard" && (
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 mt-6">
                {dashboard?.length > 0 &&
                  dashboard.map((item, index) => (
                    <Card key={index} title={item.title} count={item.count} />
                  ))}
              </div>
            )}
            {selectedSection === "profile" && (
              <ProfileContent role="reseller" />
            )}
            {
              selectedSection === "orders" && (<ResellerOrders />)
            }
            {
              selectedSection === "store-orders" && (<OrderFromResellerStore />)
            }
            {selectedSection === "video-course" && <VideoCourses />}
            {selectedSection === "payment-settings" && (
              <PaymentSettings
                createWithdrawalMethod={createWithdrawalMethodByReseller}
                getWithdrawalMethods={getWithdrawalMethodsByReseller}
                deleteWithdrawalMethod={deleteWithdrawalMethodByReseller}
              />
            )}
            {selectedSection === "withdraw" && <Withdraw from="reseller" />}
            {selectedSection === "my-store" && <StoreDetails />}
            {selectedSection === "package" && <SubscriptionDetails />}
            {selectedSection === "product-request" && (
              <ProductRequestList from="reseller" />
            )}
            {selectedSection === "referrals" && <ResellerReferral />}
            { selectedSection === 'order-bonus' && <OrderBonus /> }
            { selectedSection === 'change-password' && <ChangePassword role="reseller"/> }
          </div>
        </>
      )}
    </div>
  );
};

export default ResellerDashboard;
