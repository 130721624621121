import React, {  useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FaEdit, FaEye, FaPlus } from 'react-icons/fa';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { getAllUsersByAdmin, updateUser,} from '../../features/user/userSlice';
import ShowCreateUserFormModal from './ShowCreateUserFormModal';
import ShowUserDetails from './ShowUserDetails';
import LoaderComponent from '../LoaderComponent';
import { toast } from 'react-toastify';
// import { debounce } from 'lodash';
import Search from "../Search";
import Pagination from '../Pagination';

const UsersList = () => {
  const { users,pagination, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [selectedUser, setSelectedUser] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);

    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const token = JSON.parse(localStorage.getItem("access_token"));
    dispatch(getAllUsersByAdmin({page,limit:20,search:"",token}));
  }, [dispatch, page]);

  

  const handleEdit = (user) => {
    setSelectedUser(user);
    setIsEditing(true);
  };

  const handleViewDetails = (user) => {
    setSelectedUser(user);
    setShowDetails(true);
  };

  const handleUpdateUser = (values) => {
    dispatch(updateUser({
      id: selectedUser._id,
      userData: values,
      token: JSON.parse(localStorage.getItem("access_token"))
    }));
    setIsEditing(false);
    alert('User updated successfully!');
  };

  // handle copy all phone numbers
  const handleCopyPhones = () => {
    const phones = users
      .filter((user) => user.phone) 
      .map((user) => user.phone) 
      .join(", "); 

    if (phones) {
      navigator.clipboard.writeText(phones)
        .then(() => toast.success("Phone numbers copied to clipboard!"))
        .catch((err) => alert("Failed to copy: " + err));
    } else {
      toast.error("No phone numbers to copy.");
    }
  };

  // handle page change 
  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

   // handle search
  
    // Debounced search handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const debouncedSearch = useCallback(
    //   debounce((query) => {
    //     // If the search query is empty, reset the search filter
    //     if (!query.trim()) {
    //       dispatch(getAllUsersByAdmin({ page: 1, limit: 20, search: "" }));
    //       setPage(1);
    //     } else {
    //       // Otherwise, update the search query
    //       dispatch(getAllUsersByAdmin({ page, search: query }));
    //     }
    //   }, 500),
    //   [dispatch]
    // );
  
    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
      // debouncedSearch(e.target.value);
    };
  
    const handleSearchSubmit = (e) => {
      e.preventDefault();
      searchQuery && dispatch(getAllUsersByAdmin({ search: searchQuery }));
    };

  

  if (loading) return <LoaderComponent />;

  return (
    <div className="container mx-auto p-4 mb-8 sm:p-6 lg:p-8 h-screen">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
        <h2 className="text-xl sm:text-2xl font-semibold">Users List</h2>
        <button
          onClick={() => setShowCreateForm(true)}
          className="mt-4 sm:mt-0 bg-blue-500 text-white py-2 px-4 rounded shadow-md hover:bg-blue-600 transition-all flex items-center"
        >
          <FaPlus className="mr-2" /> Create User
        </button>
        <button
          onClick={handleCopyPhones}
          className="mt-4 sm:mt-0 bg-blue-500 text-white py-2 px-4 rounded shadow-md hover:bg-blue-600 transition-all flex items-center"
        >
          <FaPlus className="mr-2" /> Click Here To Copy All Phone Numbers
        </button>
      </div>

      {/* Search */}
      <Search
        handleSearchSubmit={handleSearchSubmit}
        handleSearchChange={handleSearchChange}
        searchQuery={searchQuery}
        placeholder={"Search by user name"}
      />

      <div className="shadow-lg rounded-lg overflow-x-auto">
        {users.length > 0 ? (
          <table className="min-w-full bg-white text-left text-sm">
            <thead className="bg-gray-200 text-gray-700">
              <tr>
                <th className="py-2 px-3 sm:px-4">SL NO</th>
                <th className="py-2 px-3 sm:px-4">Name</th>
                <th className="py-2 px-3 sm:px-4">Email</th>
                <th className="py-2 px-3 sm:px-4">Mobile Number</th>
                <th className="py-2 px-3 sm:px-4">Affiliate</th>
                <th className='py-2 px-3 sm:px-4'>Join At</th>
                <th className="py-2 px-3 sm:px-4">Status</th>
                <th className="py-2 px-3 sm:px-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={user._id} className="border-t hover:bg-gray-100 transition">
                  <td className="py-2 px-3 sm:px-4">{index + 1}</td>
                  <td className="py-2 px-3 sm:px-4">{user.name}</td>
                  <td className="py-2 px-3 sm:px-4 truncate max-w-xs">{user.email}</td>
                  <td className="py-2 px-3 sm:px-4">{user.phone || "-"}</td>
                  <td className="py-2 px-3 sm:px-4">{user.affiliate ? "Yes" : "No"}</td>
                  <td className="py-2 px-3 sm:px-4">{new Date(user.createdAt).toLocaleString()}</td>
                  <td className="py-2 px-3 sm:px-4">{user.status || "-"}</td>
                  <td className="py-2 px-3 sm:px-4 flex gap-2 justify-center">
                    <button
                      onClick={() => handleViewDetails(user)}
                      className="bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-600 transition-all"
                    >
                      <FaEye />
                    </button>
                    <button
                      onClick={() => handleEdit(user)}
                      className="bg-green-500 text-white p-2 rounded-full shadow-md hover:bg-green-600 transition-all"
                    >
                      <FaEdit />
                    </button>
                    
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center py-4">No users found.</p>
        )}
      </div>

      {/* Pagination */}
      <Pagination
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onPageChange={handlePageChange}
      />

      {/* Modal for User Details */}
      {showDetails && selectedUser && (
        <ShowUserDetails selectedUser={selectedUser} setShowDetails={setShowDetails} />
      )}

      {/* Modal for Edit User */}
      {isEditing && selectedUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-1/2 lg:w-1/3">
            <h3 className="text-xl font-semibold mb-4">Edit User</h3>

            <Formik
              initialValues={{
                name: selectedUser.name,
                email: selectedUser.email,
                role: selectedUser.role,
                status: selectedUser.status
              }}
              validationSchema={Yup.object({
                name: Yup.string().required('Name is required'),
                email: Yup.string().email('Invalid email address').required('Email is required'),
                role: Yup.string().required('Role is required'),
                status: Yup.string().required('Status is required'),
              })}
              onSubmit={(values) => {
                handleUpdateUser(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form>
                  <div className="mb-4">
                    <label className="block text-gray-700">Name</label>
                    <Field
                      name="name"
                      type="text"
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                    <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
                  </div>

                  <div className="mb-4">
                    <label className="block text-gray-700">Email</label>
                    <Field
                      name="email"
                      type="email"
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                    <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
                  </div>

                  <div className="mb-4">
                    <label className="block text-gray-700">Role</label>
                    <Field
                      as="select"
                      name="role"
                      className="w-full p-2 border border-gray-300 rounded"
                    >
                      <option value="">Select a role</option>
                      <option value="admin">Admin</option>
                      <option value="user">User</option>
                      <option value="editor">Editor</option>
                    </Field>
                    <ErrorMessage name="role" component="div" className="text-red-500 text-sm" />
                  </div>

                  <div className="mb-4">
                    <label className="block text-gray-700">Status</label>
                    <Field
                      as="select"
                      name="status"
                      className="w-full p-2 border border-gray-300 rounded"
                    >
                      <option value="">Select a status</option>
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Field>
                    <ErrorMessage name="status" component="div" className="text-red-500 text-sm" />
                  </div>

                  <div className="flex justify-end gap-2">
                    <button
                      type="button"
                      onClick={() => setIsEditing(false)}
                      className="bg-gray-400 text-white py-2 px-4 rounded hover:bg-gray-500 transition-all"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition-all"
                    >
                      Save Changes
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}

      {/* Modal for Create User */}
      {showCreateForm && (
        <ShowCreateUserFormModal setShowCreateForm={setShowCreateForm} />
      )}
    </div>
  );
};

export default UsersList;


