import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaTrash, FaPlus, FaEye } from 'react-icons/fa';
import { getServiceCharges, deleteServiceCharge } from '../../features/serviceCharge/serviceChargeSlice';
import ServiceChargeForm from './ServiceChargeForm';
import LoaderComponent from '../LoaderComponent';

const ServiceChargeList = () => {
  const dispatch = useDispatch();
  const { serviceCharges, loading, error } = useSelector((state) => state.serviceCharge);
  const [showForm, setShowForm] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedServiceCharge, setSelectedServiceCharge] = useState(null);

  useEffect(() => {
    dispatch(getServiceCharges({ token: JSON.parse(localStorage.getItem("access_token")) }));
  }, [dispatch]);

  const handleDeleteServiceCharge = (id) => {
    if (window.confirm("Are you sure you want to delete this service charge?")) {
      dispatch(deleteServiceCharge({ serviceChargeId: id, token: JSON.parse(localStorage.getItem("access_token")) }));
    }
  };

  const handleEditServiceCharge = (serviceCharge) => {
    setIsEditing(true);
    setSelectedServiceCharge(serviceCharge)
  };

  const toggleForm = () => setShowForm(!showForm);

  if (loading) return <LoaderComponent message="Loading service charges..." />;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">Service Charges</h2>
        <button 
          onClick={toggleForm}
          className="flex items-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
        >
          <FaPlus className="mr-2" /> Add Service Charge
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg">
          <thead>
            <tr className="bg-gray-100 text-gray-700">
              <th className="px-6 py-3 text-left">SL No</th>
              <th className="py-3 px-6 font-semibold text-left border-b">Area Name</th>
              <th className="py-3 px-6 font-semibold text-left border-b">Service Charge</th>
              <th className="py-3 px-6 font-semibold text-left border-b">Actions</th>
            </tr>
          </thead>
          <tbody>
            {serviceCharges && serviceCharges.length > 0 ? (
              serviceCharges.map((charge, index) => (
                <tr key={charge._id} className="hover:bg-gray-50">
                  <td className="py-3 px-6 border-b">{index + 1}</td>
                  <td className="py-3 px-6 border-b">{charge.name}</td>
                  <td className="py-3 px-6 border-b">{charge.amount}</td>
                  <td className="py-3 px-6 border-b">
                    <button onClick={() => handleDeleteServiceCharge(charge._id)} className="px-4 py-2 text-sm text-white bg-red-500 rounded hover:bg-red-600 mr-1">
                      <FaTrash />
                    </button>
                    <button onClick={() => handleEditServiceCharge(charge)} className="px-4 py-2 text-sm text-white bg-blue-500 rounded hover:bg-blue-600">
                      <FaEye />
                    </button>
                  </td>
                  
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className="text-center py-6 text-gray-500">No service charges found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {showForm && <ServiceChargeForm setShowForm={setShowForm} />}
      {isEditing && <ServiceChargeForm setShowForm={setIsEditing} initialValue={selectedServiceCharge}  mode="edit" />}
    </div>
  );
};

export default ServiceChargeList;
