import React, {  useEffect, useState } from 'react';
import { FaEdit, FaEye, FaPlus } from 'react-icons/fa';
import ShowExecutiveFormModal from './ShowExecutiveFormModal';
import EditExecutiveFormModal from './EditExecutiveFormModal';
import ShowExecutiveDetailsModal from './ShowExecutiveDetailsModal';
import { getExecutives } from '../../features/executive/executiveSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoaderComponent from '../LoaderComponent';
// import { debounce } from "lodash";
import Search from "../Search";

const ExecutiveList = () => {

  const {executives, loading} = useSelector((state) => state.executive);
  const [selectedExecutive, setSelectedExecutive] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);

    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getExecutives({ page, limit: 20, search: "" }));
  }, [dispatch, page, searchQuery]);

  // const handleDelete = (executiveId) => {
  //   if (window.confirm('Are you sure you want to delete this executive?')) {
  //     dispatch(deleteExecutive(executiveId));
  //     alert('Executive deleted successfully!');
  //   }
  // };

  const handleEdit = (executive) => {
    setSelectedExecutive(executive);
    setIsEditing(true);
  };

  const handleViewDetails = (executive) => {
    setSelectedExecutive(executive);
    setShowDetails(true);
  };

  const closeDetailsModal = () => {
    setShowDetails(false);
    setSelectedExecutive(null);
  }
  const closeCreateModal = () => {
    setShowCreateForm(false);
  }

  const closeEditModal = () => {
    setIsEditing(false);
    setSelectedExecutive(null);
  }

   // handle search
  
    // Debounced search handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const debouncedSearch = useCallback(
    //   debounce((query) => {
    //     // If the search query is empty, reset the search filter
    //     if (!query.trim()) {
    //       dispatch(getExecutives({ page: 1, limit: 20, search: "" }));
    //       setPage(1);
    //     } else {
    //       // Otherwise, update the search query
    //       dispatch(getExecutives({ page, limit: 20, search: query }));
    //     }
    //   }, 500),
    //   [dispatch]
    // );
  
    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
      // debouncedSearch(e.target.value);
    };
  
    const handleSearchSubmit = (e) => {
      e.preventDefault();
      if(searchQuery) {
        dispatch(getExecutives({ page: 1, limit: 20, search: searchQuery }));
        setPage(1);
      } else {
        dispatch(getExecutives({ page, limit: 20, search: "" }));
      }
    };

  if (loading) return <LoaderComponent message="Loading executives..." />
  
  return (
    <div className="container mx-auto p-6 mb-8 h-screen">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Executive List</h2>
        <button
          onClick={() => setShowCreateForm(true)}
          className="bg-blue-500 text-white p-2 rounded shadow-md hover:bg-blue-600 transition-all flex items-center"
        >
          <FaPlus className="mr-2" /> Add Executive
        </button>
      </div>

    {/* Search */}
    <Search
        handleSearchSubmit={handleSearchSubmit}
        handleSearchChange={handleSearchChange}
        searchQuery={searchQuery}
        placeholder={"Search by stuffId"}
      />

      {
        executives &&  executives.length > 0 ? (
          <div className="shadow-lg rounded-lg overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-2 px-4">Sl NO</th>
              <th className="py-2 px-4">Stuff ID</th>
              <th className="py-2 px-4">Name</th>
              <th className="py-2 px-4">Role</th>
              <th className="py-2 px-4">Contact</th>
              <th className="py-2 px-4">Join At</th>
              <th className="py-2 px-4">Status</th>
              <th className="py-2 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {executives.map((executive,index) => (
              <tr key={executive._id} className="border-t">
                <td className="py-2 px-4">{index+1}</td>
                <td className="py-2 px-4">{executive.stuffId || '-'}</td>
                <td className="py-2 px-4">{executive.name}</td>
                <td className="py-2 px-4">{executive.role}</td>
                <td className="py-2 px-4">
                  <div>{executive.contact.phone}</div>
                  <div>{executive.contact.email}</div>
                  <div>{executive.contact.address}</div>
                </td>
                <td className="py-2 px-4">{new Date(executive.createdAt).toLocaleDateString()}</td>
                <th className="py-2 px-4">{executive.status}</th>
                <td className="py-2 px-4 flex gap-2">
                  <button
                    onClick={() => handleViewDetails(executive)}
                    className="bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-600 transition-all"
                  >
                    <FaEye />
                  </button>
                  <button
                    onClick={() => handleEdit(executive)}
                    className="bg-green-500 text-white p-2 rounded-full shadow-md hover:bg-green-600 transition-all"
                  >
                    <FaEdit />
                  </button>
                  {/* <button
                    onClick={() => handleDelete(executive._id)}
                    className="bg-red-500 text-white p-2 rounded-full shadow-md hover:bg-red-600 transition-all"
                  >
                    <FaTrash />
                  </button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
        ):(
          <div className="text-center  text-2xl font-semibold">
          <h2> no Executive to show</h2>
          </div>
        )

      }

      {showDetails && selectedExecutive && <ShowExecutiveDetailsModal executive={selectedExecutive} closeDetailsModal={closeDetailsModal} />}
      {showCreateForm && <ShowExecutiveFormModal setShowCreateForm={setShowCreateForm} onClose={closeCreateModal} />}
      {isEditing && selectedExecutive && <EditExecutiveFormModal executiveData={selectedExecutive} isOpen={isEditing} onClose={closeEditModal} />}
    </div>
  );
};

export default ExecutiveList;
