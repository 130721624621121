import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  getSingleProduct,
  getRecommendationProducts,
} from "../features/products/productsSlice";
import { getReviewsByProductId } from "../features/reviews/reviewSlice";
import { getPhoneOrderDetails } from "../features/phoneOrderDetails/phoneOrderSlice";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../components/LoaderComponent";
import { addToCart } from "../utils/cartUtils";
import DescriptionsAndReviews from "../components/DescriptionAndReviews";
import RelatedProducts from "../components/RelatedProducts";
import ShareComponent from "../components/ShareComponent";
import SEO from "../components/Seo";
import { FaPhone, FaPhoneAlt, FaWhatsapp, FaYoutube } from "react-icons/fa";
import NotFound from "./NotFound";
import ImageSlider from "../components/ImageSlider";
// import ImoIcon from "../images/imo.png";

const SingleProduct = () => {
  const { singleProduct, recommendedProducts, loading } = useSelector(
    (state) => state.products
  );
  const { singleProductReviews } = useSelector((state) => state.review);
  const { isAuthenticated, userInfo } = useSelector((state) => state.auth);
  const { affiliate } = useSelector((state) => state.affiliate);
  const { phoneOrderDetails } = useSelector((state) => state.phoneOrderDetails);

  const [selectedColor, setSelectedColor] = useState({});
  const [selectedSize, setSelectedSize] = useState({});
  const [selectedVariant, setSelectedVariant] = useState({});
  const [quantity, setQuantity] = useState(1);

  const { productTitle } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (productTitle) {
      dispatch(getSingleProduct(productTitle));
      dispatch(getPhoneOrderDetails());
    }
  }, [productTitle, dispatch]);

  useEffect(() => {
    if (singleProduct) {
      if (singleProduct.colorSizeVariants?.length > 0) {
        setSelectedColor(singleProduct.colorSizeVariants[0]);
        setSelectedSize(singleProduct.colorSizeVariants[0]?.sizes[0]);
        setSelectedVariant({});
      } else if (singleProduct.sizeVariants?.length > 0) {
        setSelectedVariant(singleProduct.sizeVariants[0]);
        setSelectedColor({});
        setSelectedSize({});
      }

      const categoryIds = Array.isArray(singleProduct?.category)
        ? singleProduct?.category?.map((cat) => cat._id)
        : [];

      if (singleProduct?._id) {
        dispatch(
          getRecommendationProducts({
            category: categoryIds.join(","),
            productId: singleProduct?._id,
          })
        );
        dispatch(getReviewsByProductId(singleProduct?._id));
      }
    }
  }, [singleProduct, dispatch]);

  const queryParams = new URLSearchParams(location.search);
  const refCode = queryParams.get("ref");

  useEffect(() => {
    if (refCode) {
      localStorage.setItem("ref_code", refCode);
    }
  }, [refCode]);

  /**
   *
   * handle increment and decrement
   */
  const incrementQuantity = () => {
    if (
      (selectedSize?.stock && quantity < selectedSize.stock) ||
      (selectedVariant?.stock && quantity < selectedVariant.stock)
    ) {
      setQuantity(quantity + 1);
    }
  };
  const decrementQuantity = () => {
    if (quantity > 1) setQuantity(quantity - 1);
  };
  /**
   * handle add to cart
   */
  const handleAddToCart = () => {
    const productDetails =
      singleProduct.colorSizeVariants?.length > 0
        ? {
            _id: singleProduct._id,
            title: singleProduct.title,
            productCode: singleProduct.productCode,
            image: selectedColor.image,
            price: singleProduct.inOffer
              ? selectedSize.offerPrice
              : singleProduct.inCampaign?.isActive
              ? selectedSize.campaignPrice
              : selectedSize.price,
            color: selectedColor.colorName,
            size: selectedSize.sizeName,
            affiliateCommission: selectedColor.affiliateCommission,
          }
        : {
            _id: singleProduct._id,
            title: singleProduct.title,
            productCode: singleProduct.productCode,
            image: selectedVariant.image,
            price: singleProduct.inCampaign?.isActive
              ? selectedVariant.campaignPrice
              : singleProduct.inOffer
              ? selectedSize.offerPrice
              : selectedSize.price,
            size: selectedVariant.sizeName,
            affiliateCommission: selectedVariant.affiliateCommission,
          };

    addToCart(productDetails, Number(quantity), "shop"); // Adds the selected product configuration to the cart
  };

  /**
   * handle buy now
   */

  const handleBuyNow = () => {
    if (!isAuthenticated) {
      navigate("/login", { state: { from: `/shop/products/${productTitle}` } });
      return;
    }

    // create checkout data
    const products = [
      {
        id: singleProduct._id,
        title: singleProduct.title,
        productCode: singleProduct.productCode,
        image: selectedVariant.image || selectedColor.image,
        color: selectedColor.colorName || "",
        size: selectedSize.sizeName || selectedVariant.sizeName,
        quantity,
        price: singleProduct.inCampaign?.isActive
          ? selectedSize.campaignPrice || selectedVariant.campaignPrice
          : singleProduct.inOffer
          ? selectedSize.offerPrice || selectedVariant.offerPrice
          : selectedSize.price || selectedVariant.price,
        affiliateCommission:
          selectedSize.affiliateCommission ||
          selectedVariant.affiliateCommission,
        resellerPrice:
          selectedSize.resellerPrice || selectedVariant.resellerPrice || 0,
      },
    ];

    const refCode = localStorage.getItem("ref_code");
    const totalPrice =
      quantity *
      (singleProduct.inOffer
        ? selectedSize.offerPrice || selectedVariant.offerPrice
        : singleProduct.inCampaign?.isActive
        ? selectedSize.campaignPrice || selectedVariant.campaignPrice
        : selectedSize.price || selectedVariant.price);

    navigate("/checkout", {
      state: {
        products,
        totalPrice,
        affiliateCode: refCode,
        reseller: null,
        orderByReseller: null,
      },
    });
  };

  if (loading) return <LoaderComponent />;

  if (!singleProduct)
    return (
      <NotFound title="your requested product not found. the product may have been removed or is currently unavailable" />
    );

  return (
    <>
      <SEO
        title={"Paribarmart || " + singleProduct.title}
        url={`https://www.paribarmart.com/shop/products/${productTitle}`}
        description={singleProduct.description}
        image={
          selectedColor?.image
            ? selectedColor.image?.replace(
                "/upload/",
                "/upload/w_1200,h_630,c_fill/"
              )
            : selectedVariant?.image?.replace(
                "/upload/",
                "/upload/w_1200,h_630,c_fill/"
              )
        }
      />
      <div className="container mx-auto md:px-16 md:pr-16">
        <div className="bg-white p-6 flex flex-col md:flex-row rounded-lg">
          {/* Image Gallery */}
          <div className="flex-1 flex flex-col justify-end no-stretch">
            <div className="w-full h-auto md:w-[500px] md:h-[480px] md:mx-auto">
              <img
                src={
                  singleProduct && singleProduct.colorSizeVariants?.length > 0
                    ? selectedColor?.image
                    : selectedVariant?.image
                }
                alt={
                  selectedColor?.colorName || selectedVariant?.sizeName || ""
                }
                className="w-full h-full object-fit shadow-lg rounded-lg"
              />
            </div>

            {Object.keys(singleProduct).length > 0 && (
              <ImageSlider
                singleProduct={singleProduct}
                setSelectedColor={setSelectedColor}
                setSelectedVariant={setSelectedVariant}
              />
            )}

            {/* description condition reviews */}
            <div className="hidden md:block">
              <DescriptionsAndReviews
                reviews={singleProductReviews}
                description={singleProduct?.description || "no description"}
                condition={singleProduct?.condition || "no condition available"}
              />
            </div>
          </div>

          {/* Product Details */}
          <div className="flex-1 px-1 mt-8 md:mt-0">
            <h1 className="text-2xl md:text-3xl font-bold mb-2">
              {singleProduct.title}
            </h1>
            <p className="text-gray-800 font-semibold">
              Product Code: {singleProduct.productCode || "N/A"}
            </p>
            <p className="text-gray-800 font-semibold">
              Short description:{" "}
              {singleProduct.description?.substring(0, 60) || "N/A"}
            </p>
            <p className="text-gray-800 font-semibold">
              Brand: {singleProduct.brand || "N/A"}
            </p>
            <p className="text-gray-800 font-semibold">Quantity: {quantity}</p>

            {singleProduct.upcoming ? (
              <p className="text-gray-800 semibold">Coming Soon</p>
            ) : (
              <>
                {singleProduct &&
                  singleProduct.colorSizeVariants &&
                  selectedColor?.sizes?.length > 0 && (
                    <>
                      <p className="text-gray-800 font-semibold">
                        Color Name: {selectedColor.colorName}
                      </p>

                      <h3 className="font-semibold">Available Sizes:</h3>
                      <div className="flex flex-wrap mb-2">
                        {selectedColor.sizes?.map((size, index) => (
                          <button
                            key={index}
                            onClick={() => setSelectedSize(size)}
                            className={`mr-2 mb-2 px-3 py-1 border rounded transition duration-200 ${
                              selectedSize.sizeName === size.sizeName
                                ? "bg-blue-500 text-white"
                                : "bg-white text-gray-800 border-gray-300 hover:bg-gray-100"
                            }`}
                            disabled={size?.stock === 0}
                          >
                            {size.sizeName}
                            {/* {size?.stock > 0
                              ? `(${size?.stock} available)`
                              : "(Out of stock)"} */}
                          </button>
                        ))}
                      </div>
                    </>
                  )}

                {singleProduct &&
                  singleProduct.sizeVariants &&
                  singleProduct.sizeVariants?.length > 0 && (
                    <>
                      <h3 className="font-semibold">Available Sizes:</h3>
                      <div className="flex flex-wrap mb-4">
                        {singleProduct &&
                          singleProduct.sizeVariants.map((variant, index) => (
                            <button
                              key={index}
                              onClick={() => setSelectedVariant(variant)}
                              className={`mr-2 mb-2 px-3 py-1 border rounded transition duration-200 ${
                                selectedVariant?.sizeName === variant?.sizeName
                                  ? "bg-blue-500 text-white"
                                  : "bg-white text-gray-800 border-gray-300 hover:bg-gray-100"
                              }`}
                              disabled={variant?.stock === 0}
                            >
                              {variant?.sizeName}
                              {/* {variant?.stock > 0
                                ? `(${variant?.stock} available)`
                                : "(Out of stock)"} */}
                            </button>
                          ))}
                      </div>
                    </>
                  )}
              </>
            )}

            {singleProduct.upcoming ? (
              <p className="text-gray-800 semibold">Price: upcoming</p>
            ) : (
              <div className="flex items-center">
                <button
                  className={`font-semibold bg-pink-500 p-1 md:p-2 hover:cursor-not-allowed ${
                    (singleProduct.inOffer ||
                      singleProduct?.inCampaign?.isActive) &&
                    "line-through text-black-500"
                  }`}
                >
                  Price: TK.
                  {singleProduct && singleProduct.colorSizeVariants?.length > 0
                    ? selectedSize?.price?.toFixed(2)
                    : selectedVariant?.price?.toFixed(2)}
                </button>
                {singleProduct.inOffer && (
                  <button
                    className={`font-semibold bg-green-500 p-1 md:p-2 hover:cursor-not-allowed`}
                  >
                    Offer Price: TK.
                    {singleProduct &&
                    singleProduct.colorSizeVariants?.length > 0
                      ? selectedSize?.offerPrice?.toFixed(2)
                      : selectedVariant?.offerPrice?.toFixed(2)}
                  </button>
                )}
                {singleProduct.inCampaign?.isActive && (
                  <button
                    className={`font-semibold bg-green-500 p-1 md:p-2 hover:cursor-not-allowed`}
                  >
                    campaign Price: TK.
                    {singleProduct &&
                    singleProduct.colorSizeVariants?.length > 0
                      ? selectedSize?.campaignPrice?.toFixed(2)
                      : selectedVariant?.campaignPrice?.toFixed(2)}
                  </button>
                )}
              </div>
            )}

            {singleProduct?.videoUrl && (
              <div className="mt-4">
                <a
                  href={singleProduct?.videoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2"
                >
                  <span>youtube video:</span>
                  <FaYoutube className="text-4xl text-red-500" />
                </a>
              </div>
            )}

            <div className="flex items-center mb-2 mt-4">
              <button
                className="px-4 py-2 bg-gray-300 rounded"
                onClick={decrementQuantity}
                disabled={quantity === 1}
              >
                -
              </button>
              <span className="px-4">{quantity}</span>
              <button
                className="px-4 py-2 bg-gray-300 rounded"
                onClick={incrementQuantity}
                disabled={
                  quantity === selectedSize?.stock ||
                  quantity === selectedVariant?.stock
                }
              >
                +
              </button>
              <p className="ml-4 bg-blue-600 p-2 rounded text-white">
                {selectedSize?.stock === 0 || selectedVariant?.stock === 0
                  ? "(Out of Stock)"
                  : `(Available: ${
                      selectedSize?.stock || selectedVariant?.stock
                    })`}
              </p>
            </div>

            <div className="flex items-center">
              <button
                onClick={handleAddToCart}
                className={`w-1/2 bg-green-500 text-black p-2 hover:bg-green-600 transition duration-200 ${
                  singleProduct && singleProduct.colorSizeVariants?.length > 0
                    ? selectedSize?.stock === 0
                    : selectedVariant?.stock === 0
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={
                  (singleProduct && singleProduct.colorSizeVariants?.length > 0
                    ? selectedSize?.stock === 0
                    : selectedVariant?.stock === 0) ||
                  singleProduct.upcoming ||
                  userInfo?.role === "reseller"
                }
              >
                {singleProduct && singleProduct.colorSizeVariants?.length > 0
                  ? selectedSize?.stock > 0
                    ? "Add to Cart"
                    : "Out of Stock"
                  : selectedVariant?.stock > 0
                  ? "Add to Cart"
                  : "Out of Stock"}
              </button>
              <button
                onClick={handleBuyNow}
                className={`w-1/2 bg-pink-500 text-black p-2 hover:bg-pink-600 transition duration-200 ${
                  singleProduct.colorSizeVariants
                    ? selectedSize?.stock === 0
                    : selectedVariant?.stock === 0
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={
                  (singleProduct.colorSizeVariants
                    ? selectedSize?.stock === 0
                    : selectedVariant?.stock === 0) ||
                  singleProduct.upcoming ||
                  userInfo?.role === "reseller"
                }
              >
                {singleProduct && singleProduct.colorSizeVariants?.length > 0
                  ? selectedSize?.stock > 0
                    ? "Buy Now"
                    : "Out of Stock"
                  : selectedVariant?.stock > 0
                  ? "Buy Now"
                  : "Out of Stock"}
              </button>
            </div>

            {/* description condition reviews */}
            <div className="block md:hidden">
              <DescriptionsAndReviews
                reviews={singleProductReviews}
                description={singleProduct?.description || "no description"}
                condition={singleProduct?.condition || "no condition available"}
              />
            </div>

            <ShareComponent
              url={location.pathname}
              title={singleProduct.title}
              description={singleProduct.description}
            />
            {phoneOrderDetails.length > 0 && (
              <div className="bg-white p-6 rounded-lg shadow-md text-center">
                <p className="text-lg font-bold mb-4">
                  ফোন অর্ডার করতে কল করুন
                </p>

                <div className="flex flex-wrap justify-center gap-4">
                  <div className="flex items-center text-red-500 font-bold">
                    <FaPhone className="mr-2" /> {phoneOrderDetails[0]?.phone}
                  </div>
                  <div className="flex items-center text-red-500 font-bold">
                    <FaPhoneAlt className="mr-2" />{" "}
                    {phoneOrderDetails[0]?.mobile}
                  </div>
                </div>

                <div className="flex flex-wrap justify-center gap-4 mt-4">
                  <div className="flex items-center text-green-500 font-bold">
                    <FaWhatsapp className="mr-2" />{" "}
                    {phoneOrderDetails[0]?.whatsapp}
                  </div>
                  <div className="flex items-center text-blue-500 font-bold">
                    <img
                      src="/imo.png"
                      style={{ width: "20px", height: "20px" }}
                      alt="imo"
                      className="mr-2"
                    />{" "}
                    {phoneOrderDetails[0]?.imo}
                  </div>
                </div>
              </div>
            )}
            {userInfo?.affiliate && affiliate?.status === "active" && (
              <div className="bg-white p-6 rounded-lg shadow-md text-center">
                <h2 className="text-lg font-bold mb-4">
                  Affiliate Link For This Product:
                </h2>
                <a
                  href={`https://www.paribarmart.com/shop/products/${productTitle}?ref=${affiliate?.ref_code}`}
                  className="text-blue-600 hover:underline"
                >
                  {`https://www.paribarmart.com/shop/products/${productTitle}?ref=${affiliate?.ref_code}`}
                </a>
              </div>
            )}
          </div>
        </div>

        <RelatedProducts relatedProducts={recommendedProducts} />
      </div>
    </>
  );
};

export default SingleProduct;

/*

// Color Selector for `colorSizeVariants` with images 
{singleProduct && singleProduct.colorSizeVariants.length > 0 && (
  <div className="flex mx-auto">
    {singleProduct?.colorSizeVariants.map((color) => (
      <button
        key={color.colorName}
        onClick={() => {
          setSelectedColor(color);
          setSelectedSize(color.sizes[0]); // Reset size to the first available
        }}
      >
        <img
          src={color.image}
          alt={color.colorName}
          className={`w-16 h-16 mt-4 md:w-16 md:h-16 object-fit border-gray-300 transition duration-200`}
        />
      </button>
    ))}
  </div>
)}
{/* Size Selector for `sizeVariants` with images 
{singleProduct && singleProduct.sizeVariants.length > 0 && (
  <div className="flex mx-auto ">
    {singleProduct?.sizeVariants.map((size) => (
      <button
        key={size.sizeName}
        onClick={() => setSelectedVariant(size)}
      >
        <img
          src={size.image}
          alt={size.sizeName}
          className={`w-16 h-16 mt-4 md:w-16 md:h-16 object-fit border-gray-300 transition duration-200`}
        />
      </button>
    ))}
  </div>
)}

*/
