import React from 'react';
import './HeadlineTicker.css'; 

const HeadlineTicker = ({headline}) => {
  
  return (
    <div className="ticker-container bg-green-900 py-2"> 
      <div className="ticker-wrapper">
        <div className="ticker-content text-white text-lg tracking-widest">
          {headline && headline.headline ? headline.headline : ""} 
        </div>
      </div>
    </div>
  );
};

export default HeadlineTicker;