import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";
import { toast } from "react-toastify";

// forgot password --user --admin
export const forgotPassword = createAsyncThunk(
  "forgotPassword/forgotPassword",
  async (data) => {
    const response = await api.post("/auth/forgot-password", data);
    if( response.status === 200) {
      toast.success("password reset link sent successfully");
  }
    return response.data;
  }
);

// reset password --user --admin
export const resetPassword = createAsyncThunk(
  "forgotPassword/resetPassword",
  async (data) => {
    const response = await api.post("/auth/reset-password", data);
    if( response.status === 200) {
      toast.success("password reset successfully"); 
  }
    return response.data;
});

// forgot password --reseller
export const forgotPasswordReseller = createAsyncThunk(
  "forgotPassword/forgotPasswordReseller",
  async (data) => {
    const response = await api.post("/auth/reseller/forgot-password",data);
    return response.data;
  }
  );

  // reset password --reseller
export const resetPasswordReseller = createAsyncThunk(
  "forgotPassword/resetPasswordReseller",
  async (data) => {
    const response = await api.post("/auth/reseller/reset-password",data);
    if( response.status === 200) {
      toast.success("password reset successfully");
      }
    return response.data;    
  }
  );

const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState: {
    status: null,
    loading: false,
    error: null,
    message: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(forgotPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.status = "success";
        state.loading = false;
        state.message = action.payload.message;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = "success";
        state.loading = false;
        state.message = action.payload.message;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(forgotPasswordReseller.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(forgotPasswordReseller.fulfilled, (state, action) => {
        state.status = "success";
        state.loading = false;
        state.message = action.payload.message;
      })
      .addCase(forgotPasswordReseller.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(resetPasswordReseller.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(resetPasswordReseller.fulfilled, (state, action) => {
        state.status = "success";
        state.loading = false;
        state.message = action.payload.message;
      })
      .addCase(resetPasswordReseller.rejected, (state, action) => {
        state.status = "failed";
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default forgotPasswordSlice.reducer;
