import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";
import {
  getAffiliates,
  updateAffiliate,
} from "../../features/affiliate/affiliateSlice";
import LoaderComponent from "../LoaderComponent";
// import { debounce } from "lodash";
import Search from "../Search";
import Pagination from "../Pagination";

const AffiliateList = () => {
  const dispatch = useDispatch();
  const { affiliates, pagination, loading, error } = useSelector(
    (state) => state.affiliate
  );

  const [selectedAffiliate, setSelectedAffiliate] = useState(null);
  const [showAffiliateDetails, setShowAffiliateDetails] = useState(false);

  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(getAffiliates({ page, limit: 20, search: "" }));
  }, [dispatch, page]);

  const handleStatusChange = (id, newStatus) => {
    dispatch(
      updateAffiliate({
        id,
        affiliateData: { status: newStatus },
        token: JSON.parse(localStorage.getItem("access_token")),
      })
    );
  };

  // const handleDeleteAffiliate = (id) => {
  //   if (window.confirm("Are you sure you want to delete this affiliate?")) {
  //     dispatch(deleteAffiliate({ id, token: JSON.parse(localStorage.getItem("access_token")) }));
  //   }
  // };

  const openAffiliateDetails = (affiliate) => {
    setSelectedAffiliate(affiliate);
    setShowAffiliateDetails(true);
  };

  const closeAffiliateDetails = () => {
    setShowAffiliateDetails(false);
    setSelectedAffiliate(null);
  };

  // handle search

  // Debounced search handler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debouncedSearch = useCallback(
  //   debounce((query) => {
  //     // If the search query is empty, reset the search filter
  //     if (!query.trim()) {
  //       dispatch(getAffiliates({ page: 1, limit: 20, search: "" }));
  //       setPage(1);
  //     } else {
  //       // Otherwise, update the search query
  //       dispatch(getAffiliates({ page, limit: 20, search: query }));
  //     }
  //   }, 4500),
  //   [dispatch]
  // );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    // debouncedSearch(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery) {
      dispatch(getAffiliates({ page: 1, limit: 20, search: searchQuery }));
      setPage(1);
    }else {
      dispatch(getAffiliates({ page, limit: 20, search: "" }));
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    // dispatch(getAffiliates({ page: newPage, limit: 20, search: searchQuery }));
  };

  if (loading) return <LoaderComponent message="Loading affiliates..." />;
  if (error) return <p>Error: {error}</p>;
  if (!affiliates || affiliates.length === 0)
    return <p>No affiliates found.</p>;

  return (
    <div className="container mx-auto px-4 py-8 mb-8 h-screen">
      <h2 className="text-2xl font-bold mb-4">Affiliate List</h2>

      {/* Search */}
      <Search
        handleSearchSubmit={handleSearchSubmit}
        handleSearchChange={handleSearchChange}
        searchQuery={searchQuery}
        placeholder={"Search by customer name"}
      />

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border border-gray-200 shadow-md rounded-lg">
          <thead>
            <tr className="bg-gray-100 text-gray-700">
              <th className="py-3 px-6 font-semibold text-left border-b">
                SL No
              </th>
              <th className="py-3 px-6 font-semibold text-left border-b">
                Name
              </th>
              <th className="py-3 px-6 font-semibold text-left border-b">
                Email
              </th>
              <th className="py-3 px-6 font-semibold text-left border-b">
                Phone
              </th>
              <th className="py-3 px-6 font-semibold text-left border-b">
                Referral Code
              </th>
              <th className="py-3 px-6 font-semibold text-left border-b">
                Social Links
              </th>
              <th className="py-3 px-6 font-semibold text-left border-b">
                Total Commission
              </th>
              <th className="py-3 px-6 font-semibold text-left border-b">
                Status
              </th>
              <th className="py-3 px-6 font-semibold text-left border-b">
                Joined On
              </th>
              <th className="py-3 px-6 font-semibold text-left border-b">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {affiliates.map((affiliate, index) => (
              <tr key={affiliate._id} className="hover:bg-gray-50">
                <td className="py-3 px-6 border-b">{index + 1}</td>
                <td className="py-3 px-6 border-b">{affiliate.userId.name}</td>
                <td className="py-3 px-6 border-b">{affiliate.userId.email}</td>
                <td className="py-3 px-6 border-b">{affiliate.userId.phone}</td>
                <td className="py-3 px-6 border-b">{affiliate.ref_code}</td>
                <td className="py-3 px-6 border-b">
                  <ul className="space-y-1">
                    {affiliate.social_links.facebook && (
                      <li>
                        <a
                          href={affiliate.social_links.facebook}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-600 hover:underline"
                        >
                          Facebook
                        </a>
                      </li>
                    )}
                    {affiliate.social_links.instagram && (
                      <li>
                        <a
                          href={affiliate.social_links.instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-pink-500 hover:underline"
                        >
                          Instagram
                        </a>
                      </li>
                    )}
                    {affiliate.social_links.twitter && (
                      <li>
                        <a
                          href={affiliate.social_links.twitter}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-400 hover:underline"
                        >
                          Twitter
                        </a>
                      </li>
                    )}
                    {affiliate.social_links.youtube && (
                      <li>
                        <a
                          href={affiliate.social_links.youtube}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-red-600 hover:underline"
                        >
                          YouTube
                        </a>
                      </li>
                    )}
                  </ul>
                </td>
                <td className="py-3 px-6 border-b">
                  TK. {affiliate.total_commission.toFixed(2)}
                </td>
                <td className="py-3 px-6 border-b">
                  <select
                    value={affiliate.status}
                    onChange={(e) =>
                      handleStatusChange(affiliate._id, e.target.value)
                    }
                    className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </select>
                </td>
                <td className="py-3 px-6 border-b">
                  {new Date(affiliate.createdAt).toLocaleDateString()}
                </td>
                <td className="py-3 px-6 border-b flex gap-2">
                  <button
                    onClick={() => openAffiliateDetails(affiliate)}
                    className="px-4 py-2 text-sm text-white bg-blue-500 rounded hover:bg-blue-600 mr-2"
                  >
                    <FaEye />
                  </button>
                  {/* <button onClick={() => handleDeleteAffiliate(affiliate._id)} className="px-4 py-2 text-sm text-white bg-red-500 rounded hover:bg-red-600">
                    <FaTrash />
                  </button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Affiliate Details Modal */}
      {showAffiliateDetails && selectedAffiliate && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 h-screen">
          <div className=" bg-white p-8 rounded-lg shadow-xl w-full max-h-[90vh] max-w-md mx-auto relative transform scale-105 transition-all overflow-auto">
          <button onClick={closeAffiliateDetails} className=" absolute top-0 right-0 bg-red-500  p-2 text-500 hover:text-white hover:bg-red-700">
                    close
                </button>
            {/* Profile Picture Section */}
            <div className="flex flex-col items-center">
              <img
                src={selectedAffiliate.userId?.profilePicture || "/profile.webp"}
                alt="Profile"
                className="w-28 h-28 rounded-full border-4 border-blue-600 shadow-md mb-6"
              />
              <h3 className="text-2xl font-bold text-gray-800 mb-2">
                {selectedAffiliate.userId?.name}
              </h3>
              <p className="text-sm text-gray-500 italic mb-6">
                Joined on{" "}
                {new Date(selectedAffiliate.createdAt).toLocaleDateString()}
              </p>
            </div>

            <div className="space-y-4 text-gray-700">
              <div className="flex justify-between">
                <span className="font-medium">Name:</span>
                <span className="text-gray-500">{selectedAffiliate.userId?.name}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Email:</span>
                <span className="text-gray-500">{selectedAffiliate.userId?.email}</span>
              </div>
              
              <div className="flex justify-between">
                <span className="font-medium">Address:</span>
                <span className="text-gray-500">{selectedAffiliate.userId?.address || "N/A"}</span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Total Commission Earned:</span>
                <span className="text-gray-500">TK. {selectedAffiliate.total_commission || "N/A"}</span>
              </div>

              <div className="flex justify-between">
                <span className="font-medium">Status:</span>
                <span
                  className={`text-sm font-bold ${
                    selectedAffiliate.status === "active"
                      ? "text-green-600"
                      : "text-red-600"
                  }`}
                >
                  {selectedAffiliate.status}
                </span>
              </div>
              <div className="flex justify-between">
                <span className="font-medium">Phone:</span>
                <span className="text-gray-500">
                  {selectedAffiliate.userId?.phone || "N/A"}
                </span>
              </div>

              <div className="space-y-4 text-gray-700">
          {selectedAffiliate.voterId ? (
            <img
              src={selectedAffiliate.voterId}
              alt="Profile"
              className="w-28 h-28 rounded-full border-4 border-blue-600 shadow-md mb-6"
            />
          ) : (
            <p>No Voter Id Card Uploaded yet.</p>
          )}
        </div>

            </div>

            <div className="mt-4">
              <button
                onClick={closeAffiliateDetails}
                className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <Pagination
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default AffiliateList;
