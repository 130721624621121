import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api/api';
import { toast } from 'react-toastify';

// Create offers by category
export const createOfferByCategory = createAsyncThunk(
  'offer/createOfferByCategory',
  async (data) => {
    const response = await api.post('/offers/category', data);
    if(response.status === 200){
      toast.success("offer campaign created successfully");
    }
    return response.data;
  }
);

// Create offers for all products
export const createOfferAllProducts = createAsyncThunk(
  'offer/createOfferAllProducts',
  async () => {
    const response = await api.post('/offers/all');
    if(response.status === 200){
      toast.success("offer campaign created successfully");
    }
    return response.data;
  }
);

// Create offers for specific products
export const createOfferSpecificProducts = createAsyncThunk(
  'offer/createOfferSpecificProducts',
  async (data) => {
    const response = await api.post('/offers/specific', data);
    if(response.status === 200){
      toast.success("offer campaign created successfully");
    }
    return response.data;
  }
);

// Stop offer
export const stopOffer = createAsyncThunk(
  'offer/stopOffer',
  async () => {
    const response = await api.post('/offers/stop');
    if(response.status === 200){
      toast.success("offer campaign stopped successfully");
    }
    return response.data;
  }
);

const offerSlice = createSlice({
  name: 'offer',
  initialState: {
    status: 'idle',
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createOfferByCategory.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createOfferAllProducts.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createOfferSpecificProducts.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(stopOffer.fulfilled, (state) => {
        state.status = 'succeeded';
      })
      .addCase(createOfferByCategory.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default offerSlice.reducer;
