import React from "react";

const HeroSection = ({storeDetails}) => {
  return (
    <div className="flex items-center p-5 bg-gradient-to-r from-blue-400 to-blue-600 rounded-lg relative overflow-hidden mt-2">
      {/* Store Card */}
      <div className="bg-white p-4 w-48 rounded-lg shadow-lg text-center">
        <div className="mb-2">
          <img
            src="https://img.icons8.com/ios-filled/50/000000/shop.png"
            alt="Store Icon"
            className="mx-auto"
          />
        </div>
        <h2 className="text-lg font-semibold text-gray-800"> PARIBAR MART || STORE </h2>
        <p className="text-sm text-gray-600">STORE ID: {storeDetails.storeId}</p>
        <p className="text-sm text-gray-600">98% Active</p>
      </div>

      {/* Mascot Image */}
      <div className="flex-grow flex justify-end">
        <img
          src="https://img.icons8.com/color/96/000000/peacock.png"
          alt="Mascot"
          className="absolute right-5 bottom-0 w-24 opacity-80"
        />
      </div>
    </div>
  );
};

export default HeroSection;
