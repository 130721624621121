import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { fetchCategories } from "../features/category/categorySlice";
import LoaderComponent from "./LoaderComponent";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CategoryMenu = ({ type = "shop" }) => {
  const { categories, loading } = useSelector((state) => state.category);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  if (loading) return <LoaderComponent message="Loading categories..." />;

  const sliderSettings = {
    infinite: categories.length > 1,
    speed: 500,
    slidesToShow: Math.min(8, categories.length), // Show 6 items at a time
    slidesToScroll: 2, // Scroll 2 items at a time
    responsive: [
      {
        breakpoint: 1024, // For tablets and below
        settings: {
          slidesToShow: Math.min(4, categories.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For mobile devices
        settings: {
          slidesToShow: Math.min(4, categories.length),
          slidesToScroll: 1,
        },
      },
    ],
    autoplay: categories.length > 1,
    autoplaySpeed: 3000,
    arrows: categories.length > 1, // Next/Prev arrows
  };

  const handleSubcategoryClick = (categoryName, subCategoryName) => {
    const query = `category=${categoryName}&subCategory=${subCategoryName}`;
    if (type === "shop") {
      navigate(`/shop/products/categories?${query}`);
    } else {
      navigate(`/reseller/products/categories?${query}`);
    }
  };

  return (
    <div className="py-8 px-4 md:px-8 lg:px-16">
      {/* Categories Dropdown */}
      <div className="relative mb-4">
        <button
          onClick={() => setShowDropdown((prev) => !prev)}
          className="flex items-center px-4 py-2 bg-sky-500 text-white font-semibold rounded-md shadow hover:bg-sky-600 transition"
        >
          Categories
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 ml-2"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </button>
        {showDropdown && (
          <div className="absolute left-0 mt-2 w-64 bg-white shadow-lg rounded-md z-50">
            <ul className="divide-y divide-gray-200">
              {categories.map((category) => (
                <li
                  key={category.name}
                  className="relative group px-4 py-2 hover:bg-gray-100 flex items-center justify-between"
                >
                  <span className="cursor-pointer font-medium text-gray-900 group-hover:text-sky-500">
                    {category.name}
                  </span>
                  {category.subcategories?.length > 0 && (
                    <>
                      <span className="text-gray-400 group-hover:text-sky-500">
                        ›
                      </span>
                      <div className="absolute top-0 left-full mt-0 hidden group-hover:block w-64 bg-white shadow-lg">
                        <ul className="divide-y divide-gray-200">
                          {category.subcategories.map((subCategory) => (
                            <li
                              key={subCategory._id}
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() =>
                                handleSubcategoryClick(
                                  category.name,
                                  subCategory.name
                                )
                              }
                            >
                              {subCategory.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      {/* <CategoryDropdown type={type} /> */}

      <Slider {...sliderSettings}>
        {categories &&
          categories.map((category, index) => (
            <div key={index} className="px-2">
              <Link
                to={
                  type === "shop"
                    ? `/shop/products/categories?category=${category.name}`
                    : `/reseller/products/categories?category=${category.name}`
                }
                className="group flex flex-col items-center text-center"
              >
                {/* Circular Image */}
                <div className="w-12 h-12 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-full bg-gray-200 mb-2 shadow-lg overflow-hidden">
                  {category.image && (
                    <img
                      src={category.image}
                      alt={category.name}
                      className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-110"
                    />
                  )}
                </div>
                {/* Category Title */}
                <span className="text-sm md:text-lg font-semibold text-gray-900 group-hover:text-sky-500 transition duration-300">
                  {category.name}
                </span>
              </Link>
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default CategoryMenu;

// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
// import { fetchCategories } from "../features/category/categorySlice";
// import LoaderComponent from "./LoaderComponent";

// const CategoryMenu = ({ type = "shop" }) => {
//   const { categories, loading } = useSelector((state) => state.category);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     dispatch(fetchCategories());
//   }, [dispatch]);

//   if (loading) return <LoaderComponent message="Loading categories..." />;

//   return (
//     <div className="py-8 px-4 md:px-8 lg:px-16 ">
//       <nav className="grid grid-cols-6 md:grid-cols-12 lg:grid-cols-10 xl:grid-cols-12 gap-2">
//         {categories &&
//           categories.map((category, index) => (
//             <Link
//               key={index}
//               to={
//                 type === "shop"
//                   ? `/shop/products/categories?category=${category.name}`
//                   : `/reseller/products/categories?category=${category.name}`
//               }
//               className="group flex flex-col items-center text-center"
//             >
//               {/* Circular Image */}
//               <div className="w-10 h-10 md:w-16 md:h-16 lg:w-20 lg:h-20 rounded  bg-gray-200 mb-2 shadow-lg">
//                 {category.image && (
//                   <img
//                     src={category.image}
//                     alt={category.name}
//                     className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-105"
//                   />
//                 )}
//               </div>
//               {/* Category Title */}
//               <span className="text-xs md:text-md text-gray-900">
//                 {category.name}
//               </span>
//             </Link>
//           ))}
//       </nav>
//     </div>
//   );
// };

// export default CategoryMenu;
