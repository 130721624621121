import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStoreByResellerId, removeProductFromResellerStore, updateResellerStore } from '../../features/resellerStore/resellerStoreSlice';
import LoaderComponent from '../LoaderComponent';
import { Link } from 'react-router-dom';
import { FaEye, FaTrash } from 'react-icons/fa';
import ProductDetails from '../admin/ProductDetails';
import Modal from '../common/Modal';
import Pagination from '../Pagination';

const StoreDetails = () => {

    const { store,pagination, loading } = useSelector((state) => state.resellerStore);
    const { userInfo } = useSelector((state) => state.auth);

  // Local state for editing store details
  const [isEditing, setIsEditing] = useState(false);
  const [storeDetails, setStoreDetails] = useState({ });
  const [selectedProduct,setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
    const [page,setPage] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    if(userInfo && userInfo._id) {
        const token = JSON.parse(localStorage.getItem("access_token"));
        dispatch(getStoreByResellerId({resellerId:userInfo._id,token,page,limit:10}));
    }
}, [dispatch, userInfo,page]);



useEffect(() => {
    if (store && store.storeId) {
      setStoreDetails({
        storeName: store.storeName,
        storeId: store.storeId,
      });
    }
  }, [store]);


  
  // Handler for editing store details
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStoreDetails((prevDetails) => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  // Toggle edit mode
  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  // Function to save updated store details
  const handleSave = () => {
    dispatch(updateResellerStore({storeId:store.storeId,storeData:{storeName:storeDetails.storeName},token:JSON.parse(localStorage.getItem("access_token"))}));
    setIsEditing(false);
  };

  // Handler for removing a product
  const handleRemoveProduct = (productId) => {
    dispatch(removeProductFromResellerStore({productId,storeId:store.storeId,token:JSON.parse(localStorage.getItem("access_token"))}));
    window.location.reload();
  };

  const handleShowDetails = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(!isModalOpen);
  };

 

  if( loading) return <LoaderComponent />
  

  return (
    <div className="container mx-auto p-6 bg-white rounded-lg shadow-md">
      {/* Store Details Section */}
      {
        store ? <>
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">Reseller Store Details</h2>
      <Link to={`/reseller/store?storeid=${storeDetails.storeId}`} className="bg-blue-500 text-white p-2 rounded shadow-md hover:bg-blue-600 transition-al">
         View your live store
      </Link>
      <div className="mb-6">
        {isEditing ? (
          <div>
            <label className="block text-gray-600 text-sm font-medium mb-1">Store Name:</label>
            <input
              type="text"
              name="storeName"
              value={storeDetails.storeName}
              onChange={handleInputChange}
              className="mb-4 w-full p-2 border border-gray-300 rounded"
            />
            {/* <label className="block text-gray-600 text-sm font-medium mb-1">Store ID:</label>
            <input
              type="text"
              name="storeId"
              value={storeDetails.storeId}
              onChange={handleInputChange}
              className="mb-4 w-full p-2 border border-gray-300 rounded"
            /> */}
            <button
              onClick={handleSave}
              className="mr-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Save
            </button>
            <button
              onClick={toggleEditMode}
              className="bg-gray-300 text-gray-700 px-4 py-2 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div>
            <p className="text-lg text-gray-600"><span className="font-bold">Store Name:</span> {storeDetails.storeName}</p>
            <p className="text-lg text-gray-600"><span className="font-bold">Store ID:</span> {storeDetails.storeId}</p>
            <button
              onClick={toggleEditMode}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Edit Details
            </button>
          </div>
        )}
      </div>

      {/* Product List Table */}
      <div className="overflow-x-auto">
        <table className="w-full bg-gray-100 rounded-lg shadow-sm">
          <thead>
            <tr className="bg-gray-200 text-gray-700 text-left">
              <th className="p-3 text-sm font-semibold">Product Title</th>
              <th className="p-3 text-sm font-semibold">Retail Price</th>
              <th className="p-3 text-sm font-semibold">Reseller Price</th>
              <th className="p-3 text-sm font-semibold">Custom Price</th>
              <th className="p-3 text-sm font-semibold">Action</th>
            </tr>
          </thead>
          <tbody>
            {store && store.products?.map((product,index) => (
              <tr key={index} className="border-b">
                <td className="p-3 text-sm text-gray-600">{product?.title}</td>
                <td className="p-3 text-sm text-gray-600">{
                  product.colorSizeVariants?.length > 0 ? product.colorSizeVariants[0]?.sizes[0]?.price : product.sizeVariants[0].price
                  }</td>
                <td className="p-3 text-sm text-gray-600">{
                  product.colorSizeVariants?.length > 0 ? product.colorSizeVariants[0]?.sizes[0]?.resellerPrice : product.sizeVariants[0].resellerPrice
                  }</td>
                <td className="p-3 text-sm text-gray-600">{
                  product.colorSizeVariants?.length > 0 ? product.colorSizeVariants[0]?.sizes[0]?.customPrice : product.sizeVariants[0].customPrice
                  }</td>
                <td className="p-3 text-sm">
                  <button
                    className="bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-600 transition-all"
                    onClick={() => handleShowDetails(product)}
                  >
                    <FaEye />
                  </button>
                  <button
                    className="text-red-500 text-lg p-2 hover:text-red-700"
                    onClick={() => handleRemoveProduct(product?._id)}
                  >
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
        </>: <p>
          No store found. You don't have a store yet. please go to single product page and add a product to a store. then automatically your store will be created. you can able to see your store details here.
        </p>
      }

      {
        <Pagination currentPage={page} totalPages={pagination?.totalPages} onPageChange={setPage}/>
      }

    {
      isModalOpen && (
        <Modal title="Product Details" isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <ProductDetails selectedProduct={selectedProduct} setShowDetails={setIsModalOpen} from='reseller' />
        </Modal>
      )
    }

    </div>
  );
};

export default StoreDetails;
