import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api/api';
import { toast } from 'react-toastify';

// Thunks

export const createProductRequest = createAsyncThunk('productRequests/create', async (requestData) => {
  const response = await api.post('/product-requests', requestData);
  if(response.status === 201) {
    toast.success("Product request created successfully");
  }
  return response.data;
});

export const updateProductRequest = createAsyncThunk('productRequests/update', async ({ id, data }) => {
  const response = await api.put(`/admin/product-requests/${id}`, data);
  if(response.status === 200) {
    toast.success("Product request updated successfully");
  }
  return response.data;
});

// get all product requests by admin
export const getAllProductRequests = createAsyncThunk('productRequests/getAll', async () => {
  const response = await api.get('/admin/product-requests');
  
  return response.data;
});

// get all product requests by reseller
export const getAllProductRequestsByReseller = createAsyncThunk('productRequests/getAllByReseller', async (token) => {
  const response = await api.get(`/reseller/product-requests/`, {
    headers: {
      "Authorization": `Bearer ${token}`
    }
  });
  return response.data;
});

const productRequestSlice = createSlice({
  name: 'productRequests',
  initialState: {
    productRequests: [],
    loading: false,
    error: null,
  },
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProductRequest.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createProductRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.productRequests.push(action.payload);
      })
      .addCase(createProductRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateProductRequest.pending, (state) => {
        state.loading = true
        })
      .addCase(updateProductRequest.fulfilled, (state, action) => {
        state.loading = false
        state.productRequests = state.productRequests.map((item) => {
          if (item._id === action.payload._id) {
            return action.payload;
          }
          return item;
        });
      })
      .addCase(updateProductRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllProductRequests.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllProductRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.productRequests = action.payload;
      })
      .addCase(getAllProductRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getAllProductRequestsByReseller.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllProductRequestsByReseller.fulfilled, (state, action) => {
        state.loading = false;
        state.productRequests = action.payload;
      })
      .addCase(getAllProductRequestsByReseller.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { clearError } = productRequestSlice.actions;

export default productRequestSlice.reducer;
