import React from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addToCart } from "../utils/cartUtils";

const StoreProductCard = ({ product, storeId }) => {
  const { isAuthenticated, userInfo } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const handleAddToCart = () => {
    const productDetails =
      product.colorSizeVariants?.length > 0
        ? {
            _id: product._id,
            title: product.title,
            productCode: product.productCode,
            image: product.colorSizeVariants?.[0]?.image,
            price: product.colorSizeVariants[0]?.sizes[0]?.customPrice,
            color: product.colorSizeVariants?.[0]?.colorName,
            size: product.colorSizeVariants[0]?.sizeName,
            affiliateCommission:
              product.colorSizeVariants?.[0]?.sizes[0]?.affiliateCommission,
            resellerPrice:
              product.colorSizeVariants?.[0]?.sizes[0]?.resellerPrice,
            customPrice: product.colorSizeVariants[0]?.sizes[0]?.customPrice,
          }
        : {
            _id: product._id,
            title: product.title,
            productCode: product.productCode,
            image: product.sizeVariants[0]?.image,
            price: product.sizeVariants[0]?.customPrice,
            size: product.sizeVariants[0]?.sizeName,
            affiliateCommission: product.sizeVariants[0]?.affiliateCommission,
            resellerPrice: product.sizeVariants?.[0]?.resellerPrice,
            customPrice: product.sizeVariants[0]?.customPrice,
          };

    addToCart(productDetails, Number(1), storeId, product?.reseller); // Adds the selected product configuration to the cart
  };

  /**
   * handle buy now
   */

  const handleBuyNow = () => {
    if (!isAuthenticated) {
      navigate("/login", {
        state: { from: `/shop/products/${product.title}` },
      });
      return;
    }

    // create checkout data
    const products = [
      {
        id: product._id,
        title: product.title,
        image:
          product.colorSizeVariants?.[0]?.image ||
          product.sizeVariants[0]?.image,
        color: product.colorSizeVariants?.[0]?.colorName || "",
        size:
          product.colorSizeVariants?.[0]?.sizeName ||
          product.sizeVariants[0]?.sizeName,
        quantity: 1,
        price:
          product.colorSizeVariants[0]?.sizes[0]?.customPrice ||
          product.sizeVariants[0]?.customPrice,
        affiliateCommission:
          product.colorSizeVariants?.[0]?.sizes[0]?.affiliateCommission ||
          product.sizeVariants[0]?.affiliateCommission,
        resellerPrice:
          product.colorSizeVariants?.[0]?.sizes[0]?.resellerPrice ||
          product.sizeVariants[0]?.resellerPrice ||
          0,
        customPrice:
          product.colorSizeVariants[0]?.sizes[0]?.customPrice ||
          product.sizeVariants[0]?.customPrice,
      },
    ];

    const refCode = localStorage.getItem("ref_code");
    const totalPrice =
      1 *
      (product.colorSizeVariants[0]?.sizes[0]?.customPrice ||
        product.sizeVariants[0]?.customPrice);

    navigate("/checkout", {
      state: {
        products,
        totalPrice,
        affiliateCode: refCode,
        reseller: product?.reseller,
        orderByReseller: null,
      },
    });
  };

  return (
    <div className="bg-white border rounded-lg hover:shadow-lg hover:border-1 p-3">
      <div className="relative w-full h-40 sm:h-48 md:h-56 lg:h-64">
        <Link to={`/reseller/store/${storeId}/products/${product.title}`}>
          <img
            src={
              product.colorSizeVariants[0]?.image ||
              product.sizeVariants[0]?.image
            }
            alt={product.title}
            className="w-full h-full object-fit"
          />
        </Link>
      </div>
      <div className="p-2 md:p-4">
        <h2 className="text-xs sm:text-sm md:text-lg font-bold line-clamp-2">
          {product.title}
        </h2>

        {product.colorSizeVariants && product.colorSizeVariants.length > 0 && (
          <div className="mt-2">
            {product.colorSizeVariants[0].sizes[0] && (
              <div
                key={product.colorSizeVariants[0].sizes[0].sizeName}
                className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-1 text-sm md:text-base"
              >
                <span>
                  TK.
                  {product.colorSizeVariants[0].sizes[0].customPrice.toFixed(2)}
                </span>
              </div>
            )}
          </div>
        )}

        {product.sizeVariants && product.sizeVariants.length > 0 && (
          <div className="mt-2">
            <h3 className="font-semibold">Sizes:</h3>
            {product.sizeVariants[0] && (
              <div
                key={product.sizeVariants[0].sizeName}
                className="flex justify-between items-center mt-1"
              >
                <span>
                  TK. {product.sizeVariants[0].customPrice.toFixed(2)}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      {/* Buttons */}
      <div className="flex flex-col md:flex-row justify-between items-center gap-2 md:gap-4 p-2 md:p-4">
        <button
        disabled={ userInfo?.role === 'reseller'}
          onClick={handleAddToCart}
          className="bg-blue-500 text-white text-sm font-semibold px-2 py-2 rounded hover:bg-blue-600 transition w-full md:w-auto"
        >
          Add to Cart
        </button>
        <button
         disabled={ userInfo?.role === 'reseller'}
          onClick={handleBuyNow}
          className="bg-green-500 text-white text-sm font-semibold px-2 py-2 rounded hover:bg-green-600 transition w-full md:w-auto"
        >
          Order Now
        </button>
      </div>
    </div>
  );
};

export default StoreProductCard;
