import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";
import { toast } from "react-toastify";

// create subcategory by admin
export const createSubcategory = createAsyncThunk(
  "category/createSubcategory",
  async (subcategory) => {
    const response = await api.post("/subcategories", subcategory, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if(response.status === 201){
      toast.success("Subcategory created successfully");
    }
    return response.data;
  }
);

// get all subcategories
export const fetchSubcategories = createAsyncThunk(
  "category/fetchSubcategories",
  async () => {
    const response = await api.get(`/subcategories`);
    return response.data;
  }
);

// get all subcategories by admin
export const fetchSubcategoriesByAdmin = createAsyncThunk(
  "category/fetchSubcategoriesByAdmin",
  async ({search = ""}) => {
    const response = await api.get(`/admin/subcategories?search=${search}`);
    return response.data;
  }
);

// update subcategory by admin
export const updateSubcategory = createAsyncThunk(
  "category/updateSubcategory",
  async ({ id, subcategory }) => {
    const response = await api.put(`/subcategories/${id}`, subcategory, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if(response.status === 200){
      toast.success("Subcategory updated successfully");
      }
    return response.data;
  }
);

// delete subcategory by admin
export const deleteSubcategory = createAsyncThunk(
  "category/deleteSubcategory",
  async (id) => {
    const response = await api.delete(`/subcategories/${id}`);
    if(response.status === 200){
      toast.success("Subcategory deleted successfully");
      }
    return response.data;
    
  }
);

const subcategorySlice = createSlice({
  name: "subcategory",
  initialState: {
    subcategories: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(fetchSubcategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubcategories.fulfilled, (state, action) => {
        state.subcategories = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchSubcategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createSubcategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSubcategory.fulfilled, (state, action) => {
        state.subcategories.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createSubcategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateSubcategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateSubcategory.fulfilled, (state, action) => {
        state.subcategories = state.subcategories.map((subcategory) => {
          if (subcategory._id === action.payload._id) {
            return action.payload;
          }
          return subcategory;
        });
        state.loading = false;
        state.error = null;
      })
      .addCase(updateSubcategory.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
      })
      .addCase(deleteSubcategory.pending, (state) => {
          state.loading = true;
          state.error = null;
      })
      .addCase(deleteSubcategory.fulfilled, (state, action) => {
          state.subcategories = state.subcategories.filter((subcategory) => subcategory._id !== action.payload._id);
          state.loading = false;
          state.error = null;
      })
      .addCase(deleteSubcategory.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
      })
      .addCase(fetchSubcategoriesByAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubcategoriesByAdmin.fulfilled, (state, action) => {
        state.subcategories = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchSubcategoriesByAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

  },
});

export default subcategorySlice.reducer;