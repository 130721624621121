import React from "react";
import Slider from "react-slick";
import ProductCard from "./ProductCard";

const ProductSlider = ({ productsOnOffer }) => {
  // Slider settings
  const sliderSettings = {
    infinite: productsOnOffer.length > 1, // Disable infinite scroll for single item
    speed: 500,
    slidesToShow: Math.min(5, productsOnOffer.length), // Adjust slides based on items available
    slidesToScroll: 1,
    autoplay: productsOnOffer.length > 1, // Disable autoplay for single item
    autoplaySpeed: 3000,
    arrows: productsOnOffer.length > 1, // Hide arrows for single item
    responsive: [
      {
        breakpoint: 1024, // For tablets and smaller
        settings: {
          slidesToShow: Math.min(4, productsOnOffer.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For mobile devices
        settings: {
          slidesToShow: Math.min(3, productsOnOffer.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // For extra small devices
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div >
      <Slider {...sliderSettings}>
        {productsOnOffer.map((product) => (
          <div key={product._id} className="p-2">
            <ProductCard product={product} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductSlider;




// import React from "react";
// import Slider from "react-slick";
// import ProductCard from "./ProductCard";

// const ProductSlider = ({ productsOnOffer }) => {
//   // Slider settings
//   const sliderSettings = {
//     dots: true,
//     infinite: productsOnOffer.length > 1, // Disable infinite scroll for single item
//     speed: 500,
//     slidesToShow: productsOnOffer.length > 1 ? Math.min(5, productsOnOffer.length) : 1, // Show 1 slide for single product
//     slidesToScroll: 1,
//     autoplay: productsOnOffer.length > 1, // Disable autoplay for single item
//     autoplaySpeed: 3000,
//     arrows: productsOnOffer.length > 1, // Hide arrows for single item
//     centerMode: productsOnOffer.length === 1, // Center a single product
//     centerPadding: productsOnOffer.length === 1 ? "30%" : "0", // Add padding for a single product
//     responsive: [
//       {
//         breakpoint: 1024, // For tablets and smaller
//         settings: {
//           slidesToShow: productsOnOffer.length > 1 ? Math.min(3, productsOnOffer.length) : 1,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 768, // For mobile devices
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//           centerMode: true,
//           centerPadding: "20%", // Smaller padding on mobile
//         },
//       },
//     ],
//   };

//   return (
//     <div className="py-8 px-4 md:px-8 lg:px-16">
//       <Slider {...sliderSettings}>
//         {productsOnOffer.map((product) => (
//           <div key={product._id} className="p-2 max-w-[300px] mx-auto">
//             <ProductCard product={product} />
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// };

// export default ProductSlider;

