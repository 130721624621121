
import React, { useEffect, useState } from "react";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import { fetchCategories } from "../../features/category/categorySlice";
import { createProduct } from "../../features/products/productsSlice";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { fetchSubcategories } from "../../features/subcategory/subcategorySlice";
import LoaderComponent from "../LoaderComponent";

const CreateProductForm = () => {
  const [imagePreviews, setImagePreviews] = useState({});

  const { categories,loading } = useSelector((state) => state.category);
  const { subcategories,loading: subLoading } = useSelector((state) => state.subcategory);
  const dispatch = useDispatch();

  // fetch all categories on mount
  useEffect(() => {
    dispatch(fetchCategories());
    dispatch(fetchSubcategories());
  }, [dispatch]);

  // category options
  const categoryOptions = categories.map((category) => ({
    value: category._id,
    label: category.name,
  }));

  // subcategory options
  const subcategoryOptions = subcategories.map((subcategory) => ({
    value: subcategory._id,
    label: subcategory.name,
  }));

 
  

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Product Name is required"),
    productCode: Yup.string().required("Product Code is required"),
    brand: Yup.string().required("Brand is required"),
    videoUrl: Yup.string(),
    description: Yup.string().required("Description is required"),
    condition: Yup.string().required("Condition is required"),
    category: Yup.array().min(1, "At least one category is required"),
    subcategory: Yup.array().min(1, "At least one subcategory is required"),
    productsSupplier: Yup.object().shape({
      name: Yup.string(),
      mobileNumber: Yup.string(),
    }),
    upcoming: Yup.boolean(),
    forReseller: Yup.boolean(),
    variantType: Yup.string().required("Variant Type is required"),
    colorSizeVariants: Yup.array().when("variantType", (variantType, schema) =>
      variantType === "colorSizeVariants"
        ? schema.of(
            Yup.object().shape({
              colorName: Yup.string().required("Color Name is required"),
              image: Yup.string().required("Image is required"),
              sizes: Yup.array()
                .of(
                  Yup.object().shape({
                    sizeName: Yup.string().required("Size Name is required"),
                    price: Yup.number()
                      .required("Price is required")
                      .positive("Price must be positive"),
                    offerPrice: Yup.number().optional(),
                    affiliateCommission: Yup.number().optional(),
                    resellerPrice: Yup.number().optional(),
                    minimumSoldPrice: Yup.number().optional(),
                    stock: Yup.number()
                      .required("Stock is required")
                      .min(0, "Stock cannot be negative"),
                  })
                )
                .required("At least one size is required"),
            })
          )
        : schema.notRequired()
    ),
    quantities: Yup.array().when("variantType", (variantType, schema) =>
      variantType === "sizeVariants"
        ? schema.of(
            Yup.object().shape({
              sizeName: Yup.string()
                .required("Quantity is required"),
              price: Yup.number()
                .required("Price is required")
                .positive("Price must be positive"),
              offerPrice: Yup.number().optional(),
              affiliateCommission: Yup.number().optional(),
              resellerPrice: Yup.number().optional(),
              minimumSoldPrice: Yup.number().optional(),
              stock: Yup.number()
                .required("Stock is required")
                .min(0, "Stock cannot be negative"),
              image: Yup.string().required("Image is required"),
            })
          )
        : schema.notRequired()
    ),
  });

  const handleImageChange = (event, setFieldValue, index, variantType) => {
    const file = event.currentTarget.files[0];
    if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
            const base64Image = reader.result; // Base64 string
            setFieldValue(`${variantType}[${index}].image`, base64Image); // Save to Formik state
            setImagePreviews((prev) => ({
                ...prev,
                [`${variantType}-${index}`]: base64Image, // Save preview
            }));
        };
        reader.readAsDataURL(file);
    }
};

if(loading || subLoading) return <LoaderComponent />


  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">Create Product</h1>
      <Formik
        initialValues={{
          name: "",
          productCode: "",
          brand: "",
          videoUrl: "",
          description: "",
          condition: "",
          category: [],
          subcategory: [],
          productsSupplier: {
            name: "",
            mobileNumber: "",
          },
          inOffer: false,
          upcoming: false,
          forReseller: false,
          variantType: "", // Added field for variant type
          colorSizeVariants: [],
          quantities: [],
        }}
        validationSchema={ValidationSchema}
        onSubmit={(values,{resetForm}) => {
          const productData = {
            title: values.name,
            productCode: values.productCode,
            brand: values.brand,
            videoUrl: values.videoUrl,
            description: values.description,
            condition: values.condition,
            category: values.category.map((category) => category.value),
            subcategory: values.subcategory.map((subcategory) => subcategory.value),
            productsSupplier: {
              name: values.productsSupplier.name,
              mobileNumber: values.productsSupplier.mobileNumber,
            },
            inOffer: values.inOffer,
            upcoming: values.upcoming,
            forReseller: values.forReseller,
            colorSizeVariants: values.colorSizeVariants,
            sizeVariants: values.quantities,
          };
          // Send product data to server
          dispatch(createProduct(productData));
          resetForm();
          setImagePreviews({});
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
           {/* Product Name */}
            <div className="mb-4">
              <Field
                name="name"
                placeholder="Product Name"
                className="border rounded p-2 w-full"
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            {/* Product Code */}
            <div className="mb-4">
              <Field
                name="productCode"
                placeholder="Product Code"
                className="border rounded p-2 w-full"
              />
              <ErrorMessage
                name="productCode"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          {/* Brand */}
            <div className="mb-4">
              <Field
                name="brand"
                placeholder="Brand name"
                className="border rounded p-2 w-full"
              />
              <ErrorMessage
                name="brand"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            {/* video url */}
            <div className="mb-4">
              <Field
                name="videoUrl"
                placeholder="youtube video url"
                className="border rounded p-2 w-full"
              />
              <ErrorMessage
                name="videoUrl"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>

            {/* supplier */}
            <div className="mb-4">
              <Field
                name="productsSupplier.name"
                placeholder="supplier name"
                className="border rounded p-2 w-full"
              />
              <ErrorMessage
                name="productsSupplier.name"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            <div className="mb-4">
              <Field
                name="productsSupplier.mobileNumber"
                placeholder="supplier mobile number"
                className="border rounded p-2 w-full"
              />
              <ErrorMessage
                name="productsSupplier.mobileNumber"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
          {/* description */}
            <div className="mb-4">
              <Field
                name="description"
                placeholder="Product Description"
                as="textarea"
                className="border rounded p-2 w-full"
              />
              <ErrorMessage
                name="description"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            {/* condition */}
            <div className="mb-4">
              <Field
                name="condition"
                placeholder="Product condition"
                as="textarea"
                className="border rounded p-2 w-full"
              />
              <ErrorMessage
                name="condition"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            {/* category */}
            <div className="mb-4">
              <Select
                isMulti
                name="category"
                placeholder="Category"
                options={categoryOptions}
                value={values.category}
                onChange={(selectedOptions) => 
                  setFieldValue("category", selectedOptions)                  
                }
                className="border rounded p-2 w-full"
              />
              <ErrorMessage
                name="category"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
            {/* sub category */}
            <div className="mb-4">
              <Select
                isMulti
                name="subcategory"
                placeholder="subcategory"
                options={subcategoryOptions}
                value={values.subcategory}
                onChange={(selectedOptions) => 
                  setFieldValue("subcategory", selectedOptions)
                }
                className="border rounded p-2 w-full"
              />
              <ErrorMessage
                name="subcategory"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
                {/* variant */}
            <div className="mb-4">
              <Field
                as="select"
                name="variantType"
                className="border rounded p-2 w-full"
              >
                <option value="">Select Variant Type</option>
                <option value="colorSizeVariants">Color Variant</option>
                <option value="sizeVariants">Quantity Variant</option>
              </Field>
              <ErrorMessage
                name="variantType"
                component="div"
                className="text-red-500 text-sm"
              />
            </div>
           
           {/* {values.variantType === "colorSizeVariants" && */}

            {values.variantType === "colorSizeVariants" && (
              <FieldArray name="colorSizeVariants">
                {({ push, remove }) => (
                  <div>
                    <h3 className="text-lg font-semibold mb-2">
                      Color Size Variants
                    </h3>
                    {values.colorSizeVariants.map((color, index) => (
                      <div key={index} className="mb-4 border p-4 rounded">
                        <Field
                          name={`colorSizeVariants[${index}].colorName`}
                          placeholder="Color Name"
                          className="border rounded p-2 w-full"
                        />
                        <ErrorMessage
                          name={`colorSizeVariants[${index}].colorName`}
                          component="div"
                          className="text-red-500 text-sm"
                        />

                        <input
                          type="file"
                          accept="image/*"
                          onChange={(event) =>
                            handleImageChange(
                              event,
                              setFieldValue,
                              index,
                              "colorSizeVariants"
                            )
                          }
                          className="mt-2"
                        />
                        <ErrorMessage
                          name={`colorSizeVariants[${index}].image`}
                          component="div"
                          className="text-red-500 text-sm"
                        />
                        {imagePreviews[`colorSizeVariants-${index}`] && (
                          <img
                            src={imagePreviews[`colorSizeVariants-${index}`]}
                            alt="Preview"
                            className="mt-2 w-24 h-24 object-cover"
                          />
                        )}

                        <FieldArray name={`colorSizeVariants[${index}].sizes`}>
                          {({ push: addSize, remove: removeSize }) => (
                            <div className="mt-4">
                              <h4 className="text-md font-semibold">Sizes</h4>
                              {color.sizes.map((size, sizeIndex) => (
                                <div
                                  key={sizeIndex}
                                  className="mb-2 border p-2 rounded"
                                >
                                  <label>Size Name</label>
                                  <Field
                                    name={`colorSizeVariants[${index}].sizes[${sizeIndex}].sizeName`}
                                    placeholder="Size Name"
                                    className="border rounded p-2 w-full"
                                  />
                                  <ErrorMessage
                                    name={`colorSizeVariants[${index}].sizes[${sizeIndex}].sizeName`}
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                  <label>Price</label>
                                  <Field
                                    name={`colorSizeVariants[${index}].sizes[${sizeIndex}].price`}
                                    placeholder="Price"
                                    type="number"
                                    className="border rounded p-2 w-full"
                                  />
                                  <ErrorMessage
                                    name={`colorSizeVariants[${index}].sizes[${sizeIndex}].price`}
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                  <label>Offer Price</label>
                                  <Field
                                    name={`colorSizeVariants[${index}].sizes[${sizeIndex}].offerPrice`}
                                    placeholder="offer price"
                                    type="number"
                                    className="border rounded p-2 w-full"
                                  />
                                  <ErrorMessage
                                    name={`colorSizeVariants[${index}].sizes[${sizeIndex}].offerPrice`}
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                  <label>Reseller Price</label>
                                  <Field
                                    name={`colorSizeVariants[${index}].sizes[${sizeIndex}].resellerPrice`}
                                    placeholder="Reseller price"
                                    type="number"
                                    className="border rounded p-2 w-full"
                                  />
                                  <ErrorMessage
                                    name={`colorSizeVariants[${index}].sizes[${sizeIndex}].resellerPrice`}
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                  <label>Minimum Sold Price</label>
                                  <Field
                                    name={`colorSizeVariants[${index}].sizes[${sizeIndex}].minimumSoldPrice`}
                                    placeholder="minimum sold price"
                                    type="number"
                                    className="border rounded p-2 w-full"
                                  />
                                  <ErrorMessage
                                    name={`colorSizeVariants[${index}].sizes[${sizeIndex}].minimumSoldPrice`}
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                  <label>Affiliate commission</label>
                                  <Field
                                    name={`colorSizeVariants[${index}].sizes[${sizeIndex}].affiliateCommission`}
                                    placeholder="affiliate commission"
                                    type="number"
                                    className="border rounded p-2 w-full"
                                  />
                                  <ErrorMessage
                                    name={`colorSizeVariants[${index}].sizes[${sizeIndex}].affiliateCommission`}
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />
                                  <label>Stock</label>
                                  <Field
                                    name={`colorSizeVariants[${index}].sizes[${sizeIndex}].stock`}
                                    placeholder="Stock"
                                    type="number"
                                    className="border rounded p-2 w-full"
                                  />
                                  <ErrorMessage
                                    name={`colorSizeVariants[${index}].sizes[${sizeIndex}].stock`}
                                    component="div"
                                    className="text-red-500 text-sm"
                                  />

                                  <button
                                    type="button"
                                    onClick={() => removeSize(sizeIndex)}
                                    className="text-white bg-red-500 hover:bg-red-600 py-2 px-4 mt-2 rounded"
                                  >
                                    Remove Size
                                  </button>
                                </div>
                              ))}
                              <button
                                type="button"
                                onClick={() =>
                                  addSize({ sizeName: "", price: 0, stock: 0 })
                                }
                                className="text-white bg-blue-500 hover:bg-blue-600 py-2 px-4 mb-2 mt-2 rounded"
                              >
                                Add Size
                              </button>
                            </div>
                          )}
                        </FieldArray>

                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className="text-white bg-red-500 hover:bg-red-600 py-2 px-4 rounded"
                        >
                          Remove Color Variant
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() =>
                        push({ colorName: "", image: "", sizes: [] })
                      }
                      className="text-white bg-blue-500 text-white hover:bg-blue-600 py-2 px-4 rounded"
                    >
                      Add Color Variant
                    </button>
                  </div>
                )}
              </FieldArray>
            )}
            {/* Size Variants */}
            {values.variantType === "sizeVariants" && (
              <FieldArray name="quantities">
                {({ push, remove }) => (
                  <div>
                    <h3 className="text-lg font-semibold mb-2">
                      Quantity Variants
                    </h3>
                    {values.quantities.map((quantity, index) => (
                      <div key={index} className="mb-4 border p-4 rounded">
                        <label>Quantity Size Name</label>
                        <Field
                          name={`quantities[${index}].sizeName`}
                          placeholder="Quantity size name"
                          className="border rounded p-2 w-full"
                        />
                        <ErrorMessage
                          name={`quantities[${index}].sizeName`}
                          component="div"
                          className="text-red-500 text-sm"
                        />
                        <label>Image</label>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(event) =>
                            handleImageChange(
                              event,
                              setFieldValue,
                              index,
                              "quantities"
                            )
                          }
                          className="mt-2"
                        />
                        <ErrorMessage
                          name={`quantities[${index}].image`}
                          component="div"
                          className="text-red-500 text-sm"
                        />
                        {imagePreviews[`quantities-${index}`] && (
                          <img
                            src={imagePreviews[`quantities-${index}`]}
                            alt="Preview"
                            className="mt-2 w-24 h-24 object-cover"
                          />
                        )}
                        <br/>
                        <label>Price</label>
                        <Field
                          name={`quantities[${index}].price`}
                          placeholder="Price"
                          type="number"
                          className="border rounded p-2 w-full"
                        />
                        <ErrorMessage
                          name={`quantities[${index}].price`}
                          component="div"
                          className="text-red-500 text-sm"
                        />
                        <label>Offer Price</label>
                        <Field
                          name={`quantities[${index}].offerPrice`}
                          placeholder="Offer Price"
                          type="number"
                          className="border rounded p-2 w-full"
                        />
                        <ErrorMessage
                          name={`quantities[${index}].offerPrice`}
                          component="div"
                          className="text-red-500 text-sm"
                        />
                        <label>Reseller Price</label>
                        <Field
                          name={`quantities[${index}].resellPrice`}
                          placeholder="Resell Price"
                          type="number"
                          className="border rounded p-2 w-full"
                        />
                        <ErrorMessage
                          name={`quantities[${index}].resellPrice`}
                          component="div"
                          className="text-red-500 text-sm"
                        />
                        <label>Minimum Sold Price for reseller</label>
                        <Field
                          name={`quantities[${index}].minimumSoldPrice`}
                          placeholder="minimum Sold Price"
                          type="number"
                          className="border rounded p-2 w-full"
                        />
                        <ErrorMessage
                          name={`quantities[${index}].minimumSoldPrice`}
                          component="div"
                          className="text-red-500 text-sm"
                        />
                        <label>affiliate commission</label>
                        <Field
                          name={`quantities[${index}].affiliateCommission`}
                          placeholder="affiliate commission"
                          type="number"
                          className="border rounded p-2 w-full"
                        />
                        <ErrorMessage
                          name={`quantities[${index}].affiliateCommission`}
                          component="div"
                          className="text-red-500 text-sm"
                        />
                        <label>Stock</label>
                        <Field
                          name={`quantities[${index}].stock`}
                          placeholder="Stock"
                          type="number"
                          className="border rounded p-2 w-full"
                        />
                        <ErrorMessage
                          name={`quantities[${index}].stock`}
                          component="div"
                          className="text-red-500 text-sm"
                        />

                        <button
                          type="button"
                          onClick={() => remove(index)}
                          className="text-white bg-red-500 hover:bg-red-600 py-2 px-4 rounded"
                        >
                          Remove Quantity Variant
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      onClick={() =>
                        push({ quantity: "", image: "", price: 0, stock: 0 })
                      }
                      className="text-white bg-blue-500 hover:bg-blue-600 py-2 px-4 rounded"
                    >
                      Add Quantity Variant
                    </button>
                  </div>
                )}
              </FieldArray>
            )}
            {/* for in offer */}
            <div className="mb-4">
              <label className="block text-lg font-semibold text-gray-800 mb-2">
                Product for offer            
              </label>
              <Field
                type='checkbox'
                name="inOffer"
                className="h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 mt-1"
              />
              <span className="text-gray-600 text-sm italic p-1">
                  Check this box if the product is for offer.
              </span>
              
            </div>
            {/* for upcoming */}
            <div className="mb-4">
              <label className="block text-lg font-semibold text-gray-800 mb-2">
                upcoming            
              </label>
              <Field
                type='checkbox'
                name="upcoming"
                className="h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 mt-1"
              />
              <span className="text-gray-600 text-sm italic p-1">
                  Check this box if the product is upcoming.
              </span>
              
            </div>
            {/* for reseller */}
            <div className="mb-4">
            <label className="block text-lg font-semibold text-gray-800 mb-2">
                For Reseller            
              </label>
              <Field
                type='checkbox'
                name="forReseller"
                className="h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500 focus:ring-2 mt-1"
               />
               <span className="text-gray-600 text-sm italic p-1">
            Check this box if the product is for reseller.
          </span>
                
            </div>
            <button
              type="submit"
              className="w-full mt-4 bg-blue-500 text-white rounded p-2"
            >
              Submit Product
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateProductForm;
