import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import ProductList from './pages/ProductList';
import SingleProduct from './pages/SingleProduct';

import Login from './pages/Login';
import Signup from './pages/Signup';
import ResellerSignup from './pages/resellerPages/ResellerSignup';
import ResellerLogin from './pages/resellerPages/ResellerLogin';
import ResellerDashboard from './pages/resellerPages/ResellerDashboard';
import Footer from './components/Footer';
import Profile from './pages/userPages/Profile';

import ProtectedRoute from './routes/ProtectedRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// admin dashboard pages
import AdminDashboard from './pages/admin/AdminDashboard';
import AdminLogin from './pages/admin/AdminLogin';
import Package from './pages/resellerPages/Package';
import ManagingDirectorMessage from './pages/ManagingDirectorMessage';
// check out pages
import Checkout from './pages/Checkout';
import PackageOrderCheckout from './pages/resellerPages/PackageOrderCheckout';
import ManualPayment from './pages/resellerPages/ManualPayment';
import ManualSuccessPayment from './pages/resellerPages/ManualSuccessPayment';

import ResellerHome from './pages/resellerPages/ResellerHome';
import ResellerProductList from './pages/resellerPages/ResellerProductList';
import ResellerSingleProduct from './pages/resellerPages/ResellerSingleProduct';
import {store} from './app/store';
import { setupInterceptors } from './features/api/api';
import { useDispatch } from 'react-redux';
import { initializeAuth } from './features/auth/authSlice';
import VideoCourses from './components/reseller/VideoCourses';
import Shop from './pages/Shop';
import Store from './pages/store/Store';
import StoreSingleProduct from './pages/store/StoreSingleProduct';
import CategorizedProducts from './pages/CategorizedProducts';
import Contact from './pages/Contact';
import TermsAndConditions from './pages/TermsAndConditions';
import ReturnPolicy from './pages/ReturnPolicy';
import FAQPage from './pages/FAQPage';
import TrackOrderPage from './pages/TrackOrder';
import AffiliatePage from './pages/AffiliatePage';
import BusinessUnderConstruction from './pages/BusinessUnderConstruction';
import NotFound from './pages/NotFound';
import ForgotPassword from './pages/ForgotPassword';
import ResetPassword from './pages/ResetPassword';
import WhatsAppContact from './components/WhatsAppContact';
import ResellerCategorizedProducts from './pages/resellerPages/ResellerCategorizedProducts';

import { clearCart } from './utils/cartUtils';
import ProductsOnOffer from './pages/ProductsOnOffer';
import ScrollToTop from './components/ScrollToTop';
import DynamicNavbarUpdater from './components/DynamicNavbarUpdeter';


// protected route

function App() {

  const dispatch = useDispatch();

  const navigate =  useNavigate();
  useEffect(() => {
    // setup interceptors with store and navigate
 
      setupInterceptors(store,navigate);
 
    
  },[navigate])
    


  useEffect(() => {
    
      dispatch(initializeAuth());
    
  }, [dispatch]);

  
  
  
  

  // setup a cron job to clear the cart every 24 hours
 
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     clearCart();
  //   }, 24 * 60 * 60 * 1000); // 24 hours in milliseconds
  //   return () => clearInterval(intervalId);
  // }, []);

  useEffect(() => {
    
      const checkAndClearCart = () => {
        const lastClearedDate = localStorage.getItem('lastClearedDate');
        const currentDate = new Date().toISOString().split('T')[0];
  
        if (lastClearedDate !== currentDate) {
          clearCart();
          localStorage.setItem('lastClearedDate', currentDate);
        }
      };
  
      const intervalId = setInterval(checkAndClearCart, 60 * 60 * 1000);
      checkAndClearCart(); // Initial check
  
      return () => clearInterval(intervalId); // Cleanup on unmount
   
  }, []);


  return (
    
      <div>
        <Navbar />
        <DynamicNavbarUpdater />
        <ToastContainer />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/reseller/signup" element={<ResellerSignup />} />
          <Route path="/reseller/login" element={<ResellerLogin />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />

          <Route path="/shop" element = {<Shop />} />
          <Route path="/shop/products" element={<ProductList />} />
          <Route path="/shop/products/on-offer" element={<ProductsOnOffer />} />
          <Route path="/shop/products/:productTitle" element={<SingleProduct />} />
          <Route path="/shop/products/categories" element={<CategorizedProducts />} />

          <Route path="/reseller/products" element={<ResellerProductList />} />
          
          <Route path="/reseller/products/:productTitle" element={<ResellerSingleProduct />} />
          <Route path="/reseller/products/categories" element={<ResellerCategorizedProducts />} />
          <Route path='/reseller/store' element = {<Store />} />
          <Route path='/reseller/store/:storeid/products/:productTitle' element={<StoreSingleProduct />} />

          
          <Route path="/affiliate" element={<AffiliatePage />} />
          <Route path="/reseller" element={<ResellerHome />} />
          <Route path="/business" element={<BusinessUnderConstruction />} />
          <Route path="/admin/login" element={<AdminLogin />} />  
          <Route path ="/user/dashboard" element={<Profile />} />
          <Route path="/reseller/packages" element={<Package />} />
          <Route path="/checkout" element={<Checkout />} />

          <Route path="/reseller/package/:name/order" element={<PackageOrderCheckout />} />
          <Route path="/reseller/package/:name/order/manual-payment" element={<ManualPayment />} />
          <Route path="/reseller/package/:name/order/manual-payment/success/:paymentId" element={<ManualSuccessPayment />} />
          
          <Route path="/managing-director/:id/message" element={<ManagingDirectorMessage />} />

          <Route path="/video-courses" element={<VideoCourses />} />
          {/* protected routes */}
          <Route 
            path='/admin/dashboard'
            element={
              <ProtectedRoute role='admin' redirectPath={'/admin/login'}>
                <AdminDashboard />
              </ProtectedRoute>
            }
           />
          <Route path="/reseller/dashboard" element={
            <ProtectedRoute role='reseller' redirectPath={'/reseller/login'}>
              <ResellerDashboard />
            </ProtectedRoute >
          } />

          <Route path='/contacts' element={<Contact />} />
          <Route path='/terms-and-conditions' element={<TermsAndConditions />} />
          <Route path='/return-policy' element={<ReturnPolicy />} />
          <Route path='/faq' element={<FAQPage />} />
          <Route path='/track-order' element={<TrackOrderPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes> 
        <Footer />
        <WhatsAppContact />
      </div>
    
  );
}

export default App;

