import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";
import { toast } from "react-toastify";

// async thunk for getting all slider images --home
export const getSliderImagesForHome = createAsyncThunk("sliderImage/getSliderImagesForHome", async (forType="home") => {
    const response = await api.get(`/slider-images/${forType}`);
    return response.data;
});

// async thunk for getting all slider images --reseller
export const getSliderImagesForReseller = createAsyncThunk("sliderImage/getSliderImagesForReseller", async (forType="reseller") => {
    const response = await api.get(`/slider-images/${forType}`);
    return response.data;
})

// async thunk for getting all slider images --e-commerce
export const getSliderImagesForEcommerce = createAsyncThunk("sliderImage/getSliderImagesForEcommerce", async (forType="e-commerce") => {
    const response = await api.get(`/slider-images/${forType}`);
    return response.data;
})

// async thunk for creating a slider image --admin
export const createSliderImage = createAsyncThunk("sliderImage/createSliderImage", async ({sliderImageData,token}) => {
    const response = await api.post("/slider-images", sliderImageData,{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    if(response.status === 201){
        toast.success("slider image created successfully");
    }
    return response.data;
});

// async thunk for delete slider image --admin
export const deleteSliderImage = createAsyncThunk("sliderImage/deleteSliderImage", async ({sliderImageId,token}) => {
    const response = await api.delete(`/slider-images/${sliderImageId}`,{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    if(response.status === 200){
        toast.success("slider image deleted successfully");
    }
    return response.data;
});

// update slider images
export const updateSliderImage = createAsyncThunk("sliderImage/updateSliderImage", async ({id,sliderImageData,token}) => {
    const response = await api.put(`/slider-images/${id}`, sliderImageData,{
        headers: {
        "Authorization": `Bearer ${token}`
        }
    });
    if(response.status === 200){
        toast.success("slider image updated successfully");
    }
    return response.data;
})

// get all slider images --admin
export const getSliderImages = createAsyncThunk("sliderImage/getSliderImages", async () => {
    const response = await api.get("/admin/slider-images");
    return response.data;
});





const sliderImageSlice = createSlice({
    name: "sliderImage",
    initialState: {
        sliderImages: [],
        sliderImagesForHome: [],
        sliderImagesForReseller: [],
        sliderImagesForEcommerce: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSliderImages.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getSliderImages.fulfilled, (state, action) => {
                state.sliderImages = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(getSliderImages.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getSliderImagesForHome.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getSliderImagesForHome.fulfilled, (state, action) => {
                state.sliderImagesForHome = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(getSliderImagesForHome.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getSliderImagesForReseller.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getSliderImagesForReseller.fulfilled, (state, action) => {
                state.sliderImagesForReseller = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(getSliderImagesForReseller.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getSliderImagesForEcommerce.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getSliderImagesForEcommerce.fulfilled, (state, action) => {
                state.sliderImagesForEcommerce = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(getSliderImagesForEcommerce.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createSliderImage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createSliderImage.fulfilled, (state, action) => {
                state.sliderImages.push(action.payload);
                state.loading = false;
                state.error = null;
            })
            .addCase(createSliderImage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(deleteSliderImage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteSliderImage.fulfilled, (state, action) => {
                state.sliderImages = state.sliderImages.filter((sliderImage) => sliderImage._id !== action.payload._id);
                state.loading = false;
                state.error = null;
            })
            .addCase(updateSliderImage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateSliderImage.fulfilled, (state, action) => {
                state.sliderImages = state.sliderImages.map((sliderImage) => sliderImage._id === action.payload._id ? action.payload : sliderImage);
                state.loading = false;
                state.error = null;
            })
            .addCase(updateSliderImage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default sliderImageSlice.reducer;