import React from 'react';
import { Link } from 'react-router-dom';

const BusinessLinks = () => {
  return (
    <div className="text-center my-8">
      <h2 className="text-3xl font-bold text-green-600 mb-6">Our Services</h2>
      <div className="grid lg:grid-cols-4 gap-6 p-4">
        <Link 
          to="/shop" 
          className="shadow-lg p-6 bg-green-500 hover:bg-green-600 text-white rounded-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl"
        >
          <h3 className="text-xl font-semibold mb-2">Shopping</h3>
          <h2>Go to Shopping</h2>
        </Link> 
        <Link 
          to="/affiliate" 
          className="shadow-lg p-6 bg-yellow-500 hover:bg-yellow-600 text-white rounded-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl"
        >
          <h3 className="text-xl font-semibold mb-2">Affiliate</h3>
          <h2>Go to Affiliate</h2>
        </Link>
        <Link 
          to="/reseller" 
          className="shadow-lg p-6 bg-red-500 hover:bg-red-600 text-white rounded-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl"
        >
          <h3 className="text-xl font-semibold mb-2">Reseller</h3>
          <h2>Go to Reseller</h2>
        </Link>
        <Link 
          to="/business" 
          className="shadow-lg p-6 bg-orange-500 hover:bg-orange-600 text-white rounded-lg transform transition duration-300 hover:scale-105 hover:shadow-2xl"
        >
          <h3 className="text-xl font-semibold mb-2">Business</h3>
          <h2>Go to Business</h2>
        </Link>
      </div>
    </div>
  );
};

export default BusinessLinks;
