
import ResellerProductCard from "./ResellerProductCard";

const RelatedProductsAndReviewsForReseller = ({ relatedProducts }) => {
  return (
    <div className="w-full mt-10 px-4 mb-4">
      <h2 className="text-2xl font-bold text-gray-800 italic mb-4">You may also like</h2>
      
        {/* Content Section */}
        <div className="relative flex flex-col items-center">
          <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-5 gap-2 w-full">
            {(relatedProducts &&
              relatedProducts.map((product) => (
                <ResellerProductCard key={product._id} product={product} />
              ))) || <p>No related products found.</p>}
          </div>
        </div>
      
    </div>
  );
};

export default RelatedProductsAndReviewsForReseller;
