import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleOrder } from "../features/orders/ordersSlice";
import LoaderComponent from "../components/LoaderComponent";

const TrackOrderPage = () => {
  const [orderId, setOrderId] = useState("");
  const dispatch = useDispatch();
  const { singleOrder, loading, error } = useSelector((state) => state.orders);

  const handleInputChange = (e) => {
    setOrderId(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (orderId.trim()) {
      dispatch(getSingleOrder(orderId));
    } else {
      alert("Please enter a valid Order ID.");
    }
  };

  if (loading) return <LoaderComponent />;

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-6 text-center">Track Your Order</h1>

      <form onSubmit={handleSubmit} className="mb-8 w-2/3 mx-auto">
        <div className="flex flex-col items-center gap-4">
          <input
            type="text"
            value={orderId}
            onChange={handleInputChange}
            placeholder="Enter Order ID"
            className="w-full p-3 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
          <button
            type="submit"
            className="px-6 py-3 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600"
          >
            Track Order
          </button>
        </div>
      </form>

      {loading && <div>Loading...</div>}
      {error && <div>Error: {error}</div>}

      {singleOrder && (
        <div>
          <div className="bg-white shadow-md rounded p-4 mb-6">
            <h2 className="text-xl font-semibold mb-2">Order Details</h2>
            <p className="mb-2 bg-blue-800 text-white rounded p-2 text-center">
              <strong>Status:</strong> {singleOrder.status}
            </p>
            <p>
              <strong>Payment Status:</strong> {singleOrder.paymentStatus}
            </p>
            <p>
              <strong>Product Total:</strong> ৳{singleOrder.totalPrice}
            </p>
            <p>
              <strong>Delivery Charge:</strong> ৳{singleOrder.deliveryCharge}
            </p>
            <p>
              <strong>Order Discount:</strong> ৳{singleOrder.orderDiscount}
            </p>
            <p>
              <strong>Total Price:</strong> ৳{singleOrder.totalPrice + singleOrder.deliveryCharge - singleOrder.orderDiscount} 
            </p>
            <p>
              <strong>Payment ID:</strong>{" "}
              {singleOrder.transaction?.paymentId || "N/A"}
            </p>
          </div>

          <div className="bg-white shadow-md rounded-lg p-6 mb-8">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">Products</h2>
            {singleOrder.products.map((product, index) => (
              <div
                key={index}
                className="flex flex-col md:flex-row items-start md:items-center justify-between border-b pb-4 mb-4 gap-4"
              >
                <div className="flex-1">
                  <p className="text-lg text-gray-700">
                    <strong>Title:</strong> {product.title}
                  </p>
                  <p className="text-gray-600">
                    <strong>Quantity:</strong> {product.quantity}
                  </p>
                  <p className="text-gray-600">
                    <strong>Price:</strong> ৳{product.soldPrice}
                  </p>
                </div>
                <div>
                  <img
                    src={product.image}
                    alt={product.title}
                    className="w-32 h-32 object-cover rounded-lg shadow-md"
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="bg-white shadow-md rounded p-4 mb-6">
            <h2 className="text-xl font-semibold mb-2">Addresses</h2>
            <p>
              <strong>Billing Address:</strong>{" "}
              {`${singleOrder.billingAddress.fullName}, ${singleOrder.billingAddress.address}, ${singleOrder.billingAddress.city}`}
            </p>
            <p>
              <strong>Shipping Address:</strong>{" "}
              {`${singleOrder.shippingAddress.fullName}, ${singleOrder.shippingAddress.address}, ${singleOrder.shippingAddress.city}`}
            </p>
          </div>

          <div className="bg-white shadow-md rounded p-4">
            <h2 className="text-xl font-semibold mb-2">Order Timeline</h2>
            <ul className="list-disc pl-6">
              {
                singleOrder.createdAt && (
                  <li className="mb-2 bg-gray-200 rounded p-2 list-none">
                    <strong>Created At:</strong>
                    {new Date(singleOrder.createdAt).toLocaleString()}
                  </li>
                )
              }
              {singleOrder.timestamps?.shippedAt && (
                <li className="mb-2 bg-gray-200 rounded p-2 list-none">
                  <strong>Shipped At:</strong>{" "}
                  {new Date(singleOrder.timestamps.shippedAt).toLocaleString()}
                </li>
              )}
              {singleOrder.timestamps?.deliveredAt && (
                <li className="mb-2 bg-gray-200 rounded p-2 list-none">
                  <strong>Delivered At:</strong>{" "}
                  {new Date(
                    singleOrder.timestamps.deliveredAt
                  ).toLocaleString()}
                </li>
              )}
              {singleOrder.timestamps?.cancelledAt && (
                <li className="mb-2 bg-gray-200 rounded p-2 list-none">
                  <strong>Cancelled At:</strong>{" "}
                  {new Date(
                    singleOrder.timestamps.cancelledAt
                  ).toLocaleString()}
                </li>
              )}
              {singleOrder.timestamps?.returnedAt && (
                <li className="mb-2 bg-gray-200 rounded p-2 list-none">
                  <strong>Returned At:</strong>{" "}
                  {new Date(singleOrder.timestamps.returnedAt).toLocaleString()}
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrackOrderPage;
