import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { addToCart } from "../utils/cartUtils";
import { useSelector } from "react-redux";

const ProductCard = ({ product }) => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const getDisplayedPrice = () => {
    if (product.inOffer) {
      if (product.colorSizeVariants?.length > 0) {
        return {
          offerPrice: product.colorSizeVariants[0]?.sizes[0]?.offerPrice || 0,
          originalPrice: product.colorSizeVariants[0]?.sizes[0]?.price || 0,
        };
      }
      if (product.sizeVariants?.length > 0) {
        return {
          offerPrice: product.sizeVariants[0]?.offerPrice || 0,
          originalPrice: product.sizeVariants[0]?.price || 0,
        };
      }
    }
    return {
      originalPrice:
        product.colorSizeVariants?.[0]?.sizes[0]?.price ||
        product.sizeVariants?.[0]?.price ||
        0,
    };
  };

  const getCampaignPrice = () => {
    if (product.inCampaign?.isActive) {
      if (product.colorSizeVariants?.length > 0) {
        return {
          campaignPrice: product.colorSizeVariants[0]?.sizes[0]?.campaignPrice || 0,
        };
      }
      if (product.sizeVariants?.length > 0) {
        return {
          campaignPrice: product.sizeVariants[0]?.campaignPrice || 0,
        };
      }
    }
    return {
      campaignPrice: 0,
    };
  };

  const { offerPrice, originalPrice } = getDisplayedPrice();
  const { campaignPrice } = getCampaignPrice();

  const handleAddToCart = () => {
    const productDetails =
      product.colorSizeVariants?.length > 0
        ? {
            _id: product._id,
            title: product.title,
            productCode: product.productCode,
            image: product.colorSizeVariants?.[0]?.image,
            price: offerPrice ? offerPrice : campaignPrice ? campaignPrice : originalPrice,
            color: product.colorSizeVariants?.[0]?.colorName,
            size: product.colorSizeVariants?.[0]?.sizeName,
            affiliateCommission: product.colorSizeVariants?.[0]?.sizes[0]?.affiliateCommission,
          }
        : {
            _id: product._id,
            title: product.title,
            productCode: product.productCode,
            image: product.sizeVariants[0]?.image,
            price: offerPrice ? offerPrice : originalPrice,
            size: product.sizeVariants[0]?.sizeName,
            affiliateCommission: product.sizeVariants[0]?.affiliateCommission,
          };

    addToCart(productDetails, Number(1), "shop"); // Adds the selected product configuration to the cart
  };

  /**
   * handle buy now
   */

  const handleBuyNow = () => {
    if (!isAuthenticated) {
      navigate("/login", { state: { from: `/shop/products/${product.title}` } });
      return;
    }

    // create checkout data
    const products = [
      {
        id: product._id,
        title: product.title,
        image: product.colorSizeVariants?.[0]?.image || product.sizeVariants[0]?.image,
        color: product.colorSizeVariants?.[0]?.colorName || "",
        size: product.colorSizeVariants?.[0]?.sizeName || product.sizeVariants[0]?.sizeName,
        quantity: 1,
        price: offerPrice ? offerPrice : campaignPrice ? campaignPrice : originalPrice,
        affiliateCommission:
        product.colorSizeVariants?.[0]?.sizes[0]?.affiliateCommission ||
        product.sizeVariants[0]?.affiliateCommission,
        resellerPrice:
        product.colorSizeVariants?.[0]?.sizes[0]?.resellerPrice || product.sizeVariants[0]?.resellerPrice || 0,
      },
    ];

    const refCode = localStorage.getItem("ref_code");
    const totalPrice = 1 * ( offerPrice ? offerPrice : originalPrice);

    navigate("/checkout", {
      state: {
        products,
        totalPrice,
        affiliateCode: refCode,
        reseller: null,
        orderByReseller: null,
      },
    });
  };

  return (
    <div className="relative border rounded-lg shadow-lg overflow-hidden bg-white transition-transform transform hover:scale-105 hover:shadow-xl">
      {/* Discount Badge */}
      {product.inCampaign?.isActive && (
        <div className="absolute top-2 right-2 bg-red-500 text-white text-xs font-bold px-2 py-1 rounded z-50">
          {product.inCampaign.discountType === "percentage"
            ? `-${product.inCampaign.discountValue}%`
            : `-TK. ${product.inCampaign.discountValue}`}
        </div>
      )}

      {/* Product Image */}
      <Link to={`/shop/products/${product.title}`}>
        <div className="relative w-full h-40 md:h-56">
          <img
            src={
              product.colorSizeVariants?.[0]?.image ||
              product.sizeVariants?.[0]?.image ||
              "/placeholder.png"
            }
            alt={product.title}
            className="w-full h-full object-fit"
          />
        </div>
      </Link>

      {/* Product Details */}
      <div className="p-4">
        <h2 className="text-sm md:text-lg font-bold line-clamp-2">
          {product.title}
        </h2>

        {product.upcoming ? (
          <p className="text-sm text-gray-500 mt-1">Coming soon</p>
        ) : (
          <div className="mt-2">
            {/* Price Section */}
            <div className="flex justify-between items-center text-sm md:text-base">
             
              <span
                className={`${
                  product?.inOffer || product?.inCampaign?.isActive ? "line-through text-gray-500" : "text-black"
                }`}
              >
                TK. {originalPrice.toFixed(2)}
              </span>
              {offerPrice ? (
                <span className="text-red-500 font-semibold">
                  TK. {offerPrice.toFixed(2)}
                </span>
              ) : null}
              {campaignPrice ? (
                <span className="text-green-500 font-semibold">
                  TK. {campaignPrice.toFixed(2)}
                </span>
              ) : null}
            </div>
          </div>
        )}
      </div>

      {/* Buttons */}
      <div className="flex flex-col md:flex-row justify-between items-center gap-2 md:gap-4 p-2 md:p-4">
  <button 
    onClick={handleAddToCart} 
    className="bg-blue-500 text-white text-sm font-semibold px-2 py-2 rounded hover:bg-blue-600 transition w-full md:w-auto"
  >
    Add to Cart
  </button>
  <button 
    onClick={handleBuyNow} 
    className="bg-green-500 text-white text-sm font-semibold px-2 py-2 rounded hover:bg-green-600 transition w-full md:w-auto"
  >
    Order Now
  </button>
</div>

    </div>
  );
};

export default ProductCard;













// import React from "react";
// import { Link } from "react-router-dom";

// const ProductCard = ({ product }) => {
  
//   return (
//     <Link
//       to={`/shop/products/${product.title}`}
//       className="border rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl bg-white"
//     >
//       <div className="relative w-full h-48 md:h-56">
//         {product.colorSizeVariants && product.colorSizeVariants.length > 0 ? (
//           <img
//             src={product.colorSizeVariants[0]?.image}
//             alt={product.title}
//             className="w-full h-full object-contain transition-all duration-300 ease-in-out transform hover:scale-110"
//           />
//         ) : (
//           <img
//             src={product.sizeVariants[0]?.image}
//             alt={product.title}
//             className="w-full h-full object-contain transition-all duration-300 ease-in-out transform hover:scale-110"
//           />
//         )}
//       </div>
//       <div className="p-4">
//         <h2 className="text-xs sm:text-sm md:text-lg font-bold line-clamp-2">{product.title}</h2>

//         {product.upcoming ? (
//           <p className="text-sm text-gray-500">Coming soon</p>
//         ) : (
//           <>
//             {product.colorSizeVariants &&
//               product.colorSizeVariants.length > 0 && (
//                 <div className="mt-2">
//                   <div
//                     className="flex flex-col md:flex-row justify-between items-start sm:items-center mt-1 text-sm md:text-base"
//                   >

//                     {product.inOffer?.isActive ? (
//                       <span className="flex flex-col md:flex-row items-center space-x-2">
//                         <span className="text-red-500 text-xs sm:text-sm md:text-lg font-semibold">
//                           TK.
//                           {product.colorSizeVariants[0]?.sizes[0].offerPrice.toFixed(
//                             2
//                           )}
//                         </span>
//                         <span className="text-gray-500 text-xs sm:text-sm md:text-lg font-semibold line-through">
//                           TK.
//                           {product.colorSizeVariants[0]?.sizes[0].price.toFixed(
//                             2
//                           )}
//                         </span>
//                       </span>
//                     ) : (
//                       <span>
//                         TK.
//                         {product.colorSizeVariants[0]?.sizes[0].price.toFixed(
//                           2
//                         )}
//                       </span>
//                     )}
//                   </div>
//                 </div>
//               )}

//             {product.sizeVariants && product.sizeVariants.length > 0 && (
//               <div className="mt-2">
//                 <div
//                   className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-1 text-sm md:text-base"
//                 >

//                   {product.inOffer ? (
//                     <span className="flex flex-col md:flex-row items-center space-x-2">
//                       <span className="text-red-500 text-xs sm:text-sm md:text-lg font-semibold">
//                         TK. {product.sizeVariants[0]?.offerPrice.toFixed(2)}
//                       </span>
//                       <span className="text-gray-500 text-xs sm:text-sm md:text-lg line-through">
//                         TK. {product.sizeVariants[0]?.price.toFixed(2)}
//                       </span>
//                     </span>
//                   ) : (
//                     <span>TK. {product.sizeVariants[0]?.price.toFixed(2)}</span>
//                   )}
//                 </div>
//               </div>
//             )}
//           </>
//         )}
//       </div>
//     </Link>
//   );
// };

// export default ProductCard;
