import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Carousel from '../components/Carousel';
import HeadlineTicker from '../components/HeadlineTicker';
import BusinessLinks from '../components/BusinessLinks';
import SliderComponent from '../components/SliderComponent';
import ShowCompanyEmploys from '../components/ShowCompanyEmploys';
import CompanyProfile from '../components/CompanyProfile';
import LoaderComponent from '../components/LoaderComponent';
import {getMetaData} from '../features/metaData/metaDataSlice';
import SEO from '../components/Seo';
import { getSliderImagesForHome } from '../features/sliderImage/sliderImageSlice';


const Home = () => {

  const { metaData, loading } =  useSelector((state) => state.metaData);
  const { sliderImagesForHome } = useSelector((state) => state.sliderImage);

  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getMetaData());
    dispatch(getSliderImagesForHome());
  }, [dispatch]);

  
 

  const  { executives, headline, employees} = metaData;

  const imageUrl = `https://www.paribarmart.com/introduction.jpg`;
  
  if(loading) return <LoaderComponent />

  return (
    <>
    <SEO title={"Paribarmart || Home"} url={"https://www.paribarmart.com"} description={`We are dedicated to providing innovative solutions that push the
      boundaries and deliver unmatched quality to our clients.`} image={imageUrl}  />
      
      <div className="container mx-auto">
      {sliderImagesForHome && sliderImagesForHome.length > 0 && <Carousel slides={sliderImagesForHome}/>}
      <HeadlineTicker headline={headline} />
        <BusinessLinks />
        <CompanyProfile />
        {executives && executives.length > 0 && <SliderComponent executives={executives} /> }
        {employees && employees.length > 0 && <ShowCompanyEmploys employees={employees} /> }
      </div>
      </>
    
  );
};

export default Home;
