import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createHeadline,updateHeadline } from '../../features/headline/headlineSlice';
import { useDispatch } from 'react-redux';

const HeadlineForm = ({mode="create",initialValue={}}) => {
  
  const dispatch = useDispatch();
  // Formik validation schema for the CreateCategoryForm
  const formik = useFormik({
    initialValues: {
      headline: initialValue.headline || '',
    },
    validationSchema: Yup.object({
      headline: Yup.string().required('headline body is required'),
    }),
    onSubmit: (values, { resetForm }) => {
      // Perform the API request to create the category 
      if (mode === 'create') {
      dispatch(createHeadline({headline:values.headline}));
      resetForm();
      } else {
        dispatch(updateHeadline({headlineId:initialValue._id,headlineData:values.headline}));
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-4">
        <label className="block text-gray-700">headline body</label>
        <input
          type="text"
          name="headline"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.headline}
          className="mt-1 p-2 block w-full border rounded-md"
        />
        {formik.touched.body && formik.errors.headline ? (
          <div className="text-red-500 text-sm">{formik.errors.headline}</div>
        ) : null}
      </div>

      <button type="submit" className="w-full text-center bg-blue-600 text-white p-2 rounded hover:bg-blue-700">
        {mode === 'create' ? 'Create Headline' : 'Update Headline'}
      </button>
    </form>
  );
};

export default HeadlineForm;