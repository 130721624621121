import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";
import { toast } from "react-toastify";

// get all phone order details
export const getPhoneOrderDetails = createAsyncThunk(
  "phoneOrder/getAllPhoneOrderDetails",
  async () => {
    const response = await api.get("/phone-order-details");
    return response.data;
  }
);

// create phone order details
export const createPhoneOrderDetails = createAsyncThunk(
  "phoneOrder/createPhoneOrderDetails",
  async (data) => {
    const response = await api.post("/phone-order-details", data);
    if ( response.status === 200) {
        toast.success("Phone order details created successfully");
    }
    return response.data;
  }
);

// update phone order details
export const updatePhoneOrderDetails = createAsyncThunk(
  "phoneOrder/updatePhoneOrderDetails",
  async ({id,data}) => {
    const response = await api.put(`/phone-order-details/${id}`, data);
    if ( response.status === 200) {
        toast.success("Phone order details updated successfully");
    }
    return response.data;
  }
);

const phoneOrderSlice = createSlice({
  name: "phoneOrder",
  initialState: {
    phoneOrderDetails: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPhoneOrderDetails.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPhoneOrderDetails.fulfilled, (state, action) => {
        state.phoneOrderDetails = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getPhoneOrderDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createPhoneOrderDetails.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createPhoneOrderDetails.fulfilled, (state, action) => {
        state.phoneOrderDetails = [...state.phoneOrderDetails, action.payload];
        state.loading = false;
        state.error = null;
      })
      .addCase(createPhoneOrderDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updatePhoneOrderDetails.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updatePhoneOrderDetails.fulfilled, (state, action) => {
        state.phoneOrderDetails = state.phoneOrderDetails.map((item) => {
          if (item._id === action.payload._id) {
            return action.payload;
            }
            return item;
            });
        state.loading = false;
        state.error = null;
      })
      .addCase(updatePhoneOrderDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default phoneOrderSlice.reducer;
