import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllReferCommission,
  getAllReferralsByAffiliate,
} from "../../features/allReferrals/allReferralSlice";
import { getBonusesByAffiliate } from "../../features/bonus/bonusSlice";
import LoaderComponent from "../LoaderComponent";
import Pagination from "../Pagination";

const ReferralContent = () => {
  const { allReferralsByAffiliate, allReferCommission, pagination, totalAmount, loading } =
    useSelector((state) => state.allReferral);
  const { bonuses,totalBonus,pagination:bonusPagination, loading: bonusLoading } = useSelector(
    (state) => state.bonus
  );
  const [activeTab, setActiveTab] = useState("affiliateReferral");

  const [filter, setFilter] = useState("all"); // Filter options: all, today, thisMonth, custom
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState({
    filterType: filter,
    limit: 10,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (activeTab === "affiliateReferral") {
      dispatch(getAllReferralsByAffiliate({ query, page }));
    } else if (activeTab === "referCommission") {
      dispatch(getAllReferCommission({ query, page }));
    } else if (activeTab === "bonus") {
      dispatch(getBonusesByAffiliate());
    }
  }, [dispatch, query, activeTab, page]);

  const applyFilter = () => {
    if (filter === "all") {
      setQuery({ filterType: filter, limit });
    } else if (filter === "today") {
      setQuery({ filterType: filter, limit });
    } else if (filter === "thisMonth") {
      setQuery({ filterType: filter, limit });
    } else if (filter === "custom" && startDate && endDate) {
      setQuery({
        filterType: filter,
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate).toISOString(),
        limit,
      });
    }
  };

  // const totalAffiliateCommission = allReferralsByAffiliate.reduce(
  //   (sum, referral) => sum + referral.commission,
  //   0
  // );
  // const totalReferCommission = allReferCommission.reduce(
  //   (sum, commission) => sum + commission.commission,
  //   0
  // );

  // const totalBonuses = bonuses.reduce((sum, bonus) => sum + bonus.amount, 0);

  if (loading || bonusLoading) return <LoaderComponent />;

  return (
    <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4">My Referrals</h2>

      {/* Tab Navigation */}
      <div className="flex justify-center mb-6">
        <button
          onClick={() => setActiveTab("affiliateReferral")}
          className={`px-4 py-2 mx-2 font-semibold rounded ${
            activeTab === "affiliateReferral"
              ? "bg-blue-500 text-white"
              : "bg-gray-300 text-gray-700"
          }`}
        >
          Affiliate Referral
        </button>
        <button
          onClick={() => setActiveTab("referCommission")}
          className={`px-4 py-2 mx-2 font-semibold rounded ${
            activeTab === "referCommission"
              ? "bg-blue-500 text-white"
              : "bg-gray-300 text-gray-700"
          }`}
        >
          Refer Commission
        </button>
        <button
          onClick={() => setActiveTab("bonus")}
          className={`px-4 py-2 mx-2 font-semibold rounded ${
            activeTab === "bonus"
              ? "bg-blue-500 text-white"
              : "bg-gray-300 text-gray-700"
          }`}
        >
          Bonuses
        </button>
      </div>

      {/* Date Filter */}
      {activeTab !== "bonus" && (
        <div className="flex flex-col sm:flex-row items-center justify-between mb-6 gap-4">
          <select
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
            className="px-4 py-2 rounded border bg-white"
          >
            <option value="all">All</option>
            <option value="today">Today</option>
            <option value="thisMonth">This Month</option>
            <option value="custom">Custom</option>
          </select>

          {filter === "custom" && (
            <div className="flex flex-col md:flex-row items-center gap-4">
              <div>
                <label
                  htmlFor="startDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDate"
                  value={startDate || ""}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="px-4 py-2 rounded border"
                />
              </div>
              <div>
                <label
                  htmlFor="endDate"
                  className="block text-sm font-medium text-gray-700"
                >
                  End Date
                </label>
                <input
                  type="date"
                  id="endDate"
                  value={endDate || ""}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="px-4 py-2 rounded border"
                />
              </div>
            </div>
          )}

          <div className="mb-4">
            <label className="block text-sm font-medium mb-2">Set Limit</label>
            <input
              type="number"
              value={limit}
              onChange={(e) => setLimit(e.target.value)}
            />
          </div>

          <button
            onClick={applyFilter}
            className="px-5 py-2 rounded-full text-sm font-semibold shadow-md transition-colors bg-blue-500 text-white hover:bg-blue-600"
          >
            Apply
          </button>
        </div>
      )}

      {/* Summary Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <SummaryCard
          label={
            activeTab === "affiliateReferral"
              ? "Total Affiliate Commission"
              : activeTab === "referCommission"
              ? "Total Refer Commission"
              : "Total Bonus"
          }
          amount={`TK. ${
            activeTab === "affiliateReferral"
              ? totalAmount
              : activeTab === "referCommission"
              ? totalAmount
              : totalBonus
          }`}
        />
        {/* <SummaryCard
          label={
            activeTab === "affiliateReferral"
              ? "Total Affiliate Commission"
              : activeTab === "referCommission"
              ? "Total Refer Commission"
              : "Total Bonus"
          }
          amount={`TK. ${
            activeTab === "affiliateReferral"
              ? totalAffiliateCommission
              : activeTab === "referCommission"
              ? totalReferCommission
              : totalBonuses
          }`}
        /> */}
        <SummaryCard
          label={
            activeTab === "affiliateReferral"
              ? "Total Order Count"
              : activeTab === "referCommission"
              ? "Total Refer Count"
              : "Total Bonus Count"
          }
          amount={`Total:  ${
            activeTab === "affiliateReferral"
              ? pagination?.totalItems || 0
              : activeTab === "referCommission"
              ? pagination?.totalItems || 0
              : bonusPagination?.totalItems || 0
          }`}
        />
      </div>

      {/* Referrals List */}
      <div className="bg-white p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">
          {activeTab === "affiliateReferral"
            ? "Affiliate Referral Details"
            : activeTab === "referCommission"
            ? "Refer Commission Details"
            : "Bonus Details"
            }
        </h3>
        <div className="divide-y">
          {(activeTab === "affiliateReferral"
            ? allReferralsByAffiliate
            : activeTab === "referCommission"
            ? allReferCommission
            : bonuses
          ).length === 0 ? (
            <p className="text-gray-500 text-center">
              You have no{" "}
              {activeTab === "affiliateReferral"
                ? "affiliate referrals"
                : "refer commissions"}
              .
            </p>
          ) : (
            (activeTab === "affiliateReferral"
              ? allReferralsByAffiliate
              : activeTab === "referCommission"
              ? allReferCommission
              : bonuses
            ).map((referral, index) => (
              <ReferralCard
                key={index}
                referral={referral}
                isAffiliate={activeTab === "affiliateReferral"}
                isBonus={activeTab === "bonus"}
              />
            ))
          )}
        </div>
      </div>
      {
        activeTab === "bonus" ? <Pagination
          currentPage={page}
          totalPages={bonusPagination?.totalPages}
          onPageChange={() => setPage(page)}
        /> :<Pagination
        currentPage={page}
        totalPages={pagination?.totalPages}
        onPageChange={() => setPage(page)}
      />
      }
      
    </div>
  );
};

// Summary Card Component
const SummaryCard = ({ label, amount }) => (
  <div className="p-4 bg-blue-500 text-white rounded-lg shadow-md">
    <p className="text-sm font-medium">{label}</p>
    <p className="text-xl font-bold">{amount}</p>
  </div>
);

// Referral Card Component
const ReferralCard = ({ referral, isAffiliate, isBonus }) => (
  <div className="flex justify-between items-center py-3">
    <div>
      {isAffiliate ? (
        <>
          <p className="text-gray-700 font-medium">
            Order ID: {referral.order?.orderId}
          </p>
          <p className="text-sm text-gray-500">
            Commission: TK. {referral.commission}
          </p>
        </>
      ) : isBonus ? (
        <>
          <p className="text-gray-700 font-medium">
            Notes: {referral.notes}
          </p>
          <p className="text-sm text-gray-500">
            Amount: TK. {referral.amount}
          </p>
        </>
      ): (
        <>
          <p className="text-gray-700 font-medium">
            Affiliate Name: {referral.referralOfAffiliate?.name}
          </p>
          <p className="text-sm text-gray-500">
            Commission: TK. {referral.commission}
          </p>
        </>
      )}
    </div>
    {/* {isAffiliate && (
      <button className="text-blue-500 text-sm underline hover:text-blue-700">
        View Order
      </button>
    )} */}
  </div>
);

export default ReferralContent;
