import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createVideoCourse, updateVideoCourse } from "../../features/videoCourse/videoCourseSlice";
import { useDispatch } from "react-redux";

const VideoCourseForm = ({ initialValues, onClose, isEditing }) => {

  const dispatch = useDispatch();

  const { title, description, url } =
    initialValues || {};
  const newValues = {
    title,
    description,
    url,
  };


  const formik = useFormik({
    initialValues: newValues || {
        title: "",
        description: "",
        url: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Name is required"),
      description: Yup.string().required("Description is required"),
      url: Yup.string().required("URL is required"),
    }),
    onSubmit: (values,{resetForm}) => {
      if (isEditing) {
        dispatch(updateVideoCourse({videoCourseId:initialValues._id,videoCourseData:values}));
        resetForm()
       
        onClose(); // Close the modal after submission
      } else {
        dispatch(createVideoCourse({videoCourse:values,token:JSON.parse(localStorage.getItem("token"))}));
        resetForm()
        onClose()
      }
    },
  });

 

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-md shadow-md">
      <form onSubmit={formik.handleSubmit} className="space-y-4">
        {/* name */}
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Title
          </label>
          <input
            type="text"
            name="title"
            className="w-full p-2 border rounded-md"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.title}
          />
          {formik.touched.title && formik.errors.title && (
            <p className="text-red-500 text-xs mt-1">{formik.errors.title}</p>
          )}
        </div>

        {/* description */}
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Description
          </label>
          <textarea
            name="description"
            className="w-full p-2 border rounded-md"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.description}
          />
          {formik.touched.description && formik.errors.description && (
            <p className="text-red-500 text-xs mt-1">
              {formik.errors.description}
            </p>
          )}
        </div>

        {/* url */}
        <div>
          <label className="block text-sm font-medium text-gray-600">
            Video Url ( youtube video url )
          </label>
          <textarea
            name="url"
            className="w-full p-2 border rounded-md"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.url}
          />
          {formik.touched.description && formik.errors.url && (
            <p className="text-red-500 text-xs mt-1">
              {formik.errors.url}
            </p>
          )}
        </div>

        <button
          type="submit"
          className="w-full mt-4 p-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
        >
          {isEditing ? "Update video course" : "Create video course"}
        </button>
      </form>
    </div>
  );
};

export default VideoCourseForm;
