import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createContact, resetSuccess } from '../features/contact/contactSlice';

const Contact = () => {
  const dispatch = useDispatch();
  const { loading, success, error } = useSelector((state) => state.contact);

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        dispatch(resetSuccess());
      }, 3000);
    }
  }, [success, dispatch]);

  // Formik with Yup validation schema
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email').required('Email is required'),
      message: Yup.string().min(10, 'Message must be at least 10 characters').required('Message is required'),
    }),
    onSubmit: (values,{resetForm}) => {
      dispatch(createContact(values));
      resetForm();
    },
  });


  return (
    <div>
      {/* Hero Section */}
      <section className="relative bg-gradient-to-r from-blue-500 to-teal-500 text-white py-20">
        <div className="absolute inset-0 bg-cover bg-center opacity-40" ></div>
        <div className="relative container mx-auto text-center">
          <h1 className="text-4xl md:text-5xl font-bold leading-tight mb-4">Get In Touch</h1>
          <p className="text-lg md:text-xl mb-8">We'd love to hear from you! Fill out the form below or reach out to us directly.</p>
        </div>
      </section>

      {/* Contact Form */}
      <section className="container mx-auto py-12 px-6 md:px-12">
        <div className="max-w-xl mx-auto rounded-lg shadow-lg p-8 space-y-6">
          <h2 className="text-3xl font-semibold text-gray-800 text-center">Contact Us</h2>

          {success && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4 text-center">
              Message sent successfully!
            </div>
          )}

          <form onSubmit={formik.handleSubmit}>
            <div className="grid grid-cols-1 gap-6">
              {/* Name */}
              <div>
                <label htmlFor="name" className="block text-gray-700 font-medium">Your Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your name"
                />
                {formik.touched.name && formik.errors.name && (
                  <div className="text-red-600 text-sm mt-1">{formik.errors.name}</div>
                )}
              </div>

              {/* Email */}
              <div>
                <label htmlFor="email" className="block text-gray-700 font-medium">Your Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Enter your email"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="text-red-600 text-sm mt-1">{formik.errors.email}</div>
                )}
              </div>

              {/* Message */}
              <div>
                <label htmlFor="message" className="block text-gray-700 font-medium">Your Message</label>
                <textarea
                  id="message"
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  className="w-full px-4 py-2 mt-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                  rows="6"
                  placeholder="Your message"
                ></textarea>
                {formik.touched.message && formik.errors.message && (
                  <div className="text-red-600 text-sm mt-1">{formik.errors.message}</div>
                )}
              </div>
            </div>

            <button
              type="submit"
              disabled={loading}
              className={`w-full mt-6 py-3 rounded-md shadow-md font-semibold transition-all duration-300 ${
                loading ? 'bg-gray-400' : 'bg-blue-600 text-white hover:bg-blue-700'
              }`}
            >
              {loading ? 'Sending...' : 'Send Message'}
            </button>
          </form>

          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mt-4 text-center">
              {error}
            </div>
          )}
        </div>
      </section>

      {/* Location Section */}
      {/* <section className="bg-gray-100 py-16">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-semibold text-gray-800 mb-4">Our Location</h2>
          <p className="text-lg text-gray-600 mb-8">Come visit us or find us on the map below!</p>

          <div className="mx-auto w-full max-w-4xl mb-8">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.952270635267!2d144.96305841531694!3d-37.81249797975196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d5f0919b419%3A0xb8ad6ccf7d6abf5f!2sFederation%20Square!5e0!3m2!1sen!2sau!4v1617872724440!5m2!1sen!2sau"
              width="100%"
              height="400"
              style={{ border: '0' }}
              allowFullScreen=""
              loading="lazy"
              title="map"
            ></iframe>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default Contact;
