import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { changePassword, changeResellerPassword } from '../../features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';

const ChangePassword = ({role="user"}) => {
    const { userInfo } = useSelector(state => state.auth);

    const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      newPassword: '',
      confirmedPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(6, 'Password must be at least 8 characters long')
        .required('New Password is required'),
      confirmedPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm Password is required'),
    }),
    onSubmit: (values, { resetForm }) => {
      const { newPassword } = values;

      if (role === 'user' || role === 'admin') {
        dispatch(changePassword({id: userInfo._id, userData: {password: newPassword,type: role}}));
        resetForm();
      }else if(role === 'reseller'){
          dispatch(changeResellerPassword({id: userInfo._id, userData: {password: newPassword,type: role}}));
          resetForm();
      }
    },
  });

  return (
    <div className="max-w-md mx-auto bg-white p-6 rounded-md shadow-md pt-8">
      <h2 className="text-2xl font-bold mb-4">Change Password</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4">
          <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
            New Password
          </label>
          <input
            id="newPassword"
            name="newPassword"
            type="password"
            className={`mt-1 block w-full px-3 py-2 border ${
              formik.touched.newPassword && formik.errors.newPassword
                ? 'border-red-500'
                : 'border-gray-300'
            } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
          />
          {formik.touched.newPassword && formik.errors.newPassword && (
            <p className="mt-1 text-sm text-red-500">{formik.errors.newPassword}</p>
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="confirmedPassword" className="block text-sm font-medium text-gray-700">
            Confirm Password
          </label>
          <input
            id="confirmedPassword"
            name="confirmedPassword"
            type="password"
            className={`mt-1 block w-full px-3 py-2 border ${
              formik.touched.confirmedPassword && formik.errors.confirmedPassword
                ? 'border-red-500'
                : 'border-gray-300'
            } rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500`}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmedPassword}
          />
          {formik.touched.confirmedPassword && formik.errors.confirmedPassword && (
            <p className="mt-1 text-sm text-red-500">{formik.errors.confirmedPassword}</p>
          )}
        </div>
        <button
          type="submit"
          className="w-full bg-indigo-500 text-white py-2 px-4 rounded-md hover:bg-indigo-600"
        >
          Change Password
        </button>
      </form>
    </div>
  );
};

export default ChangePassword;
