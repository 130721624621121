import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/api";
import { toast } from "react-toastify";

// get all bonuses by admin
export const getBonusesByAdmin = createAsyncThunk("bonus/getBonusesByAdmin", async ({
    page=1,
    limit=10
}) => {
    const response = await api.get(`/bonus?page=${page}&limit=${limit}`);
    return response.data;
});


// get all bonuses by reseller
export const getBonusesByReseller = createAsyncThunk("bonus/getBonusesByReseller", async ({page=1,limit=10,token}) => {
    const response = await api.get(`/bonus/resellers?page=${page}&limit=${limit}`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
});

// get all bonuses by affiliate
export const getBonusesByAffiliate = createAsyncThunk("bonus/getBonusesByAffiliate", async (page=1,limit=10,{token}) => {
    const response = await api.get(`/bonus/affiliates?page=${page}&limit=${limit}`, {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
});

// create a bonus by admin
export const createBonusByAdmin = createAsyncThunk("bonus/createBonusByAdmin", async (data) => {
    const response = await api.post("/bonus", data);
    if(response.status === 200) {
        toast.success("Bonus created successfully");
    };
    
    return response.data;
});

// update a bonus by admin
export const updateBonusByAdmin = createAsyncThunk("bonus/updateBonusByAdmin", async ({id,data}) => {
    const response = await api.put(`/bonus/${id}`, data);
    if(response.status === 200) {
        toast.success("Bonus updated successfully");
    }
    return response.data;
});

const bonusSlice = createSlice({
    name: "bonus",
    initialState: {
        bonuses: [],
        totalBonus: 0,
        pagination: {},
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBonusesByAdmin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getBonusesByAdmin.fulfilled, (state, action) => {
                state.loading = false;
                state.bonuses = action.payload.bonuses;
                state.pagination = action.payload.pagination;
            })
            .addCase(getBonusesByAdmin.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getBonusesByReseller.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getBonusesByReseller.fulfilled, (state, action) => {
                state.loading = false;
                state.bonuses = action.payload.bonuses;
                state.totalBonus = action.payload.totalAmount;
                state.pagination = action.payload.pagination;
            })
            .addCase(getBonusesByReseller.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getBonusesByAffiliate.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getBonusesByAffiliate.fulfilled, (state, action) => {
                state.loading = false;
                state.bonuses = action.payload.bonuses;
                state.totalBonus = action.payload.totalAmount;
                state.pagination = action.payload.pagination;
            })
            .addCase(getBonusesByAffiliate.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createBonusByAdmin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createBonusByAdmin.fulfilled, (state, action) => {
                state.loading = false;
                state.bonuses.push(action.payload);
            })
            .addCase(createBonusByAdmin.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(updateBonusByAdmin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateBonusByAdmin.fulfilled, (state, action) => {
                state.loading = false;
                state.bonuses = state.bonuses.map((item) => {
                    if (item._id === action.payload._id) {
                        return action.payload;
                    }
                    return item;
                });
            })
            .addCase(updateBonusByAdmin.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default bonusSlice.reducer;