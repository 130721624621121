import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { createOrderBonus , updateOrderBonus} from '../../features/orderBonus/orderBonusSlice';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const OrderBonusCreateForm = ({ onClose, initialValue ={}, mode="create" }) => {
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState(initialValue.image || null);

  const formik = useFormik({
    initialValues: {
      title: initialValue.title || '',
      description: initialValue.description || '',
      image: initialValue.image || '',
    },
    validationSchema: Yup.object({
      title: Yup.string().required('Title is required'),
      description: Yup.string().required('Description is required'),
      image: Yup.string().required('Image is required'),
    }),
    onSubmit: (values, { resetForm }) => {
      if (mode === 'create') {        
        dispatch(createOrderBonus({orderBonus:values, token:JSON.parse(localStorage.getItem("token"))}));
        resetForm();
        onClose();
      }
      if (mode === 'edit') {
        dispatch(updateOrderBonus({id:initialValue._id,orderBonusData:values, token:JSON.parse(localStorage.getItem("token"))}));        
        resetForm();
        onClose();
      }
    },
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        formik.setFieldValue('image', reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white w-2/3 max-w-lg p-4 rounded shadow-md overflow-auto max-h-[90vh] md:max-h-[80vh] relative">
      <button
          onClick={onClose}
          className="absolute text-white mb-6 p-2 bg-red-500 top-2 right-2 rounded hover:bg-red-600 transition-all"
        >
          Close
        </button>
        <form onSubmit={formik.handleSubmit} className="space-y-4">
          <h2 className="text-xl font-semibold text-center"> {mode === 'create' ? 'Create Order Bonus' : 'Edit Order Bonus'} </h2>

          <div>
            <label className="block font-semibold">Title</label>
            <input
              type="text"
              name="title"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.title}
              className="mt-1 p-2 w-full border rounded"
            />
            {formik.touched.title && formik.errors.title && (
              <p className="text-red-500 text-sm">{formik.errors.title}</p>
            )}
          </div>

          <div>
            <label className="block font-semibold">Description</label>
            <textarea
              name="description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.description}
              className="mt-1 p-2 w-full border rounded"
            />
            {formik.touched.description && formik.errors.description && (
              <p className="text-red-500 text-sm">{formik.errors.description}</p>
            )}
          </div>

          <div>
            <label className="block font-semibold">Image</label>
            <input
              type="file"
              onChange={handleImageChange}
              className="mt-1 p-2 w-full border rounded"
            />
            {imagePreview && <img src={imagePreview} alt="Preview" className="mt-2 w-full h-32 object-cover rounded" />}
            {formik.touched.image && formik.errors.image && (
              <p className="text-red-500 text-sm">{formik.errors.image}</p>
            )}
          </div>

          <button type="submit" className="w-full bg-blue-500 hover:bg-blue-600 text-white py-2 rounded">
            {mode === 'create' ? 'Create Order Bonus' : 'Update Order Bonus'}
          </button>
          <button onClick={onClose} type="button" className="mt-2 w-full bg-gray-300 text-gray-700 py-2 rounded">
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default OrderBonusCreateForm;
