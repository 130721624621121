import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FaShoppingCart, FaSearch, FaBell, FaPhoneAlt } from "react-icons/fa";
import { FiMenu, FiX } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../features/auth/authSlice";
// import Logo from "../images/logo.png";
// import userProfile from "../images/profile.webp";
import CartSidebar from "../pages/Cart";
import { getCartItems } from "../utils/cartUtils";
// import { debounce } from "lodash";


const Navbar = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { menuItems } = useSelector((state) => state.navbar);
  const [cart, setCart] = useState([]);
  const [cartOpen, setCartOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isResellerDropdownOpen, setIsResellerDropdownOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const location = useLocation();

  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
    setMenuOpen(false);
    window.location.reload();
  };

  // handle toggle dropdown
  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const toggleResellerDropdown = () =>
    setIsResellerDropdownOpen(!isResellerDropdownOpen);

  // Debounced search handler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debouncedSearch = useCallback(
  //   debounce((query) => {
  //     if (query) {
  //       navigate(`/shop/products?search=${query}`);
  //     }
  //   }, 5000), // Adjust debounce delay as needed
  //   [navigate, searchQuery]
  // );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    // debouncedSearch(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery) {
      navigate(`/shop/products?search=${searchQuery}`);
    }
  };

  // useEffect(() => {
  //   return () => {
  //     debouncedSearch.cancel(); // Cleanup debounce on unmount
  //   };
  // }, [debouncedSearch]);

  useEffect(() => {
    // Update cart when items change
    const updateCart = () => {
      setCart(getCartItems());
    };

    // Listen for the custom event
    window.addEventListener("cartUpdated", updateCart);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("cartUpdated", updateCart);
    };
  }, []);

  return (
    <>
      {/* Main Navbar */}
      <nav className="bg-green-900 shadow-lg sticky top-0 z-50">
        <div className="hidden md:container md:flex justify-between items-center mx-auto ">
          <Link to="/" className="w-full text-black bg-red-400 hover:text-black hover:bg-gray-200 p-2 flex items-center justify-between">
            Company Profile
          </Link>
          <Link to="/track-order" className="w-full text-black bg-green-200 hover:text-black hover:bg-gray-200 p-2 flex items-center justify-between">
            Track your order
          </Link>
          <a href="tel:+8809613822141" className="w-full text-black bg-pink-600 hover:text-black hover:bg-gray-200 pt-2 pb-2 pl-2 pr-4 flex items-center justify-between">
            <div className="flex items-center">
              <FaPhoneAlt className="text-2xl" />
              <span>+8809613822141</span>
            </div>
          </a>

          <Link
            to="/"
            className="w-full text-black bg-yellow-400 hover:text-black hover:bg-gray-200 p-2 flex items-center justify-between"
          >
            <FaBell className="text-2xl" />
          </Link>
        </div>

        {/* Mobile top bar */}
        <div className="flex justify-center md:hidden">
          <div className="flex items-center">
            <Link
              to="/track-order"
              className={`text-black bg-green-200 hover:text-black hover:bg-gray-200 p-2 flex items-center justify-between last:justify-end mb-0`}
            >
              Track your order
            </Link>
            <a
              href="tel:+8809613822141"
              className={`text-black bg-pink-600 hover:text-black hover:bg-gray-200 pt-2 pb-2 pl-2 pr-4 flex items-center justify-between last:justify-end mb-0`}
            >
              <div className="flex items-center">
                <FaPhoneAlt className="text-2xl" />
                <span>+8809613822141</span>
              </div>
            </a>
          </div>
        </div>

        <div className="container flex justify-between items-center mx-auto">
          {/* Logo */}
          <Link to="/" className="text-black text-2xl font-bold">
            <img src="/logo.png" alt="Logo" className="w-24 md:32 mr-2" />
          </Link>

          {/* Search Bar */}
          <form
            onSubmit={handleSearchSubmit}
            className="flex items-center w-full max-w-md mx-4"
          >
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search On Paribar"
              className="p-2 w-full border border-gray-300 rounded-l-lg focus:outline-none"
            />
            <button type="submit" className="bg-purple-600 p-3 rounded-r-lg">
              <FaSearch className="text-white" />
            </button>
          </form>

          {/* Desktop Links */}
          <div className="hidden md:flex space-x-4 items-center">
            {menuItems.map((item) => (
              <Link
                key={item.name}
                to={item.link}
                className={`${
                  window.location.url === item.link
                    ? "bg-gray-200 text-black rounded"
                    : "text-white"
                } hover:text-black hover:bg-gray-200 p-2 rounded`}
              >
                {item.name}
              </Link>
            ))}

            {!userInfo && window.location.pathname.startsWith("/reseller") && (
              <div className="relative">
                <button
                  onClick={toggleResellerDropdown}
                  className={`flex items-center space-x-2 ${
                    isResellerDropdownOpen
                      ? "bg-gray-200 text-black rounded"
                      : "text-white"
                  }  hover:text-black hover:bg-gray-200 p-2 rounded-lg`}
                >
                  <span>BECOME A RESELLER</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                {isResellerDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-sky-200 text-gray-800 rounded shadow-lg z-10">
                    <Link
                      onClick={toggleResellerDropdown}
                      to="/reseller/signup"
                      className="block px-4 py-2 hover:bg-gray-200"
                    >
                      Register
                    </Link>
                    <Link
                      onClick={toggleResellerDropdown}
                      to="/reseller/login"
                      className="block px-4 py-2 hover:bg-gray-200"
                    >
                      Signin
                    </Link>
                    <Link
                      onClick={toggleResellerDropdown}
                      to="/reseller"
                      className="block px-4 py-2 hover:bg-gray-200"
                    >
                      Reseller Shop
                    </Link>
                  </div>
                )}
              </div>
            )}
            {!userInfo && !window.location.pathname.startsWith("/reseller") && (
              <>
                <Link
                  to="/login"
                  className={`${
                    window.location.pathname === "/login"
                      ? "bg-gray-200 text-black rounded"
                      : "text-white"
                  } hover:text-black hover:bg-gray-200 p-2 rounded-lg`}
                >
                  SIGN IN
                </Link>
                {/* Become a Reseller */}
                <div className="relative">
                  <button
                    onClick={toggleResellerDropdown}
                    className={`flex items-center space-x-2 ${
                      isResellerDropdownOpen
                        ? "bg-gray-200 text-black rounded"
                        : "text-white"
                    }  hover:text-black hover:bg-gray-200 p-2 rounded-lg`}
                  >
                    <span>BECOME A RESELLER</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                    >
                      <path
                        fillRule="evenodd"
                        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>

                  {isResellerDropdownOpen && (
                    <div className="absolute right-0 mt-2 w-48 bg-sky-200 text-gray-800 rounded shadow-lg z-10">
                      <Link
                        onClick={toggleResellerDropdown}
                        to="/reseller/signup"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        Register
                      </Link>
                      <Link
                        onClick={toggleResellerDropdown}
                        to="/reseller/login"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        Signin
                      </Link>
                      <Link
                        onClick={toggleResellerDropdown}
                        to="/reseller"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        Reseller Shop
                      </Link>
                    </div>
                  )}
                </div>
              </>
            )}

            {/* Dropdown for My Account */}
            {userInfo && (
              <div className="relative">
                <button
                  onClick={handleDropdownToggle}
                  className="flex items-center space-x-2 text-white hover:text-black hover:bg-gray-200 p-2 rounded-lg"
                >
                  <img
                    src={userInfo.profilePicture || "/profile.webp"}
                    alt="User"
                    className="w-8 h-8 rounded-full"
                  />
                  <span>My Account</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>

                {/* Dropdown Menu */}
                {isDropdownOpen && (
                  <div className="absolute right-0 mt-2 w-48 bg-sky-200 text-gray-800 rounded shadow-lg z-10">
                    <Link
                      onClick={handleDropdownToggle}
                      to={`/${userInfo.role}/dashboard`}
                      className="block px-4 py-2 hover:bg-gray-200"
                    >
                      Dashboard
                    </Link>
                    <Link
                      onClick={handleDropdownToggle}
                      to={`/${userInfo.role}/dashboard`}
                      state={{ active: "profile" }}
                      className="block px-4 py-2 hover:bg-gray-200"
                    >
                      Profile
                    </Link>
                    {userInfo.role === "user" && (
                      <Link
                        onClick={handleDropdownToggle}
                        to={`/${userInfo.role}/dashboard`}
                        state={{
                          active: userInfo.affiliate
                            ? "affiliate"
                            : "join-affiliate",
                        }}
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        Affiliate
                      </Link>
                    )}
                    <button
                      onClick={handleLogout}
                      className="block w-full text-left px-4 py-2 hover:bg-gray-200"
                    >
                      Log Out
                    </button>
                  </div>
                )}
              </div>
            )}

            {/* Cart Icon */}
            <button
              onClick={() => setCartOpen(true)}
              className="text-green-300 text-2xl hover:text-green-800 hover:bg-gray-200 p-4 rounded-lg relative"
            >
              <FaShoppingCart />
              {cart.length > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full px-2 text-xs">
                  {cart.length}
                </span>
              )}
            </button>
          </div>

          {/* Hamburger Icon for Mobile */}
          <button
            onClick={() => setMenuOpen(true)}
            className="md:hidden text-black-300 p-2"
            aria-label="Open Menu"
          >
            <FiMenu size={24} />
          </button>
        </div>
      </nav>

      {/* Mobile Menu Modal with Animation */}
      {menuOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex justify-end ">
          <div
            className={`w-3/4 max-w-sm bg-red-600 p-6 shadow-lg transform transition-transform duration-400 h-full overflow-y-auto ${
              menuOpen ? "translate-x-0" : "translate-x-full"
            }`}
          >
            {/* Close Button */}
            <button
              onClick={() => setMenuOpen(false)}
              className="text-black-300 p-2 absolute top-4 right-4"
              aria-label="Close Menu"
            >
              <FiX size={24} />
            </button>

            {/* Mobile Navigation Links */}
            <nav className="flex flex-col space-y-4 mt-8">
              <Link
                to="/"
                className="text-white hover:text-white p-2"
                onClick={() => setMenuOpen(false)}
              >
                HOME
              </Link>

              {window.location.pathname.startsWith("/reseller") ? (
                <Link
                  to="/reseller"
                  className="text-white hover:text-white p-2"
                  onClick={() => setMenuOpen(false)}
                >
                  RESELLER-SHOP
                </Link>
              ) : (
                <Link
                  to="/shop"
                  className="text-white hover:text-white p-2"
                  onClick={() => setMenuOpen(false)}
                >
                  SHOP
                </Link>
              )}

              <Link
                to="/contacts"
                className="text-white hover:text-white p-2"
                onClick={() => setMenuOpen(false)}
              >
                Contact Us
              </Link>
              {!userInfo && (
                <>
                  <Link
                    onClick={() => setMenuOpen(false)}
                    to="/login"
                    className="text-white hover:text-white hover:bg-gray-200 p-2 rounded-lg"
                  >
                    SIGN IN
                  </Link>
                  <Link
                    onClick={() => setMenuOpen(false)}
                    to="/reseller/signup"
                    className="text-white hover:text-white hover:bg-gray-200 p-2 rounded-lg"
                  >
                    BECOME A RESELLER
                  </Link>
                </>
              )}

              {userInfo && (
                <Link
                  to={`/${userInfo.role}/dashboard`}
                  className="text-white hover:text-white p-2"
                  onClick={() => setMenuOpen(false)}
                >
                  <img
                    src={userInfo?.profilePicture || "/profile.webp"}
                    alt="User"
                    className="w-10 h-10 rounded-full inline-block"
                  />
                </Link>
              )}

              {userInfo && (
                <Link
                  onClick={handleLogout}
                  className="text-white hover:text-white hover:bg-gray-200 p-2 rounded-l"
                >
                  logout
                </Link>
              )}
              <button
                onClick={() => {
                  setCartOpen(true);
                  setMenuOpen(false);
                }}
                className="relative text-green-300 text-2xl hover:text-green-800 hover:bg-gray-200 p-4 rounded-lg relative"
              >
                <FaShoppingCart />
                {cart.length > 0 && (
                  <span className="absolute -top-2 left-8 bg-red-500 text-white rounded-full px-2 text-xs">
                    {cart.length}
                  </span>
                )}
              </button>
            </nav>
          </div>
        </div>
      )}

      {/* Cart Sidebar */}
      <CartSidebar isOpen={cartOpen} onClose={() => setCartOpen(false)} />
    </>
  );
};

export default Navbar;
