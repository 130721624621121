import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMenuItems } from "../features/navbar/navbarSlice";

const DynamicNavbarUpdater = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      // Case 1: Exact match for "/"
      dispatch(
        setMenuItems([
          { id: 1, name: "HOME", link: "/" },
          { id: 2, name: "SHOP", link: "/shop" },
        ])
      );
    } else if (location.pathname.startsWith("/reseller")) {
      // Case 2: Path starts with "/reseller"
      dispatch(
        setMenuItems([
          { id: 1, name: "HOME", link: "/" },
          { id: 2, name: "RESELLER SHOP", link: "/reseller" },
        ])
      );
    } else {
      // Default case
      dispatch(
        setMenuItems([
          { id: 1, name: "HOME", link: "/" },
          { id: 2, name: "SHOP", link: "/shop" },
        ])
      );
    }
  }, [location, dispatch]);

  return null; // This component doesn't render anything
};

export default DynamicNavbarUpdater;











// import { useEffect } from "react";
// import { useDispatch } from "react-redux";
// import { useLocation } from "react-router-dom";
// import { setMenuItems } from "../features/navbar/navbarSlice";

// const DynamicNavbarUpdater = () => {
//   const dispatch = useDispatch();
//   const location = useLocation();
//   useEffect(() => {
//     switch (location.pathname) {
//       case "/":
//         dispatch(
//           setMenuItems([
//             { id: 1, name: "HOME", link: "/" },
//             { id: 2, name: "SHOP", link: "/shop" },
//           ])
//         );
//         break;
//       case location.pathname.startsWith("/reseller"):
//         dispatch(
//           setMenuItems([
//             { id: 1, name: "HOME", link: "/" },
//             { id: 2, name: "RESELLER SHOP", link: "/reseller" },
//           ])
//         );
//         break;
//       default:
//         dispatch(
//           setMenuItems([
//             { id: 1, name: "HOME", link: "/" },
//             { id: 2, name: "SHOP", link: "/shop" },
//           ])
//         );
//     }
//   }, [location, dispatch]);

//   return null; // This component doesn't render anything
// };

// export default DynamicNavbarUpdater;
