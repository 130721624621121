export const getCartItems = (isServer = false, serverCart = []) => {
 
    // const cart = localStorage.getItem("cart");
    // return cart ? JSON.parse(cart) : [];
    if (isServer) {
      return serverCart; // Use server-provided cart if available
    }
    if (typeof window !== 'undefined') {
      const cart = localStorage.getItem("cart");
      return cart ? JSON.parse(cart) : [];
    }
    return [];
 
  };
  
  export const saveCartItems = (items) => {
    localStorage.setItem("cart", JSON.stringify(items));
  };
  
  // export const addToCart = (product, quantity = 1, reseller=null) => {
  //   const currentCart = getCartItems();
  //   const existingProduct = currentCart.find(item => item._id === product._id);
  
  //   if (existingProduct) {
  //     existingProduct.quantity += quantity;
  //   } else {
  //     currentCart.push({ ...product, quantity });
  //   }

  //   if(reseller) {
  //     localStorage.setItem('reseller', JSON.stringify(reseller));
  //   }
  
  //   saveCartItems(currentCart);
  //   // the event for immediately the product cart
  //   const event = new Event('cartUpdated');
  //   window.dispatchEvent(event);
  // };
  
  export const addToCart = (product, quantity = 1, from = 'shop', reseller=null) => {
    
    const existingFrom = localStorage.getItem('cartFrom'); // Retrieve the current 'from' value from local storage
  
    // If the `from` parameter is different from the saved value, clear the cart
    if (existingFrom && existingFrom !== from) {
      saveCartItems([]); // Clear the cart
      localStorage.setItem('cartFrom', from); // Update the source in local storage
      if(reseller) {
        localStorage.setItem('reseller', JSON.stringify(reseller));
      }
    } else if (!existingFrom) {
      localStorage.setItem('cartFrom', from); // Save the source if not already set
      if(reseller) {
        localStorage.setItem('reseller', JSON.stringify(reseller));
      }
    }
  
    

    // Retrieve the (now potentially cleared) cart
    const updatedCart = getCartItems();
    const existingProduct = updatedCart.find(item => item._id === product._id);
  
    if (existingProduct) {
      existingProduct.quantity += quantity; // Update quantity if product exists in the cart
    } else {
      updatedCart.push({ ...product, quantity }); // Add new product to the cart
    }
  
    // Save the updated cart
    saveCartItems(updatedCart);
  
    // Dispatch the cartUpdated event
    const event = new Event('cartUpdated');
    window.dispatchEvent(event);
  };
  

  export const removeFromCart = (productId) => {
    const updatedCart = getCartItems().filter(item => item._id !== productId);
    if (updatedCart.length === 0) {
      localStorage.removeItem("reseller");
    }
    saveCartItems(updatedCart);  
    window.dispatchEvent(new Event('cartUpdated'));  
  };
  
  // src/utils/cartUtils.js
export const clearCart = () => {
    localStorage.removeItem("cart");
    localStorage.removeItem("reseller");
    window.dispatchEvent(new Event('cartUpdated'));  
  };
  
  export const updateQuantity = (productId, quantity) => {
    const currentCart = getCartItems();
    const updatedCart = currentCart.map((item) =>
      item.id === productId ? { ...item, quantity } : item
    );
    saveCartItems(updatedCart);
  };
  