import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/api";
import { toast } from "react-toastify";

// fetch categories
export const fetchCategories = createAsyncThunk(
  "category/fetchCategories",
  async () => {
    const response = await api.get("/categories");
    return response.data;
  }
);

// fetch categories by admin
export const fetchCategoriesByAdmin = createAsyncThunk(
  "category/fetchCategoriesByAdmin",
  async ({search=""}) => {
    const response = await api.get(`/admin/categories?search=${search}`);
    return response.data;
  })

// create category by admin
export const createCategory = createAsyncThunk(
  "category/createCategory",
  async (category) => {
    const response = await api.post("/categories", category, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if(response.status === 201) {
      toast.success("Category created successfully");
    }
    return response.data;
  }
);

// update category by admin
export const updateCategory = createAsyncThunk(
  "category/updateCategory",
  async ({ id, category }) => {
    const response = await api.put(`/categories/${id}`, category, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    if(response.status === 200) {
      toast.success("Category updated successfully");
    }
    return response.data;
  }
);

// delete category by admin
export const deleteCategory = createAsyncThunk(
  "category/deleteCategory",
  async (id) => {
    const response = await api.delete(`/categories/${id}`);
    return response.data;
  }
);




const categorySlice = createSlice({
  name: "category",
  initialState: {
    categories: [],
    subcategories: [],
    pagination: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCategories.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchCategories.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.categories.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.categories = state.categories.map((category) => {
          if (category._id === action.payload._id) {
            return action.payload;
          }
          return category;
        });
        state.loading = false;
        state.error = null;
      })
      .addCase(updateCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
          state.categories = state.categories.filter((category) => category._id !== action.payload._id);
          state.loading = false;
          state.error = null;
      })
      .addCase(deleteCategory.rejected, (state, action) => {
          state.loading = false;
          state.error = action.error.message;
      })
      .addCase(fetchCategoriesByAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCategoriesByAdmin.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.loading = false;
      })
      .addCase(fetchCategoriesByAdmin.rejected, (state, action)=>{
        state.loading = false;
        state.error = action.error.message
      })
  },
});

export default categorySlice.reducer;
