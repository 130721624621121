import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { findAffiliatesByAdmin } from "../../features/affiliate/affiliateSlice";
import { findResellersByAdmin } from "../../features/user/userSlice";
import {
  createBonusByAdmin,
  updateBonusByAdmin,
} from "../../features/bonus/bonusSlice";
import { useDispatch, useSelector } from "react-redux";

const BonusForm = ({ setShowForm, initialValue = {}, mode = "create" }) => {
  const { resellers, loading } = useSelector((state) => state.user);
  const { affiliates, loading: loadingAffiliates } = useSelector(
    (state) => state.affiliate
  );

  

  const [userType, setUserType] = useState(""); // Affiliate or Reseller
  const dispatch = useDispatch();

  // Fetch affiliates or resellers when userType changes
  useEffect(() => {
    if (userType === "Reseller") {
      dispatch(findResellersByAdmin());
    } else if (userType === "Affiliate") {
      dispatch(findAffiliatesByAdmin());
    }
  }, [userType, dispatch]);

  useEffect(() => {
    if (initialValue.userType) {
      setUserType(initialValue.userType);
    }
  }, [initialValue.userType]);

  const users =
    userType === "Reseller"
      ? resellers?.map((reseller) => ({
          value: reseller._id,
          label: reseller.name + "-" + reseller.phone,
        }))
      : affiliates?.map((affiliate) => ({
          label: affiliate.userId?.name + "-" + affiliate.userId?.phone,
          value: affiliate._id,
        })) || [];
  


  // Form validation schema
  const validationSchema = Yup.object({
    userType: Yup.string().required("User type is required"),
    user: Yup.object()
      .shape({
        value: Yup.string().required("User is required"),
        label: Yup.string(),
      })
      .nullable()
      .required("User is required"),
    amount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be a positive number"),
    notes: Yup.string(),
  });

  // Initial form values
  const initialValues = {
    userType: initialValue.userType || "",
    user: initialValue?.user
      ? { value: initialValue.user, label: initialValue.name }
      : null,
    amount: initialValue.amount || "",
    notes: initialValue.notes || "stay tuned for more",
  };

  // Form submission handler
  const handleSubmit = async (values, { resetForm }) => {
    const payload = {
      user: values.user.value, // Extract ObjectId
      userType: values.userType,
      amount: values.amount,
      notes: values.notes,
    };

    if (mode === "edit") {
      dispatch(updateBonusByAdmin({ id: initialValue._id, data: payload }));
      setShowForm();
    } else {
      dispatch(createBonusByAdmin(payload));
      resetForm();
      setShowForm();
    }
  };

  return (
    <div className="p-6 bg-white rounded shadow-md">
      <h2 className="text-lg font-bold mb-4">
        {mode === "edit" ? "Edit Bonus" : "Create Bonus"}
      </h2>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {/* User Type Selection */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">
                User Type
              </label>
              <Field
                as="select"
                name="userType"
                onChange={(e) => {
                  const newType = e.target.value;
                  setUserType(newType);
                  setFieldValue("userType", newType);

                  // Reset only if user type changes
                  if (newType !== userType) {
                    setFieldValue("user", null);
                  }
                }}
                className="w-full p-2 border rounded"
              >
                <option value="">Select User Type</option>
                <option value="Affiliate">Affiliate</option>
                <option value="Reseller">Reseller</option>
              </Field>
              <ErrorMessage
                name="userType"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            {/* User Selection */}
            {userType && (
              <div className="mb-4">
                <label className="block text-sm font-medium mb-2">
                  Select {userType}
                </label>
                <Select
                  options={users}
                  isLoading={loading || loadingAffiliates}
                  onChange={(option) => setFieldValue("user", option)}
                  value={
                    users.find((user) => user.value === values.user?.value) ||
                    values.user
                  }
                  placeholder={`Search ${userType}...`}
                  isClearable
                />
                <ErrorMessage
                  name="user"
                  component="div"
                  className="text-red-500 text-sm mt-1"
                />
              </div>
            )}

            {/* Amount */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">Amount</label>
              <Field
                type="number"
                name="amount"
                className="w-full p-2 border rounded"
              />
              <ErrorMessage
                name="amount"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            {/* Notes */}
            <div className="mb-4">
              <label className="block text-sm font-medium mb-2">Notes</label>
              <Field
                as="textarea"
                name="notes"
                className="w-full p-2 border rounded"
                rows="4"
              />
              <ErrorMessage
                name="notes"
                component="div"
                className="text-red-500 text-sm mt-1"
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            >
              {mode === "edit" ? "Update Bonus" : "Create Bonus"}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default BonusForm;
