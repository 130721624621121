import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCategoriesByAdmin } from "../../features/category/categorySlice";
import { FaEdit, FaPlus } from "react-icons/fa";
import ShowCreateCategoryFormModal from "./ShowCreateCategoryFormModal";
import LoaderComponent from "../LoaderComponent";
import Modal from "../common/Modal";
import CreateCategoryForm from "./CreateCategoryForm";
// import { debounce } from "lodash";
import Search from "../Search";

const CategoriesList = () => {
  const dispatch = useDispatch();
  const { categories, loading } = useSelector((state) => state.category);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  // fetch categories on mount
  useEffect(() => {
    dispatch(fetchCategoriesByAdmin({ search: "" }));
  }, [dispatch]);

  // handle category edit
  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setIsEditing(true);
  };

  // const handleDelete = (id) => {
  //   if (window.confirm("Are you sure you want to delete this category?")) {
  //     // dispatch(deleteCategory(id));
  //   }
  // };

  // handle search

  // Debounced search handler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debouncedSearch = useCallback(
  //   debounce((query) => {
  //     // If the search query is empty, reset the search filter
  //     if (!query.trim()) {
  //       dispatch(fetchCategoriesByAdmin({ page: 1, limit: 20, search: "" }));
  //       setPage(1);
  //     } else {
  //       // Otherwise, update the search query
  //       dispatch(fetchCategoriesByAdmin({ page, search: query }));
  //     }
  //   }, 500),
  //   [dispatch]
  // );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    // debouncedSearch(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery) {
      dispatch(fetchCategoriesByAdmin({ search: searchQuery }));
    } else {
      dispatch(fetchCategoriesByAdmin({ search: "" }));
    }
  };

  if (loading) return <LoaderComponent message="Loading categories..." />;

  return (
    <div className="container mx-auto p-6 mb-8 h-screen">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Categories</h2>
        <button
          onClick={() => setShowCreateForm(true)}
          className="bg-blue-500 text-white p-2 rounded shadow-md hover:bg-blue-600 transition-all flex items-center"
        >
          <FaPlus className="mr-2" /> Create categories
        </button>
      </div>

      {/* Search */}
      <Search
        handleSearchSubmit={handleSearchSubmit}
        handleSearchChange={handleSearchChange}
        searchQuery={searchQuery}
        placeholder={"Search by category name"}
      />

      {/* Table */}
      <div className="shadow-lg rounded-lg overflow-hidden">
        {categories.length > 0 ? (
          <table className="min-w-full bg-white border border-gray-200 rounded shadow-md">
            <thead className="bg-gray-200">
              <tr>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">
                  Serial Number
                </th>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">
                  Category Name
                </th>
                <th className="py-2 px-4 border-b text-center font-semibold text-gray-700">
                  Category Image
                </th>
                <th className="py-2 px-4 border-b text-center font-semibold text-gray-700">
                  Subcategories
                </th>
                <th className="py-2 px-4 border-b text-center font-semibold text-gray-700">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) => (
                <tr key={category._id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b">
                    {category.serialNumber || "N/A"}
                  </td>
                  <td className="py-2 px-4 border-b">{category.name}</td>
                  <td className="py-2 px-4 border-b">
                    <img
                      src={category.image}
                      alt={category.name}
                      className="w-20 h-20 object-cover rounded-lg"
                    />
                  </td>
                  <td className="py-2 px-4 border-b">
                    {category.subcategories.map((sub) => sub.name).join(", ") ||
                      "-"}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    <button
                      onClick={() => handleEditCategory(category)}
                      className="bg-green-500 text-white p-2 rounded-full shadow-md hover:bg-green-600 transition-all"
                    >
                      <FaEdit />
                    </button>
                    {/* <button
                      onClick={() => handleDelete(category._id)}
                      className="bg-red-500 text-white p-2 rounded-full shadow-md hover:bg-green-600 transition-all"
                    >
                      <FaTrash />
                    </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No categories available.</p>
        )}
      </div>
      {showCreateForm && (
        <ShowCreateCategoryFormModal setShowCreateForm={setShowCreateForm} />
      )}
      {isEditing && (
        <Modal
          title={"edit category"}
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
        >
          <CreateCategoryForm mode="edit" initialValues={selectedCategory} />
        </Modal>
      )}
    </div>
  );
};

export default CategoriesList;
