import React from "react";
// import IntroductionImage from "../images/introduction.jpg";
// import OverviewImage from "../images/overview.jpg";
// import MissionImage from "../images/mission.jpg";
// import VisionImage from "../images/vision.jpg";
import SeeMore from "./SeeMore";

const CompanyProfile = () => {
  const missionTxt = ` Our vision is to revolutionize industries by reducing the
              dependency on human labor, thereby creating opportunities for
              additional income streams and fostering economic growth. By
              leveraging innovation and technology, we aim to empower
              individuals and businesses, streamlining processes while enhancing
              productivity. Over the next five years, our efforts will focus on
              driving sustainable economic development, enabling equitable
              progress for all. We are committed to building a future where
              automation and human ingenuity coexist harmoniously, propelling
              our nation toward prosperity and global competitiveness. Together,
              we envision a thriving economy built on efficiency, inclusively,
              and innovation. `;

  const visionTxt = `Our mission is to create opportunities for individuals to earn
              additional income while continuing their existing occupations. We
              aim to empower people by ensuring they have access to high quality
              products at fair prices delivered conveniently to their homes. By
              doing so, we reduce the need for excessive labor, allowing
              individuals to focus on living more fulfilling and balanced lives.
              We are dedicated to building a system that not only improves
              financial stability but also promotes convenience, fairness, and
              trust in every transaction. Through innovation and commitment, we
              strive to simplify daily lives, reduce unnecessary stress, and
              provide more time for people to enjoy meaningful experiences. By
              prioritizing customer satisfaction and ethical practices, our
              mission is to contribute to a society where individuals can work
              less, live better, and pursue their aspirations in harmony with
              economic and social growth.`;

  return (
    <div className="container mx-auto p-4 md:p-8 pt-0 mt-0">
      {/* Introduction Section */}
      <section className="sm:mb-4 md:mb-16 flex flex-col lg:flex-row items-center">
        {/* Image for Medium and Larger Screens */}
        <div className="hidden md:w-1/2 md:flex justify-center">
          <img
            src="/introduction.jpg"
            alt="Introduction"
            className="rounded-lg shadow-lg w-full h-80 p-4 md:animate-bounceSlow"
          />
        </div>

        {/* Text Section */}
        <div className="sm:w-full lg:w-1/2 text-center lg:text-left lg:ml-4">
          <h2 className="text-3xl font-bold text-green-600 m-4">
            Our Introduction
          </h2>
          <p className="text-gray-600 sm:text-sm md:text-lg text-justify">
            We are dedicated to providing innovative solutions that push the
            boundaries and deliver unmatched quality to our clients.
          </p>
        </div>

        {/* Image for Small Screens */}
        <div className="sm:w-full md:hidden flex justify-center">
          <img
            src="/introduction.jpg"
            alt="Introduction"
            className="rounded-lg shadow-lg w-72 h-44 p-4 md:animate-bounceSlow"
          />
        </div>
      </section>

      {/* Overview Section */}
      <section className="sm:mb-4 md:mb-16 flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0 lg:mr-4">
          <h2 className="text-3xl font-bold text-green-600 m-4">
            Our Overview
          </h2>
          <p className="text-gray-600 sm:text-sm md:text-lg text-justify">
            Our journey began with a vision to redefine the industry standards
            and make a positive impact through our products and services.
          </p>
        </div>
        <div className="sm:w-full lg:w-1/2">
          <img
            src="/overview.jpg"
            alt="Overview"
            className="rounded-lg shadow-lg  w-72 h-44 md:w-full md:h-80 p-4 md:animate-fadeIn" // Fade-in animation
          />
        </div>
      </section>

      {/* Mission Section */}

      <section className="sm:mb-4 md:mb-16">
        <div className="flex flex-col lg:flex-row items-center">
          {/* Image Grid for Medium and Larger Screens */}
          <div className="hidden md:w-1/2 md:flex md:justify-center">
            
              <img
                src="/mission.jpg"
                alt="Mission1"
                className="rounded-lg shadow-lg w-full h-80 p-4 md:animate-bounceSlow"
                />
           
          </div>

          {/* Text Section */}
          <div className="sm:w-full lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0 ml-4">
            <h2 className="text-3xl font-bold text-green-600 m-4">
              Our Mission
            </h2>
            <SeeMore text={missionTxt} />
          </div>

          {/* Image Grid for Small Screens */}
          <div className="sm:w-full  md:hidden">
           
            <img
                src="/mission.jpg"
                alt="Mission1"
                className="rounded-lg shadow-lg w-72 h-44 p-4 md:animate-bounceSlow "
                />
          </div>
        </div>
      </section>

      {/* Vision Section */}
      <section className="sm:mb-4 mt-4 md:mb-16">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="sm:w-full lg:w-1/2 text-center lg:text-left mb-8 lg:mb-0">
            <h2 className="text-3xl font-bold text-green-600 mb-4">
              Our Vision
            </h2>
            <SeeMore text={visionTxt} />
          </div>
          <div className="lg:w-1/2 flex justify-center">
           
            <img
                src="/vision.jpg"
                alt="Vision"
                className="rounded-lg shadow-lg w-72 h-44 md:w-full  md:h-80 p-4 md:animate-zoomIn"
                />
          </div>
        </div>
      </section>
    </div>
  );
};

export default CompanyProfile;
