import StoreProductCard from "../../components/StoreProductCard";

const RelatedProductsAndReviewsFromStore = ({ relatedProducts }) => {
  return (
    <div className="w-full mt-10 px-4">
      <h2 className="text-2xl font-bold text-gray-800 italic mb-4">
        {
          relatedProducts.length > 0
            ? "You may also like"
            : ""
        }
      </h2>

      {/* Content Section */}
      <div className="relative flex flex-col items-center">
        {
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 w-full">
            {(relatedProducts &&
              relatedProducts.map((product) => (
                <StoreProductCard key={product._id} product={product} />
              ))) || <p>No related products found.</p>}
          </div>
        }
      </div>
    </div>
  );
};

export default RelatedProductsAndReviewsFromStore;
