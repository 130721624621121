import React, { useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import HeroSection from "../../components/HeroSection";
import { getResellerStore, resetProducts } from "../../features/resellerStore/resellerStoreSlice";
import LoaderComponent from "../../components/LoaderComponent";
import StoreProductCard from "../../components/StoreProductCard";
import { useInView } from "react-intersection-observer";


const Store = () => {
  const { store,products, hasMore, loading } = useSelector((state) => state.resellerStore);

  const [page,setPage] = useState(1);
  const dispatch = useDispatch();
  const location = useLocation();

    const { ref, inView } = useInView({
      threshold: 0.5,
      rootMargin: "100px",
    });

  // get query params
  const queryParams = new URLSearchParams(location.search);
  const storeId = queryParams.get("storeid");

  useEffect(() => {
    if (storeId) {
      dispatch(getResellerStore({storeId,page,limit:20}));
    }
  }, [dispatch, storeId,page]);

  useEffect(() => {
    if (inView && hasMore && !loading) {
      setPage((prevPage) => prevPage + 1);
    }
  }, [inView, hasMore, loading, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetProducts());
      setPage(1);
    }
    }, [dispatch]);

  if (loading) return <LoaderComponent />;

  //@TODO maintain store 404 page
  if (!storeId) return <div>Store not found</div>;

  return (
    <div className="container mx-auto mb-8">
      <HeroSection storeDetails={{name: store?.storeName,storeId:store?.storeId}} />
      <div>
        <h1 className="text-3xl font-bold mb-4 mt-4">PRODUCTS FROM STORE</h1>
        <div className="grid grid-cols-1 md:grid-cols-5 gap-2">
          {" "}
          {/*gap-4 */}
          {products.length > 0 && products?.map((product) => (
            <StoreProductCard key={product._id} product={product} storeId={storeId}/>
          ))}
          {/* Infinite scroll observer */}
          {!loading && hasMore && (
              <div ref={ref} className="h-4">
                <LoaderComponent />
              </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default Store;
