import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateByUserId } from "../../features/affiliate/affiliateSlice";
import LoaderComponent from "../LoaderComponent";
import { toast } from "react-toastify";
import { FaCheck, FaRegClipboard } from "react-icons/fa";

const AffiliateContent = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { affiliate, loading, error } = useSelector((state) => state.affiliate);
  const dispatch = useDispatch();

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (userInfo) {
      dispatch(
        getAffiliateByUserId({
          userId: userInfo._id,
          token: JSON.parse(localStorage.getItem("access_token")),
        })
      );
    }
  }, [dispatch, userInfo]);

  const handleCopyClick = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((error) => {
        //@TODO handle error
        toast.error(error.message || "Failed to copy to clipboard");
      });
  };

  if (loading) {
    return <LoaderComponent message="Loading affiliate details..." />;
  }

  if (error)
    return (
      <div>
        Error:{" "}
        {error.message
          ? error.message
          : " something went wrong , please try again later"}
      </div>
    );

  return (
    <div className="max-w-2xl mx-auto p-6 bg-gray-100  h-screen mb-8">
      <h2 className="text-3xl font-bold mb-4 text-center">
        Affiliate Program Details
      </h2>

      {/* Display Affiliate Code */}
      <div className="bg-green-200 p-4 rounded-lg mb-6 text-center">
        <h3 className="text-xl font-semibold">Your Affiliate Code :</h3>
        <p className="text-2xl font-bold text-green-600">
          {affiliate?.ref_code}
        </p>
        <div className="flex items-center justify-between border rounded-lg p-2 bg-gray-300 w-full">
          <h3 className="text-lg font-semibold mt-2">Your refer link :</h3>
          <span
            className="text-blue-600 hover:underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://paribarmart.com/signup?refer={affiliate?.ref_code}
          </span>
          <button
            onClick={() =>
              handleCopyClick(
                `https://paribarmart.com/signup?refer=${affiliate?.ref_code} `
              )
            }
            className="text-blue-500 hover:text-blue-700 transition-colors duration-200 flex items-center"
          >
            {copied ? (
              <FaCheck className="text-green-500" />
            ) : (
              <FaRegClipboard />
            )}
          </button>
        </div>
      </div>

      {/* Program Overview */}
      <section className="mb-6">
        <h3 className="text-xl font-semibold">
          How Our Affiliate Program Works
        </h3>
        <p>
          Our affiliate program is designed to reward you for promoting our
          products and bringing in sales. We use a product-based commission
          system, which means you earn different commission rates depending on
          the product category.
        </p>
      </section>

      {/* Marketing Methods */}
      <section className="mb-6">
        <h3 className="text-xl font-semibold">Marketing Our Brand</h3>
        <p>As an affiliate, you can market our brand in two ways:</p>
        <ul className="list-disc list-inside space-y-2">
          <li>
            <strong>General Product Link:</strong> Share this link to direct
            customers to our products:
            <br />
            <a
              href={`https://www.paribarmart.com/products?ref=${affiliate?.ref_code}`}
              className="text-blue-600 hover:underline"
            >
              www.paribarmart.com/shop/products?ref={affiliate?.ref_code}
            </a>
          </li>
          <li>
            <strong>Specific Product Link:</strong> Share this link to direct
            customers to a specific product:
            <br />
            <a
              href={`https://www.paribarmart.com/products/example-product-title?ref=${affiliate?.ref_code}`}
              className="text-blue-600 hover:underline"
            >
              www.paribarmart.com/shop/products/example-product-title?ref=
              {affiliate?.ref_code}
            </a>
          </li>
        </ul>
      </section>

      {/* Commission Benefits */}
      <section>
        <h3 className="text-xl font-semibold">Commission Benefits</h3>
        <p>
          Enjoy a generous commission structure, exclusive offers, and ongoing
          support to help maximize your earnings. The more you promote, the more
          you earn!
        </p>
      </section>
    </div>
  );
};
export default AffiliateContent;
