const ShowResellerDetails = ({ selectedReseller, setShowDetails }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 h-screen">
      <div className="bg-white p-8 rounded-lg shadow-xl w-full max-h-[90vh] max-w-md mx-auto relative transform scale-105 transition-all overflow-auto">
        <div className="absolute top-4 right-4">
          <button
            onClick={() => setShowDetails(false)}
            className="text-gray-500 hover:text-red-500 focus:outline-none"
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="2"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="flex flex-col items-center">
          <img
            src={selectedReseller.profilePicture || "/profile.webp"}
            alt="Profile"
            className="w-28 h-28 rounded-full border-4 border-blue-600 shadow-md mb-6"
          />
          <h3 className="text-2xl font-bold text-gray-800 mb-2">
            {selectedReseller.name}
          </h3>
          <p className="text-sm text-gray-500 italic mb-6">
            Joined on{" "}
            {new Date(selectedReseller.createdAt).toLocaleDateString()}
          </p>
        </div>

        <div className="space-y-4 text-gray-700">
          <div className="flex justify-between">
            <span className="font-medium">Email:</span>
            <span className="text-gray-500">{selectedReseller.email}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium">Gender:</span>
            <span className="text-gray-500">{selectedReseller.gender || "N/A"}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium">Role:</span>
            <span className="text-gray-500">{selectedReseller.role}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium">Address:</span>
            <span className="text-gray-500">{selectedReseller.address || "N/A"}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium">Total Earnings:</span>
            <span className="text-gray-500">TK. {selectedReseller.total_earnings}</span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium">Status:</span>
            <span
              className={`text-sm font-bold ${
                selectedReseller.status === "active"
                  ? "text-green-600"
                  : "text-red-600"
              }`}
            >
              {selectedReseller.status}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="font-medium">Phone:</span>
            <span className="text-gray-500">
              {selectedReseller.phone || "N/A"}
            </span>
          </div>
        </div>
        <div className="space-y-4 text-gray-700">
          {selectedReseller.voterId ? (
            <img
              src={selectedReseller.voterId}
              alt="Profile"
              className="w-28 h-28 rounded-full border-4 border-blue-600 shadow-md mb-6"
            />
          ) : (
            <p>No Voter Id Card Uploaded yet.</p>
          )}
        </div>

        <button
          onClick={() => setShowDetails(false)}
          className="w-full mt-6 py-2 bg-gradient-to-r from-blue-500 to-indigo-600 text-white font-semibold rounded-lg shadow-md hover:from-blue-600 hover:to-indigo-700 focus:outline-none transition-all"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ShowResellerDetails;
