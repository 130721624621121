import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { addServiceCharge, updateServiceCharge } from '../../features/serviceCharge/serviceChargeSlice';

const ServiceChargeForm = ({ setShowForm, initialValue={}, mode="create" }) => {
  const dispatch = useDispatch();

  const initialValues = {
    name: initialValue.name || '',
    amount: initialValue.amount || '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    amount: Yup.number().required('Amount is required'),
  });

  const onSubmit = (values, { resetForm }) => {
    if (mode === "edit") {
      dispatch(updateServiceCharge({ id: initialValue._id, serviceCharge: values, token: JSON.parse(localStorage.getItem("access_token")) }));
      setShowForm(false);
    };
    if ( mode === "create") {
      dispatch(addServiceCharge({ serviceCharge: values, token: JSON.parse(localStorage.getItem("access_token")) }));
      resetForm();
      setShowForm(false);
    };
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-2/3 max-h-[90vh] overflow-auto">
      <div className="flex justify-between items-center border-b p-4">
        <h2 className="text-2xl font-bold mb-4">Add Service Charge</h2>
        <button onClick={() => setShowForm(false)} className="text-red-900 font-semibold hover:text-red-600 w-32">
            ✕
          </button>
      </div>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          <Form>
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700">Area Name</label>
              <Field
                type="text"
                id="name"
                name="name"
                className="w-full p-2 border border-gray-300 rounded mt-1"
              />
              <ErrorMessage name="name" component="div" className="text-red-500 text-sm mt-1" />
            </div>
            <div className="mb-4">
              <label htmlFor="amount" className="block text-gray-700">Service Charge Amount</label>
              <Field
                type="number"
                id="amount"
                name="amount"
                className="w-full p-2 border border-gray-300 rounded mt-1"
              />
              <ErrorMessage name="amount" component="div" className="text-red-500 text-sm mt-1" />
            </div>
            

            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => setShowForm(false)}
                className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 mr-2"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
              >
                Submit
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ServiceChargeForm;
