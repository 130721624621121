import React from 'react';

const ShowExecutiveDetailsModal = ({ executive, closeDetailsModal }) => {
    
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
            <div className="relative bg-white rounded-lg shadow-lg w-full max-w-lg max-h-[90vh] overflow-y-auto">
                
                {/* Close Button */}
                <button onClick={closeDetailsModal} className=" absolute top-0 right-0 bg-red-500  p-2 text-500 hover:text-white hover:bg-red-700">
                    close
                </button>
                
                {/* Profile Picture Section */}
                <div className="flex justify-center p-6 bg-gray-100 rounded-t-lg">
                    <img
                        src={executive.profilePicture}
                        alt={`${executive.name} profile`}
                        className="w-32 h-32 object-cover rounded-full border-4 border-gray-300 shadow-md"
                    />
                </div>

                {/* Details Section */}
                <div className="p-6">
                    {/* Name */}
                    <h2 className="text-2xl font-semibold text-center text-gray-800 mb-2">{executive.name}</h2>
                    
                    {/* Role */}
                    <p className="text-center text-gray-600 mb-4">
                        <span className="text-gray-500">Role:</span> {executive.role}
                    </p>

                    {/* Contact Details */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-700">Contact Information</h3>
                        <p className="text-gray-600"><span className="font-medium">Phone:</span> {executive.contact.phone}</p>
                        <p className="text-gray-600"><span className="font-medium">Email:</span> {executive.contact.email}</p>
                        <p className="text-gray-600"><span className="font-medium">Address:</span> {executive.contact.address}</p>
                    </div>

                    {/* Message */}
                    <div className="mb-4">
                        <h3 className="text-lg font-semibold text-gray-700">Message</h3>
                        <p className="text-gray-600"><span className="font-medium">Title:</span> {executive.message.title}</p>
                        <p className="text-gray-600"><span className="font-medium">Body:</span> {executive.message.body}</p>
                    </div>

                    {/* Social Links */}
                    <div>
                        <h3 className="text-lg font-semibold text-gray-700">Social Links</h3>
                        <div className="flex flex-wrap justify-center space-x-4 mt-2">
                            {executive.socialLinks.linkedin && (
                                <a href={executive.socialLinks.linkedin} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                                    LinkedIn
                                </a>
                            )}
                            {executive.socialLinks.twitter && (
                                <a href={executive.socialLinks.twitter} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                                    Twitter
                                </a>
                            )}
                            {executive.socialLinks.facebook && (
                                <a href={executive.socialLinks.facebook} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                                    Facebook
                                </a>
                            )}
                            {executive.socialLinks.instagram && (
                                <a href={executive.socialLinks.instagram} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:text-blue-700">
                                    Instagram
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShowExecutiveDetailsModal;
