import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";
import { toast } from "react-toastify";

// get all reviews by admin
export const getReviews = createAsyncThunk("reviews/getReviews", async () => {
  const response = await api.get("/reviews");
  return response.data;
});

// delete review by admin
export const deleteReview = createAsyncThunk(
  "reviews/deleteReview",
  async (reviewId) => {
    const response = await api.delete(`/reviews/${reviewId}`);
    if(response.status === 200) {
      toast.success("Review deleted successfully");
      }
    return response.data;
  }
);

// create reviews by user
export const createReview = createAsyncThunk(
  "reviews/createReview",
  async (reviewData) => {
    const response = await api.post("/reviews", reviewData);
    if(response.status === 201) {
      toast.success("Review created successfully");
    }
    return response.data;
  }
);
// get all reviews by a productId
export const getReviewsByProductId = createAsyncThunk(
  "reviews/getReviewsByProductId",
  async (productId) => {
    const response = await api.get(`/reviews/product/${productId}`);
    return response.data;
  }
);



const reviewSlice = createSlice({
  name: "reviews",
  initialState: {
    reviews: [],
    singleProductReviews: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReviews.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getReviews.fulfilled, (state, action) => {
        state.reviews = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getReviews.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createReview.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createReview.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(createReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getReviewsByProductId.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getReviewsByProductId.fulfilled, (state, action) => {
        state.singleProductReviews = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getReviewsByProductId.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteReview.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteReview.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.reviews.findIndex(
          (review) => review._id === action.payload._id
        )
        state.reviews = state.reviews.filter(
          (_review, i) => i !== index
        )
        state.error = null;
      })
      .addCase(deleteReview.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default reviewSlice.reducer;
