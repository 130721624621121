import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { addReferAmount,updateReferAmount } from '../../features/referAmount/referAmountSlice';

const ReferAmountForm = ({ setShowForm, initialValue={}, mode="create" }) => {
  const dispatch = useDispatch();

  const initialValues = {
    referAmountForAffiliate: initialValue.referAmountForAffiliate || '',
    referAmountForReseller: initialValue.referAmountForReseller || '',
  };

  const validationSchema = Yup.object({
    referAmountForAffiliate: Yup.number()
      .required('Affiliate amount is required')
      .positive('Must be a positive number'),
    referAmountForReseller: Yup.number()
      .required('Reseller amount is required')
      .positive('Must be a positive number'),
  });

  const onSubmit = (values, { resetForm }) => {
    if (mode === "edit") {
      dispatch(updateReferAmount({ id: initialValue._id, referAmount: values, token: JSON.parse(localStorage.getItem("access_token")) }));
      setShowForm(false);
    };
    if ( mode === "create") {
      dispatch(addReferAmount({ ...values, token: JSON.parse(localStorage.getItem("access_token")) }));
      resetForm();
      setShowForm(false);
    };
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded shadow-lg w-2/3 mt-6">
        <h2 className="text-2xl font-bold mb-4">Add Refer Amount</h2>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          <Form>
            <div className="mb-4">
              <label htmlFor="referAmountForAffiliate" className="block text-gray-700">Affiliate Amount</label>
              <Field
                type="number"
                id="referAmountForAffiliate"
                name="referAmountForAffiliate"
                className="mt-1 p-2 w-full border rounded"
              />
              <ErrorMessage name="referAmountForAffiliate" component="div" className="text-red-500 text-sm" />
            </div>
            <div className="mb-4">
              <label htmlFor="referAmountForReseller" className="block text-gray-700">Reseller Amount</label>
              <Field
                type="number"
                id="referAmountForReseller"
                name="referAmountForReseller"
                className="mt-1 p-2 w-full border rounded"
              />
              <ErrorMessage name="referAmountForReseller" component="div" className="text-red-500 text-sm" />
            </div>
            <div className="flex justify-end">
              <button
                type="button"
                onClick={() => setShowForm(false)}
                className="mr-4 px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Submit
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ReferAmountForm;
