import React from "react";

const OrderBonusDetails = ({ bonus, onClose }) => {
  return (
    <div
      className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
      onClick={onClose}
    >
      <div className="bg-white w-full max-w-lg p-4 rounded shadow-md overflow-auto max-h-[90vh] md:max-h-[80vh] relative">
        <button
          onClick={onClose}
          className="absolute text-white mb-6 p-2 bg-red-500 top-2 right-2 rounded hover:bg-red-600 transition-all"
        >
          Close
        </button>
        <div className="p-4 mt-8">
          <h2 className="text-2xl font-semibold mb-4">{bonus.title}</h2>
          <p className="mb-4">{bonus.description}</p>
          <div className="w-full h-64">
            <img
              src={bonus.image}
              alt={bonus.title}
              className="w-full h-full object-cover rounded"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderBonusDetails;
