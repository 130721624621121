import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/api";

// Async thunk to fetch products based on filters and page number
export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async ({ filters, page }, { getState }) => {
    const { resellerProducts } = getState();

    // Construct the query string based on filters
    const queryString = new URLSearchParams({
      page,
      limit: resellerProducts.limit,
      sortBy: filters.sortBy,
      sortType: filters.sortType,
      search: filters.search,
      categoryName: filters.category,
      subCategoryName: filters.subCategory,
      minPrice: filters.minPrice,
      maxPrice: filters.maxPrice,
      minRating: filters.rating,
      inStock: filters.inStock,
      reseller: filters.reseller,
    }).toString();

    const response = await api.get(`/products/reseller?${queryString}`);
    return response.data;
  }
);

// fetch reseller just for you products
export const fetchJustForYouProducts = createAsyncThunk(
  "products/fetchJustForYouProducts",
  async () => {
    const response = await api.get("/just-for-you/products/reseller");
    return response.data;
  }
);
// fetch recommendation products
export const fetchRecommendationProductsForReseller = createAsyncThunk(
  "products/fetchRecommendationProducts",
  async ({ category, productId }) => {
    const response = await api.get(
      `/products/recommendations/reseller?categoryIds=${category}&productId=${productId}`
    );
    return response.data;
  }
);

// get upcoming products by reseller
export const fetchUpcomingProductsByReseller = createAsyncThunk(
  "products/fetchUpcomingProductsByReseller",
  async () => {
    const response = await api.get("/products/upcoming/reseller");
    return response.data;
  }
);

// new arrival products by reseller
export const fetchNewArrivalProductsByReseller = createAsyncThunk(
  "products/fetchNewArrivalProductsByReseller",
  async () => {
    const response = await api.get("/new-arrival/products/reseller");
    return response.data;
  }
);
// fetch filtered products by reseller
export const fetchFilteredProductsByReseller = createAsyncThunk(
  "products/fetchFilteredProductsByReseller",
  async ({ filters, page }) => {
    const queryString = new URLSearchParams({
      page,
      limit: 10,
      sortBy: filters.sortBy,
      sortType: filters.sortType,
      search: filters.search,
      categoryName: filters.category,
      subCategoryName: filters.subCategory,
      minPrice: filters.minPrice,
      maxPrice: filters.maxPrice,
      minRating: filters.rating,
      inStock: filters.inStock,
      reseller: filters.reseller,
    }).toString();
    const response = await api.get(`/products/reseller?${queryString}`);
    return response.data;
  }
);

const productSlice = createSlice({
  name: "products",
  initialState: {
    items: [],
    suggestedProducts: [],
    recommendedProducts: [],
    upcomingProducts: [],
    newArrivalProducts: [],
    filteredProducts: [],
    loading: false,
    error: null,
    filters: {
      category: "",
      subCategory: "",
      minPrice: 0,
      maxPrice: 100000,
      inStock: false,
      rating: 0,
      sortBy: "title",
      sortType: "asc",
      search: "",
      reseller: true,
    },
    page: 1,
    limit: 10,
    hasMore: true,
  },
  reducers: {
    setFilter: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
      state.page = 1;
      state.items = []; // Clear current items for fresh load
    },
    incrementPage: (state) => {
      state.page += 1;
    },
    resetProducts: (state) => {
      state.items = []; // Clear products
      state.page = 1; // Reset pagination
      state.hasMore = true; // Reset hasMore to true
    },
    resetFilteredProducts: (state) => {
      state.filteredProducts = []; // Clear products
      state.page = 1; // Reset pagination
      state.hasMore = true; // Reset hasMore to true
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.loading = false;
        const { products, pagination } = action.payload;

        // Append new products
        state.items = [...state.items, ...products];
        state.hasMore = pagination.next > state.page;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchJustForYouProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchJustForYouProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.suggestedProducts = action.payload;
      })
      .addCase(fetchJustForYouProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchRecommendationProductsForReseller.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchRecommendationProductsForReseller.fulfilled,
        (state, action) => {
          state.loading = false;
          state.recommendedProducts = action.payload;
        }
      )
      .addCase(
        fetchRecommendationProductsForReseller.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      )
      .addCase(fetchNewArrivalProductsByReseller.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNewArrivalProductsByReseller.fulfilled, (state, action) => {
        state.loading = false;
        state.newArrivalProducts = action.payload;
      })
      .addCase(fetchNewArrivalProductsByReseller.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchFilteredProductsByReseller.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFilteredProductsByReseller.fulfilled, (state, action) => {
        state.loading = false;
        const { products, pagination } = action.payload;
        state.filteredProducts = [...state.filteredProducts, ...products];
        state.hasMore = pagination.next > state.page;
      })
      .addCase(fetchFilteredProductsByReseller.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchUpcomingProductsByReseller.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUpcomingProductsByReseller.fulfilled, (state, action) => {
        state.loading = false;
        state.upcomingProducts = action.payload;
      })
      .addCase(fetchUpcomingProductsByReseller.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const {
  setFilter,
  incrementPage,
  resetProducts,
  resetFilteredProducts,
} = productSlice.actions;
export default productSlice.reducer;

