import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateProduct } from "../../features/products/productsSlice";

const StockDetailsAndEdit = ({ product }) => {
  // State to manage variants for editing
  const [variants, setVariants] = useState({
    colorSizeVariants: product.colorSizeVariants || [],
    sizeVariants: product.sizeVariants || [],
  });
  const dispatch = useDispatch();

  const handleStockChange = (type, colorIndex, sizeIndex, newStock) => {
    const updatedVariants = { ...variants };

    if (type === "colorSizeVariants") {
      updatedVariants.colorSizeVariants = updatedVariants.colorSizeVariants.map(
        (variant, index) =>
          index === colorIndex
            ? {
                ...variant,
                sizes: variant.sizes.map((size, idx) =>
                  idx === sizeIndex ? { ...size, stock: newStock === "" ? "" : +newStock } : size
                ),
              }
            : variant
      );
    }

    if (type === "sizeVariants") {
      updatedVariants.sizeVariants = updatedVariants.sizeVariants.map(
        (variant, index) =>
          index === colorIndex ? { ...variant, stock: newStock === "" ? "" : +newStock } : variant
      );
    }

    setVariants(updatedVariants); // Update state
  };

  const handleSave = () => {
    const updatedProduct = {
      ...product,
      colorSizeVariants: variants.colorSizeVariants,
      sizeVariants: variants.sizeVariants,
    };


    // Dispatch an action to save updated product details
    dispatch(updateProduct({id:product._id,product:updatedProduct,message:"stock updated successfully"}));
  };

  return (
    <div className="p-6 space-y-4">
      <h2 className="text-lg font-semibold text-gray-700">
        {product.title} - Stock Details
      </h2>
      <table className="w-full border border-gray-200 text-sm text-left text-gray-500">
        <thead className="text-gray-700 uppercase bg-gray-50">
          <tr>
            <th className="px-4 py-2">Variant Type</th>
            <th className="px-4 py-2">
              {product.colorSizeVariants.length > 0
                ? "ColorName & SizeName"
                : "SizeName"}
            </th>
            <th className="px-4 py-2">Stock</th>
            <th className="px-4 py-2">Action</th>
          </tr>
        </thead>
        <tbody>
          {/* ColorSizeVariants */}
          {variants.colorSizeVariants.map((variant, colorIndex) =>
            variant.sizes.map((size, sizeIndex) => (
              <tr
                key={`${variant.colorName}-${size.sizeName}`}
                className="bg-white border-b hover:bg-gray-50"
              >
                <td className="px-4 py-2">Color & Size</td>
                <td className="px-4 py-2">{`${variant.colorName}, ${size.sizeName}`}</td>
                <td className="px-4 py-2">
                  <input
                    type="number"
                    value={size.stock}
                    onChange={(e) =>
                      handleStockChange(
                        "colorSizeVariants",
                        colorIndex,
                        sizeIndex,
                        e.target.value
                      )
                    }
                    className="w-20 p-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                </td>
                <td className="px-4 py-2">
                  <button
                    className="text-indigo-600 hover:underline"
                    onClick={handleSave}
                  >
                    Update Stock
                  </button>
                </td>
              </tr>
            ))
          )}

          {/* SizeVariants */}
          {variants.sizeVariants.map((variant, sizeIndex) => (
            <tr
              key={variant.sizeName}
              className="bg-white border-b hover:bg-gray-50"
            >
              <td className="px-4 py-2">Size</td>
              <td className="px-4 py-2">{variant.sizeName}</td>
              <td className="px-4 py-2">
                <input
                  type="number"
                  value={variant.stock}
                  onChange={(e) =>
                    handleStockChange(
                      "sizeVariants",
                      sizeIndex,
                      null,
                      e.target.value
                    )
                  }
                  className="w-20 p-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-indigo-500"
                />
              </td>
              <td className="px-4 py-2">
                <button
                  onClick={handleSave}
                  className="text-indigo-600 hover:underline"
                >
                  Update Stock
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StockDetailsAndEdit;
