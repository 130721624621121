import React, { useEffect, useRef, useState } from "react";

const SeeMore = ({ text, maxLength = 350 }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [textHeight, setTextHeight] = useState("auto");
    const textRef = useRef(null);
  
    // Calculate the height of the text dynamically
    useEffect(() => {
      if (!isExpanded) {
        setTextHeight(`${textRef.current.scrollHeight}px`); // Get full height for animation
        setTimeout(() => setTextHeight("3.5em"), 10); // Transition back to truncated height
      } else {
        setTextHeight(`${textRef.current.scrollHeight}px`);
      }
    }, [isExpanded]);
  
    const toggleExpanded = () => {
      setIsExpanded((prev) => !prev);
    };
  
    const isTruncated = text.length > maxLength;
  
    return (
      <>
        <div
          ref={textRef}
          style={{
            overflow: "hidden",
            height: isExpanded ? textHeight : "3.5em",
            transition: "height 0.3s ease",
          }}
          className="text-pink-700 leading-relaxed text-justify"
        >
          {isExpanded ? text : `${text.slice(0, maxLength)}${isTruncated ? "..." : ""}`}
        </div>
        {isTruncated && (
          <button
            onClick={toggleExpanded}
            className="text-blue-500 underline  hover:text-blue-700 transition duration-200"
          >
            {isExpanded ? "See Less" : "See More"}
          </button>
        )}
      </>
   
  );
};

export default SeeMore;
