import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllReferCommissionByReseller,
  getAllResellerCommission,
} from "../../features/allReferrals/allReferralSlice";
import { getBonusesByReseller } from "../../features/bonus/bonusSlice";
import LoaderComponent from "../LoaderComponent";
import Pagination from "../Pagination";

const ResellerReferral = () => {
  const { allReferCommissionByReseller,pagination,totalAmount, allResellerCommission, loading } =
    useSelector((state) => state.allReferral);

  const { bonuses,totalBonus,pagination:bonusPagination, loading: bonusLoading } = useSelector((state) => state.bonus);
  const [activeTab, setActiveTab] = useState("ordersCommission");
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();

  useEffect(() => {
    if( activeTab === "ordersCommission"){
      dispatch(getAllResellerCommission({page,limit:10}));
    }else if( activeTab === "referCommission"){
      dispatch(getAllReferCommissionByReseller({page,limit:10}));      
    } else if( activeTab === "bonus"){      
      dispatch(getBonusesByReseller({page,limit:10}));
    }
  }, [dispatch, activeTab, page]);

  // const totalOrderCommission = allResellerCommission.reduce(
  //   (sum, referral) => sum + referral.commission,
  //   0
  // );
  // const totalReferCommission = allReferCommissionByReseller.reduce(
  //   (sum, commission) => sum + commission.commission,
  //   0
  // );

  // const totalBonus = bonuses.reduce((sum, bonus) => sum + bonus.amount, 0);

  if (loading || bonusLoading) return <LoaderComponent message="Loading referrals..." />;

  return (
    <div className="max-w-2xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg h-screen">
      <h2 className="sm:text-xl text-2xl font-bold mb-4">My Referrals</h2>

      {/* Tab Navigation */}
      <div className="flex justify-center mb-6">
        <button
          onClick={() => setActiveTab("ordersCommission")}
          className={`px-4 py-2 mx-2 font-semibold rounded ${
            activeTab === "ordersCommission"
              ? "bg-blue-500 text-white"
              : "bg-gray-300 text-gray-700"
          }`}
        >
          Commission of Order From Store
        </button>
        <button
          onClick={() => setActiveTab("referCommission")}
          className={`px-4 py-2 mx-2 font-semibold rounded ${
            activeTab === "referCommission"
              ? "bg-blue-500 text-white"
              : "bg-gray-300 text-gray-700"
          }`}
        >
          Refer Commission
        </button>
        <button
          onClick={() => setActiveTab("bonus")}
          className={`px-4 py-2 mx-2 font-semibold rounded ${
            activeTab === "bonus"
              ? "bg-blue-500 text-white"
              : "bg-gray-300 text-gray-700"
          }`}
        >
          Bonuses
        </button>
      </div>

      {/* Summary Section */}
      <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <SummaryCard
          label={
            activeTab === "ordersCommission"
              ? "Total Order Commission"
              : activeTab === "referCommission"
              ? "Total Refer Commission"
              : "Total Bonus"
          }
          amount={`TK. ${
            activeTab === "ordersCommission"
              ? totalAmount
              : activeTab === "referCommission"
              ? totalAmount
              : totalBonus
          }`}
        />
        <SummaryCard
          label={
            activeTab === "ordersCommission"
              ? "Total Order Count"
              : activeTab === "referCommission"
              ? "Total Refer Count"
              : "Total Bonus Count"
          }
          amount={`Total. ${
            activeTab === "ordersCommission"
              ? pagination?.totalItems || 0
              : activeTab === "referCommission"
              ? pagination?.totalItems || 0
              : bonusPagination?.totalItems || 0
          }`}
        />
      </div>

      {/* Referrals List */}
      <div className="bg-white p-4 rounded-lg shadow">
        <h3 className="text-lg font-semibold mb-4">
          {activeTab === "ordersCommission"
            ? "Commission Of Orders From Store"
            : activeTab === "referCommission"
            ? "Refer Commissions"
            : "Bonuses"
          }
        </h3>
        <div className="divide-y">
          {(activeTab === "ordersCommission"
            ? allResellerCommission
            : activeTab === "referCommission"
            ? allReferCommissionByReseller
            : bonuses
          ).length === 0 ? (
            <p className="text-gray-500 text-center">
              You have no{" "}
              {activeTab === "ordersCommission"
                ? "orders from store"
                : "refer commissions"}
              .
            </p>
          ) : (
            (activeTab === "ordersCommission"
              ? allResellerCommission
              : activeTab === "referCommission"
              ? allReferCommissionByReseller
              : bonuses
            ).map((referral, index) => (
              <ReferralCard
                key={index}
                referral={referral}
                isOrder={activeTab === "ordersCommission"}
                isBonus={activeTab === "bonus"}
              />
            ))
          )}
        </div>
      </div>

      {/* Pagination */}
      {
        activeTab === 'bonus' ? <Pagination currentPage={page} totalPages={bonusPagination?.totalPages} onPageChange={setPage} /> : <Pagination currentPage={page} totalPages={pagination?.totalPages} onPageChange={setPage} />
      }
      
    </div>
  );
};

// Summary Card Component
const SummaryCard = ({ label, amount }) => (
  <div className="p-4 bg-blue-500 text-white rounded-lg shadow-md">
    <p className="text-sm font-medium">{label}</p>
    <p className="text-xl font-bold">{amount}</p>
  </div>
);

// Referral Card Component
const ReferralCard = ({ referral, isOrder, isBonus }) => (
  <div className="flex justify-between items-center py-3">
    <div>
      {isOrder ? (
        <>
          <p className="text-gray-700 font-medium">
            Order ID: {referral.order?.orderId}
          </p>
          <p className="text-sm text-gray-500">
            Commission: TK. {referral.commission}
          </p>
          <p className="text-sm text-gray-500">Date: {new Date(referral.createdAt).toLocaleDateString()}</p>
        </>
      ) : isBonus ? (
        <>
           <p className="text-gray-700 font-medium">
            Notes: {referral.notes}
          </p>
          <p className="text-sm text-gray-500">
            Bonus: TK. {referral.amount}
          </p>
          <p className="text-sm text-gray-500">Date: {new Date(referral.createdAt).toLocaleDateString()}</p>
        </>
      ) : (
        <>
          <p className="text-gray-700 font-medium">
            Reseller: {referral.referralOfReseller?.name}
          </p>
          <p className="text-sm text-gray-500">
            Commission: TK. {referral.commission}
          </p>
          <p className="text-sm text-gray-500">Date: {new Date(referral.createdAt).toLocaleDateString()}</p>
        </>
      )}
    </div>
    {/* {isAffiliate && (
      <button className="text-blue-500 text-sm underline hover:text-blue-700">
        View Order
      </button>
    )} */}
  </div>
);

export default ResellerReferral;
