import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api/api';
import { toast } from 'react-toastify';

export const getServiceCharges = createAsyncThunk('serviceCharge/getServiceCharges', async ({ token }) => {
  const response = await api.get('/service-charges', { headers: { Authorization: `Bearer ${token}` } });
  return response.data;
});

export const addServiceCharge = createAsyncThunk('serviceCharge/addServiceCharge', async ({ serviceCharge, token }) => {
  const response = await api.post('/service-charges', serviceCharge, { headers: { Authorization: `Bearer ${token}` } });
  if(response.status === 201){
    toast.success("Service charge created successfully");
  }
  return response.data;
});

export const deleteServiceCharge = createAsyncThunk('serviceCharge/deleteServiceCharge', async ({ serviceChargeId, token }) => {
  await api.delete(`/service-charges/${serviceChargeId}`, { headers: { Authorization: `Bearer ${token}` } });
  return serviceChargeId;
});

// update service charge --admin
export const updateServiceCharge = createAsyncThunk('serviceCharge/updateServiceCharge', async ({ id, serviceCharge, token }) => {
  const response = await api.put(`/service-charges/${id}`, serviceCharge, { headers: { Authorization: `Bearer ${token}` } });
  if(response.status === 200){
    toast.success("Service charge updated successfully");
  }
  return response.data;
});

// get current service charge --public
export const getCurrentServiceCharge = createAsyncThunk('serviceCharge/getCurrentServiceCharge', async () => {
  const response = await api.get('/service-charges/current');
  return response.data;
});


const serviceChargeSlice = createSlice({
  name: 'serviceCharge',
  initialState: {
    serviceCharges: [],
    currentServiceCharge: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getServiceCharges.pending, (state) => { state.loading = true; })
      .addCase(getServiceCharges.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceCharges = action.payload;
      })
      .addCase(getServiceCharges.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addServiceCharge.fulfilled, (state, action) => {
        state.serviceCharges.push(action.payload);
      })
      .addCase(deleteServiceCharge.fulfilled, (state, action) => {
        state.serviceCharges = state.serviceCharges.filter(item => item._id !== action.payload);
      })
      .addCase(getCurrentServiceCharge.pending, (state) => { state.loading = true; })
      .addCase(getCurrentServiceCharge.fulfilled, (state, action) => {
        state.loading = false;
        state.currentServiceCharge = action.payload;
      })
      .addCase(getCurrentServiceCharge.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateServiceCharge.pending, (state) => { state.loading = true; })
      .addCase(updateServiceCharge.fulfilled, (state, action) => {
        state.loading = false;
        state.serviceCharges = state.serviceCharges.map(item => item._id === action.payload._id ? action.payload : item);
      })
      .addCase(updateServiceCharge.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default serviceChargeSlice.reducer;
