import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const ProductRequestForm = ({ onSubmit, initialValues, mode }) => {

  const [imagePreview, setImagePreview] = React.useState('');
  
  const formik = useFormik({
    initialValues: initialValues || {
      productName: '',
      description: '',
      category: '',
      price: '',
      image: '',
      quantityRequested: 1,
      responseMessage: '',
      status: 'pending',
    },
    validationSchema: Yup.object({
      productName: Yup.string().required('Product Name is required').min(2),
      description: Yup.string().required('Description is required').max(500),
      category: Yup.string().required('Category is required').min(2),
      quantityRequested: Yup.number().required('Quantity is required').positive().integer(),
      responseMessage: Yup.string().when("mode", {
        is: 'edit',
        then: Yup.string().max(500, 'Response message is too long'),
      }),
      status: Yup.string().when("mode", {
        is: 'edit',
        then: Yup.string().required('Status is required').oneOf(['Pending', 'Approved', 'Rejected']),
      }),
    }),
    onSubmit,
  });


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        formik.setFieldValue('image', reader.result);
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-4">
      <div>
        <label className="block text-gray-700 font-medium">Product Name</label>
        <input
          type="text"
          name="productName"
          className="mt-1 p-2 border border-gray-300 rounded w-full"
          {...formik.getFieldProps('productName')}
        />
        {formik.touched.productName && formik.errors.productName && (
          <div className="text-red-500 text-sm">{formik.errors.productName}</div>
        )}
      </div>

      <div>
        <label className="block text-gray-700 font-medium">Description</label>
        <textarea
          name="description"
          className="mt-1 p-2 border border-gray-300 rounded w-full"
          {...formik.getFieldProps('description')}
        />
        {formik.touched.description && formik.errors.description && (
          <div className="text-red-500 text-sm">{formik.errors.description}</div>
        )}
      </div>

      <div>
        <label className="block text-gray-700 font-medium">Category</label>
        <input
          type="text"
          name="category"
          className="mt-1 p-2 border border-gray-300 rounded w-full"
          {...formik.getFieldProps('category')}
        />
        {formik.touched.category && formik.errors.category && (
          <div className="text-red-500 text-sm">{formik.errors.category}</div>
        )}
      </div>

      <div>
        <label className="block text-gray-700 font-medium">Quantity Requested</label>
        <input
          type="number"
          name="quantityRequested"
          className="mt-1 p-2 border border-gray-300 rounded w-full"
          {...formik.getFieldProps('quantityRequested')}
          min="1"
        />
        {formik.touched.quantityRequested && formik.errors.quantityRequested && (
          <div className="text-red-500 text-sm">{formik.errors.quantityRequested}</div>
        )}
      </div>

      <div>
        <label className="block text-gray-700 font-medium">Unit Price</label>
        <input
          type="number"
          name="price"
          className="mt-1 p-2 border border-gray-300 rounded w-full"
          {...formik.getFieldProps('price')}
        />
        {formik.touched.price && formik.errors.price && (
          <div className="text-red-500 text-sm">{formik.errors.price}</div>
        )}
      </div>

      <div>
        <label className="block text-gray-700 font-medium">Product Image</label>
        <input
          type="file"
          name="image"
          accept="image/*"
          onChange={handleImageChange}
          className="mt-1 p-2 border border-gray-300 rounded w-full"
        />
        {formik.touched.image && formik.errors.image && (
          <div className="text-red-500 text-sm">{formik.errors.image}</div>
        )}
        {imagePreview && (
          <img
            src={imagePreview}
            alt="Preview"
            className="mt-2 w-32 h-32 object-cover border rounded-md"
          />
        )}
      </div>

      {mode === 'edit' && (
        <>
          <div>
            <label className="block text-gray-700 font-medium">Response Message</label>
            <textarea
              name="responseMessage"
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              {...formik.getFieldProps('responseMessage')}
            />
            {formik.touched.responseMessage && formik.errors.responseMessage && (
              <div className="text-red-500 text-sm">{formik.errors.responseMessage}</div>
            )}
          </div>

          <div>
            <label className="block text-gray-700 font-medium">Status</label>
            <select
              name="status"
              className="mt-1 p-2 border border-gray-300 rounded w-full"
              {...formik.getFieldProps('status')}
            >
              <option value="" label="Select status" />
              <option value="Pending" label="Pending" />
              <option value="Approved" label="Approved" />
              <option value="Rejected" label="Rejected" />
            </select>
            {formik.touched.status && formik.errors.status && (
              <div className="text-red-500 text-sm">{formik.errors.status}</div>
            )}
          </div>
        </>
      )}

      <button
        type="submit"
        className="w-full bg-blue-600 text-white py-2 rounded hover:bg-blue-700"
      >
        {mode === 'edit' ? 'Update Request' : 'Submit Request'}
      </button>
    </form>
  );
};

export default ProductRequestForm;
