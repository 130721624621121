import React from "react";
import { Link } from "react-router-dom";

const ResellerProductCard = ({ product, userInfo = null }) => {
  return (
    <Link
      to={{ pathname: `/reseller/products/${product.title}` }}
      state={{ fromReseller: true }}
      className="bg-white rounded-lg shadow-lg hover:shadow-xl transition-transform transform hover:scale-105"
    >
      <div className="relative w-full h-40 sm:h-48 md:h-56 lg:h-64">
        {product.colorSizeVariants && product.colorSizeVariants.length > 0 ? (
          <img
            src={product.colorSizeVariants[0]?.image}
            alt={product.title}
            className="w-full h-full object-fit"
          />
        ) : (
          <img
            src={product.sizeVariants[0]?.image}
            alt={product.title}
            className="w-full h-full object-fit "
          />
        )}
      </div>
      <div className="p-2 md:p-4">
        <h2 className="text-xs sm:text-sm md:text-lg font-bold line-clamp-2">
          {product.title}
        </h2>

        {product.upcoming ? (
          <span className="text-green-600 font-semibold text-sm md:text-base">
            Coming Soon
          </span>
        ) : (
          <>
            {product.colorSizeVariants && product.colorSizeVariants.length > 0 && (
              <div className="mt-2">
                {product.colorSizeVariants[0].sizes[0] && (
                  <div
                    className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-1 text-sm md:text-base"
                  >
                    {userInfo &&
                    userInfo.role === "reseller" &&
                    userInfo.subscriptionStatus === true ? (
                      <>
                        <span className="text-green-600 font-semibold">
                          Retail Price: ট{" "}
                          {product.colorSizeVariants[0].sizes[0].price}
                        </span>
                        <span className="text-green-600 font-semibold">
                          Reseller Price: ট{" "}
                          {product.colorSizeVariants[0].sizes[0].resellerPrice}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="text-red-600 font-semibold">
                          Retail Price: *****
                        </span>
                        <span className="text-red-600 font-semibold">
                          Reseller Price: *****
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}

            {product.sizeVariants && product.sizeVariants.length > 0 && (
              <div className="mt-2">
                {product.sizeVariants[0] && (
                  <div
                    key={product.sizeVariants[0].sizeName}
                    className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-1 text-sm md:text-base"
                  >
                    {userInfo &&
                    userInfo.role === "reseller" &&
                    userInfo.subscriptionStatus === true ? (
                      <>
                        <span className="text-green-600 font-semibold">
                          Retail Price: ট {product.sizeVariants[0].price}
                        </span>
                        <span className="text-green-600 font-semibold">
                          Reseller Price: ট {product.sizeVariants[0].resellerPrice}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="text-red-600 font-semibold">
                          Retail Price: *****
                        </span>
                        <span className="text-red-600 font-semibold">
                          Reseller Price: *****
                        </span>
                      </>
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </Link>
  );
};

export default ResellerProductCard;









// import React from "react";
// import { Link } from "react-router-dom";

// const ResellerProductCard = ({ product, userInfo = null }) => {
//   return (
//     <Link
//       to={{ pathname: `/reseller/products/${product.title}` }}
//       state={{ fromReseller: true }}
//       className="bg-white p-4 rounded-lg shadow-lg hover:shadow-xl transition-transform transform hover:scale-105"
//     >
//       <div className="relative w-full h-56">
//         {product.colorSizeVariants && product.colorSizeVariants.length > 0 ? (
//           <img
//             src={product.colorSizeVariants[0]?.image}
//             alt={product.title}
//             className="w-full h-full object-contain transition-all duration-300 ease-in-out transform hover:scale-110"
//           />
//         ) : (
//           <img
//             src={product.sizeVariants[0]?.image}
//             alt={product.title}
//             className="w-full h-full object-contain transition-all duration-300 ease-in-out transform hover:scale-110"
//           />
//         )}
//       </div>
//       <div className="p-4">
//         <h2 className="text-sm md:text-sm lg:text-lg font-bold">{product.title}</h2>

//         {
//           product.upcoming ? (
//             <span className="text-green-600 font-semibold"> Coming Soon</span>
//           ) : (
//             <>
//               {product.colorSizeVariants && product.colorSizeVariants.length > 0 && (
//           <div className="mt-2">
//             {/* <h3 className="font-semibold">Color and Sizes:</h3> */}
//             {/* <p className="mt-1">
//               Color: {product.colorSizeVariants[0].colorName}
//             </p> */}
//             {product.colorSizeVariants[0].sizes[0] && (
//               <div
//                 key={product.colorSizeVariants[0].sizes[0].sizeName}
//                 className="flex justify-between items-center mt-1"
//               >
                
//                 {userInfo &&
//                 userInfo.role === "reseller" &&
//                 userInfo.subscriptionStatus === true ? (
//                   <>
//                     <span className="text-green-600 font-semibold">
//                       Retailer Price: ট {product.colorSizeVariants[0].sizes[0].price}
//                     </span>
//                     <br />
//                     <span className="text-green-600 ml-2 font-semibold">
//                       Reseller Price: {product.colorSizeVariants[0].sizes[0].resellerPrice}
//                     </span>
//                   </>
//                 ) : (
//                   <>
//                     <span className="text-red-600 font-semibold">
//                       Retailer Price: *****{" "}
//                     </span>
//                     <span className="text-red-600 font-semibold">
//                       Reseller Price: *****{" "}
//                     </span>
//                   </>
//                 )}
                
//               </div>
//             )}
//           </div>
//         )}

//         {product.sizeVariants && product.sizeVariants.length > 0 && (
//           <div className="mt-2">
//             {/* <h3 className="font-semibold">Sizes:</h3> */}
//             {product.sizeVariants[0] && (
//               <div
//                 key={product.sizeVariants[0].sizeName}
//                 className="flex justify-between items-center mt-1"
//               >
//                 {/* <span>{product.sizeVariants[0].sizeName}</span> */}
//                 {userInfo &&
//                 userInfo.role === "reseller" &&
//                 userInfo.subscriptionStatus === true ? (
//                   <>
//                     <span className="text-green-600 font-semibold">
//                       Retailer Price: ট {product.sizeVariants[0].price}
//                     </span>
//                     <br />
//                     <span className="text-green-600 ml-2 font-semibold">
//                       Reseller Price: {product.sizeVariants[0].resellerPrice}
//                     </span>
//                   </>
//                 ) : (
//                   <>
//                     <span className="text-red-600 font-semibold">
//                       Retailer Price: *****{" "}
//                     </span>
//                     <span className="text-red-600 font-semibold">
//                       Reseller Price: *****{" "}
//                     </span>
//                   </>
//                 )}
                
//               </div>
//             )}
//           </div>
//         )}
//             </>
//           )
//         }
        
//       </div>
     
//     </Link>
//   );
// };

// export default ResellerProductCard;

