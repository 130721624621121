import React, { useState, useEffect } from 'react';
import { getCartItems, removeFromCart, clearCart, updateQuantity } from '../utils/cartUtils';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentServiceCharge } from '../features/serviceCharge/serviceChargeSlice';

const CartSidebar = ({ isOpen, onClose }) => {
  const {isAuthenticated, userInfo} = useSelector((state) => state.auth);
  const { currentServiceCharge } = useSelector((state) => state.serviceCharge);
  const [cart, setCart] = useState(getCartItems());

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const updateCart = () => {
      setCart(getCartItems());
    };

    // Listen for the custom event
    window.addEventListener('cartUpdated', updateCart);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('cartUpdated', updateCart);
    };
  }, []);

  useEffect(() => {
    dispatch(getCurrentServiceCharge());
  }, [dispatch]);

  const handleIncreaseQuantity = (productId) => {
    const item = cart.find(item => item._id === productId);
    updateQuantity(productId, item.quantity + 1);
    setCart(getCartItems());
  };

  const handleDecreaseQuantity = (productId) => {
    const item = cart.find(item => item._id === productId);
    if (item.quantity > 1) {
      updateQuantity(productId, item.quantity - 1);
      setCart(getCartItems());
    }
  };

  const handleRemoveItem = (productId) => {
    removeFromCart(productId);
    setCart(getCartItems());
  };

  const handleClearCart = () => {
    clearCart();
    setCart([]);
  };

  const subtotal = cart.reduce((total, item) => total + Number(item.price) * Number(item.quantity), 0) 
  

  // handle checkout for user
  const handleCheckout = () => {
    if (!isAuthenticated) {
      navigate("/login", { state: { from: `/shop/products` } });
      return;
    }
    const products = cart.map((item) => ({ 
      id: item._id,
      title: item.title,
      productCode: item.productCode,
      image: item.image,
      color: item.color  || '',
      size: item.size,
      quantity: item.quantity,
      price: item.price,
      affiliateCommission: item.affiliateCommission,
      resellerPrice: item.resellerPrice ? item.resellerPrice : 0,
      customPrice: item.customPrice ? item.customPrice : 0,
     }));
    const refCode = localStorage.getItem('ref_code');
    navigate('/checkout',{state:{products, totalPrice: subtotal, affiliateCode: refCode, reseller: JSON.parse(localStorage.getItem('reseller')) ? JSON.parse(localStorage.getItem('reseller')) : null, orderByReseller: userInfo.role ==='reseller' ? userInfo._id : null}});

    if(isOpen) onClose();
  };

  

  return (
    <div className={`fixed inset-y-0 right-0 w-80 bg-white shadow-lg transform ${isOpen ? 'translate-x-0' : 'translate-x-full'} transition-transform duration-300 z-50 overflow-y-auto`}>
      <div className="p-6">
        <button
          onClick={onClose}
          className="absolute top-4 right-2 bg-red-900 text-white rounded-full w-8 h-8  justify-center hover:bg-red-400"
        >
          &#x2715;
        </button>
        <h2 className="text-xl bg-green-900 text-center text-white p-2 font-bold mt-8 mb-4">Shopping Cart</h2>
        {cart.length ? (
          <div className="flex flex-col space-y-4">
            {cart.map((item) => (
              <div key={item._id} className="flex items-center justify-between border-b pb-2">
                <div>
                  <p className="font-semibold">{item.title}</p>
                  <p className="text-gray-600">Price: TK. {item.price}</p>
                  <div className="flex items-center mt-2">
                    <button onClick={() => handleDecreaseQuantity(item._id)} className="px-2 py-1 border rounded-l bg-gray-200 hover:bg-gray-300">
                      -
                    </button>
                    <span className="px-3 border-t border-b">{item.quantity}</span>
                    <button onClick={() => handleIncreaseQuantity(item._id)} className="px-2 py-1 border rounded-r bg-gray-200 hover:bg-gray-300">
                      +
                    </button>
                  </div>
                </div>
                <button
                  onClick={() => handleRemoveItem(item._id)}
                  className="text-red-600 hover:text-red-800"
                >
                  Remove
                </button>
              </div>
            ))}
            <div className="mt-4">
              <p className="text-lg font-semibold">Subtotal: TK. {subtotal.toFixed(2)}</p>              
              <p className="text-lg font-semibold"> service charge will be added automatically based on your area</p>
              {
                currentServiceCharge && currentServiceCharge.length > 0 && (
                  currentServiceCharge?.map((item) => (
                    <div key={item._id} className="flex items-center justify-between border-b pb-2">
                      <p className="font-semibold">{item.name}</p>
                      <p className="text-gray-600">TK. {item.amount}</p>
                    </div>
                  ))
                )
              }
            </div>
            <button
              onClick={handleClearCart}
              className="text-red-500 hover:text-red-700 mt-4 text-sm"
            >
              Clear Cart
            </button>
            <button disabled={cart.length === 0 } onClick={ handleCheckout } className="w-full mt-4 px-4 py-2 bg-blue-500 text-white font-semibold rounded hover:bg-blue-600">
              Proceed to Checkout
            </button>
          </div>
        ) : (
          <p>Your cart is empty.</p>
        )}
      </div>
    </div>
  );
};

export default CartSidebar;
