import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api/api';
import { toast } from 'react-toastify';

// async thunk for getting all order bonuses
export const fetchOrderBonuses = createAsyncThunk('orderBonus/fetchOrderBonuses', async (token) => {
    const response = await api.get('/order-bonuses', {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
});

// async thunk for creating an order bonus
export const createOrderBonus = createAsyncThunk('orderBonus/createOrderBonus', async ({orderBonus,token}) => {
    const response = await api.post('/order-bonuses', orderBonus,{headers: {
        "Content-Type":"application/json",
        "Authorization": `Bearer ${token}`
    }});
    if(response.status === 201){
        toast.success("order bonus notice created successfully");
    }
    return response.data;
});

// async thunk for deleting in order bonus
export const deleteOrderBonus = createAsyncThunk('orderBonus/deleteOrderBonus', async ({id,token}) => {
    const response = await api.delete(`/order-bonuses/${id}`,{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    if(response.status === 200){
        toast.success("order bonus notice deleted successfully");
    }
    return response.data;
});

// update order bonus -- admin
export const updateOrderBonus = createAsyncThunk('orderBonus/updateOrderBonus', async ({id,orderBonusData,token}) => {
    const response = await api.put(`/order-bonuses/${id}`, orderBonusData, {headers: {
        "Content-Type":"application/json",
        "Authorization": `Bearer ${token}`
    }});
    if(response.status === 200){
        toast.success("order bonus notice updated successfully");
    }
    return response.data;

});

// async thunk for current order bonus --for reseller
export const fetchCurrentOrderBonus = createAsyncThunk('orderBonus/fetchCurrentOrderBonus', async () => {   
    const response = await api.get('/order-bonuses/current');
    return response.data;
});



const orderBonusSlice = createSlice({
    name: 'orderBonus',
    initialState: {
        orderBonuses: [],
        currentOrderBonus: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrderBonuses.fulfilled, (state, action) => {
                state.loading = false;
                state.orderBonuses = action.payload;
            })
            .addCase(createOrderBonus.fulfilled, (state, action) => {
                state.loading = false;
                state.orderBonuses.push(action.payload);
            })
            .addCase(deleteOrderBonus.fulfilled, (state, action) => {
                state.loading = false;
                state.orderBonuses = state.orderBonuses.filter((orderBonus) => orderBonus._id !== action.payload._id);
            })
            .addCase(fetchCurrentOrderBonus.fulfilled, (state, action) => {
                state.loading = false;
                state.currentOrderBonus = action.payload;
            })
            .addCase(fetchCurrentOrderBonus.rejected, (state, action) => {
                state.currentOrderBonus = null;
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchOrderBonuses.rejected, (state, action) => {
                state.orderBonuses = [];
            })
            .addCase(createOrderBonus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(deleteOrderBonus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(fetchCurrentOrderBonus.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(fetchOrderBonuses.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createOrderBonus.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteOrderBonus.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateOrderBonus.fulfilled, (state, action) => {
                state.loading = false;
                state.orderBonuses = state.orderBonuses.map((orderBonus) => {
                    if (orderBonus._id === action.payload._id) {
                        return action.payload;
                    }
                    return orderBonus;
                });
            })
            .addCase(updateOrderBonus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(updateOrderBonus.pending, (state, action) => {
                state.loading = true;
            });
    },
});

export default orderBonusSlice.reducer;