import SliderImageForm from "./SliderImageForm";
const ShowSliderImageForm = ({
  setShowForm,
  sliderImageData = {},
  mode = "create",
}) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-2/3 max-h-[90vh] overflow-auto">
        <div className="flex justify-between items-center border-b p-4">
          <h3 className="text-xl text-center bg-green-900 text-white p-2 font-semibold mb-4">
            {mode === "create" ? "Create Slider Image" : "Edit Slider Image"}
          </h3>
          <button
            onClick={() => setShowForm(false)}
            className="text-red-900 font-semibold hover:text-red-600 w-32"
          >
            ✕
          </button>
        </div>

        <SliderImageForm
          setShowForm={setShowForm}
          sliderImageData={sliderImageData}
          mode={mode}
        />
        <button
          onClick={() => setShowForm(false)}
          className="w-full mt-4 bg-red-600 text-white text-center p-2 rounded hover:bg-red-700 transition-all"
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ShowSliderImageForm;
