import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";
import { toast } from "react-toastify";

//  upload images
export const uploadImages = createAsyncThunk("uploadImage/uploadImage", async ({imageData,token}) => {
    const response = await api.post("/products/images", imageData,{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    if(response.status === 201){
        toast.success("Images uploaded successfully");
    }
    return response.data;
});


const uploadImageSlice = createSlice({
    name: "uploadImage",
    initialState: {
        loading: false,
        error: null,
        uploadedImages: [],
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(uploadImages.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(uploadImages.fulfilled, (state, action) => {
                state.loading = false;
                console.log(action.payload);
                state.uploadedImages = action.payload;
            })
            .addCase(uploadImages.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export default uploadImageSlice.reducer