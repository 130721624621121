import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import resellerBanner from "../../images/reseller.jpg";
import { registerReseller } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../../components/LoaderComponent";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ResellerSignup = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const { isAuthenticated, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/reseller/login");
    }
  }, [isAuthenticated, navigate]);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, "name minimum 5 character")
      .required("Name is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    phone: Yup.string().required("Phone number is required"),
    refCode: Yup.string(),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const queryParams = new URLSearchParams(location.search);
  const referCode = queryParams.get("refer");

  const handleSubmit = (values) => {
    // Handle form submission
    
    dispatch(
      registerReseller({
        name: values.name,
        email: values.email,
        phone: values.phone,
        refCode: values.refCode,
        password: values.password,
      })
    );
  };

  if (loading) return <LoaderComponent />;

  return (
    <div className="min-h-screen flex items-center justify-center pt-3 pb-3">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full space-y-6">
        {/* Banner Image */}
        <div className="w-full flex justify-center mb-4">
          <img
            src="/reseller.jpg"
            alt="Login Banner"
            className="w-full h-48 rounded-lg"
          />
        </div>

        {/* Register Form */}
        <h2 className="text-center text-2xl font-bold text-gray-700">
          Create Your Reseller Account
        </h2>
        <Formik
          initialValues={{
            name: "",
            email: "",
            phone: "",
            refCode: referCode || "",
            password: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form className="space-y-4">
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-600"
                >
                  Name
                </label>
                <Field
                  id="name"
                  name="name"
                  type="text"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Enter your name"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-600"
                >
                  Email
                </label>
                <Field
                  id="email"
                  name="email"
                  type="email"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Enter your email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-600"
                >
                  Phone
                </label>
                <Field
                  id="phone"
                  name="phone"
                  type="text"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Enter your phone number"
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="refCode"
                  className="block text-sm font-medium text-gray-600"
                >
                  Refer By Code (optional)
                </label>
                <Field
                  id="refCode"
                  name="refCode"
                  type="text"
                  disabled={referCode}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Enter your referral code"
                />
                <ErrorMessage
                  name="refCode"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="relative">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-600"
                >
                  Password
                </label>
                <div className="relative w-full">
                  <Field
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                    placeholder="Enter your password"
                  />
                  <div
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash className="w-6 h-6"/> : <FaEye className="w-6 h-6" />}
                  </div>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>
              <div className="relative">
                <label
                  htmlFor="confirm-password"
                  className="block text-sm font-medium text-gray-600"
                >
                  Confirm Password
                </label>
                <div className="relative w-full">
                <Field
                  id="confirm-password"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Confirm your password"
                />
                 <div
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? <FaEyeSlash className="w-6 h-6" /> : <FaEye className="w-6 h-6"/>}
                  </div>
                </div>
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full bg-green-600 text-white p-2 rounded-lg hover:bg-green-700 transition duration-300"
                >
                  Signup
                </button>
              </div>
            </Form>
          )}
        </Formik>

        {/* Sign Up Link */}
        <p className="text-center text-sm text-gray-600">
          Already Have an Account?{" "}
          <Link
            to="/reseller/login"
            className="text-green-600 hover:text-green-700 font-medium"
          >
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ResellerSignup;
