import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaCheckCircle, FaEye, FaSyncAlt } from 'react-icons/fa';
import { getWithdrawalRequestsByAdmin, updateWithdrawalRequestByAdmin } from '../../features/withdrawalRequest/withdrawalRequestSlice';
import LoaderComponent from '../LoaderComponent';
import Pagination from '../Pagination';

const WithdrawalRequestsList = () => {
  const dispatch = useDispatch();
  const { withdrawalRequests,pagination, loading } = useSelector((state) => state.withdrawalRequest);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [page,setPage] = useState(1);

  useEffect(() => {
    dispatch(getWithdrawalRequestsByAdmin({ page,limit:10 }));
  }, [dispatch,page]);

  const handleUpdateStatus = (id, status) => {
    if (window.confirm(`Are you sure you want to mark this request as ${status}?`)) {
      dispatch(updateWithdrawalRequestByAdmin({ id, withdrawalRequest: { status } }));
    }
  };

  const getStatusColor = (status) => {
    switch (status.toLowerCase()) {
      case "paid":
        return "#38a169"; // Green
      case "pending":
        return "#d69e2e"; // Yellow
      default:
        return "#718096"; // Gray
    }
  };
  

  if (loading) return <LoaderComponent />;

  return (
    <div className="container mx-auto p-6 mb-8 h-screen">
      <h2 className="text-center text-2xl font-bold text-blue-600 mb-6">Withdrawal Requests</h2>
      <div className="overflow-x-auto shadow-lg rounded-lg bg-white">
        {withdrawalRequests.length > 0 ? (
          <table className="min-w-full bg-white border border-gray-200 rounded-md overflow-x-auto">
            <thead>
              <tr className="bg-gray-100">
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">SL No</th>
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">User</th>
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">User Type</th>
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">Amount</th>
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">Method</th>
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">Account No.</th>
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">Provider</th>
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">Status</th>
                <th className="py-3 px-4 text-left text-gray-600 font-semibold">Date</th>
                <th className="py-3 px-4 text-center text-gray-600 font-semibold">Actions</th>
              </tr>
            </thead>
            <tbody>
              {withdrawalRequests?.length > 0 && withdrawalRequests.map((request, index) => (
                <tr key={request._id} className="border-t border-gray-200 hover:bg-gray-50">
                  <td className="py-4 px-4 text-gray-800">{index + 1}</td>
                  <td className="py-4 px-4 text-gray-800">{request.user?.name}</td>
                  <td className="py-4 px-4 text-gray-800">{request.requesterType}</td>
                  <td className="py-4 px-4 text-gray-800">Tk. {request?.amount}</td>
                  <td className="py-4 px-4 text-gray-800">{request.withdrawalMethod?.paymentMethod}</td>
                  <td className="py-4 px-4 text-gray-800">{request.withdrawalMethod[request.withdrawalMethod?.paymentMethod]?.accountNumber}</td>
                  <td className="py-4 px-4 text-gray-800">{request.withdrawalMethod[request.withdrawalMethod?.paymentMethod]?.provider}</td>
                  <td className={`py-4 px-4 text-center font-semibold ${
                    request.status === 'paid' ? 'text-green-600' :
                    'text-yellow-600'
                  }`}>
                    {request.status}
                  </td>
                  <td className="py-4 px-4 text-gray-800">{new Date(request.createdAt).toLocaleDateString()}</td>
                  <td className="py-4 px-4 flex justify-center gap-4 text-gray-600">
                    <button
                      onClick={() => setSelectedRequest(request)}
                      className="text-blue-500 hover:text-blue-600 transition-all"
                      title="View Details"
                    >
                      <FaEye size={18} />
                    </button>
                    <button
                      onClick={() => handleUpdateStatus(request._id, 'paid')}
                      className="text-green-500 hover:text-green-600 transition-all"
                      title="Approve"
                    >
                      <FaCheckCircle size={18} />
                    </button>
                    
                    <button
                      onClick={() => handleUpdateStatus(request._id, 'pending')}
                      className="text-yellow-500 hover:text-yellow-600 transition-all"
                      title="Set to Pending"
                    >
                      <FaSyncAlt size={18} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-center text-gray-500 py-8">No withdrawal requests found.</p>
        )}
      </div>

      <Pagination currentPage={page} totalPages={pagination?.totalPages} onPageChange={setPage} />

      {selectedRequest && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60 z-50">
        <div className="bg-white p-8 rounded-xl shadow-2xl w-11/12 sm:w-4/5 md:w-1/2 lg:w-1/3 transform transition-transform duration-300 ease-in-out scale-105">
          <h3 className="text-2xl font-bold mb-6 text-center text-gray-800">Request Details</h3>
          <div className="space-y-4 text-gray-700">
            
            <p className="flex justify-between">
              <span className="font-medium">User:</span>
              <span>{selectedRequest.user.name}</span>
            </p>
            <p className="flex justify-between">
              <span className="font-medium">Amount:</span>
              <span>TK. {selectedRequest.amount}</span>
            </p>
            <p className="flex justify-between">
              <span className="font-medium">Method:</span>
              <span>{selectedRequest.withdrawalMethod?.paymentMethod}</span>
            </p>
            <p className="flex justify-between">
              <span className="font-medium">Account Number:</span>
              <span>{selectedRequest.withdrawalMethod[selectedRequest.withdrawalMethod?.paymentMethod]?.accountNumber}</span>
            </p>
            <p className="flex justify-between">
              <span className="font-medium">Provider:</span>
              <span>{selectedRequest.withdrawalMethod[selectedRequest.withdrawalMethod?.paymentMethod]?.provider}</span>
            </p>
            <p className="flex justify-between">
              <span className="font-medium">Status:</span>
              <span className="px-2 py-1 rounded-full text-sm font-semibold text-white" style={{ backgroundColor: getStatusColor(selectedRequest.status) }}>
                {selectedRequest.status}
              </span>
            </p>
            <p className="flex justify-between">
              <span className="font-medium">Requested On:</span>
              <span>{new Date(selectedRequest.createdAt).toLocaleString()}</span>
            </p>
          </div>
          <button
            onClick={() => setSelectedRequest(null)}
            className="mt-6 w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700 transition-all shadow-md text-lg font-medium"
          >
            Close
          </button>
        </div>
      </div>
      
      )}
    </div>
  );
};

export default WithdrawalRequestsList;
