import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUpcomingProductsByReseller,
  fetchNewArrivalProductsByReseller, 
  fetchJustForYouProducts
} from "../../features/resellerProducts/resellerProductSlice";
import { getSliderImagesForReseller } from "../../features/sliderImage/sliderImageSlice";
import ResellerProductCard from "./ResellerProductCard";
import Carousel from "../../components/Carousel";
import LoaderComponent from "../../components/LoaderComponent";
import CategoryMenu from "../../components/CategoryMenu";
import { Link } from "react-router-dom";
import SEO from "../../components/Seo";



const Shop = () => {

  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { newArrivalProducts,suggestedProducts, upcomingProducts,  loading, error } = useSelector(
    (state) => state.resellerProducts
  );


  const { sliderImagesForReseller } = useSelector(
    (state) => state.sliderImage
  );

  useEffect(() => {
    dispatch(fetchUpcomingProductsByReseller())
    dispatch(fetchNewArrivalProductsByReseller());
    dispatch(fetchJustForYouProducts());
    dispatch(getSliderImagesForReseller());
  }, [dispatch]);

  if (loading) return <LoaderComponent message="loading products" />;
  if (error) return <p>Error: {error}</p>;

  

  return (
    <>
    <div className="container mx-auto">
      <SEO title={"Paribarmart || Reseller Home"} url={"https://www.paribarmart.com/reseller"} description={"Welcome to Reseller Shop. We are dedicated to providing innovative solutions that push the boundaries and deliver unmatched quality to our clients."} />
      {sliderImagesForReseller && sliderImagesForReseller.length > 0 && <Carousel slides={sliderImagesForReseller} /> }
      <CategoryMenu type="reseller" />
      <h2 className="text-2xl font-bold mb-4 mt-4">NEW ARRIVAL</h2>
      <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
        {newArrivalProducts.map((product) => (
          <ResellerProductCard key={product._id} product={product} userInfo={userInfo} />
        ))}
      </div>
      {
        upcomingProducts && upcomingProducts.length > 0 && (
          <>
            <h2 className="text-xs sm:text-sm md:text-lg font-bold line-clamp-2">UPCOMING</h2>
            <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
              {upcomingProducts.map((product) => (
                <ResellerProductCard key={product._id} product={product} userInfo={userInfo} />
              ))}
            </div>
          </>
        )
      }
      <div className="flex  justify-between w-full  p-4">
        <h2 className="text-xs sm:text-sm md:text-lg font-bold line-clamp-2">JUST FOR YOU</h2>
        <Link to="/reseller/products" className="bg-gray-600 hover:bg-gray-700 text-center text-xs sm:text-sm md:text-lg font-bold line-clamp-2 text-white p-1">
          SEE ALL PRODUCTS
        </Link>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-5 gap-2">
        {
          suggestedProducts.map((product) => (
            <ResellerProductCard key={product._id} product={product} userInfo={userInfo} />
          ))}
      </div>
    </div>
    </>
  );
};

export default Shop;
