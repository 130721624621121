import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api/api';
import { toast } from 'react-toastify';

export const createPackage = createAsyncThunk('package/createPackage', async (packageData) => {
    const response = await api.post('/subscription-packages', packageData,{headers: {
        "Content-Type":"application/json"
    }});
    if(response.status === 201) {
        toast.success("package created successfully");
    }
    return response.data;
});

export const getPackages = createAsyncThunk('package/getPackages', async () => {
    const response = await api.get('/subscription-packages');
    return response.data;
});

export const updatePackage = createAsyncThunk('package/updatePackage', async ({packageId,packageData}) => {
    
    const response = await api.put(`/subscription-packages/${packageId}`, packageData, {headers: {
        "Content-Type":"application/json"
    }});
    if(response.status === 200) {
        toast.success("package updated successfully");
    }
    return response.data;
});

export  const deletePackage = createAsyncThunk('package/deletePackage', async (packageId) => {
    const response = await api.delete(`/subscription-packages/${packageId}`);
    if(response.status === 200) {
        toast.success("package deleted successfully");
    }
    return response.data;    
});


const packageSlice = createSlice({
    name: 'package',
    initialState: {
        packages: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createPackage.pending, (state) => {
                state.loading = true;
            })
            .addCase(createPackage.fulfilled, (state, action) => {
                state.loading = false;
                state.packages.push(action.payload);
            })
            .addCase(createPackage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getPackages.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPackages.fulfilled, (state, action) => {
                state.loading = false;
                state.packages = action.payload;
            })
            .addCase(getPackages.rejected, (state, action) => { 
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(updatePackage.pending, (state) => {
                state.loading = true;
            })
            .addCase(updatePackage.fulfilled, (state, action) => {
                state.loading = false;
                const updatedPackage = action.payload;
                const index = state.packages.findIndex((p) => p._id === updatedPackage._id);
                if (index !== -1) {
                    state.packages[index] = updatedPackage;
                }
            })
            .addCase(updatePackage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(deletePackage.pending, (state) => {
                state.loading = true;
            })
            .addCase(deletePackage.fulfilled, (state, action) => {
                state.loading = false;
                const deletedPackageId = action.payload._id;
                state.packages = state.packages.filter((p) => p._id !== deletedPackageId);
            })
            .addCase(deletePackage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });

    }
});

export default packageSlice.reducer;