import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaPlus, FaEdit } from 'react-icons/fa';
import { getPhoneOrderDetails } from '../../features/phoneOrderDetails/phoneOrderSlice';
import PhoneOrderDetailsForm from './PhoneOrderDetailsForm';
import Modal from '../common/Modal';
import LoaderComponent from '../LoaderComponent';

const PhoneOrderDetailsList = () => {

  const { phoneOrderDetails, loading, error } = useSelector((state) => state.phoneOrderDetails);

const [showCreateForm, setShowCreateForm] = useState(false);
const [isEditing, setIsEditing] = useState(false);
const [selectedPhoneOrder, setSelectedPhoneOrder] = useState(null);
const dispatch = useDispatch();

// fetch headlines on mount

useEffect(() => {
  dispatch(getPhoneOrderDetails());
}, [dispatch]);

  // const handleDelete = (headlineId) => {
  //   if (window.confirm('Are you sure you want to delete this headline?')) {
  //     dispatch(deleteHeadline(headlineId));
  //   }
  // };

  const handleEdit = (phoneOrder) => {
    setIsEditing(true);
    setSelectedPhoneOrder(phoneOrder);
  };

  if(loading) return <LoaderComponent message="Loading categories..." />
  if(error) return <p className="text-red-500">{error}</p>

  return (
    <div className="container mx-auto p-6 mb-8 h-screen ">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Phone Order Notice List</h2>
        <button
          onClick={() => setShowCreateForm(true)}
          className="bg-blue-500 text-white p-2 rounded shadow-md hover:bg-blue-600 transition-all flex items-center"
        >
          <FaPlus className="mr-2" /> create phone order notice
        </button>
      </div>

      {/* {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>} */}
      <div className='shadow-lg rounded-lg overflow-x-auto'>
        {phoneOrderDetails.length > 0 ? (
          <table className="min-w-full bg-white border border-gray-200 rounded shadow-md">
            <thead className="bg-gray-200">
              <tr>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">SL NO</th>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">Telephone</th>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">Mobile</th>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">Whatsapp</th>
                <th className="py-2 px-4 border-b text-center font-semibold text-gray-700">Imo</th>
                <th className="py-2 px-4 border-b text-center font-semibold text-gray-700">Actions</th>
              </tr>
            </thead>
            <tbody>
              {phoneOrderDetails.map((phoneOrder,index) => (
                <tr key={phoneOrder._id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b">{index+1}</td>
                  <td className="py-2 px-4 border-b">{phoneOrder.phone}</td>
                  <td className="py-2 px-4 border-b">{phoneOrder.mobile}</td>
                  <td className="py-2 px-4 border-b">{phoneOrder.whatsapp}</td>
                  <td className="py-2 px-4 border-b">{phoneOrder.imo}</td>
                  
                  <td className="py-2 px-4 border-b text-center">
                    
                    {/* <button
                      className="text-green-500 hover:text-green-700 mx-1"
                      onClick={() => console.log(`Editing category ${headline._id}`)}
                    >
                      <FaEdit />
                    </button> */}
                    <button
                      className="text-red-500 hover:text-red-700 mx-1"
                      onClick={() => handleEdit(phoneOrder)}
                    >
                      <FaEdit />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      ) : (
        <p>No phone order notice available.</p>
      )}
      </div>
      {
        showCreateForm && (
            <Modal title={"create phone order notice"} isOpen={showCreateForm} onClose={() => setShowCreateForm(false)}>
                <PhoneOrderDetailsForm showForm={()=>setShowCreateForm(false)} />
            </Modal>
        )
      }
      {
        isEditing && (
            <Modal title={"Edit Phone Order Notice"} isOpen={isEditing} onClose={() => setIsEditing(false)}>
                <PhoneOrderDetailsForm showForm={()=>setIsEditing(false)} initialValue={selectedPhoneOrder} mode='edit' />
            </Modal>

        )
      }
    </div>

  );
};

export default PhoneOrderDetailsList;
