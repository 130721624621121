import React, { useEffect, useState } from "react";
// import { FaShoppingCart, FaFacebook, FaTwitter, FaInstagram, FaShoppingBag } from 'react-icons/fa';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleProduct,
  getRecommendationProductsByReseller,
} from "../../features/products/productsSlice";
import { getReviewsByProductId } from "../../features/reviews/reviewSlice";
import LoaderComponent from "../../components/LoaderComponent";
import { addToCart } from "../../utils/cartUtils";
import RelatedProducts from "./RelatedProducts";
import {
  FaPhone,
  FaPhoneAlt,
  FaShoppingBag,
  FaWhatsapp,
  FaYoutube,
} from "react-icons/fa";
import ShowModal from "../../components/admin/ShowModal";
import AddToStoreForm from "../../components/reseller/AddToStoreForm";
import ShareComponent from "../../components/ShareComponent";
import DescriptionsAndReviews from "../../components/DescriptionAndReviews";
import SEO from "../../components/Seo";
import { getPhoneOrderDetails } from "../../features/phoneOrderDetails/phoneOrderSlice";
import ImageSlider from "../../components/ImageSlider";

const SingleProduct = () => {
  const { productTitle } = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { singleProduct, recommendedProductsForReseller, loading } =
    useSelector((state) => state.products);
  const { singleProductReviews } = useSelector((state) => state.review);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { phoneOrderDetails } = useSelector((state) => state.phoneOrderDetails);
  // const [zoomStyle, setZoomStyle] = useState({ display: 'none' });
  const [quantity, setQuantity] = useState(1);

  //   // add to shop
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const navigate = useNavigate();

  const [selectedColor, setSelectedColor] = useState({});
  const [selectedSize, setSelectedSize] = useState({});
  const [selectedVariant, setSelectedVariant] = useState({});

  useEffect(() => {
    if (productTitle) {
      dispatch(getSingleProduct(productTitle));
      dispatch(getPhoneOrderDetails());
    }
  }, [productTitle, dispatch]);

  useEffect(() => {
    if (singleProduct) {
      if (singleProduct.colorSizeVariants?.length > 0) {
        setSelectedColor(singleProduct?.colorSizeVariants[0]);
        setSelectedSize(singleProduct.colorSizeVariants[0]?.sizes[0]);
        setSelectedVariant({});
      } else if (singleProduct?.sizeVariants?.length > 0) {
        setSelectedVariant(singleProduct?.sizeVariants[0]);
        setSelectedColor({});
        setSelectedSize({});
      }

      const categoryIds = Array.isArray(singleProduct?.category)
        ? singleProduct?.category.map((cat) => cat._id)
        : [];

      if (singleProduct?._id) {
        dispatch(
          getRecommendationProductsByReseller({
            category: categoryIds?.join(","),
            productId: singleProduct?._id,
          })
        );
        dispatch(getReviewsByProductId(singleProduct?._id));
      }
      // else {
      //   console.error("Product ID is undefined");
      // }
    }
  }, [singleProduct, dispatch]);

  const incrementQuantity = () => {
    if (
      (selectedSize?.stock && quantity < selectedSize.stock) ||
      (selectedVariant?.stock && quantity < selectedVariant.stock)
    ) {
      setQuantity(quantity + 1);
    }
  };
  const decrementQuantity = () =>
    quantity > 1 && setQuantity((prev) => prev - 1);

  // const magnifyImage = (index) => {
  //   if (singleProduct?.images?.[index]?.url) {
  //     setSelectedImage(singleProduct.images[index].url);
  //   }
  // };

  // const handleMouseMove = (e) => {
  //   const { left, top, width, height } = e.target.getBoundingClientRect();
  //   const x = e.clientX - left;
  //   const y = e.clientY - top;
  //   const bgPosX = (x / width) * 100;
  //   const bgPosY = (y / height) * 100;
  //   setZoomStyle({
  //     display: 'block',
  //     backgroundImage: `url(${selectedImage})`,
  //     backgroundSize: `${width * 2}px ${height * 2}px`,
  //     backgroundPosition: `${bgPosX}% ${bgPosY}%`,
  //     left: x - 75,
  //     top: y - 75,
  //   });
  // };

  // const handleMouseLeave = () => setZoomStyle({ display: 'none' });

  /**
   * handle add to cart
   */
  const handleAddToCart = () => {
    const productDetails =
      singleProduct.colorSizeVariants?.length > 0
        ? {
            _id: singleProduct._id,
            title: singleProduct.title,
            productCode: singleProduct.productCode,
            image: selectedColor.image,
            price: selectedSize.resellerPrice,
            color: selectedColor.colorName,
            size: selectedSize.sizeName,
            affiliateCommission: selectedColor.affiliateCommission,
          }
        : {
            _id: singleProduct._id,
            title: singleProduct.title,
            productCode: singleProduct.productCode,
            image: selectedVariant.image,
            price: selectedVariant.resellerPrice,
            size: selectedVariant.sizeName,
            affiliateCommission: selectedVariant.affiliateCommission,
          };

    addToCart(productDetails, Number(quantity), "resellerShop"); // Adds the selected product configuration to the cart
  };

  // handle order now for reseller
  const handleOrderNow = () => {
    if (!isAuthenticated) {
      navigate("/reseller/login", {
        state: { from: `/reseller/products/${productTitle}` },
      });
      return;
    }
    // create checkout data
    const products = [
      {
        id: singleProduct._id,
        productCode: singleProduct.productCode,
        title: singleProduct.title,
        image: selectedVariant.image || selectedColor.image,
        color: selectedColor.colorName || "",
        size: selectedSize.sizeName || selectedVariant.sizeName,
        quantity,
        price: selectedSize.resellerPrice || selectedVariant.resellerPrice,
        affiliateCommission:
          selectedSize.affiliateCommission ||
          selectedVariant.affiliateCommission,
        resellerPrice:
          selectedSize.resellerPrice || selectedVariant.resellerPrice,
      },
    ];

    const totalPrice =
      quantity * (selectedSize.resellerPrice || selectedVariant.resellerPrice);

    navigate("/checkout", {
      state: {
        products,
        totalPrice,
        affiliateCode: null,
        reseller: null,
        orderByReseller: userInfo._id,
      },
    });
  };

  if (loading) return <LoaderComponent message="Loading..." />;
  if (!singleProduct) return <p>Product not found</p>;

  return (
    <>
      <SEO
        title={"Paribarmart || " + singleProduct?.title}
        url={`https://www.paribarmart.com/reseller/products/${productTitle}`}
        description={singleProduct?.description}
        image={
          selectedColor?.image
            ? selectedColor.image?.replace(
                "/upload/",
                "/upload/w_1200,h_630,c_fill/"
              )
            : selectedVariant?.image?.replace(
                "/upload/",
                "/upload/w_1200,h_630,c_fill/"
              )
        }
      />
      <div className="container mx-auto md:px-16 md:pr-16">
        <div className="bg-white p-6 flex flex-col md:flex-row">
          {/* Image Gallery */}
          <div className="flex-1">
            <div className="w-full h-auto md:w-full md:h-[480px]">
              <img
                src={
                  singleProduct && singleProduct.colorSizeVariants?.length > 0
                    ? selectedColor?.image
                    : selectedVariant?.image
                }
                alt={
                  selectedColor?.colorName || selectedVariant?.sizeName || ""
                }
                className="w-full h-auto md:w-full md:h-full md:object-fit shadow-lg rounded-lg"
              />
            </div>

            {Object.keys(singleProduct).length > 0 && (
              <ImageSlider
                singleProduct={singleProduct}
                setSelectedColor={setSelectedColor}
                setSelectedVariant={setSelectedVariant}
              />
            )}

            <div className="hidden md:block">
              <DescriptionsAndReviews
                reviews={singleProductReviews}
                description={singleProduct?.description || "no description"}
                condition={singleProduct?.condition || "no condition available"}
              />
            </div>
          </div>
          {/* Product Details */}
          <div className="flex-1 md:ml-6">
            <h1 className="text-3xl font-bold mb-2">{singleProduct.title}</h1>
            <p className="text-gray-800 font-semibold">
              Product Code: {singleProduct.productCode || "N/A"}
            </p>
            <p className="text-gray-800 font-semibold">
              Short description:{" "}
              {singleProduct.description?.substring(0, 60) || "N/A"}
            </p>
            <p className="text-gray-800 font-semibold">
              Brand: {singleProduct.brand || "N/A"}
            </p>
            <p className="text-gray-800 font-semibold">Quantity: {quantity}</p>
            {/* Size Selector for `colorSizeVariants` */}
            {singleProduct.upcoming ? (
              <p className="text-gray-800 mb-4">Coming soon</p>
            ) : (
              <>
                {singleProduct &&
                  singleProduct.colorSizeVariants &&
                  selectedColor?.sizes?.length > 0 && (
                    <>
                      <p className="text-gray-800 font-semibold">
                        Color Name: {selectedColor.colorName}
                      </p>

                      <h3 className="font-semibold">Available Sizes:</h3>
                      <div className="flex flex-wrap mb-4">
                        {selectedColor.sizes.map((size, index) => (
                          <button
                            key={index}
                            onClick={() => setSelectedSize(size)}
                            className={`mr-2 mb-2 px-3 py-1 border rounded transition duration-200 ${
                              selectedSize.sizeName === size.sizeName
                                ? "bg-blue-500 text-white"
                                : "bg-white text-gray-800 border-gray-300 hover:bg-gray-100"
                            }`}
                            disabled={size.stock === 0}
                          >
                            {size.sizeName}
                            {/* {size.stock > 0
                              ? `(${size.stock} available)`
                              : "(Out of stock)"} */}
                          </button>
                        ))}
                      </div>
                    </>
                  )}

                {/* Size Selector for `sizeVariants` */}
                {singleProduct &&
                  singleProduct.sizeVariants &&
                  singleProduct.sizeVariants?.length > 0 && (
                    <>
                      <h3 className="font-semibold">Available Sizes:</h3>
                      <div className="flex flex-wrap mb-4">
                        {singleProduct &&
                          singleProduct.sizeVariants.map((variant, index) => (
                            <button
                              key={index}
                              onClick={() => setSelectedVariant(variant)}
                              className={`mr-2 mb-2 px-3 py-1 border rounded transition duration-200 ${
                                selectedVariant.sizeName === variant.sizeName
                                  ? "bg-blue-500 text-white"
                                  : "bg-white text-gray-800 border-gray-300 hover:bg-gray-100"
                              }`}
                              disabled={variant.stock === 0}
                            >
                              {variant.sizeName}
                              {/* {variant.stock > 0
                                ? `(${variant.stock} available)`
                                : "(Out of stock)"} */}
                            </button>
                          ))}
                      </div>
                    </>
                  )}
              </>
            )}

            {singleProduct?.videoUrl && (
              <div className="mt-4">
                <a
                  href={singleProduct?.videoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2"
                >
                  <span>youtube video:</span>
                  <FaYoutube className="text-4xl text-red-500" />
                </a>
              </div>
            )}

            {/* Quantity Selector */}
            <div className="flex items-center mb-4">
              <button
                className="px-4 py-2 bg-gray-300 rounded"
                onClick={decrementQuantity}
                disabled={
                  quantity === selectedSize?.stock ||
                  quantity === selectedVariant?.stock
                }
              >
                -
              </button>
              <span className="px-4">{quantity}</span>
              <button
                className="px-4 py-2 bg-gray-300 rounded"
                onClick={incrementQuantity}
              >
                +
              </button>
              <p className="ml-4 bg-blue-600 p-2 rounded text-white">
                {selectedSize?.stock === 0 || selectedVariant?.stock === 0
                  ? "(Out of Stock)"
                  : `(Available: ${
                      selectedSize?.stock || selectedVariant?.stock
                    })`}
              </p>
            </div>

            {/* <h3 className="font-semibold">
            Price: TK.
            {singleProduct && singleProduct.colorSizeVariants?.length > 0
              ? selectedSize?.price?.toFixed(2)
              : selectedVariant?.price?.toFixed(2)}
          </h3> */}
            <div className="text-gray-700 ">
              {singleProduct.upcoming ? (
                <p>coming soon</p>
              ) : (
                <>
                  {userInfo &&
                  userInfo.role === "reseller" &&
                  userInfo.subscriptionStatus === true ? (
                    <div className="flex items-center">
                      <span className="text-black-600 p-1 bg-pink-500 font-semibold md:p-2 line-through">
                        Retailer Price: TK.{" "}
                        {singleProduct &&
                        singleProduct.colorSizeVariants?.length > 0
                          ? selectedSize?.price?.toFixed(2)
                          : selectedVariant?.price?.toFixed(2)}
                      </span>
                      <br />
                      <span className="text-black-600 p-1  bg-green-500 font-semibold md:p-2">
                        Reseller Price: Tk.{" "}
                        {singleProduct &&
                        singleProduct.colorSizeVariants?.length > 0
                          ? selectedSize?.resellerPrice?.toFixed(2)
                          : selectedVariant?.resellerPrice?.toFixed(2)}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <span className="text-red-600 font-semibold">
                        Retailer Price: *****{" "}
                      </span>
                      <span className="text-red-600 font-semibold">
                        Reseller Price: *****{" "}
                      </span>
                    </div>
                  )}
                </>
              )}
            </div>
            <div className="flex flex-col md:flex-row items-center mt-4">
              <button
                disabled={
                  !isAuthenticated ||
                  singleProduct.upcoming ||
                  userInfo?.role !== "reseller" ||
                  !userInfo.subscriptionStatus ||
                  (singleProduct && singleProduct.colorSizeVariants?.length > 0
                    ? selectedSize.stock === 0
                    : selectedVariant.stock === 0)
                }
                onClick={toggleModal}
                className="w-full bg-pink-500 text-white px-6 py-2 rounded flex items-center justify-center"
              >
                <FaShoppingBag className="mr-2" /> Add to Shop
              </button>
              <button
                onClick={handleAddToCart}
                className={`mt-4 w-full md:w-1/2 bg-blue-500 text-white m-2 p-2 rounded hover:bg-blue-600 transition duration-200 ${
                  singleProduct && singleProduct.colorSizeVariants?.length > 0
                    ? selectedSize.stock === 0
                    : selectedVariant.stock === 0
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }
              ${
                userInfo &&
                userInfo?.role !== "reseller" &&
                userInfo?.subscriptionStatus === false
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }
              `}
                disabled={
                  (singleProduct && singleProduct.colorSizeVariants?.length > 0
                    ? selectedSize.stock === 0
                    : selectedVariant.stock === 0) ||
                  !isAuthenticated ||
                  userInfo?.role !== "reseller" ||
                  !userInfo?.subscriptionStatus ||
                  singleProduct.upcoming ||
                  (singleProduct && singleProduct.colorSizeVariants?.length > 0
                    ? selectedSize.stock === 0
                    : selectedVariant.stock === 0)
                }
              >
                {singleProduct && singleProduct.colorSizeVariants?.length > 0
                  ? selectedSize.stock > 0
                    ? "Add to Cart"
                    : "Out of Stock"
                  : selectedVariant.stock > 0
                  ? "Add to Cart"
                  : "Out of Stock"}
              </button>
              <button
                onClick={handleOrderNow}
                className={`mt-4 w-full md:w-1/2 bg-green-500 text-white m-2 p-2 rounded hover:bg-blue-600 transition duration-200 ${
                  singleProduct.colorSizeVariants
                    ? selectedSize.stock === 0
                    : selectedVariant.stock === 0
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                disabled={
                  (singleProduct && singleProduct.colorSizeVariants?.length > 0
                    ? selectedSize.stock === 0
                    : selectedVariant.stock === 0) ||
                  !isAuthenticated ||
                  !userInfo ||
                  userInfo?.role !== "reseller" ||
                  !userInfo.subscriptionStatus ||
                  singleProduct.upcoming ||
                  (singleProduct && singleProduct.colorSizeVariants?.length > 0
                    ? selectedSize.stock === 0
                    : selectedVariant.stock === 0)
                }
              >
                Buy Now
              </button>
            </div>
            <div className="block md:hidden">
              <DescriptionsAndReviews
                reviews={singleProductReviews}
                description={singleProduct?.description || "no description"}
                condition={singleProduct?.condition || "no condition available"}
              />
            </div>
            <ShareComponent
              url={window.location.href}
              title={singleProduct.title}
              description={singleProduct.description}
            />

            {phoneOrderDetails.length > 0 && (
              <div className="bg-white p-6 rounded-lg shadow-md text-center">
                <p className="text-lg font-bold mb-4">
                  ফোন অর্ডার করতে কল করুন
                </p>

                <div className="flex flex-wrap justify-center gap-4">
                  <div className="flex items-center text-red-500 font-bold">
                    <FaPhone className="mr-2" /> {phoneOrderDetails[0]?.phone}
                  </div>
                  <div className="flex items-center text-red-500 font-bold">
                    <FaPhoneAlt className="mr-2" />{" "}
                    {phoneOrderDetails[0]?.mobile}
                  </div>
                </div>

                <div className="flex flex-wrap justify-center gap-4 mt-4">
                  <div className="flex items-center text-green-500 font-bold">
                    <FaWhatsapp className="mr-2" />{" "}
                    {phoneOrderDetails[0]?.whatsapp}
                  </div>
                  <div className="flex items-center text-blue-500 font-bold">
                    <img
                      src="/imo.png"
                      style={{ width: "20px", height: "20px" }}
                      alt="imo"
                      className="mr-2"
                    />{" "}
                    {phoneOrderDetails[0]?.imo}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <RelatedProducts relatedProducts={recommendedProductsForReseller} />
        {showModal && (
          <ShowModal
            ChildComponent={AddToStoreForm}
            setShowCreateForm={setShowModal}
            onClose={toggleModal}
            title="Add Your Custom Price to sell"
            product={singleProduct}
          />
        )}
      </div>
    </>
  );
};

export default SingleProduct;

/*
{/* Color Selector for `colorSizeVariants` 
{singleProduct && singleProduct.colorSizeVariants.length > 0 && (
  <div className="flex space-x-2">
    {singleProduct?.colorSizeVariants.map((color) => (
      <button
        key={color.colorName}
        onClick={() => {
          setSelectedColor(color);
          setSelectedSize(color.sizes[0]); // Reset size to the first available
        }}
      >
        <img
          src={color.image}
          alt={color.colorName}
          className={`w-10 h-10 mt-4 md:w-16 md:h-16 object-contain border-2 border-gray-300 transition duration-200`}
        />
      </button>
    ))}
  </div>
)}

*/

// import React, { useEffect, useState } from 'react';
// import { FaShoppingCart,FaFacebook, FaTwitter, FaInstagram, } from 'react-icons/fa';
// import { Link, useNavigate, useParams } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
// import { getSingleProduct } from '../features/products/productsSlice';
// import LoaderComponent from '../components/LoaderComponent';
// import { addToCart } from '../utils/cartUtils';

// const SingleProduct = () => {

//   const { productTitle } = useParams();
//   const dispatch = useDispatch();
//   const { singleProduct, loading } = useSelector((state) => state.products);
//   const { isAuthenticated } = useSelector((state) => state.auth);
//   const [selectedImage, setSelectedImage] = useState('');
//   const [zoomStyle, setZoomStyle] = useState({ display: 'none' });
//   const [quantity, setQuantity] = useState(1);

//   const navigate = useNavigate();

//   useEffect(() => {
//     if (productTitle) {
//       dispatch(getSingleProduct(productTitle));
//     }
//   }, [dispatch, productTitle]);

//   useEffect(() => {
//     if (singleProduct && singleProduct.images && singleProduct.images.length > 0) {
//       setSelectedImage(singleProduct.images[0].url);
//     }
//   }, [singleProduct]);

//   const incrementQuantity = () => setQuantity((prev) => prev + 1);
//   const decrementQuantity = () => quantity > 1 && setQuantity((prev) => prev - 1);

//   const magnifyImage = (index) => {
//     if (singleProduct?.images?.[index]?.url) {
//       setSelectedImage(singleProduct.images[index].url);
//     }
//   };

//   const handleMouseMove = (e) => {
//     const { left, top, width, height } = e.target.getBoundingClientRect();
//     const x = e.clientX - left;
//     const y = e.clientY - top;

//     const bgPosX = (x / width) * 100;
//     const bgPosY = (y / height) * 100;

//     setZoomStyle({
//       display: 'block',
//       backgroundImage: `url(${selectedImage})`,
//       backgroundSize: `${width * 2}px ${height * 2}px`,
//       backgroundPosition: `${bgPosX}% ${bgPosY}%`,
//       left: x - 75,
//       top: y - 75,
//     });
//   };

//   const handleMouseLeave = () => setZoomStyle({ display: 'none' });

//   // handle checkout from Buy Now  button
//   const handleBuyNow = () => {

//     if (!isAuthenticated) {
//       navigate('/login',{ state: { from: `/products/${productTitle}` } });
//       return;
//     }

//     // create checkout data
//     const products = [{
//       id: singleProduct._id,
//       quantity,
//       price: singleProduct.price,
//       affiliate_commission: singleProduct.affiliate_commission
//     }]

//     const ref_code = localStorage.getItem('ref_code');
//     const total_price = quantity * singleProduct.price;

//     navigate('/checkout', { state: { products, total_price, affiliate_code: ref_code } });

//   }

// console.log('singleProduct',singleProduct);

//   if (loading) return <LoaderComponent message="Loading..." />;
//   if (!singleProduct) return <p>Product not found</p>;

//   return (
//     <div className="container mx-auto py-8 px-4 md:px-0">
//       <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
//         <div className="mx-auto">
//           <div
//             className="relative w-80 h-80 mx-auto  border"
//             onMouseMove={handleMouseMove}
//             onMouseLeave={handleMouseLeave}
//           >
//             <img src={selectedImage} alt={singleProduct.title} className="w-full h-full " />
//             <div
//               className="absolute w-60 h-60 rounded-full border-4 border-gray-300 pointer-events-none"
//               style={{
//                 ...zoomStyle,
//                 position: 'absolute',
//                 transform: 'translate(-50%, -50%)',
//               }}
//             ></div>
//           </div>
//           <div className="flex space-x-4 content-around mt-4">
//             {singleProduct.images?.map((image, index) => (
//               <img
//                 onClick={() => magnifyImage(index)}
//                 key={index}
//                 src={image.url}
//                 alt={`Thumbnail ${index + 1}`}
//                 className="w-16 h-16 object-cover border border-gray-300 cursor-pointer"
//               />
//             ))}
//           </div>
//         </div>

//         <div>
//           <h1 className="text-2xl font-semibold text-gray-800 mb-2">{singleProduct.title}</h1>
//           <p className="text-sm text-gray-500">{singleProduct.description}</p>
//           <div className="flex items-center mb-4">
//             <span className="text-gray-400">({singleProduct.ratings?.rate})</span>
//           </div>
//           {
//             singleProduct.in_offer ? (
//               <>
//               <div className="text-lg text-red-600 mb-2">
//               TK. {singleProduct.offer_price} <span className="line-through text-gray-500">TK. {singleProduct.price}</span>
//             </div>
//             <span className="text-sm text-green-600 mb-4">OFF TK. {singleProduct.price - singleProduct.offer_price}</span>
//               </>
//             ): (

//               <div className="text-lg text-gray-600 mb-2">
//                 TK. {singleProduct.price}
//                 </div>
//             )
//           }

//           <div className="flex items-center space-x-4 my-4">
//             <button onClick={decrementQuantity} className="px-2 py-1 border">-</button>
//             <span>{quantity}</span>
//             <button onClick={incrementQuantity} className="px-2 py-1 border">+</button>
//             <span className="text-gray-500 text-sm">({singleProduct.stock} available/order)</span>
//           </div>

//           <div className="flex space-x-4">
//             <button className="bg-pink-500 text-white px-6 py-2 rounded flex items-center" onClick={() => addToCart(singleProduct,quantity)}>
//               <FaShoppingCart className="mr-2" /> Add to Cart
//             </button>
//             <button onClick={handleBuyNow} className="bg-red-500 text-white px-6 py-2 rounded">Buy Now</button>
//           </div>

//           <div className="mt-6 flex content-center space-x-4 py-3 text-lg">
//             <Link to="#">
//               <FaFacebook />
//             </Link>
//             <Link to="#">
//               <FaInstagram />
//             </Link>
//             <Link to="#">
//               <FaTwitter />
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default SingleProduct;
