import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ImageSlider({ singleProduct, setSelectedColor, setSelectedVariant }) {
  // Combine the arrays and tag each element
  const combinedElements = [
    ...(singleProduct?.colorSizeVariants.map((color) => ({
      type: "color",
      data: color,
    })) || []),
    ...(singleProduct?.sizeVariants.map((size) => ({
      type: "size",
      data: size,
    })) || []),
  ];

  // Slick slider settings
  const sliderSettings = {
    infinite: combinedElements.length !== 1,
    speed: 500,
    slidesToShow: Math.min(5, combinedElements.length),
    slidesToScroll: 1,
    autoplay: combinedElements.length > 1,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: Math.min(4, combinedElements.length),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: Math.min(3, combinedElements.length),
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {combinedElements.length > 0 ? (
        <div className="w-full max-w-[500px] mx-auto items-center mt-8 md:mt-12">
          <Slider {...sliderSettings}>
            {combinedElements.map((item, index) => {
              if (item.type === "color") {
                // Render Color Element
                return (
                  <button
                    key={`color-${index}`}
                    onClick={() => {
                      setSelectedColor(item.data);
                      setSelectedVariant(item.data.sizes[0]); // Reset size to the first available
                    }}
                    className="focus:outline-none"
                  >
                    <img
                      src={item.data?.image}
                      alt={item.data?.colorName}
                      className="w-20 h-20 md:w-24 md:h-24 object-cover border border-gray-300 transition duration-200 hover:border-blue-500 rounded-lg"
                    />
                  </button>
                );
              } else if (item.type === "size") {
                // Render Size Element
                return (
                  <button
                    key={`size-${index}`}
                    onClick={() => setSelectedVariant(item.data)}
                    className="focus:outline-none"
                  >
                    <img
                      src={item.data.image}
                      alt={item.data.sizeName}
                      className="w-16 h-16 md:w-24 md:h-24 object-cover border border-gray-300 transition duration-200 hover:border-blue-500 rounded-lg"
                    />
                  </button>
                );
              }
              return null;
            })}
          </Slider>
        </div>
      ) : (
        <p className="text-gray-500 text-center">No variants available</p>
      )}
    </>
  );
}










// import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// export default function ImageSlider({ singleProduct, setSelectedColor, setSelectedVariant }) {
//   // Combine the arrays and tag each element
//   const combinedElements = [
//     ...(singleProduct?.colorSizeVariants.map((color) => ({
//       type: "color",
//       data: color,
//     })) || []),
//     ...(singleProduct?.sizeVariants.map((size) => ({
//       type: "size",
//       data: size,
//     })) || []),
//   ];

//   // Slick slider settings
//   const sliderSettings = {
//     infinite: combinedElements.length !== 0,
//     speed: 500,
//     slidesToShow: Math.min(4, combinedElements.length),
//     slidesToScroll: 1,
//     responsive: [
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: Math.min(4, combinedElements.length),
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: Math.min(3, combinedElements.length),
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div className="w-full max-w-[800px] mx-auto">
//       {/* Combined Slider */}
//       {combinedElements.length > 0 && (
//         <Slider {...sliderSettings}>
//           {combinedElements?.map((item, index) => {
//             if (item.type === "color") {
//               // Render Color Element
//               return (
//                 <button
//                   key={`color-${index}`}
//                   onClick={() => {
//                     setSelectedColor(item.data);
//                     setSelectedVariant(item.data.sizes[0]); // Reset size to the first available
//                   }}
//                   className="focus:outline-none"
//                 >
//                   <img
//                     src={item.data?.image}
//                     alt={item.data?.colorName}
//                     className="w-20 h-20 mt-4 md:w-24 md:h-24 object-cover border border-gray-300 transition duration-200 hover:border-blue-500"
//                   />
//                 </button>
//               );
//             } else if (item.type === "size") {
//               // Render Size Element
//               return (
//                 <button
//                   key={`size-${index}`}
//                   onClick={() => setSelectedVariant(item.data)}
//                   className="focus:outline-none"
//                 >
//                   <img
//                     src={item.data.image}
//                     alt={item.data.sizeName}
//                     className="w-16 h-16 mt-4 md:w-24 md:h-24 object-cover border border-gray-300 transition duration-200 hover:border-blue-500"
//                   />
//                 </button>
//               );
//             }
//             return null;
//           })}
//         </Slider>
//       )}
//     </div>
//   );
// }
