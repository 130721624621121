import React from 'react'
import { FaSearch } from 'react-icons/fa';

 const Search = ({handleSearchSubmit, handleSearchChange, searchQuery,placeholder}) => {


  return (
    <form
            onSubmit={handleSearchSubmit}
            className="flex mx-auto items-center w-full max-w-md mx-4 mb-4"
          >
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={placeholder || "search here..."}
              className="p-2 w-full border border-gray-300 rounded-l-lg focus:outline-none"
            />
            <button type="submit" className="bg-purple-600 p-3 rounded-r-lg">
              <FaSearch className="text-white" />
            </button>
          </form>
  )
}

export default Search;