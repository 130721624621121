import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCategories } from "../features/category/categorySlice";
import { useDispatch, useSelector } from 'react-redux';
import LoaderComponent from "./LoaderComponent";


const CategoryDropdown = ({type="shop"}) => {

    const { categories, loading } = useSelector((state) => state.category);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);
  
    useEffect(() => {
      dispatch(fetchCategories());
    }, [dispatch]);

    const handleSubcategoryClick = (categoryName, subCategoryName) => {
        const query = `category=${categoryName}&subCategory=${subCategoryName}`;
        if(type === "shop"){
            navigate(`/shop/products/categories?${query}`);
        }else{
            navigate(`/reseller/products/categories?${query}`);
        }
      };

      
  
    if (loading) return <LoaderComponent message="Loading categories..." />;

  return (
    <div className="relative">
        <button
          onClick={() => setShowDropdown((prev) => !prev)}
          className="flex items-center px-4 py-2 bg-sky-500 text-white font-semibold rounded-md shadow hover:bg-sky-600 transition"
        >
          Categories
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 ml-2"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </button>
        {showDropdown && (
          <div className="absolute left-0 mt-2 w-64 bg-white shadow-lg rounded-md z-50">
            <ul className="divide-y divide-gray-200">
              {categories.map((category) => (
                <li
                  key={category.name}
                  className="relative group px-4 py-2 hover:bg-gray-100 flex items-center justify-between"
                >
                  <span className="cursor-pointer font-medium text-gray-900 group-hover:text-sky-500">
                    {category.name}
                  </span>
                  {category.subcategories?.length > 0 && (
                    <>
                      <span className="text-gray-400 group-hover:text-sky-500">
                        ›
                      </span>
                      <div className="absolute top-0 left-full mt-0 hidden group-hover:block w-64 bg-white shadow-lg">
                        <ul className="divide-y divide-gray-200">
                          {category.subcategories.map((subCategory) => (
                            <li
                              key={subCategory._id}
                              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
                              onClick={() =>
                                handleSubcategoryClick(
                                  category.name,
                                  subCategory.name
                                )
                              }
                            >
                              {subCategory.name}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
  )
}

export default CategoryDropdown