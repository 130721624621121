import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
// import resellerBanner from '../../images/reseller.jpg';
import { loginReseller } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../../components/LoaderComponent";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ResellerLogin = () => {
  const { userInfo, loading } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (userInfo && userInfo.subscriptionOrder === null) {
      navigate("/reseller/packages");
    }
    if (
      userInfo &&
      userInfo.subscriptionOrder !== null &&
      userInfo.subscriptionOrder?.manualPaymentId &&
      userInfo.subscriptionOrder?.provider
    ) {
      navigate("/reseller/dashboard");
    }
    if (
      userInfo &&
      userInfo.subscriptionOrder !== null &&
      !userInfo.subscriptionOrder?.manualPaymentId &&
      !userInfo.subscriptionOrder?.provider
    ) {
      navigate(
        `/reseller/package/${userInfo.subscriptionOrder?.name}/order/manual-payment`,
        { state: { singleSubscriptionOrder: userInfo?.subscriptionOrder } }
      );
    }
  }, [userInfo, navigate]);

  // Validation schema using Yup
  const validationSchema = Yup.object().shape({
    phone: Yup.string().required("Phone number is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleSubmit = (values) => {
    dispatch(loginReseller({ phone: values.phone, password: values.password }));
  };

  if (loading) return <LoaderComponent />;

  return (
    <div className="min-h-screen flex items-center justify-center pt-3">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full space-y-6">
        <div className="w-full flex justify-center mb-4">
          <img
            src="/reseller.jpg"
            alt="Login Banner"
            className="w-full h-auto rounded-lg"
          />
        </div>
        {/* Login Form */}
        <h2 className="text-center text-2xl font-bold text-gray-700">
          Reseller Login
        </h2>
        <Formik
          initialValues={{
            phone: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form className="space-y-4">
              <div>
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-600"
                >
                  Phone
                </label>
                <Field
                  id="phone"
                  name="phone"
                  type="text"
                  className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                  placeholder="Enter your phone number"
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div className="relative">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-600"
                >
                  Password
                </label>
                <div className="relative w-full">
                  <Field
                    id="password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                    placeholder="Enter your password"
                  />
                  <div
                    className="absolute inset-y-0 right-3 flex items-center cursor-pointer text-gray-600"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </div>
                </div>
                <ErrorMessage
                  name="password"
                  component="div"
                  className="text-red-500 text-sm"
                />
              </div>

              <div>
                <button
                  type="submit"
                  className="w-full bg-green-600 text-white p-2 rounded-lg hover:bg-green-700 transition duration-300"
                >
                  Login
                </button>
              </div>
            </Form>
          )}
        </Formik>

        <div className="flex items-center justify-between">
          <Link
            to="/forgot-password"
            state = {{from: "reseller"}}
            className="text-sm text-green-600 hover:text-green-700"
          >
            Forgot Password?
          </Link>
        </div>

        {/* Sign Up Link */}
        <p className="text-center text-sm text-gray-600">
          Don't Have an Account?{" "}
          <Link
            to="/reseller/signup"
            className="text-green-600 hover:text-green-700 font-medium"
          >
            Sign Up
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ResellerLogin;

// import React, { useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { Formik, Form, Field, ErrorMessage } from 'formik';
// import * as Yup from 'yup';
// import resellerBanner from '../../images/reseller.jpg';
// import { loginReseller } from '../../features/auth/authSlice';
// import { useDispatch, useSelector } from 'react-redux';
// import LoaderComponent from '../../components/LoaderComponent';

// const ResellerLogin = () => {

//   const { userInfo, loading } = useSelector(state => state.auth);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();

//   useEffect(() => {
//     if(userInfo && userInfo.subscriptionOrder === null) {
//       navigate('/reseller/packages');
//     }
//     if(userInfo && userInfo.subscriptionOrder !== null && userInfo.subscriptionOrder?.manualPaymentId && userInfo.subscriptionOrder?.provider ) {
//       navigate('/reseller/dashboard')
//      }
//     if(userInfo && userInfo.subscriptionOrder !== null && !userInfo.subscriptionOrder?.manualPaymentId && !userInfo.subscriptionOrder?.provider) {
//         navigate(`/reseller/package/${userInfo.subscriptionOrder?.name}/order/manual-payment`, { state: { singleSubscriptionOrder: userInfo?.subscriptionOrder } });
//     }
//   },[userInfo, navigate]);
//   // Validation schema using Yup
//   const validationSchema = Yup.object().shape({
//     phone: Yup.string().required('Phone number is required'),
//     password: Yup.string().required('Password is required'),
//   });
//   console.log("from login:",userInfo)

//   const handleSubmit = (values) => {

//     dispatch(loginReseller({ phone: values.phone, password: values.password }));
//   };

//   if (loading) return <LoaderComponent />

//   return (
//     <div className="min-h-screen flex items-center justify-center pt-3">
//       <div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full space-y-6">
//       <div className="w-full flex justify-center mb-4">
//           <img src={resellerBanner} alt="Login Banner" className="w-full h-auto rounded-lg" />
//         </div>
//         {/* Login Form */}
//         <h2 className="text-center text-2xl font-bold text-gray-700">Reseller Login</h2>
//         <Formik
//           initialValues={{
//             phone: '',
//             password: '',
//           }}
//           validationSchema={validationSchema}
//           onSubmit={handleSubmit}
//         >
//           {() => (
//             <Form className="space-y-4">
//               <div>
//                 <label htmlFor="phone" className="block text-sm font-medium text-gray-600">Phone</label>
//                 <Field
//                   id="phone"
//                   name="phone"
//                   type="text"
//                   className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
//                   placeholder="Enter your phone number"
//                 />
//                 <ErrorMessage name="phone" component="div" className="text-red-500 text-sm" />
//               </div>
//               <div>
//                 <label htmlFor="password" className="block text-sm font-medium text-gray-600">Password</label>
//                 <Field
//                   id="password"
//                   name="password"
//                   type="password"
//                   className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
//                   placeholder="Enter your password"
//                 />
//                 <ErrorMessage name="password" component="div" className="text-red-500 text-sm" />
//               </div>

//               <div>
//                 <button
//                   type="submit"
//                   className="w-full bg-green-600 text-white p-2 rounded-lg hover:bg-green-700 transition duration-300"
//                 >
//                   Login
//                 </button>
//               </div>
//             </Form>
//           )}
//         </Formik>

//         {/* Sign Up Link */}
//         <p className="text-center text-sm text-gray-600">
//           Don't Have an Account?{' '}
//           <Link to="/reseller-signup" className="text-green-600 hover:text-green-700 font-medium">
//             Sign Up
//           </Link>
//         </p>
//       </div>
//     </div>
//   );
// };

// export default ResellerLogin;
