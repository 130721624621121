import React from 'react';
import { logout } from '../../features/auth/authSlice'
import { useNavigate } from 'react-router-dom';

// import profileImage from '../../images/profile.webp';
import { FiX } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

const UserSidebar = ({ selectedSection, setSelectedSection, isAffiliate = false, isOpen, toggleSidebar }) => {
  const menuItems = [
    { id: 'profile', label: 'Profile' },
    { id: 'change-password', label: 'Change Password' },
    { id: 'orders', label: 'My Orders' },
    { id: 'affiliate', label: 'Affiliate Program' },
    { id: 'dashboard', label: 'Dashboard' },
    { id: 'referrals', label: 'Referrals' },
    { id: 'payment-settings', label: 'Payment Settings' },
    { id: 'withdraw', label: 'Withdraw' },
    { id: 'join-affiliate', label: 'Join Affiliate' },
    { id: 'order-bonus', label: 'Order Bonus Notice' },
  ];

  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Filter the menu items based on isAffiliate
  const filteredMenuItems = isAffiliate
    ? menuItems.filter(item => item.id !== 'join-affiliate')
    : menuItems.filter(item => 
        !['affiliate', 'referrals', 'payment-settings', 'withdraw', 'order-bonus','dashboard'].includes(item.id)
      );

  const handleToggleSidebarAndSelectedSection = (id) => {
    setSelectedSection(id);
    if(isOpen) toggleSidebar();
  };

  const handleLogout = () =>{
    dispatch(logout());
    navigate('/');
  }

  return (
    <div
      className={`fixed inset-0 bg-green-800 text-white transform ${
        isOpen ? 'translate-x-0 z-50' : '-translate-x-full'
      } transition-transform duration-300 lg:translate-x-0 lg:relative lg:w-64 h-screen overflow-y-auto`}
    >
      {/* Close button for mobile view */}
      <button onClick={toggleSidebar} className="lg:hidden text-white top-4 right-4 absolute">
        <FiX size={24} />
      </button>
      <img
        src={ userInfo?.profilePicture || "/profile.webp"}
        alt="Profile"
        className="w-32 h-32 rounded-full mx-auto mt-8"
      />
      <ul className="p-4">
        {filteredMenuItems.map((item) => (
          <li
            key={item.id}
            onClick={() => handleToggleSidebarAndSelectedSection(item.id)}
            className={`p-3 cursor-pointer hover:bg-gray-700 ${
              selectedSection === item.id ? 'bg-blue-600' : 'hover:bg-gray-700'
            }`}
          >
            {item.label}
          </li>
        ))}
        <li
          onClick={handleLogout}
          className={`p-3 cursor-pointer hover:bg-gray-700`}
        >
          log out
        </li>
      </ul>
    </div>
  );
};

export default UserSidebar;
