import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch} from 'react-redux';
import { createSubcategory, updateSubcategory } from "../../features/subcategory/subcategorySlice";


const SubcategoryForm = ({ mode = 'create', initialValues = {} }) => {

  // const {categories,loading} = useSelector(state => state.category);
  const dispatch = useDispatch();


  // Fetch all categories
  // useEffect(() => {
  //   dispatch(fetchCategories())
  // }, [dispatch]);

  // const categoryOptions = categories?.map((category) => ({
  //   value: category._id,
  //   label: category.name,
  //   }));

  // Formik setup
  const formik = useFormik({
    initialValues: {
      name: initialValues.name || '',
      // categories: initialValues?.categories?.map((category) => ({
      //   value: category._id,
      //   label: category.name,
      // })) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Subcategory name is required'),
      // categories: Yup.array()
      //   .of(Yup.string())
      //   .min(1, 'At least one category must be selected')
      //   .required('Categories are required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      const payload = {
        name: values.name,
        // categories: values.categories.map((cat) => cat.value),
      };

      if (mode === 'create') {
         dispatch(createSubcategory(payload));
         resetForm();
      } else {
         dispatch(updateSubcategory({ id: initialValues._id, subcategory:payload }));
      }

    
    },
  });

  // Handle category selection
  // const handleCategoryChange = (selectedOptions) => {
  //   formik.setFieldValue('categories', selectedOptions);
  // };

  // if(loading) return <LoaderComponent />;

  return (
    <form onSubmit={formik.handleSubmit}>
      {/* Subcategory Name */}
      <div className="mb-4">
        <label className="block text-gray-700">Subcategory Name</label>
        <input
          type="text"
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          className="mt-1 p-2 block w-full border rounded-md"
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="text-red-500 text-sm">{formik.errors.name}</div>
        ) : null}
      </div>

      {/* Categories Selection */}
      {/* <div className="mb-4">
        <label className="block text-gray-700">Categories</label>
        <Select
          isMulti
          options={categoryOptions}
          value={formik.values.categories}
          onChange={handleCategoryChange}
          className="mt-1"
        />
        {formik.touched.categories && formik.errors.categories ? (
          <div className="text-red-500 text-sm">{formik.errors.categories}</div>
        ) : null}
      </div> */}

      <button
        type="submit"
        className="w-full text-center bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
      >
        {mode === 'create' ? 'Create Subcategory' : 'Update Subcategory'}
      </button>
    </form>
  );
};

export default SubcategoryForm;
