import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api/api';
import { toast } from 'react-toastify';


// create an orders
export const createOrder = createAsyncThunk('order/createOrder', async ({order,token}) => {
    const response = await api.post('/orders', order,{headers: {
        "Content-Type":"application/json",
        "Authorization": `Bearer ${token}`
    }});
    if(response.status === 201){
        toast.success("order created successfully");
    }
    return response.data;
});

// create order by reseller
export const createOrderByReseller = createAsyncThunk('order/createOrderByReseller', async ({order,token}) => {
    const response = await api.post('/reseller/orders', order,{headers: {
        "Content-Type":"application/json",
        "Authorization": `Bearer ${token}`
    }});
    if(response.status === 200){
        toast.success("order created successfully");
    }
    return response.data;

});

// get all orders by user
export const getOrdersByUser = createAsyncThunk('order/getOrders', async ({page, limit=20, token}) => {
    const response = await api.get(`orders?page=${page}&limit=${limit}`,{
        
        headers: {
            "Authorization": `Bearer ${token}`
        }

    });
    return response.data;
});

// get all orders by -reseller
export const getOrdersByReseller = createAsyncThunk('order/getOrdersByReseller', async ({page,query,token}) => {
    const queryString = new URLSearchParams(query).toString();
    const response = await api.get(`/orders/reseller?${queryString}&page=${page}`,{
        headers: {
        "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
});

// get all orders by -reseller from store
export const getOrdersByResellerFromStore = createAsyncThunk('order/getOrdersByResellerFromStore', async ({page=1,limit=20,token}) => {
    const response = await api.get(`/orders/reseller/stores?page=${page}&limit=${limit}`,{
    headers: {
        "Authorization": `Bearer ${token}`
    }
    });
    return response.data;
})
    
// get all orders by Admin
export const getAllOrdersByAdmin = createAsyncThunk('order/getAllOrders', async ({page, limit=20, search=""}) => {
    const response = await api.get(`/admin/orders?page=${page}&limit=${limit}&search=${search}`);
    return response.data;
});

// get filtered orders by Admin
export const getFilteredOrdersByAdmin = createAsyncThunk(
    'order/getFilteredOrders',
    async ({ filterType,query}) => {
        const queryString = new URLSearchParams(query).toString();
        const response = await api.get(`/admin/orders/filtered/${filterType}?${queryString}`);
        return response.data;
});

// update order status by  admin
export const updateOrderStatus = createAsyncThunk('order/updateOrders', async({orderId,orderData,token}) =>{
    const response = await api.put(`/admin/orders/${orderId}`,orderData,{headers:{
        "Content-Type":"application/json",
        "Authorization": `Bearer ${token}`
        }});
        if(response.status === 200){
            toast.success('Order status updated successfully');
        }
        return response.data;
});

// update order discount, delivery charge and payment method-payment status by admin
export const updateOrderDiscountAndServiceCharge = createAsyncThunk('order/updateOrderDiscountAndServiceCharge', async({orderId,orderData,token}) =>{
    const response = await api.put(`/admin/orders/discount-service-charge/${orderId}`,orderData,{headers:{
        "Content-Type":"application/json",
        "Authorization": `Bearer ${token}`
        }});
        if(response.status === 200){
            toast.success('Order updated successfully');
        }else{
            toast.error('Failed to update order');
        }
        return response.data;
});

// assign employee to order by admin
export const assignEmployeeToOrder = createAsyncThunk('order/assignEmployeeToOrder', async({orderData,token}) =>{
    
    const response = await api.put(`/admin/assign-employee/orders`,orderData,{headers:{
        "Content-Type":"application/json",
        "Authorization": `Bearer ${token}`
        }});
        if(response.status === 200){
            toast.success('Order assigned to employee successfully');
        }
        return response.data;
});

// get a single order by user to track
export const getSingleOrder = createAsyncThunk('order/getSingleOrder', async (orderId) => {
    const response = await api.get(`/orders/track/${orderId}`);
    return response.data;
})

const orderSlice = createSlice({
    name: 'orders',
    initialState: {
        orders: [],
        pagination: {},
        singleOrder: null,
        createdOrder: null,
        orderFromStore: [],
        filteredOrders : [],
        loading: false,
        error: null,

    },
    reducers: {
        resetSuccess: (state) => {
            state.createdOrder = null;
            state.error = null;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(createOrder.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createOrder.fulfilled, (state, action) => {
                state.loading = false;
                state.createdOrder = action.payload;
                state.error = null;
            })
            .addCase(createOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getOrdersByUser.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getOrdersByUser.fulfilled, (state, action) => {
                state.loading = false;  
                state.pagination = action.payload.pagination;
                state.orders = action.payload.orders;
                state.error = null;
            })
            .addCase(getOrdersByUser.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; 

            })
            .addCase(getAllOrdersByAdmin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAllOrdersByAdmin.fulfilled, (state, action) => {
                state.loading = false;  
                const { orders, pagination } = action.payload;
                state.orders = orders;
                state.pagination = pagination;
                state.error = null;
            })
            .addCase(getAllOrdersByAdmin.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; 
            })
            .addCase(updateOrderStatus.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateOrderStatus.fulfilled, (state, action) => {
                state.loading = false;
                const index = state.orders.findIndex(order => order._id === action.payload._id);  
                state.orders[index] = action.payload;
                state.error = null;
            })
            .addCase(updateOrderStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message; 
            })
            .addCase(getOrdersByReseller.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getOrdersByReseller.fulfilled, (state, action) => {
                state.loading = false;  
                state.orders = action.payload.orders;
                state.pagination = action.payload.pagination;
                state.error = null;
            })
            .addCase(getOrdersByReseller.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getSingleOrder.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getSingleOrder.fulfilled, (state, action) => {
                state.loading = false;  
                console.log(action.payload)
                state.singleOrder = action.payload;

                state.error = null;
            })
            .addCase(getSingleOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getOrdersByResellerFromStore.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getOrdersByResellerFromStore.fulfilled, (state, action) => {
                state.loading = false;  
                state.orderFromStore = action.payload;
                state.error = null;
            })
            .addCase(getOrdersByResellerFromStore.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(createOrderByReseller.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createOrderByReseller.fulfilled, (state, action) => {
                state.loading = false;
                console.log(action.payload)
                state.createdOrder = action.payload;
                state.error = null;
            })
            .addCase(createOrderByReseller.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(getFilteredOrdersByAdmin.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getFilteredOrdersByAdmin.fulfilled, (state, action) => {
                state.loading = false;  
                state.filteredOrders = action.payload.orders;
                state.pagination = action.payload.pagination;
                state.error = null;
            })
            .addCase(getFilteredOrdersByAdmin.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(updateOrderDiscountAndServiceCharge.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateOrderDiscountAndServiceCharge.fulfilled, (state, action) => {
                state.loading = false;
                const index = state.orders.findIndex(order => order._id === action.payload._id);  
                state.orders[index] = action.payload;
                state.error = null;
            })
            .addCase(updateOrderDiscountAndServiceCharge.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })
            .addCase(assignEmployeeToOrder.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(assignEmployeeToOrder.fulfilled, (state, action) => {
                state.loading = false;
               
                const index = state.orders.findIndex(order => order._id === action.payload._id);  
                state.orders[index] = action.payload;
                state.error = null;
            })
            .addCase(assignEmployeeToOrder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

    },
});

export const { resetSuccess } = orderSlice.actions;

export default orderSlice.reducer;