import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createCategory, updateCategory } from '../../features/category/categorySlice';
import { fetchSubcategories } from '../../features/subcategory/subcategorySlice';
import Select from 'react-select';
import LoaderComponent from '../LoaderComponent';

const CategoryForm = ({ mode = 'create', initialValues = {} }) => {

  const { subcategories, loading,  } = useSelector((state)=> state.subcategory);
  const dispatch = useDispatch();
  const [previewImage, setPreviewImage] = useState(initialValues.image || null); // Preview image state



  useEffect(() => {
    dispatch(fetchSubcategories());
  }, [dispatch]);


  const subcategoryOptions = subcategories && subcategories?.map((subcategory) => ({
    value: subcategory._id,
    label: subcategory.name,
  }))

  // Formik validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required('Category name is required'),
    image: Yup.string().required('An image is required'),
    serialNumber: Yup.number().positive().required('Serial number is required'),
    subcategories: Yup.array()
        .min(1, 'At least one subcategory must be selected')
        .required('subcategories are required'),
  });

  // Formik configuration
  const formik = useFormik({
    initialValues: {
      name: initialValues.name || '',
      image: initialValues.image || '',
      serialNumber: initialValues.serialNumber || '',
      subcategories: initialValues?.subcategories?.map((subcategory) => ({
        value: subcategory._id,
        label: subcategory.name,
      })) || [],
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {

      const formData = {
        name: values.name,
        image: values.image,
        serialNumber: values.serialNumber,
        subcategories: values.subcategories.map((subcategory) => subcategory.value),
      }

      if (mode === 'create') {
        dispatch(createCategory(formData));
        resetForm();
      } else if (mode === 'edit' && initialValues._id) {
        dispatch(updateCategory({ id: initialValues._id, category:formData }));
      }
      setPreviewImage(null);
    },
    enableReinitialize: true,// Reinitialize when initialValues change
  });

  // Handle image change and convert to base64
  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];

    // Use FileReader to read the file as base64
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result;
      formik.setFieldValue('image', base64String); // Set base64 string in Formik's image field
      setPreviewImage(base64String); // Update preview
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // handle subcategory change
  const handleSubcategoryChange = (selectedOptions) => {
    formik.setFieldValue('subcategories', selectedOptions);
  };

  
 if(loading) return <LoaderComponent />
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-4">
        <label className="block text-gray-700">Category Name</label>
        <input
          type="text"
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          className="mt-1 p-2 block w-full border rounded-md"
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="text-red-500 text-sm">{formik.errors.name}</div>
        ) : null}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Category Image</label>
        <input
          type="file"
          name="image"
          onChange={handleImageChange}
          accept="image/*"
          className="mt-1 block w-full text-sm text-gray-700"
        />
        {formik.touched.image && formik.errors.image ? (
          <div className="text-red-500 text-sm">{formik.errors.image}</div>
        ) : null}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Serial Number</label>
        <input
          type="number"
          name="serialNumber"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.serialNumber}
          className="mt-1 p-2 block w-full border rounded-md"
        />
        {formik.touched.serialNumber && formik.errors.serialNumber ? (
          <div className="text-red-500 text-sm">{formik.errors.serialNumber}</div>
        ) : null}
      </div>

      {/* subcategories */}
      <div className="mb-4">
        <label className="block text-gray-700">subcategories</label>
        <Select
          isMulti
          options={subcategoryOptions}
          value={formik.values.subcategories}
          onChange={handleSubcategoryChange}
          className="mt-1"
        />
        {formik.touched.subcategories && formik.errors.subcategories ? (
          <div className="text-red-500 text-sm">{formik.errors.subcategories}</div>
        ) : null}
      </div>

      {/* Image Preview */}
      {previewImage && (
        <div className="mb-4">
          <label className="block text-gray-700">Image Preview:</label>
          <img
            src={previewImage}
            alt="Preview"
            className="w-32 h-32 object-cover mt-2 border rounded-md"
          />
        </div>
      )}

      <button
        type="submit"
        className="w-full text-center bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
      >
        {mode === 'create' ? 'Create Category' : 'Update Category'}
      </button>
    </form>
  );
};

export default CategoryForm;
















// import React, { useState } from 'react';
// import { useFormik } from 'formik';
// import * as Yup from 'yup';
// import { createCategory } from '../../features/category/categorySlice';
// import { useDispatch } from 'react-redux';

// const CreateCategoryForm = () => {
//   const dispatch = useDispatch();
//   const [previewImage, setPreviewImage] = useState(null); // State for image preview

//   // Formik validation schema
//   const formik = useFormik({
//     initialValues: {
//       name: '',
//       image: '', 
//       serialNumber: '',
//     },
//     validationSchema: Yup.object({
//       name: Yup.string().required('Category name is required'),
//       image: Yup.string().required('An image is required'),
//       serialNumber: Yup.number().positive().required('Serial number is required'),
//     }),
//     onSubmit: (values, { resetForm }) => {
//       // Send data as JSON, including base64 image
//       dispatch(createCategory(values));
//       resetForm();
//       setPreviewImage(null); // Clear preview on submit
//     },
//   });

//   // Handle image change and convert to base64
//   const handleImageChange = (event) => {
//     const file = event.currentTarget.files[0];

//     // Use FileReader to read the file as base64
//     const reader = new FileReader();
//     reader.onloadend = () => {
//       const base64String = reader.result;
//       formik.setFieldValue('image', base64String); // Set base64 string in Formik's image field
//       setPreviewImage(base64String); // Display preview
//     };
//     if (file) {
//       reader.readAsDataURL(file);
//     }
//   };

//   return (
//     <form onSubmit={formik.handleSubmit}>
//       <div className="mb-4">
//         <label className="block text-gray-700">Category Name</label>
//         <input
//           type="text"
//           name="name"
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           value={formik.values.name}
//           className="mt-1 p-2 block w-full border rounded-md"
//         />
//         {formik.touched.name && formik.errors.name ? (
//           <div className="text-red-500 text-sm">{formik.errors.name}</div>
//         ) : null}
//       </div>

//       <div className="mb-4">
//         <label className="block text-gray-700">Category Image</label>
//         <input
//           type="file"
//           name="image"
//           onChange={handleImageChange}
//           accept="image/*"
//           className="mt-1 block w-full text-sm text-gray-700"
//         />
//         {formik.touched.image && formik.errors.image ? (
//           <div className="text-red-500 text-sm">{formik.errors.image}</div>
//         ) : null}
//       </div>

//       <div className="mb-4">
//         <label className="block text-gray-700">Serial Number</label>
//         <input
//           type="number"
//           name="serialNumber"
//           onChange={formik.handleChange}
//           onBlur={formik.handleBlur}
//           value={formik.values.serialNumber}
//           className="mt-1 p-2 block w-full border rounded-md"
//         />
//         {formik.touched.serialNumber && formik.errors.serialNumber ? (
//           <div className="text-red-500 text-sm">{formik.errors.serialNumber}</div>
//         ) : null}
//       </div>

//       {/* Image Preview */}
//       {previewImage && (
//         <div className="mb-4">
//           <label className="block text-gray-700">Image Preview:</label>
//           <img src={previewImage} alt="Preview" className="w-32 h-32 object-cover mt-2 border rounded-md" />
//         </div>
//       )}

//       <button
//         type="submit"
//         className="w-full text-center bg-blue-600 text-white p-2 rounded hover:bg-blue-700"
//       >
//         Create Category
//       </button>
//     </form>
//   );
// };

// export default CreateCategoryForm;

