import React, {useEffect, useState } from 'react';
import {  FaEye } from 'react-icons/fa';
import { getAllResellersByAdmin } from '../../features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoaderComponent from '../LoaderComponent';
import ShowResellerDetails from './ShowResellerDetails';
// import { debounce } from "lodash";
import Search from "../Search";
import Pagination from '../Pagination';

const ResellerList = () => {
  const {resellers,pagination, loading, error} = useSelector((state) => state.user);
  const [selectedReseller, setSelectedReseller] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  // const [isEditing, setIsEditing] = useState(false);
  // const [showCreateForm, setShowCreateForm] = useState(false);

    const [page, setPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");


  const dispatch = useDispatch();

  // fetch all resellers on mount
  useEffect(() => {
    const token = JSON.parse(localStorage.getItem('access_token'));
    dispatch(getAllResellersByAdmin({ page, limit: 20, search: "" , token}));
  }, [dispatch,page]);

  // const handleDelete = (resellerId) => {
  //   if (window.confirm('Are you sure you want to delete this reseller?')) {
  //     dispatch(deleteResellerByAdmin({id:resellerId, token: JSON.parse(localStorage.getItem("access_token"))})); 
  //   }
  // };

  // const handleEdit = (reseller) => {
  //   setSelectedReseller(reseller);
  //   setIsEditing(true);
  // };

  const handleViewDetails = (reseller) => {
    setSelectedReseller(reseller);
    setShowDetails(true);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  
    // handle search
  
    // Debounced search handler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // const debouncedSearch = useCallback(
    //   debounce((query) => {
    //     // If the search query is empty, reset the search filter
    //     if (!query.trim()) {
    //       dispatch(getAllResellersByAdmin({ page: 1, limit: 20, search: "" }));
    //       setPage(1);
    //     } else {
    //       // Otherwise, update the search query
    //       dispatch(getAllResellersByAdmin({ page, limit: 20, search: query }));
    //     }
    //   }, 500),
    //   [dispatch]
    // );
  
    const handleSearchChange = (e) => {
      setSearchQuery(e.target.value);
      // debouncedSearch(e.target.value);
    };
  
    const handleSearchSubmit = (e) => {
      e.preventDefault();
      searchQuery && dispatch(getAllResellersByAdmin({ search: searchQuery }));
    };
  


  if(loading) return <LoaderComponent />
  if(error) return <p className="text-red-500">{error}</p>

  return (
    <div className="container mx-auto p-6 mb-8 h-screen">
      {/* <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Reseller List</h2>
        <button
          onClick={() => setShowCreateForm(true)}
          className="bg-blue-500 text-white p-2 rounded shadow-md hover:bg-blue-600 transition-all flex items-center"
        >
          <FaPlus className="mr-2" /> Create Reseller
        </button>
      </div> */}

        {/* Search */}
      <Search
        handleSearchSubmit={handleSearchSubmit}
        handleSearchChange={handleSearchChange}
        searchQuery={searchQuery}
        placeholder={"Search by category name"}
      />

      <div className="shadow-lg rounded-lg overflow-x-auto">
       {
        resellers.length > 0 ? (
          <table className="min-w-full bg-white">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-2 px-4">SL No</th>
              <th className="py-2 px-4">Name</th>
              <th className="py-2 px-4">Phone</th>
              <th className="py-2 px-4">Email</th>
              <th className="py-2 px-4">Refer Id</th>
              <th className="py-2 px-4">Joining Date</th>
              <th className="py-2 px-4">Total Earnings</th>
              <th className="py-2 px-4">Subscription</th>
              <th className="py-2 px-4">Subscription Expiration</th>
              <th className="py-2 px-4">Actions</th>
            </tr>
          </thead>
          <tbody>
            {resellers.map((reseller, index) => (
              <tr key={reseller._id} className="border-t">
                <td className="py-2 px-4">{index + 1}</td>
                <td className="py-2 px-4">{reseller.name}</td>
                <td className="py-2 px-4">{reseller.phone}</td>
                <td className="py-2 px-4">{reseller.email}</td>
                <td className="py-2 px-4">{reseller.referralCode}</td>
                <td className="py-2 px-4">{new Date(reseller.createdAt).toLocaleDateString()}</td>
                <td className="py-2 px-4">{reseller.total_earnings}</td>
                <td className="py-2 px-4">{reseller.subscription || 'N/A'} </td>
                <td className="py-2 px-4">{reseller.subscriptionExpiration || 'N/A'}</td>
                <td className="py-2 px-4 flex gap-2">
                  <button
                    onClick={() => handleViewDetails(reseller)}
                    className="bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-600 transition-all"
                  >
                    <FaEye />
                  </button>
                  
                  {/* <button
                    onClick={() => handleEdit(reseller)}
                    className="bg-red-500 text-white p-2 rounded-full shadow-md hover:bg-red-600 transition-all"
                  >
                    <FaEdit />
                  </button> */}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        ):(
          <p>No resellers found.</p>
        )
       }
      </div>

      {/* Pagination */}
      <Pagination
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onPageChange={handlePageChange}
      />

      {showDetails && selectedReseller && (
        <ShowResellerDetails selectedReseller={selectedReseller} setShowDetails={setShowDetails} />
      )}
  </div>
  )
}

export default ResellerList