import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { createPhoneOrderDetails, updatePhoneOrderDetails } from '../../features/phoneOrderDetails/phoneOrderSlice';
const PhoneOrderDetailsForm = ({showForm, initialValue={},mode="create"}) => {  


  const dispatch = useDispatch();

  // Formik validation schema for the CreateUserForm
  const formik = useFormik({
    initialValues: {
      phone: initialValue.phone || '',
      mobile: initialValue.mobile || '',
      whatsapp: initialValue.whatsapp || '',
      imo: initialValue.imo || '',
    },
    validationSchema: Yup.object({
      phone: Yup.string().required('Name is required'),
      mobile: Yup.string().required('Phone is required'),
      whatsapp: Yup.string().required('Mobile is required'),
      imo: Yup.string().required('Role is required'),
    }),
    onSubmit: (values,{resetForm}) => {
      if(mode==="create"){
        dispatch(createPhoneOrderDetails(values));
      resetForm();
      showForm();
      }else {
        dispatch(updatePhoneOrderDetails({id:initialValue._id,data:values}));
        showForm();
      }
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-4">
        <label className="block text-gray-700">Telephone Number</label>
        <input
          type="text"
          name="phone"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phone}
          className="mt-1 p-2 block w-full border rounded-md"
        />
        {formik.touched.phone && formik.errors.phone ? (
          <div className="text-red-500 text-sm">{formik.errors.phone}</div>
        ) : null}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Mobile Number</label>
        <input
          type="text"
          name="mobile"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.mobile}
          className="mt-1 p-2 block w-full border rounded-md"
        />
        {formik.touched.mobile && formik.errors.mobile ? (
          <div className="text-red-500 text-sm">{formik.errors.mobile}</div>
        ) : null}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">whatsapp number</label>
        <input
          type="text"
          name="whatsapp"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.whatsapp}
          className="mt-1 p-2 block w-full border rounded-md"
        />
        {formik.touched.whatsapp && formik.errors.whatsapp ? (
          <div className="text-red-500 text-sm">{formik.errors.whatsapp}</div>
        ) : null}
      </div>
      <div className="mb-4">
        <label className="block text-gray-700">imo number</label>
        <input
          type="text"
          name="imo"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.imo}
          className="mt-1 p-2 block w-full border rounded-md"
        />
        {formik.touched.imo && formik.errors.imo ? (
          <div className="text-red-500 text-sm">{formik.errors.imo}</div>
        ) : null}
      </div>

      <button type="submit" className="w-full text-center bg-blue-600 text-white p-2 rounded hover:bg-blue-700">
        submit
      </button>
    </form>
  );
};

export default PhoneOrderDetailsForm;
