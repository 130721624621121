import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { createUser } from '../../features/user/userSlice';
import { useDispatch } from 'react-redux';

const CreateUserForm = () => {  


  const dispatch = useDispatch();

  // Formik validation schema for the CreateUserForm
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      password: '',
      phone: '',
      role: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      phone: Yup.string().required('Phone is required'),
      password: Yup.string().min(6, 'password must be at least 6 characters').required('Password is required'),
      role: Yup.string().required('Role is required'),
    }),
    onSubmit: (values,{resetForm}) => {
      dispatch(createUser({
        userData: values,
        token: JSON.parse(localStorage.getItem("token"))
      }));
      resetForm();
      alert('User created successfully!');
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mb-4">
        <label className="block text-gray-700">Name</label>
        <input
          type="text"
          name="name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          className="mt-1 p-2 block w-full border rounded-md"
        />
        {formik.touched.name && formik.errors.name ? (
          <div className="text-red-500 text-sm">{formik.errors.name}</div>
        ) : null}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Email</label>
        <input
          type="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          className="mt-1 p-2 block w-full border rounded-md"
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="text-red-500 text-sm">{formik.errors.email}</div>
        ) : null}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Phone</label>
        <input
          type="phone"
          name="phone"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.phone}
          className="mt-1 p-2 block w-full border rounded-md"
        />
        {formik.touched.phone && formik.errors.phone ? (
          <div className="text-red-500 text-sm">{formik.errors.phone}</div>
        ) : null}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Password</label>
        <input
          type="password"
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          className="mt-1 p-2 block w-full border rounded-md"
        />
        {formik.touched.password && formik.errors.password ? (
          <div className="text-red-500 text-sm">{formik.errors.password}</div>
        ) : null}
      </div>

      <div className="mb-4">
        <label className="block text-gray-700">Role</label>
        <select
          name="role"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.role}
          className="mt-1 p-2 block w-full border rounded-md"
        >
          <option value="" label="Select role" />
          <option value="admin" label="Admin" />
          <option value="user" label="User" />
        </select>
        {formik.touched.role && formik.errors.role ? (
          <div className="text-red-500 text-sm">{formik.errors.role}</div>
        ) : null}
      </div>


      <button type="submit" className="w-full text-center bg-blue-600 text-white p-2 rounded hover:bg-blue-700">
        Create User
      </button>
    </form>
  );
};

export default CreateUserForm;
