import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredOrdersByAdmin } from "../../features/orders/ordersSlice";
import { getAffiliates } from "../../features/affiliate/affiliateSlice";
import { getAllResellersByAdmin } from "../../features/user/userSlice";
import Pagination from "../Pagination";
import LoaderComponent from "../LoaderComponent";

const OrderFilterComponent = () => {
  const dispatch = useDispatch();
  const {
    filteredOrders: orders,
    pagination,
    loading,
  } = useSelector((state) => state.orders);
  const { resellers } = useSelector((state) => state.user);
  const { affiliates } = useSelector((state) => state.affiliate);
  const [filterType, setFilterType] = useState("reseller"); // Filter type
  const [selectedName, setSelectedName] = useState(null); // Selected reseller/affiliate
  const [dateFilter, setDateFilter] = useState("today"); // Date filter
  const [startDate, setStartDate] = useState(null); // Custom start date
  const [endDate, setEndDate] = useState(null); // Custom end date
  const [page, setPage] = useState(1); // Pagination

  const [limit, setLimit] = useState(30); // Pagination limit

  // Fetch names dynamically based on filter type
  useEffect(() => {
    if (filterType === "reseller") {
      dispatch(getAllResellersByAdmin());
    } else if (filterType === "affiliate") {
      dispatch(getAffiliates());
    } else if (filterType === "resellerStore") {
      dispatch(getAllResellersByAdmin());
    }
  }, [filterType, dispatch]);

  const nameOptions = [
    ...(filterType === "reseller"
      ? resellers.map((reseller) => ({
          label: reseller.name,
          value: reseller._id,
        }))
      : filterType === "affiliate"
      ? affiliates.map((affiliate) => ({
          label: affiliate.userId.name,
          value: affiliate._id,
        }))
      : resellers.map((reseller) => ({
          label: reseller.name,
          value: reseller._id,
        }))),
  ];

  // Handle form submission
  const handleFilter = () => {
    const query = {
      page,
      limit,
      dateFilter,
      ...(filterType === "reseller" && { reseller: selectedName }),
      ...(filterType === "affiliate" && { affiliate: selectedName }),
      ...(filterType === "resellerStore" && { resellerStore: selectedName }),
    };

    if (dateFilter === "custom" && startDate && endDate) {
      query.startDate = new Date(startDate).toISOString();
      query.endDate = new Date(endDate).toISOString();
    }

    // Dispatch action to fetch filtered orders
    dispatch(getFilteredOrdersByAdmin({ filterType, query }));
  };

  // handle page change
  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);
  };

  if(loading) return <LoaderComponent />

  return (
    <div className="p-6 bg-white  h-screen">
      <h2 className="text-lg font-bold mb-4">Filter Orders</h2>

      {/* Filter Type */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Filter Type</label>
        <select
          value={filterType}
          onChange={(e) => setFilterType(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="reseller">Reseller</option>
          <option value="resellerStore">Reseller Store</option>
          <option value="affiliate">Affiliate</option>
        </select>
      </div>

      {/* Select Name */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">
          Select{" "}
          {filterType === "reseller"
            ? "Reseller"
            : filterType === "affiliate"
            ? "Affiliate"
            : "Reseller Store"}{" "}
          Name
        </label>
        <select
          value={selectedName}
          onChange={(e) => setSelectedName(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="">Select</option>
          {nameOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </div>

      {/* Date Filter */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Date Filter</label>
        <select
          value={dateFilter}
          onChange={(e) => setDateFilter(e.target.value)}
          className="w-full p-2 border rounded"
        >
          <option value="today">Today</option>
          <option value="month">This Month</option>
          <option value="custom">Custom</option>
        </select>
      </div>

      {/* Custom Date Picker */}
      {dateFilter === "custom" && (
        <div className="flex space-x-4 mb-4">
          <div>
            <label className="block text-sm font-medium mb-2">Start Date</label>
            <input
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-2">End Date</label>
            <input
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
        </div>
      )}
      {/* Limit */}
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2">Set Limit</label>
        <input
          type="number"
          value={limit}
          onChange={(e) => setLimit(e.target.value)}
        />
      </div>

      {/* Submit Button */}
      <button
        onClick={handleFilter}
        className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
      >
        Filter Orders
      </button>

      {/* Orders Table */}
      {orders.length === 0 ? (
        <p className="text-black-600 font-bold text-lg">No orders found</p>
      ) : (
        <div className="mt-6 bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-lg font-bold mb-4">Orders</h3>
          <h3 className="text-lg font-bold mb-4">Total Orders: {pagination?.totalItems}</h3>
          <table className="w-full border-collapse border border-gray-300">
            <thead>
              <tr>
                <th className="border p-2">Order ID</th>
                <th className="border p-2">Customer</th>
                <th className="border p-2">Total</th>
                <th className="border p-2">Date</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order) => (
                <tr key={order._id}>
                  <td className="border p-2">{order.orderId}</td>
                  <td className="border p-2">
                    {order?.shippingAddress?.fullName}
                  </td>
                  <td className="border p-2">
                    Order Total. {order.totalPrice}
                  </td>
                  <td className="border p-2">
                    {new Date(order.createdAt).toLocaleDateString()}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <Pagination
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default OrderFilterComponent;
