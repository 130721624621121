import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getAllOrdersByAdmin,
  updateOrderStatus,
  updateOrderDiscountAndServiceCharge,
  assignEmployeeToOrder,
} from "../../features/orders/ordersSlice";
import { getEmployees } from "../../features/executive/executiveSlice";
import { FaEdit, FaEye, FaSort } from "react-icons/fa";
import LoaderComponent from "../LoaderComponent";
import Pagination from "../Pagination";
import "./orderDetails.css";
// import { debounce } from "lodash";
import Search from "../Search";
import Invoice from "../common/Invoice";
import Modal from "../common/Modal";
import EditableOrderComponent from "./EditableOrderComponent";
// import logo from "../../images/logo.png";

const OrdersList = () => {
  const { orders, pagination, loading } = useSelector((state) => state.orders);
  const { employees } = useSelector((state) => state.executive);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [filterStatus, setFilterStatus] = useState("all");
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const [isEditing, setIsEditing] = useState(false);

  const [showInvoice, setShowInvoice] = useState(false);
  const [invoiceData, setInvoiceData] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOrdersByAdmin({ page }));
    dispatch(getEmployees());
  }, [dispatch, page]);

  // handle search

  // Debounced search handler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const debouncedSearch = useCallback(
  //   debounce((query) => {
  //     // If the search query is empty, reset the search filter
  //     if (!query.trim()) {
  //       dispatch(getAllOrdersByAdmin({ page: 1 }));
  //       setPage(1);
  //     } else {
  //       // Otherwise, update the search query
  //       dispatch(getAllOrdersByAdmin({ page, search: query }));
  //     }
  //   }, 500),
  //   [dispatch]
  // );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    // debouncedSearch(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery) {
      dispatch(getAllOrdersByAdmin({ page: 1, search: searchQuery }));
      setPage(1);
    } else {
      dispatch(getAllOrdersByAdmin({ page, search: "" }));
    }
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handleEdit = (order) => {
    setIsEditing(true);
    setSelectedOrder(order);
  };

  const onUpdate = (data) => {
    dispatch(
      updateOrderDiscountAndServiceCharge({
        orderId: selectedOrder._id,
        orderData: data,
        token: JSON.parse(localStorage.getItem("access_token")),
      })
    );
    setIsEditing(false);
    setSelectedOrder(null);
  };

  const handleViewDetails = (order) => {
    setSelectedOrder(order);
    setShowDetails(true);
  };

  const handleInvoice = (order) => {
    let data = {};

    data.invoiceNo = order.orderId;
    data.date =
      order.status === "delivered"
        ? new Date(order.deliveredAt).toLocaleDateString()
        : new Date(order.createdAt).toLocaleDateString();
    data.customerName = order.shippingAddress.fullName;
    data.contactNo = order.shippingAddress.phone;
    data.paymentMethod = order.paymentMethod;
    data.deliveryMethod = "General Delivery";
    data.deliveryAddress = order.shippingAddress.address;
    data.items = order.products.map((product) => ({
      title: product.title,
      code: product.productCode || "No code",
      color: product.color || "",
      size: product.size || "",
      quantity: product.quantity,
      rate: product.soldPrice,
      total: product.soldPrice * product.quantity,
      image: product.image,
    }));
    data.totals = {
      total: order.totalPrice,
      discount: order.orderDiscount,
      vat: 0,
      deliveryCharge: order.deliveryCharge,
      grandTotal: order.totalPrice - order.orderDiscount + order.deliveryCharge,
      paid:
        order.status === "delivered"
          ? order.totalPrice - order.orderDiscount + order.deliveryCharge
          : 0,
      balance:
        order.status === "delivered"
          ? 0
          : order.totalPrice - order.orderDiscount + order.deliveryCharge,
    };
    data.logo = "/logo.png";
    data.helpLine = "+8809613822141";
    data.officeAddress = "Mirpur 2, Mirpur, Dhaka";
    data.website = "paribarmart.com";

    setInvoiceData(data);

    setShowInvoice(true);
  };
  const handleStatusChange = (orderId, newStatus) => {
    dispatch(
      updateOrderStatus({
        orderId,
        orderData: { status: newStatus },
        token: JSON.parse(localStorage.getItem("access_token")),
      })
    );
  };

  const filteredOrders = orders.filter((order) =>
    filterStatus === "all" ? true : order.status === filterStatus
  );

  const sortedOrders = filteredOrders.sort((a, b) => {
    if (sortField) {
      if (sortOrder === "asc") {
        return a[sortField] > b[sortField] ? 1 : -1;
      } else {
        return a[sortField] < b[sortField] ? 1 : -1;
      }
    }
    return 0;
  });

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const handlePrint = () => {
    const printContents =
      document.getElementById("printable-invoice").innerHTML;
    const originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
    window.location.reload(); // To restore the original state
  };

  // handle assigned employee
  const handleAssignedEmployeeChange = (orderId, employeeId) => {
    dispatch(
      assignEmployeeToOrder({
        orderData: { orderId, employeeId },
        token: JSON.parse(localStorage.getItem("access_token")),
      })
    );
  };

  if (loading) return <LoaderComponent />;

  return (
    <div className="container mx-auto p-6 mb-8 h-screen">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">Orders List</h2>

      {/* Filter by Status */}
      <div className="mb-4 flex justify-between items-center">
        <div>
          <label className="block text-gray-700">Filter by Status: </label>
          <select
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
            className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="all">All</option>
            <option value="processing">Processing</option>
            <option value="pending">Pending</option>
            <option value="shipped">Shipped</option>
            <option value="delivered">Delivered</option>
            <option value="shipping">Shipping</option>
            <option value="cancelled">Cancelled</option>
          </select>
        </div>
      </div>

      {/* Search */}
      <Search
        handleSearchSubmit={handleSearchSubmit}
        handleSearchChange={handleSearchChange}
        searchQuery={searchQuery}
        placeholder={"Search by Order ID"}
      />

      {/* Orders Table */}
      <div className="shadow-lg rounded-lg overflow-x-auto">
        <table className="min-w-full bg-white shadow-lg rounded-lg table-auto">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-3 px-6 text-left font-semibold text-gray-700 whitespace-nowrap">
                SL No
              </th>
              <th className="py-3 px-6 text-left font-semibold text-gray-700 whitespace-nowrap">
                Order Date{" "}
              </th>
              <th className="py-3 px-6 text-left font-semibold text-gray-700 whitespace-nowrap">
                <button
                  onClick={() => handleSort("orderId")}
                  className="flex items-center text-gray-600"
                >
                  Order ID <FaSort className="ml-2 text-sm" />
                </button>
              </th>
              <th className="py-3 px-6 text-left font-semibold text-gray-700 whitespace-nowrap">
                Products
              </th>
              <th className="py-3 px-6 text-left font-semibold text-gray-700 whitespace-nowrap">
                <button
                  onClick={() => handleSort("totalPrice")}
                  className="flex items-center text-gray-600"
                >
                  Total Price <FaSort className="ml-2 text-sm" />
                </button>
              </th>
              <th className="py-3 px-6 text-left font-semibold text-gray-700 whitespace-nowrap">
                Status
              </th>
              <th className="py-3 px-6 text-left font-semibold text-gray-700 whitespace-nowrap">
                Order By <FaSort className="ml-2 text-sm" />
              </th>
              <th className="py-3 px-6 text-left font-semibold text-gray-700 whitespace-nowrap">
                {" "}
                Mobile Number
              </th>
              <th className="py-3 px-6 text-left font-semibold text-gray-700 whitespace-nowrap">
                Assigned Employee <FaSort className="ml-2 text-sm" />
              </th>
              <th className="py-3 px-6 text-left font-semibold text-gray-700 whitespace-nowrap">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedOrders.map((order, index) => (
              <tr key={order._id} className="border-t hover:bg-gray-50">
                <td className="py-3 px-6 whitespace-nowrap">{index + 1}</td>
                <td className="py-3 px-6 whitespace-nowrap">
                  {new Date(order.createdAt).toLocaleDateString()}
                </td>
                <td className="py-3 px-6 whitespace-nowrap">
                  {order.orderId || "-"}
                </td>
                <td className="py-3 px-6 whitespace-nowrap">
                  {order.products.map((product) => product.title).join(", ")}
                </td>
                <td className="py-3 px-6 whitespace-nowrap">
                  TK. {order.totalPrice.toFixed(2)}
                </td>
                <td className="py-3 px-6 whitespace-nowrap">
                  <select
                    value={order.status}
                    onChange={(e) =>
                      handleStatusChange(order._id, e.target.value)
                    }
                    disabled={order.status === "delivered"}
                    className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="processing">Processing</option>
                    <option value="pending">Pending</option>
                    <option value="shipped">Shipped</option>
                    <option value="delivered">Delivered</option>
                    <option value="shipping">Shipping</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </td>
                <td className="py-3 px-6 whitespace-nowrap">
                  {order.billingAddress?.fullName}
                </td>
                <td className="py-3 px-6 whitespace-nowrap">
                  {order.billingAddress?.phone}
                </td>
                <td className="py-3 px-6 whitespace-nowrap">
                  <select
                    value={order?.assignedExecutive?._id || ""}
                    onChange={(e) =>
                      handleAssignedEmployeeChange(order._id, e.target.value)
                    }
                    className="border border-gray-300 rounded-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Select Employee</option>
                    {employees.map((employee) => (
                      <option key={employee._id} value={employee?._id}>
                        {employee.name}-{employee.stuffId}-
                        {employee.designation}
                      </option>
                    ))}
                  </select>
                </td>
                <td className="py-3 px-6 flex gap-2 whitespace-nowrap">
                  <button
                    onClick={() => handleViewDetails(order)}
                    className="bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700 transition duration-200"
                  >
                    <FaEye />
                  </button>
                  <button
                    onClick={() => handleEdit(order)}
                    className="bg-green-600 text-white p-2 rounded-lg hover:bg-green-700 transition duration-200"
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => handleInvoice(order)}
                    className="bg-blue-600 text-white p-2 rounded-lg hover:bg-blue-700 transition duration-200"
                  >
                    Invoice
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <Pagination
        currentPage={page}
        totalPages={pagination.totalPages}
        onPageChange={handlePageChange}
      />

      {/* Modal for Order Details */}
      {showDetails && selectedOrder && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50 transition duration-300 ease-in-out">
          <div className="bg-white p-8 rounded-lg shadow-2xl max-h-[90vh] w-full md:w-2/3 lg:w-1/2 overflow-y-auto transform transition-all ease-in-out">
            {/* Header */}
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-2xl font-bold text-gray-800">
                Order Details
              </h3>
              <button
                onClick={handlePrint}
                className="text-gray-500 hover:text-red-500 transition duration-200"
              >
                print as invoice
              </button>
              <button
                onClick={() => setShowDetails(false)}
                className="text-gray-500 hover:text-red-500 transition duration-200"
              >
                ✕-close
              </button>
            </div>
            {/* Invoice area */}
            <div id="printable-invoice">
              {/* Order Info */}
              <div className="mb-6">
                <p className="text-lg font-semibold text-gray-700 mb-2">
                  <strong>Order ID:</strong> {selectedOrder.orderId || "N/A"}
                </p>
                <p className="text-lg text-gray-700">
                  <strong>Status:</strong>{" "}
                  <span
                    className={`font-semibold ${
                      selectedOrder.status === "completed"
                        ? "text-green-600"
                        : selectedOrder.status === "processing"
                        ? "text-yellow-500"
                        : "text-gray-700"
                    }`}
                  >
                    {selectedOrder.status}
                  </span>
                </p>
              </div>

              {/* Products List */}
              <div className="mb-6">
                <h4 className="text-xl font-semibold text-gray-800 mb-4">
                  Products:
                </h4>
                {/* <div className="space-y-4">
                  {selectedOrder.products.map((product) => (
                    <div
                      key={product.id._id}
                      className="flex items-center justify-between p-4 bg-gray-50 rounded-lg shadow-sm"
                    >
                      <div className="flex items-center">
                        <img
                          src={product.image}
                          alt={product.id.title}
                          className="w-14 h-14 rounded-lg mr-4"
                        />
                        <div>
                          <p className="text-xl md:text-2xl text-gray-800 font-bold">
                            title: {product.title}
                          </p>
                          <p className="text-gray-800 font-medium">
                            color: {product.color || "-"}
                          </p>
                          <p className="text-gray-800 font-medium">
                            size: {product.size}
                          </p>
                          <p className="text-sm text-gray-600">
                            Quantity: {product.quantity}
                          </p>
                          <p className="text-sm text-gray-600">
                            Price: TK. {product.soldPrice.toFixed(2)}
                          </p>
                        </div>
                      </div>
                      <p className="text-lg font-semibold text-blue-500">
                        ${product.soldPrice * product.quantity}
                      </p>
                    </div>
                  ))}
                </div> */}
                <table class="min-w-full border-collapse border border-gray-400">
                  <thead>
                    <tr>
                      <th class="border border-gray-400 p-2">Sl</th>
                      <th class="border border-gray-400 p-2">Img</th>
                      <th class="border border-gray-400 p-2">
                        Description of Goods
                      </th>
                      <th class="border border-gray-400 p-2">Quantity</th>
                      <th class="border border-gray-400 p-2">Rate</th>
                      <th class="border border-gray-400 p-2">Amount (TK)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedOrder.products.map((item, index) => (
                      <tr key={index} class="hover:bg-gray-50">
                        <td class="border border-gray-400 p-2 text-center">
                          {index + 1}
                        </td>
                        <td class="border border-gray-400 p-2 text-center">
                          <img
                            alt="Product-image-placeholder"
                            class="mx-auto"
                            height="50"
                            src={item.image}
                            width="50"
                          />
                        </td>
                        <td class="border border-gray-400 p-2">
                          {item.title} <br />
                          code: {item.productCode} <br />
                          {item.color && <span>color: {item.color}</span>}{" "}
                          <br />
                          {item.size && <span>size: {item.size}</span>}
                        </td>
                        <td class="border border-gray-400 p-2 text-center">
                          {item.quantity}
                        </td>
                        <td class="border border-gray-400 p-2 text-right">
                          {item.soldPrice}
                        </td>
                        <td class="border border-gray-400 p-2 text-right">
                          {item.soldPrice * item.quantity}
                        </td>
                      </tr>
                    ))}

                    <tr>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                    </tr>
                    <tr>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-right">
                        Total=
                      </td>
                      <td class="border border-gray-400 p-2 text-center">
                        {selectedOrder.totalPrice} TK
                      </td>
                    </tr>
                    <tr>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-right">
                        Discounts
                      </td>
                      <td class="border border-gray-400 p-2 text-center">
                        {selectedOrder.discount} Tk
                      </td>
                    </tr>
                    <tr>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-right">
                        VAT [+]=
                      </td>
                      <td class="border border-gray-400 p-2 text-center">
                        0 Tk
                      </td>
                    </tr>
                    <tr>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-right">
                        Delivery Charge [+]=
                      </td>
                      <td class="border border-gray-400 p-2 text-center">
                        {selectedOrder.deliveryCharge} TK
                      </td>
                    </tr>
                    <tr>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-right">
                        Grand Total=
                      </td>
                      <td class="border border-gray-400 p-2 text-center">
                        {selectedOrder.totalPrice} Tk
                      </td>
                    </tr>
                    <tr>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-right">
                        Paid=
                      </td>
                      <td class="border border-gray-400 p-2 text-center">
                        {selectedOrder.paymentStatus === "paid"
                          ? selectedOrder.totalPrice
                          : 0}{" "}
                        TK
                      </td>
                    </tr>
                    <tr>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-center">-</td>
                      <td class="border border-gray-400 p-2 text-right">
                        Balance =
                      </td>
                      <td class="border border-gray-400 p-2 text-center">
                        {selectedOrder.paymentStatus === "paid"
                          ? 0
                          : selectedOrder.totalPrice}{" "}
                        TK
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Address Details */}
              <div className="mb-6 grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <h4 className="text-xl font-semibold text-gray-800 mb-2">
                    Billing Address:
                  </h4>
                  <p className="text-gray-700">
                    {selectedOrder.billingAddress.fullName}
                  </p>
                  <p className="text-gray-700">
                    {selectedOrder.billingAddress.address},{" "}
                    {selectedOrder.billingAddress.city}
                  </p>
                  <p className="text-gray-700">
                    {selectedOrder.billingAddress.region},{" "}
                    {selectedOrder.billingAddress.zone} -{" "}
                    {selectedOrder.billingAddress.postalCode}
                  </p>
                  <p className="text-gray-700">
                    Phone: {selectedOrder.billingAddress.phone}
                  </p>
                </div>
                <div>
                  <h4 className="text-xl font-semibold text-gray-800 mb-2">
                    Shipping Address:
                  </h4>
                  <p className="text-gray-700">
                    {selectedOrder.shippingAddress.full_name}
                  </p>
                  <p className="text-gray-700">
                    {selectedOrder.shippingAddress.address},{" "}
                    {selectedOrder.shippingAddress.city}
                  </p>
                  <p className="text-gray-700">
                    {selectedOrder.shippingAddress.region},{" "}
                    {selectedOrder.shippingAddress.zone} -{" "}
                    {selectedOrder.shippingAddress.postalCode}
                  </p>
                  <p className="text-gray-700">
                    Phone: {selectedOrder.shippingAddress.phone}
                  </p>
                </div>
              </div>

              {/* Payment & Transaction Details */}
              {/* <div className="mb-6">
                <h4 className="text-xl font-semibold text-gray-800 mb-2">
                  Payment Information:
                </h4>
                <p className="text-gray-700">
                  <strong>Method:</strong> {selectedOrder.paymentMethod}
                </p>
                <p className="text-gray-700">
                  <strong>Status:</strong>{" "}
                  <span
                    className={`font-semibold ${
                      selectedOrder.paymentStatus === "paid"
                        ? "text-green-600"
                        : "text-red-500"
                    }`}
                  >
                    {selectedOrder.paymentStatus}
                  </span>
                </p>
                <p className="text-gray-700">
                  <strong>Payment ID:</strong>{" "}
                  {selectedOrder.transaction.paymentId}
                </p>
              </div> */}

              <div class="max-w-4xl mx-auto bg-white p-4 shadow-md">
                <h2 class="text-center font-bold text-lg mb-4">
                  Payment Status
                </h2>
                <table class="w-full border-collapse border border-gray-400 mb-6">
                  <thead>
                    <tr class="bg-gray-200">
                      <th class="border border-gray-400 p-2">
                        Installment Status
                      </th>
                      <th class="border border-gray-400 p-2">Date</th>
                      <th class="border border-gray-400 p-2">Amount</th>
                      <th class="border border-gray-400 p-2">Method</th>
                      <th class="border border-gray-400 p-2">Remark</th>
                      <th class="border border-gray-400 p-2">Received By</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="border border-gray-400 p-2 text-center">1</td>
                      <td class="border border-gray-400 p-2 text-center">
                        {selectedOrder.transaction?.receivedAt === null
                          ? "Due"
                          : new Date(
                              selectedOrder.transaction.receivedAt
                            ).toLocaleString()}
                      </td>
                      <td class="border border-gray-400 p-2 text-center text-blue-600">
                        {selectedOrder.transaction?.amount ||
                          selectedOrder.totalPrice}
                      </td>
                      <td class="border border-gray-400 p-2 text-center">
                        {selectedOrder.paymentMethod}
                      </td>
                      <td class="border border-gray-400 p-2 text-center"></td>
                      <td class="border border-gray-400 p-2 text-center"></td>
                    </tr>
                    <tr class="bg-gray-200">
                      <td class="border border-gray-400 p-2 text-center font-bold">
                        Total
                      </td>
                      <td class="border border-gray-400 p-2 text-center"></td>
                      <td class="border border-gray-400 p-2 text-center text-blue-600">
                        {selectedOrder?.transaction?.amount ||
                          selectedOrder.totalPrice}
                      </td>
                      <td class="border border-gray-400 p-2 text-center"></td>
                      <td class="border border-gray-400 p-2 text-center text-red-600 font-bold">
                        {selectedOrder.paymentStatus === "paid"
                          ? "Paid"
                          : "Unpaid"}
                      </td>
                      <td class="border border-gray-400 p-2 text-center"></td>
                    </tr>
                  </tbody>
                </table>

                <h2 class="text-center font-bold text-lg mb-4">
                  Delivery Status
                </h2>
                <table class="w-full border-collapse border border-gray-400">
                  <thead>
                    <tr class="bg-gray-200">
                      <th class="border border-gray-400 p-2">SL</th>
                      <th class="border border-gray-400 p-2">Status</th>
                      <th class="border border-gray-400 p-2">Date</th>
                      <th class="border border-gray-400 p-2">Remark/Note</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="border border-gray-400 p-2 text-center">1</td>
                      <td class="border border-gray-400 p-2 text-center">
                        {selectedOrder.status}
                      </td>
                      <td class="border border-gray-400 p-2 text-center">
                        {selectedOrder.deliveredAt === null
                          ? new Date(selectedOrder.updatedAt).toLocaleString()
                          : new Date(
                              selectedOrder.deliveredAt
                            ).toLocaleString()}
                      </td>
                      <td class="border border-gray-400 p-2 text-center">
                        {selectedOrder.status === "pending"
                          ? "Pending"
                          : "Delivered"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {/* Footer with Total Price */}
              {/* <div className="flex justify-between items-center mt-8 border-t pt-4">
                <p className="text-lg font-semibold text-gray-800">
                  Total Price:
                </p>
                <p className="text-2xl font-bold text-blue-600">
                  TK. {selectedOrder.totalPrice.toFixed(2)}
                </p>
              </div> */}
            </div>

            {/* Close Button */}
            <div className="flex justify-end mt-6">
              <button
                onClick={() => setShowDetails(false)}
                className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition duration-200"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal for invoice */}
      {showInvoice && (
        <Modal
          title="Invoice"
          isOpen={showInvoice}
          onClose={() => setShowInvoice(false)}
        >
          <Invoice data={invoiceData} />
        </Modal>
      )}
      {/* Modal for editing payment method/status, delivery charge, discount */}
      {isEditing && (
        <Modal
          title="Edit Order"
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
        >
          <EditableOrderComponent order={selectedOrder} onUpdate={onUpdate} />
        </Modal>
      )}
    </div>
  );
};

export default OrdersList;
