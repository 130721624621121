import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleExecutive } from '../features/executive/executiveSlice';
import LoaderComponent from '../components/LoaderComponent';

const ManagingDirectorMessage = () => {

  const { executive, loading } = useSelector((state) => state.executive);

  const {id} = useParams();
  const dispatch = useDispatch();

  //@TODO fetch data from backend
  useEffect(() => {
    dispatch(getSingleExecutive(id));
  },[dispatch,id])


  if (loading) <LoaderComponent />

  return (
    <div className="max-w-6xl mx-auto p-8 bg-white">
      {/* Managing Director Section */}
      <section className="flex flex-col lg:flex-row items-center lg:items-start border-t-4 border-green-500 pt-8">
        {/* Image Section */}
        <div className="lg:w-1/2 mb-8 lg:mb-0 flex flex-col items-center lg:items-start">
          <img
            src={executive.profilePicture}
            alt="Managing Director"
            className="rounded-lg shadow-lg w-full"
          />
          <div className="bg-green-600 text-white font-semibold px-4 py-2 mt-4 rounded-full flex items-center">
            <span className="uppercase text-sm font-bold">{executive.role}</span>
            <span className="ml-2">{executive.name}</span>
          </div>
        </div>

        {/* Message Section */}
        <div className="lg:w-1/2 lg:pl-8 text-center lg:text-left">
          <h2 className="text-3xl lg:text-4xl font-bold text-gray-800 mb-4">Our {executive.role}’s Message</h2>
          <h3 className="text-2xl font-semibold text-green-600 mb-4">{executive.message?.title}'s Message</h3>
          <div className="border-t-2 border-gray-300 mb-4">
            <div className="border-t-2 border-green-200 w-1/4 mt-2"></div>
          </div>
          <p className="text-gray-600 text-lg mb-4">
          {executive.message?.body}
          </p>
          {/* <p className="text-gray-600 text-lg mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
          </p>
          <p className="text-gray-600 text-lg mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
          </p>
          <p className="text-gray-600 text-lg">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.
          </p> */}
          <div className="border-t-4 border-green-500 mt-8"></div>
        </div>
      </section>
    </div>
  );
};

export default ManagingDirectorMessage;
