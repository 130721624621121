import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserDetails,
  updateUserDetails,
  updateResellerProfile,
  fetchResellerProfile,
} from "../../features/user/userSlice";
import LoaderComponent from "../LoaderComponent";
import { updateUserInfo } from "../../features/auth/authSlice";
import { toast } from "react-toastify";
import { FaCheck, FaRegClipboard } from "react-icons/fa";
// import profilePicture from '../../images/profile.webp'

const ProfileContent = ({ role }) => {
  const { userDetails: userInfo, loading } = useSelector((state) => state.user);

  const [profilePicturePreview, setProfilePicturePreview] = useState(null);
  const [voterIdCardPreview, setVoterIdCardPreview] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    contactNumber: "",
    birthday: {
      day: "",
      month: "",
      year: "",
    },
    gender: "",
    address: "",
  });

  const [copied, setCopied] = useState(false);

  const dispatch = useDispatch();

  // fetch user details
  useEffect(() => {
    if (role === "reseller") {
      dispatch(fetchResellerProfile());
    } else if (role === "user" || role === "admin") {
      dispatch(
        fetchUserDetails(JSON.parse(localStorage.getItem("access_token")))
      );
    }
  }, [dispatch, role]);

  // Set profileData when userInfo is available
  useEffect(() => {
    if (userInfo) {
      const dateOfBirth = userInfo.birthday
        ? userInfo.birthday.split("-")
        : ["00", "00", "0000"];
      setProfileData({
        name: userInfo.name || "",
        email: userInfo.email || "",
        phone: userInfo.phone || "",
        birthday: {
          day: dateOfBirth[0],
          month: dateOfBirth[1],
          year: dateOfBirth[2],
        },
        gender: userInfo.gender || "",
        address: userInfo.address || "",
      });
      dispatch(updateUserInfo(userInfo));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  // Toggle between edit and view modes
  const toggleEditMode = () => setIsEditing(!isEditing);

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "day" || name === "month" || name === "year") {
      setProfileData((prevData) => ({
        ...prevData,
        birthday: { ...prevData.birthday, [name]: value },
      }));
    } else {
      setProfileData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // Handle gender change
  const handleGenderChange = (e) => {
    setProfileData((prevData) => ({
      ...prevData,
      gender: e.target.value,
    }));
  };

  // Handle image change and convert to base64
  const handleImageChange = (event) => {
    const file = event.currentTarget.files[0];

    // Use FileReader to read the file as base64
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result; // Set base64 string in Formik's image field
      setProfilePicturePreview(base64String); // Display preview
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  // handle voterId card change and convert to base64
  const handleVoterIdImageChange = (event) => {
    const file = event.currentTarget.files[0];

    // Use FileReader to read the file as base64
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result; // Set base64 string in Formik's image field
      setVoterIdCardPreview(base64String); // Display preview
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const saveProfile = () => {
    // Example validation check
    if (!profileData.email.includes("@")) {
      alert("Please enter a valid email.");
      return;
    }

    const updatedData = {
      ...profileData,
      birthday: `${profileData.birthday.day}-${profileData.birthday.month}-${profileData.birthday.year}`,
      profilePicture: profilePicturePreview || profileData.profilePicture,
      voterId: voterIdCardPreview || profileData.voterId,
    };
    // dispatch the update action -- based on role
    if (role === "reseller") {
      dispatch(
        updateResellerProfile({ id: userInfo._id, userData: updatedData })
      );
    } else if (role === "user" || role === "admin") {
      dispatch(updateUserDetails({ id: userInfo._id, userData: updatedData }));
    }

    // Exit edit mode
    setIsEditing(false);
  };

  const handleCopyClick = (link) => {
    navigator.clipboard
      .writeText(link)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      })
      .catch((error) => {
        //@TODO handle error
        toast.error(error.message || "Failed to copy to clipboard");
      });
  };

  if (loading) return <LoaderComponent />;
  return (
    <div className="p-6 bg-gray-100 rounded-lg h-screen">
      {/* Profile Header */}
      <div className="flex flex-col items-center md:flex-row md:justify-between mb-4">
        <div className="flex flex-col items-center md:items-start">
          <h2 className="text-xl font-bold italic">
            {userInfo?.name + "s' "}Profile
          </h2>
        </div>

        <button
          onClick={toggleEditMode}
          className="text-gray-500 hover:text-gray-700 focus:outline-none mt-4 md:mt-0"
        >
          ✏️ Edit
        </button>
      </div>

      {/* Profile Image Upload */}
      <div className="flex flex-col items-center mb-6">
        {/* <label className="block font-semibold mb-2">Profile Picture</label> */}
        <div className="flex items-center gap-4">
          <img
            src={
              profilePicturePreview ||
              userInfo?.profilePicture ||
              "/profile.webp"
            }
            alt="Profile"
            className="w-24 h-24 rounded-full"
          />

          {isEditing && (
            <div>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
              />
            </div>
          )}
        </div>
      </div>

      {userInfo?.role === "reseller" && !isEditing && (
        <div className="flex items-center justify-between border rounded-lg p-2 bg-gray-300 w-full">
          <p className="p-2">your refer link :</p>
          <span className="p-2 text-blue-600 hover:underline cursor-pointer">{`https://paribarmart.com/reseller/signup?refer=${userInfo?.referralCode}`}</span>
          <button
            onClick={()=>handleCopyClick(`https://paribarmart.com/reseller/signup?refer=${userInfo?.referralCode}`)}
            className="text-blue-500 hover:text-blue-700 transition-colors duration-200 flex items-center"
          >
            {copied ? (
              <FaCheck className="text-green-500" />
            ) : (
              <FaRegClipboard />
            )}
          </button>
        </div>
      )}

      {/* Responsive Form Grid */}
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
        {/* Name */}
        <div>
          <label className="block font-semibold">Name</label>
          {isEditing ? (
            <input
              type="text"
              name="name"
              value={profileData.name}
              onChange={handleChange}
              className="border rounded w-full px-2 py-1"
            />
          ) : (
            <p className="bg-gray-200 p-2 rounded">{profileData.name}</p>
          )}
        </div>

        {/* Email */}
        <div>
          <label className="block font-semibold">Email</label>
          {isEditing ? (
            <input
              type="email"
              name="email"
              value={profileData.email}
              onChange={handleChange}
              className="border rounded w-full px-2 py-1"
            />
          ) : (
            <p className="bg-gray-200 p-2 rounded">{profileData.email}</p>
          )}
        </div>

        {/* Contact Number */}
        <div>
          <label className="block font-semibold">Contact Number</label>
          {isEditing ? (
            <input
              type="text"
              name="phone"
              value={profileData.phone}
              onChange={handleChange}
              className="border rounded w-full px-2 py-1"
            />
          ) : (
            <p className="bg-gray-200 p-2 rounded">{profileData.phone}</p>
          )}
        </div>

        {/* Voter ID Card */}
        {(userInfo?.role === "reseller" || userInfo?.affiliate) && (
          <div className="lg:col-span-2">
            <label className="block font-semibold">Voter ID Card</label>
            <div className="flex items-center gap-4">
              <div className="bg-gray-200 p-2 rounded flex-grow">
                {userInfo?.voterId ? (
                  <img
                    src={userInfo?.voterId}
                    alt="Voter ID Card"
                    className="w-full h-full md:w-full md:h-48 object-fit"
                  />
                ) : (
                  <p>No voter ID card uploaded</p>
                )}
                {voterIdCardPreview && (
                  <img
                    src={voterIdCardPreview}
                    alt="Voter ID Card Preview"
                    className="w-full h-full md:w-full md:h-48 object-fit"
                  />
                )}
              </div>
              {isEditing && (
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleVoterIdImageChange}
                  className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
                />
              )}
            </div>
          </div>
        )}

        {/* Birthday */}
        <div className="lg:col-span-2">
          <label className="block font-semibold">Birthday</label>
          <div className="flex gap-2">
            <input
              type="text"
              name="day"
              placeholder="DD"
              value={profileData.birthday.day}
              onChange={handleChange}
              className="border rounded w-1/3 px-2 py-1"
            />
            <input
              type="text"
              name="month"
              placeholder="MM"
              value={profileData.birthday.month}
              onChange={handleChange}
              className="border rounded w-1/3 px-2 py-1"
            />
            <input
              type="text"
              name="year"
              placeholder="YYYY"
              value={profileData.birthday.year}
              onChange={handleChange}
              className="border rounded w-1/3 px-2 py-1"
            />
          </div>
        </div>

        {/* Address */}
        <div className="lg:col-span-3">
          <label className="block font-semibold">Address</label>
          {isEditing ? (
            <input
              type="text"
              name="address"
              value={profileData.address}
              onChange={handleChange}
              className="border rounded w-full px-2 py-1"
            />
          ) : (
            <p className="bg-gray-200 p-2 rounded">
              {profileData.address || "No address available"}
            </p>
          )}
        </div>

        {/* Gender */}
        <div className="lg:col-span-3 mg-4">
          <label className="block font-semibold">Gender</label>
          <div className="flex items-center gap-4">
            <label className="flex items-center">
              <input
                type="radio"
                name="gender"
                value="male"
                checked={profileData.gender === "male"}
                onChange={handleGenderChange}
                disabled={!isEditing}
                className="mr-2"
              />
              Male
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="gender"
                value="female"
                checked={profileData.gender === "female"}
                onChange={handleGenderChange}
                disabled={!isEditing}
                className="mr-2"
              />
              Female
            </label>
            <label className="flex items-center">
              <input
                type="radio"
                name="gender"
                value="N/A"
                checked={profileData.gender === "N/A"}
                onChange={handleGenderChange}
                disabled={!isEditing}
                className="mr-2"
              />
              N/A
            </label>
          </div>
        </div>
      </div>

      {/* Save/Cancel Buttons */}
      {isEditing && (
        <div className="mt-6 flex justify-center gap-4">
          <button
            onClick={saveProfile}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            {loading ? "Saving..." : "Save"}
          </button>
          <button
            onClick={toggleEditMode}
            className="px-4 py-2 bg-gray-400 text-white rounded"
          >
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export default ProfileContent;
