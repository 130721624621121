import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchProducts, setFilter, incrementPage } from "../../features/resellerProducts/resellerProductSlice";
import { fetchCategories } from "../../features/category/categorySlice";
import ResellerProductCard from "./ResellerProductCard";
import LoaderComponent from "../../components/LoaderComponent";
import { useInView } from "react-intersection-observer";
// import { debounce } from "lodash";
import { FiFilter } from "react-icons/fi"; // Importing filter icon

const ProductListing = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const { items, loading, filters, page, hasMore } = useSelector((state) => state.resellerProducts);
  const { categories } = useSelector((state) => state.category);
  const { ref, inView } = useInView({
    threshold: 0.5,
    rootMargin: "100px",
  });

  const dispatch = useDispatch();
  // const [priceRange, setPriceRange] = useState({ minPrice: 0, maxPrice: 100000 });
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // useEffect(() => {
  //   const handler = debounce(() => {
  //     dispatch(setFilter(priceRange));
  //   }, 500);

  //   handler();
  //   return () => {
  //     handler.cancel();
  //   };
  // }, [priceRange, dispatch]);

  // const handlePriceChange = (e) => {
  //   const newMaxPrice = parseInt(e.target.value, 10);
  //   setPriceRange((prevRange) => ({ ...prevRange, maxPrice: newMaxPrice }));
  // };

  const handleFilterChange = (newFilter) => {
    dispatch(setFilter(newFilter));
  };

  useEffect(() => {
    if (inView && hasMore && !loading) {
      dispatch(incrementPage());
    }
  }, [inView, hasMore, loading, dispatch]);

  useEffect(() => {
    dispatch(fetchProducts({ filters, page }));
    dispatch(fetchCategories());
  }, [page, filters, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setFilter({ category: "",subCategory: "", minPrice: 0, maxPrice: 100000, inStock: false, reseller: true, rating: 0,search: "" }));
    };
  }, [dispatch]);

  const resetFilters = () => {
    dispatch(
      setFilter({
        category: "",
        subCategory: "",
        minPrice: 0,
        maxPrice: 100000,
        inStock: false,
        rating: 0,
        reseller: true,
        search: "",
      })
    );
  };

  if (loading && items.length === 0) return <LoaderComponent />;

  return (
    <div className="flex flex-row bg-gray-100 p-4">
      {/* Toggle Button for Sidebar (Visible on small screens) */}
      <div className="fixed left-4 z-50 md:hidden">
        <button
          onClick={() => setIsSidebarOpen(true)}
          className="p-2 bg-blue-500 text-white rounded-full shadow-lg"
          aria-label="Open Filters"
        >
          <FiFilter size={24} />
        </button>
      </div>

      {/* Sidebar as Modal on Small Screens */}
      <aside
        className={`fixed inset-y-0 left-0 w-3/4 md:w-1/6 p-4 bg-white rounded-lg shadow-md  transform ${
          isSidebarOpen ? "translate-x-0 z-50 pt-12" : "-translate-x-full"
        } transition-transform duration-300 md:static md:translate-x-0`}
      >
        <button
          onClick={() => setIsSidebarOpen(false)}
          className="md:hidden absolute top-2 right-2 text-red-700 bg-red-100 p-2 rounded-full hover:bg-red-200"
          aria-label="Close Filters"
        >
          Close
        </button>
        <h3 className="text-xl font-semibold mb-4 flex justify-between items-center">
            Filters
            <button
              onClick={resetFilters}
              className="text-sm text-blue-600 hover:text-blue-800"
            >
              Reset Filters
            </button>
          </h3>
          <div className="mb-6">
            <h4 className="font-medium text-lg mb-2">Category</h4>
            {/* Iterate through categories and subcategories */}
            <div className="space-y-4">
              {categories.map((category) => (
                <div key={category.id} className="space-y-2">
                  {/* Parent Category */}
                  <div>
                    <input
                      type="radio"
                      id={`category-${category.id}`}
                      name="mainCategory"
                      value={category.name}
                      checked={filters.category === category.name}
                      onChange={() =>
                        handleFilterChange({
                          category: category.name,
                          subcategory: "", // Reset subcategory when main category changes
                        })
                      }
                      className="mr-2"
                    />
                    <label
                      htmlFor={`category-${category.id}`}
                      className="font-medium"
                    >
                      {category.name}
                    </label>
                  </div>

                  {/* Subcategories */}
                  {category.subcategories?.length > 0 &&
                    filters.category === category.name && (
                      <div className="pl-6">
                        {category.subcategories.map((subcategory) => (
                          <div key={subcategory.id}>
                            <input
                              type="radio"
                              id={`subcategory-${subcategory.id}`}
                              name="subcategory"
                              value={subcategory.name}
                              checked={filters.subcategory === subcategory.name}
                              onChange={() =>
                                handleFilterChange({
                                  subcategory: subcategory.name,
                                })
                              }
                              className="mr-2"
                            />
                            <label
                              htmlFor={`subcategory-${subcategory.id}`}
                              className="text-sm"
                            >
                              {subcategory.name}
                            </label>
                          </div>
                        ))}
                      </div>
                    )}
                </div>
              ))}
            </div>
          </div>
        {/* <div className="mb-4">
          <label className="block font-medium mb-2">Price Range</label>
          <input
            type="range"
            min="0"
            max="100000"
            value={priceRange.maxPrice}
            className="w-full"
            onChange={handlePriceChange}
          />
          <p className="text-sm">Max Price: {priceRange.maxPrice}</p>
        </div> */}
        <div className="mb-4">
          <input
            type="checkbox"
            className="mr-2"
            checked={filters.inStock}
            onChange={(e) => handleFilterChange({ inStock: e.target.checked })}
          />
          <span>In Stock Only</span>
        </div>
        <div className="mb-4">
          <label className="block font-medium mb-2">Rating</label>
          <select
            className="w-full border-gray-300 rounded-md p-2"
            onChange={(e) => handleFilterChange({ rating: e.target.value })}
          >
            <option value="">All Ratings</option>
            <option value="4">4 Stars & Up</option>
            <option value="3">3 Stars & Up</option>
          </select>
        </div>
      </aside>

      {/* Sidebar Overlay for Mobile */}
      {isSidebarOpen && (
        <div
          onClick={() => setIsSidebarOpen(false)}
          className="fixed inset-0 bg-black opacity-50 z-40 md:hidden"
        />
      )}

      {/* Product Grid */}
      <main className="w-full md:w-5/6 pl-0 md:pl-6">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {items && items.length > 0 ? (
            items.map((product) => (
              <ResellerProductCard key={product._id} product={product} userInfo={userInfo} />
            ))
          ) : (
            <p>No products found.</p>
          )}
        </div>

        {/* Infinite Scroll Observer */}
        {!loading && hasMore && <div ref={ref} className="h-4"></div>}
      </main>
    </div>
  );
};

export default ProductListing;
