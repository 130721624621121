import React, { useState } from 'react';

const EditableOrderComponent = ({ order, onUpdate }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setModalType] = useState(''); // 'discount' or 'payment'
  const [editData, setEditData] = useState({
    orderDiscount: order.orderDiscount || 0,
    deliveryCharge: order.deliveryCharge || 0,
    paymentStatus: order.paymentStatus || 'pending',
    paymentMethod: order.paymentMethod || '',
  });

  // Open and close modal
  const openModal = (type) => {
    setModalType(type);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setModalType('');
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: value });
  };

  // Submit updated data
  const handleSubmit = () => {
    onUpdate(editData);
    closeModal();
  };


  return (
    <div className="p-4 bg-white rounded-lg shadow-lg">
      {/* Table Section */}
      <h2 className="text-lg font-semibold mb-4">Order Details</h2>
      <table className="table-auto w-full border border-gray-300 mb-4">
        <thead>
          <tr className="bg-gray-100">
            <th className="border px-4 py-2 text-left">Field</th>
            <th className="border px-4 py-2 text-left">Value</th>
            <th className="border px-4 py-2 text-center">Action</th>
          </tr>
        </thead>
        <tbody>
          {/* Discount and Service Charge */}
          
          <tr>
            <td className="border px-4 py-2">Discount & Service Charge</td>
            <td className="border px-4 py-2">{order.deliveryCharge} BDT | {order.orderDiscount} BDT</td>
            <td className="border px-4 py-2 text-center">
              <button
                onClick={() => openModal('discount')}
                className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Edit
              </button>
            </td>
          </tr>
          {/* Payment Status and Method */}
          
          <tr>
            <td className="border px-4 py-2">Payment Method & Payment Status</td>
            <td className="border px-4 py-2">{order.paymentMethod} | {order.paymentStatus}</td>
            <td className="border px-4 py-2 text-center">
              <button
                onClick={() => openModal('payment')}
                className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Edit
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      {/* Modal Section */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white rounded-lg p-6 w-96">
            <h3 className="text-xl font-semibold mb-4">
              {modalType === 'discount' ? 'Edit Discount & Service Charge' : 'Edit Payment Details'}
            </h3>

            {/* Conditional Rendering for Discount/Service Charge */}
            {modalType === 'discount' && (
              <>
                <div className="mb-4">
                  <label className="block mb-1 font-medium">Order Discount</label>
                  <input
                    type="number"
                    name="orderDiscount"
                    value={editData.orderDiscount}
                    onChange={handleChange}
                    className="w-full border px-3 py-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
                <div className="mb-4">
                  <label className="block mb-1 font-medium">Service Charge</label>
                  <input
                    type="number"
                    name="deliveryCharge"
                    value={editData.deliveryCharge}
                    onChange={handleChange}
                    className="w-full border px-3 py-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              </>
            )}

            {/* Conditional Rendering for Payment Status/Method */}
            {modalType === 'payment' && (
              <>
                <div className="mb-4">
                  <label className="block mb-1 font-medium">Payment Status</label>
                  <select
                    name="paymentStatus"
                    value={editData.paymentStatus}
                    onChange={handleChange}
                    className="w-full border px-3 py-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="pending">Pending</option>
                    <option value="unpaid">Unpaid</option>
                    <option value="paid">Paid</option>
                  </select>
                </div>
                <div className="mb-4">
                  <label className="block mb-1 font-medium">Payment Method</label>
                  <select
                    name="paymentMethod"
                    value={editData.paymentMethod}
                    onChange={handleChange}
                    className="w-full border px-3 py-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="">Select Method</option>
                    <option value="cash">Cash</option>
                    <option value="bank">Bank</option>
                    <option value="bkash">bKash</option>
                    <option value="nagad">Nagad</option>
                    <option value="rocket">Rocket</option>
                  </select>
                </div>
              </>
            )}

            <div className="flex justify-end space-x-2">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-gray-300 text-gray-700 rounded hover:bg-gray-400"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EditableOrderComponent;
