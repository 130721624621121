import React, { useEffect, useState } from "react";
import UserSidebar from "./UserSidebar";
import ProfileContent from "./ProfileContent";
import OrdersContent from "./OrdersContent";
import AffiliateContent from "./AffiliateContent";
import ReferralsContent from "./ReferralsContent";
import PaymentSettings from "../common/PaymentSettings";
import Withdraw from "../common/Withdraw";
import JoinAffiliate from "./JoinAffiliate";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  createWithdrawalMethod,
  getWithdrawalMethods,
  deleteWithdrawalMethod
} from "../../features/withdrawalMethod/withdrawalSlice";
import { FiMenu } from "react-icons/fi";
import ChangePassword from "../common/ChangePassword";
import OrderBonus from "../reseller/OrderBonus";
import AffiliateDashboard from "./AffiliateDashboard";

const UserDashboard = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const location = useLocation();
  const { active } = location.state || { active: "profile" };

  const initialTab = ()=>localStorage.getItem("activeTab") || active;

  const [selectedSection, setSelectedSection] = useState(initialTab());
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem("activeTab", selectedSection);
  }, [selectedSection]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

 

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      {/* Sidebar */}
      <UserSidebar
        selectedSection={selectedSection}
        setSelectedSection={setSelectedSection}
        isAffiliate={userInfo?.affiliate}
        isOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar}
      />

      {/* Main content */}
      <div className="flex-1 overflow-y-auto h-full  bg-gray-100">
        {/* Toggle Button for Mobile View */}
        <div className="flex items-center justify-center w-full bg-blue-600 p-4">
              <button
                onClick={toggleSidebar}
                className="inline-flex lg:hidden text-white mr-4"
              >
                <FiMenu size={24} />
              </button>
              <h1 className="text-sm md:text-3xl text-white font-semibold">
                {userInfo?.name + "'s Dashboard" || "User Dashboard"}
              </h1>
            </div>

        {/* Conditionally render based on selected section */}
        {selectedSection === "profile" && <ProfileContent role="user" />}
        {selectedSection === "change-password" && <ChangePassword role="user"/>}
        {selectedSection === "orders" && <OrdersContent />}
        {selectedSection === "affiliate" && <AffiliateContent />}
        {selectedSection === "referrals" && <ReferralsContent />}
        {selectedSection === "payment-settings" && (
          <PaymentSettings
            createWithdrawalMethod={createWithdrawalMethod}
            getWithdrawalMethods={getWithdrawalMethods}
            deleteWithdrawalMethod={deleteWithdrawalMethod}
          />
        )}
        {selectedSection === "withdraw" && <Withdraw />}
        {selectedSection === "join-affiliate" && <JoinAffiliate userInfo={userInfo} />}
        {selectedSection === "order-bonus" && <OrderBonus />}
        {selectedSection === "dashboard" && <AffiliateDashboard />}
      </div>
    </div>
  );
};

export default UserDashboard;
