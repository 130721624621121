import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";
import { toast } from "react-toastify";

// get all withdrawal requests -- affiliate
export const getWithdrawalRequests = createAsyncThunk(
  "withdrawalRequest/getWithdrawalRequests",
  async ({ page = 1, limit = 10, token }) => {
    const response = await api.get(
      `/withdrawal-requests?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// create withdrawal request by affiliate
export const createWithdrawalRequest = createAsyncThunk(
  "withdrawalRequest/createWithdrawalRequest",
  async ({ withdrawalRequestData, token }) => {
    const response = await api.post(
      "/withdrawal-requests",
      withdrawalRequestData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 201) {
      toast.success("Withdrawal request created successfully");
    }
    return response.data;
  }
);

// get all withdrawal requests -- reseller
export const getWithdrawalRequestsByReseller = createAsyncThunk(
  "withdrawalRequest/getWithdrawalRequestsByReseller",
  async ({ page = 1, limit = 10, token }) => {
    const response = await api.get(
      `/reseller/withdrawal-requests?page=${page}&limit=${limit}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// create withdrawal request by reseller
export const createWithdrawalRequestByReseller = createAsyncThunk(
  "withdrawalRequest/createWithdrawalRequestByReseller",
  async ({ withdrawalRequestData, token }) => {
    const response = await api.post(
      "/reseller/withdrawal-requests",
      withdrawalRequestData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  }
);

// get all withdrawal requests -- admin
export const getWithdrawalRequestsByAdmin = createAsyncThunk(
  "withdrawalRequest/getWithdrawalRequestsByAdmin",
  async ({
    page = 1,
    limit = 10,
  }) => {
    const response = await api.get(`/admin/withdrawal-requests?page=${page}&limit=${limit}`);
    return response.data;
  }
);

// update withdrawal request by admin
export const updateWithdrawalRequestByAdmin = createAsyncThunk(
  "withdrawalRequest/updateWithdrawalRequestByAdmin",
  async ({ id, withdrawalRequest }) => {
    const response = await api.put(
      `/admin/withdrawal-requests/${id}`,
      withdrawalRequest
    );
    if (response.status === 200) {
      toast.success("Withdrawal request updated successfully");
    }
    return response.data;
  }
);

const withdrawalRequestSlice = createSlice({
  name: "withdrawalRequest",
  initialState: {
    withdrawalRequests: [],
    pagination: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWithdrawalRequests.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWithdrawalRequests.fulfilled, (state, action) => {
        state.loading = false;
        state.withdrawalRequests = action.payload.withdrawalRequests;
        state.pagination = action.payload.pagination;
      })
      .addCase(getWithdrawalRequests.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getWithdrawalRequestsByReseller.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWithdrawalRequestsByReseller.fulfilled, (state, action) => {
        state.loading = false;
        state.withdrawalRequests = action.payload.withdrawalRequests;
        state.pagination = action.payload.pagination;
      })
      .addCase(getWithdrawalRequestsByReseller.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getWithdrawalRequestsByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWithdrawalRequestsByAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.withdrawalRequests = action.payload.withdrawalRequests;
        state.pagination = action.payload.pagination;
      })
      .addCase(getWithdrawalRequestsByAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updateWithdrawalRequestByAdmin.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateWithdrawalRequestByAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.withdrawalRequests = state.withdrawalRequests.map((item) =>
          item._id === action.payload._id ? action.payload : item
        );
      })
      .addCase(updateWithdrawalRequestByAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createWithdrawalRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(createWithdrawalRequest.fulfilled, (state, action) => {
        state.loading = false;
        state.withdrawalRequests.push(action.payload);
      })
      .addCase(createWithdrawalRequest.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createWithdrawalRequestByReseller.pending, (state) => {
        state.loading = true;
      })
      .addCase(createWithdrawalRequestByReseller.fulfilled, (state, action) => {
        state.loading = false;
        state.withdrawalRequests.push(action.payload);
      })
      .addCase(createWithdrawalRequestByReseller.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default withdrawalRequestSlice.reducer;
