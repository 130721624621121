import React, { useCallback, useEffect, useState } from "react";
import { FaEdit, FaPlus } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSubcategories,
} from "../../features/subcategory/subcategorySlice";
import LoaderComponent from "../LoaderComponent";
import Modal from "../common/Modal";
import SubCategoryForm from "./SubCategoryForm";
import { debounce } from "lodash";
import Search from "../Search";

const SubcategoryList = () => {
  const { subcategories, loading } = useSelector((state) => state.subcategory);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSubcategories({ search: "" }));
  }, [dispatch]);

  // const handleDelete = (id) => {
  //   if (window.confirm("Are you sure you want to delete this subcategory?")) {
  //     dispatch(deleteSubcategory(id));
  //   }
  // };

  const handleEdit = (subcategory) => {
    setIsEditing(true);
    setSelectedSubcategory(subcategory);
  };

  // handle search

  // Debounced search handler
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce((query) => {
      // If the search query is empty, reset the search filter
      if (!query.trim()) {
        dispatch(fetchSubcategories({ search: "" }));
        setPage(1);
      } else {
        // Otherwise, update the search query
        dispatch(fetchSubcategories({ page, search: query }));
      }
    }, 500),
    [dispatch]
  );

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    searchQuery && dispatch(fetchSubcategories({ search: searchQuery }));
  };

  if (loading) return <LoaderComponent />;

  return (
    <div className="container mx-auto p-6 mb-8 h-screen ">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold">Subcategories</h2>
        <button
          onClick={() => setShowCreateForm(true)}
          className="bg-blue-500 text-white p-2 rounded shadow-md hover:bg-blue-600 transition-all flex items-center"
        >
          <FaPlus className="mr-2" /> create subcategories
        </button>
      </div>

      {/* Search */}
      <Search
        handleSearchSubmit={handleSearchSubmit}
        handleSearchChange={handleSearchChange}
        searchQuery={searchQuery}
        placeholder={"Search by category name"}
      />

      {/* {loading && <p>Loading...</p>}
      {error && <p className="text-red-500">{error}</p>} */}
      <div className="shadow-lg rounded-lg overflow-x-auto">
        {subcategories.length > 0 ? (
          <table className="min-w-full bg-white border border-gray-200 rounded shadow-md">
            <thead className="bg-gray-200">
              <tr>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">
                  SL No
                </th>
                <th className="py-2 px-4 border-b text-left font-semibold text-gray-700">
                  name
                </th>
                <th className="py-2 px-4 border-b text-center font-semibold text-gray-700">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {subcategories.map((subcategory, index) => (
                <tr key={subcategory._id} className="hover:bg-gray-100">
                  <td className="py-2 px-4 border-b">{index + 1}</td>
                  <td className="py-2 px-4 border-b">
                    {subcategory.name || "-"}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    <button
                      className="text-green-500 hover:text-green-700 mx-1"
                      onClick={() => handleEdit(subcategory)}
                    >
                      <FaEdit />
                    </button>
                    {/* <button
                      className="text-red-500 hover:text-red-700 mx-1"
                      onClick={() => handleDelete(subcategory._id)}
                    >
                      <FaTrash />
                    </button> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No subcategories available.</p>
        )}
      </div>

      {showCreateForm && (
        <Modal
          title={"Create Subcategory"}
          isOpen={showCreateForm}
          onClose={() => setShowCreateForm(false)}
        >
          <SubCategoryForm />
        </Modal>
      )}
      {isEditing && (
        <Modal
          title={"Edit Subcategory"}
          isOpen={isEditing}
          onClose={() => setIsEditing(false)}
        >
          <SubCategoryForm initialValues={selectedSubcategory} mode="edit" />
        </Modal>
      )}
    </div>
  );
};

export default SubcategoryList;
