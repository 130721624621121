import React, { useEffect } from 'react';
import Card from '../admin/Card';
import { getAffiliateDashboard } from '../../features/dashboard/dashboardSlice';
import { getAffiliateByUserId } from '../../features/affiliate/affiliateSlice';
import { useDispatch, useSelector } from 'react-redux';
import LoaderComponent from '../LoaderComponent';
const AffiliateDashboard = () => {

  const {userInfo} = useSelector((state) => state.auth);
  const { affiliate, loading } = useSelector(state=>state.affiliate);
  const { dashboard, loading: loadingDashboard } = useSelector(state=>state.dashboard);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userInfo) {
      dispatch(getAffiliateByUserId({ userId: userInfo?._id, token: JSON.parse(localStorage.getItem('access_token')) }));
    }
  }, [dispatch, userInfo]);

  useEffect(() => {
    if (affiliate) {
      dispatch(getAffiliateDashboard({ affiliateId: affiliate?._id, token: JSON.parse(localStorage.getItem('access_token')) }));
    }
  }, [dispatch, affiliate]);

 if(loading || loadingDashboard) return <LoaderComponent />
  return (
    <div className='container mx-auto'>
        <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 mt-6">
          {
            dashboard?.length > 0 && dashboard.map((item, index) => (
              <Card key={index} title={item.title} count={item.count} />
            ))
          }
        </div>
    </div>
  )
}

export default AffiliateDashboard