import React from 'react'
import ProductCard from './ProductCard'

const RelatedProducts = ({relatedProducts}) => {
  return (
    
    <div className="w-full mt-10 mb-4">
        <h2 className="text-2xl font-bold text-gray-800 italic mb-4">You may also like</h2>
        <div className="relative flex flex-col items-center">
            {
                relatedProducts && relatedProducts.length > 0 ? (
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-1">
                        {
                            relatedProducts.map((product, index) => <ProductCard key={index} product={product} />)
                        }  
                    </div>
                ):(
                    <p className='text-lg text-black-600 text-center'>No related products found</p>
                )
            }
        </div>
    </div>
  )
}

export default RelatedProducts