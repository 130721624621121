import { createSlice } from "@reduxjs/toolkit";

const initialMenuItems = [
    { id: 1, name: "HOME", link: "/" },
    { id: 2, name: "SHOP", link: "/shop" },
]

const navbarSlice = createSlice({
    name: "navbar",
    initialState: {
        menuItems: initialMenuItems
    },
    reducers: {
        setMenuItems: (state, action) => {
            
            state.menuItems = action.payload;
        },
    },
});

export const { setMenuItems } = navbarSlice.actions;

export default navbarSlice.reducer;