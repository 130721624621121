import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOrdersByResellerFromStore } from '../../features/orders/ordersSlice'; 
import LoaderComponent from '../LoaderComponent';
import Modal from '../common/Modal';
import AddReview from '../AddReview';
import moment from 'moment';
import Pagination from '../Pagination';

const OrderFromResellerStore = () => {
  const { orderFromStore:orders, pagination, loading } = useSelector((state) => state.orders);
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);

  const [filter, setFilter] = useState('All');
  const [dateFilter, setDateFilter] = useState('all'); // 'all', 'today', 'month'

  useEffect(() => {
    dispatch(getOrdersByResellerFromStore({page, limit: 20, token: JSON.parse(localStorage.getItem('access_token'))}));
  }, [dispatch, page]);

  // Filter orders by status
  const filteredByStatus = filter === 'All' ? orders : orders.filter(order => order.status === filter);

  // Filter orders by date
  const filteredOrders = filteredByStatus.filter(order => {
    if (dateFilter === 'today') {
      return moment(order.createdAt).isSame(moment(), 'day');
    } else if (dateFilter === 'month') {
      return moment(order.createdAt).isSame(moment(), 'month');
    }
    return true;
  });

 

  if (loading) return <LoaderComponent message="Loading orders..." />;
  if (orders.length === 0) return <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">No orders found.</h2>;

  return (
    <div className="container mx-auto p-6 bg-gradient-to-r from-blue-50 to-gray-100 rounded-lg shadow-lg h-screen">
      <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">Orders From Reseller Store</h2>

      {/* Summary Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
        <SummaryCard title="Today's Orders" value={filteredByStatus.filter(order => moment(order.createdAt).isSame(moment(), 'day')).length} />
        <SummaryCard title="This Month's Orders" value={filteredByStatus.filter(order => moment(order.createdAt).isSame(moment(), 'month')).length} />
      </div>

      {/* Date Filters */}
      <div className="flex flex-wrap justify-center gap-3 mb-8">
        {['all', 'today', 'month'].map(option => (
          <button
            key={option}
            onClick={() => setDateFilter(option)}
            className={`px-5 py-2 rounded-full text-sm font-semibold shadow-md transition-colors ${
              dateFilter === option
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-blue-400 hover:text-white'
            }`}
          >
            {option.charAt(0).toUpperCase() + option.slice(1)}
          </button>
        ))}
      </div>

      {/* Status Filter Buttons */}
      <div className="flex flex-wrap justify-center gap-3 mb-8">
        {['All', 'pending', 'processing', 'shipped', 'completed', 'delivered'].map(status => (
          <button
            key={status}
            onClick={() => setFilter(status)}
            className={`px-5 py-2 rounded-full text-sm font-semibold shadow-md transition-colors ${
              filter === status
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-blue-400 hover:text-white'
            }`}
          >
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </button>
        ))}
      </div>

      {/* Display Filtered Orders */}
      {filteredOrders.map(order => (
        <OrderCard key={order._id} order={order} />
      ))}
      <Pagination currentPage={pagination?.page} totalPages={pagination?.totalPages} onPageChange={setPage} />
    </div>
  );
};

// Summary Card Component
const SummaryCard = ({ title, value }) => (
  <div className="p-4 bg-white rounded-lg shadow-md">
    <h3 className="text-lg font-semibold text-gray-700">{title}</h3>
    <p className="text-2xl font-bold text-blue-600">{value}</p>
  </div>
);

// Single Order Card Component
const OrderCard = ({ order }) => {
  const [showProducts, setShowProducts] = useState(false);
  const [modal, setModal] = useState(false);
  const [productId, setProductId] = useState(null);
  const [user, setUser] = useState(null);

  const toggleModal = (productId, userId) => {
    setModal(!modal);
    setProductId(productId);
    setUser(userId);
  };

  return (
    <div className="mb-6 p-6 bg-white rounded-lg shadow-lg transition-transform transform hover:-translate-y-1">
      <div className="flex flex-col md:flex-row justify-between mb-4">
        <div>
          <p className="text-sm text-gray-500">Order ID:</p>
          <p className="sm:text-sm md:text-xl font-semibold text-gray-800">{order._id}</p>
        </div>
        <button
          onClick={() => setShowProducts(!showProducts)}
          className="text-blue-500 hover:underline focus:outline-none"
        >
          {showProducts ? 'Hide Details' : 'View Details'}
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div>
          <p className="text-sm text-gray-500">Order Status:</p>
          <p
            className={`text-lg font-semibold ${
              order.status === 'completed'
                ? 'text-green-500'
                : order.status === 'processing'
                ? 'text-yellow-500'
                : 'text-gray-700'
            }`}
          >
            {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
          </p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Payment Status:</p>
          <p
            className={`text-lg font-semibold ${
              order.paymentStatus === 'Paid' ? 'text-green-500' : 'text-red-500'
            }`}
          >
            {order.paymentStatus}
          </p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Total Price:</p>
          <p className="text-lg font-semibold text-blue-500">Tk. {order.totalPrice.toFixed(2)}</p>
        </div>
      </div>

      {/* Products List - Collapsible */}
      {showProducts && (
        <div className="mt-4 border-t pt-4">
          <h3 className="text-lg font-semibold mb-3 text-gray-700">Products:</h3>
          {order.products.map(product => (
            <div
              key={product.id}
              className="flex flex-col md:flex-row items-center justify-between mb-4 p-3 bg-gray-50 rounded-lg shadow-inner"
            >
              <div className="flex flex-col md:flex-row items-center">
                <img
                  src={product.image}
                  alt={product.title}
                  className="w-full h-full md:w-12 h-12 rounded mr-4 object-cover shadow-md"
                />
                <div>
                  <p className="text-gray-800 font-medium">{product.title}</p>
                  <p className="text-sm text-gray-500">Quantity: {product.quantity}</p>
                  <p className="text-sm text-gray-500">Price: TK. {product?.soldPrice.toFixed(2)}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      {modal && (
        <Modal title="Add Review" isOpen={modal} onClose={toggleModal}>
          <AddReview userId={user} productId={productId} />
        </Modal>
      )}
    </div>
  );
};

export default OrderFromResellerStore;











// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { getOrdersByResellerFromStore } from '../../features/orders/ordersSlice';
// import LoaderComponent from '../LoaderComponent';
// import Modal from '../common/Modal';
// import AddReview from '../AddReview';

// const OrderFromResellerStore = () => {
//   const { orderFromStore:orders, loading } = useSelector((state) => state.orders);
//   const dispatch = useDispatch();
//   const [filter, setFilter] = useState('All');


//   useEffect(() => {
//     dispatch(getOrdersByResellerFromStore(JSON.parse(localStorage.getItem('access_token'))));
//   }, [dispatch]);

//   const filteredOrders = filter === 'All' ? orders : orders.filter(order => order.status === filter);

//   if (loading) return <LoaderComponent message="Loading orders..." />;
//   if (orders.length === 0) return <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">You have no orders yet.</h2>;

//   return (
//     <div className="max-w-4xl mx-auto p-6 bg-gradient-to-r from-blue-50 to-gray-100 rounded-lg shadow-lg">
//       <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">My Orders</h2>

//       {/* Status Filter Buttons */}
//       <div className="flex flex-wrap justify-center gap-3 mb-8">
//         {['All', 'pending', 'processing', 'shipped', 'completed', 'delivered'].map(status => (
//           <button
//             key={status}
//             onClick={() => setFilter(status)}
//             className={`px-5 py-2 rounded-full text-sm font-semibold shadow-md transition-colors ${
//               filter === status
//                 ? 'bg-blue-500 text-white'
//                 : 'bg-gray-200 text-gray-700 hover:bg-blue-400 hover:text-white'
//             }`}
//           >
//             {status.charAt(0).toUpperCase() + status.slice(1)}
//           </button>
//         ))}
//       </div>

//       {/* Display Filtered Orders */}
//       {filteredOrders.map(order => (
//         <OrderCard key={order._id} order={order} />
//       ))}
//     </div>
//   );
// };

// // Single Order Card Component
// const OrderCard = ({ order }) => {
//   const [showProducts, setShowProducts] = useState(false);
  
//   const [modal, setModal] = useState(false);
//   const [productId,setProductId] = useState(null);
//   const [user, setUser] = useState(null);

//   const toggleModal = (productId,userId) => {
//     setModal(!modal);
//     setProductId(productId);
//     setUser(userId);
//   };


//   return (
//     <div className="mb-6 p-6 bg-white rounded-lg shadow-lg transition-transform transform hover:-translate-y-1">
//       <div className="flex justify-between items-center mb-4">
//         <div>
//           <p className="text-sm text-gray-500">Order ID:</p>
//           <p className="text-xl font-semibold text-gray-800">{order._id}</p>
//         </div>
//         <button
//           onClick={() => setShowProducts(!showProducts)}
//           className="text-blue-500 hover:underline focus:outline-none"
//         >
//           {showProducts ? 'Hide Details' : 'View Details'}
//         </button>
//       </div>

//       <div className="grid grid-cols-2 gap-4">
//         <div>
//           <p className="text-sm text-gray-500">Order Status:</p>
//           <p className={`text-lg font-semibold ${
//             order.status === 'completed' ? 'text-green-500' : order.status === 'processing' ? 'text-yellow-500' : 'text-gray-700'
//           }`}>
//             {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
//           </p>
//         </div>
//         <div>
//           <p className="text-sm text-gray-500">Payment Status:</p>
//           <p className={`text-lg font-semibold ${
//             order.paymentStatus === 'Paid' ? 'text-green-500' : 'text-red-500'
//           }`}>
//             {order.paymentStatus}
//           </p>
//         </div>
//         <div>
//           <p className="text-sm text-gray-500">Total Price:</p>
//           <p className="text-lg font-semibold text-blue-500">Tk. {order.totalPrice.toFixed(2)}</p>
//         </div>
//       </div>

//       {/* Products List - Collapsible */}
//       {showProducts && (
//         <div className="mt-4 border-t pt-4">
//           <h3 className="text-lg font-semibold mb-3 text-gray-700">Products:</h3>
//           {order.products.map(product => (
//             <div key={product.id} className="flex items-center justify-between mb-4 p-3 bg-gray-50 rounded-lg shadow-inner">
//               <div className="flex items-center">
//                 <img src={product.image} alt={product.title} className="w-12 h-12 rounded mr-4 object-cover shadow-md" />
//                 <div>
//                   <p className="text-gray-800 font-medium">{product.title}</p>
//                   <p className="text-sm text-gray-500">Quantity: {product.quantity}</p>
//                   <p className="text-sm text-gray-500">Price: TK. {product?.soldPrice.toFixed(2)}</p>
//                   {
//                     product.color && <p className="text-sm text-gray-500">Color: {product.color}</p>
                    
//                   }
//                   {
//                     product.size && <p className="text-sm text-gray-500">Size: {product.size}</p>
//                   }
//                 </div>
//               </div>
//               {/* <div className="flex items-center">
//                 <p className="text-lg font-semibold mr-4 text-gray-800">Total Tk.{order?.totalPrice.toFixed(2)}</p>
//                 {order.status === 'completed' && (
//                   <button onClick={() => toggleModal(product.id,order.user)} className="text-blue-500 text-sm underline hover:text-blue-700">
//                     Add Review
//                   </button>
//                 )}
//               </div> */}
//             </div>
//           ))}
//         </div>
//       )}
//       {
//         modal && <Modal title="Add Review" isOpen={modal} onClose={toggleModal}>
//           <AddReview userId={user} productId={productId} />
//         </Modal>
//       }
//     </div>
//   );
// };

// export default OrderFromResellerStore;



