import React, { useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa"; // React Icons for edit action
import { getBonusesByAdmin } from "../../features/bonus/bonusSlice";
import { useDispatch, useSelector } from "react-redux";
import LoaderComponent from "../LoaderComponent";
import BonusForm from "./BonusForm";
import Modal from "../common/Modal";
import Pagination from "../Pagination";

const BonusList = () => {

    const { bonuses,pagination, loading } = useSelector((state) => state.bonus);
    const [showForm, setShowForm] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [selectedBonus, setSelectedBonus] = useState(null);
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
 

  useEffect(() => {
    dispatch(getBonusesByAdmin({
      page,
      limit: 10
    }));
  }, [dispatch, page]);

  // Handle navigation to create or edit bonus
  const handleCreateBonus = () => {
    setShowForm(true);
  };

  const handleEditBonus = (bonus) => {
    setSelectedBonus(bonus);
    setIsEditing(true);
  };

  if(loading) return <LoaderComponent />

  return (
    <div className="p-6 bg-white rounded shadow-md">
      {/* Header */}
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-lg font-bold">Bonuses</h2>
        <button
          onClick={handleCreateBonus}
          className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
        >
          Create Bonus
        </button>
      </div>

      {/* Bonus Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white border rounded">
          <thead>
            <tr className="bg-gray-200 text-gray-600 text-sm uppercase font-semibold">
              <th className="py-3 px-6 text-left">SL No</th>
              <th className="py-3 px-6 text-left">User Type</th>
              <th className="py-3 px-6 text-left">Name</th>
              <th className="py-3 px-6 text-right">Amount</th>
              <th className="py-3 px-6 text-center">Notes</th>
              <th className="py-3 px-6 text-center">Date</th>
              <th className="py-3 px-6 text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
             {bonuses?.length > 0 ? (
              bonuses.map((bonus,index) => (
                <tr
                  key={bonus._id}
                  className="border-b hover:bg-gray-100 transition"
                >
                  <td className="py-3 px-6">{index + 1}</td>
                  <td className="py-3 px-6">{bonus.userType}</td>
                  <td className="py-3 px-6">{bonus.name}</td>
                  <td className="py-3 px-6 text-right">{bonus.amount}</td>
                  <td className="py-3 px-6">{bonus.notes}</td>
                  <td className="py-3 px-6">{new Date(bonus.createdAt).toLocaleDateString()}</td>
                  <td className="py-3 px-6 text-center">
                    <button
                      onClick={() => handleEditBonus(bonus)}
                      className="text-blue-500 hover:text-blue-700"
                    >
                      <FaEdit />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center py-4">
                  No bonuses found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      
      <Pagination currentPage={page} totalPages={pagination?.totalPages} onPageChange={setPage} />

      {
        showForm && (
            <Modal title={"Create Bonus"} isOpen={showForm} onClose={() => setShowForm(false)}>
                <BonusForm setShowForm={() => setShowForm(false)} />
            </Modal>
        )
      }
      {
        isEditing && (
            <Modal title={"Edit Bonus"} isOpen={isEditing} onClose={() => setIsEditing(false)}>
                <BonusForm setShowForm={() => setIsEditing(false)} initialValue={selectedBonus} mode="edit" />
            </Modal>
        )
      }
    </div>
  );
};

export default BonusList;
