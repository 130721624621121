import React, { useRef } from "react";

const Invoice = ({ data }) => {
  const invoiceRef = useRef(); // Reference to the printable section

  const handlePrint = () => {
    const printContent = invoiceRef.current; // Grab the printable section
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
      <html>
        <head>
          <title>Invoice</title>
          <style>
            @media print {
             .no-print {
                display: none !important;
            }
              body {
                margin: 0;
                margin-top: 40px;
                font-family: Arial, sans-serif;
                -webkit-print-color-adjust: exact; /* Ensure colors are printed correctly */
              }
             .title {
                background-color: black;
                color: white;
                padding: 10px;
             }
              table {
                width: 100% !important;
                border-collapse: collapse !important;
              }
              th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
              }
              .flex {
                display: flex !important;
              }
              .justify-between {
                justify-content: space-between !important;
              }
              .items-center {
                align-items: center !important;
              }
              .grid {
                display: grid !important;
                grid-template-columns: repeat(2, 1fr) !important;
              }
              .bg-gray-100 {
                background-color: #f7fafc !important; /* Light gray background */
              }
              .text-gray-600 {
                color: #4a5568 !important; /* Dark gray text */
              }
              .font-semibold {
                font-weight: 600 !important; /* Bold text */
              }
              .border {
                border: 1px solid black !important; /* Ensure borders are printed */
              }
              .invoice-header {
                margin-bottom: 20px; /* Space between header and content */
              }
              .text-center {
                text-align: center !important;
              }
            }
          </style>
        </head>
        <body>${printContent.outerHTML}</body>
      </html>
    `);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
    printWindow.close();
  };

  const {
    invoiceNo,
    date,
    customerName,
    contactNo,
    paymentMethod,
    deliveryMethod,
    deliveryAddress,
    items,
    totals,
    helpLine,
    officeAddress,
    website,
  } = data;

  return (
    <div className="max-w-4xl mx-auto p-6 border border-gray-300 shadow-md bg-white">
      {/* Print Button */}
      <div className="flex justify-end mb-4">
        <button
          onClick={handlePrint}
          className="px-4 py-2 bg-blue-500 text-white rounded-md shadow hover:bg-blue-600"
        >
          Print Invoice
        </button>
      </div>

      {/* Printable Section */}
      <div ref={invoiceRef}>
        {/* Header Section */}
        <h2 className="text-2xl font-bold mb-4 title text-center">Invoice</h2>
        <div className="flex items-center justify-between mb-6 invoice-header">
          {/* Logo Section and company info */}
          <div>
            <div>
              <img src="https://res.cloudinary.com/dhhojzx73/image/upload/v1735724633/product-images/ghjki57m833or6guzssp.png" alt="Company Logo"  className="w-48" />
            </div>
            {/* Company Info Section */}
            <div className="mb-6">
              <p className="text-sm text-gray-600">
                <span className="font-semibold">Help Line:</span> {helpLine}
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-semibold">Office Address:</span>{" "}
                {officeAddress}
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-semibold">E-mail:</span>{" "}
                info@paribarmart.com
              </p>
              <p className="text-sm text-gray-600">
                <span className="font-semibold">Website:</span> {website}
              </p>
            </div>
          </div>
          {/* Invoice Number and Date Section */}
          <div>
            <div className="flex items-center justify-center bg-gray-100">
              <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                <div className="grid grid-cols-2">
                  <div className="border p-2 text-center">
                    <p>Invoice No:</p>
                    <p className="font-bold">{invoiceNo}</p>
                  </div>
                  <div className="border p-2 text-center">
                    <p>Date:</p>
                    <p className="font-bold">{date}</p>
                  </div>
                  <div className="border p-2 text-center">
                    <p>Customer Name:</p>
                    <p className="font-bold">{customerName}</p>
                  </div>
                  <div className="border p-2 text-center">
                    <p>Contact No:</p>
                    <p className="font-bold">{contactNo}</p>
                  </div>
                  <div className="border p-2 text-center">
                    <p>Payment Method:</p>
                    <p className="font-bold">{paymentMethod}</p>
                  </div>
                  <div className="border p-2 text-center">
                    <p>
                      Delivery Method:{" "}
                      <span className="text-red-500">{deliveryMethod}</span>
                    </p>
                  </div>
                  <div className="border p-2 text-center">
                    <p>Delivered by:</p>
                    <p className="font-bold">N/A</p>
                  </div>
                  <div className="border p-2 text-center">
                    <p>Delivery Address:</p>
                    <p className="font-bold">{deliveryAddress}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Table Section */}
        <div className="overflow-x-auto mb-6">
          <table className="min-w-full border-collapse border border-gray-400">
            <thead>
              <tr>
                <th className="border border-gray-400 p-2">Sl</th>
                <th className="border border-gray-400 p-2">Img</th>
                <th className="border border-gray-400 p-2">Description of Goods</th>
                <th className="border border-gray-400 p-2">Quantity</th>
                <th className="border border-gray-400 p-2">Rate</th>
                <th className="border border-gray-400 p-2">Amount (TK)</th>
              </tr>
            </thead>
            <tbody>
              {items.map((item, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="border border-gray-400 p-2 text-center">
                    {index + 1}
                  </td>
                  <td className="border border-gray-400 p-2 text-center">
                    <img
                      alt="Product-image-placeholder"
                      className="mx-auto"
                      height="50"
                      src={item.image}
                      width="50"
                    />
                  </td>
                  <td className="border border-gray-400 p-2">
                    {item.title} <br />
                    code: {item.code} <br />
                    {item.color && <span>color: {item.color}</span>} <br />
                    {item.size && <span>size: {item.size}</span>}
                  </td>
                  <td className="border border-gray-400 p-2 text-center">
                    {item.quantity}
                  </td>
                  <td className="border border-gray-400 p-2 text-right">
                    {item.rate}
                  </td>
                  <td className="border border-gray-400 p-2 text-right">
                    {item.amount}
                  </td>
                </tr>
              ))}

              <tr>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
              </tr>
              <tr>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-right">Total=</td>
                <td className="border border-gray-400 p-2 text-center">
                  {totals.total} TK
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-right">Discounts</td>
                <td className="border border-gray-400 p-2 text-center">
                  {totals.discount} Tk
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-right">VAT [+]=</td>
                <td className="border border-gray-400 p-2 text-center">
                  {totals.vat} Tk
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-right">
                  Delivery Charge [+]=
                </td>
                <td className="border border-gray-400 p-2 text-center">
                  {totals.deliveryCharge} TK
                </td>Name
              </tr>
              <tr>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-right">
                  Grand Total=
                </td>
                <td className="border border-gray-400 p-2 text-center">
                  {totals.grandTotal} Tk
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-right">Paid=</td>
                <td className="border border-gray-400 p-2 text-center">
                  {totals.paid} TK
                </td>
              </tr>
              <tr>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-center">-</td>
                <td className="border border-gray-400 p-2 text-right">Balance =</td>
                <td className="border border-gray-400 p-2 text-center">
                  {totals.balance} TK
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
