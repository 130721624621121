import { useState } from "react";
import ReviewCard from "./ReviewCard";

const DescriptionAndReviews = ({ reviews, description, condition }) => {
  const [activeTab, setActiveTab] = useState("descriptions");

  const descriptions = description.split(".");
  const conditions = condition.split(".");

  return (
    <div className="w-full max-w-[600px] mx-auto mt-4 md:mt-10 px-4">
      {/* Tabs Section */}
      <div className="flex justify-center mb-6">
        <button
          onClick={() => setActiveTab("descriptions")}
          className={`w-full p-2 rounded text-sm md:px-6 md:py-3 font-semibold text-lg ${
            activeTab === "descriptions"
              ? "border bg-blue-600 text-black-600"
              : "text-black-600 bg-green-200"
          } transition-all duration-200`}
        >
          Descriptions
        </button>
        <button
          onClick={() => setActiveTab("reviews")}
          className={`w-full p-2 rounded text-sm md:px-6 md:py-3 font-semibold text-lg ${
            activeTab === "reviews"
              ? "border bg-blue-600 text-black-600 bg-blue-400"
              : "text-black-600 bg-blue-200"
          } transition-all duration-200`}
        >
          Reviews
        </button>
        <button
          onClick={() => setActiveTab("conditions")}
          className={`w-full p-2 rounded text-sm md:px-6 md:py-3 font-semibold text-lg ${
            activeTab === "conditions"
              ? "border bg-blue-600 text-black-600"
              : "text-black-600 bg-red-400"
          } transition-all duration-200`}
        >
          Conditions
        </button>
      </div>

      {/* Content Section */}
      <div className="relative flex flex-col items-center">
        {activeTab === "reviews" && (
          <div className="flex flex-col space-y-4 w-full">
            {reviews && reviews.length > 0 ? (
              reviews.map((review) => (
                <ReviewCard key={review._id} review={review} />
              ))
            ) : (
              <p className="text-center text-lg text-black-600">
                No reviews yet.
              </p>
            )}
          </div>
        )}
        {activeTab === "descriptions" && (
          <div className="flex flex-col space-y-4 w-full">
            {descriptions &&
              descriptions.map((desc, index) => (
                <p key={index} className="text-black-600 text-lg text-justify m-0 p-0">
                  {desc}
                </p>
              ))}
          </div>
        )}
        {activeTab === "conditions" && (
          <div className="flex flex-col space-y-4 w-full">
            {conditions &&
              conditions.map((condition, index) => (
                <p key={index} className="text-black-600 text-lg text-justify">
                  {condition}
                </p>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DescriptionAndReviews;
