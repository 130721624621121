import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";
import { toast } from "react-toastify";


// fetch reseller store
export const getResellerStore = createAsyncThunk('resellerStore/getResellerStore', async ({storeId,page=1,limit=10}) => {
    const response = await api.get(`/reseller/stores/${storeId}?page=${page}&limit=${limit}`);
   
    return response.data;
});

// get single product from reseller store
export const getSingleProductFromResellerStore = createAsyncThunk('resellerStore/getSingleProductFromResellerStore', async ({storeId,productTitle}) => {
    const response = await api.get(`/reseller/stores/${storeId}/products/${productTitle}`);
    
    return response.data;
})

// add product to reseller store
export const addProductToResellerStore = createAsyncThunk('resellerStore/addProductToResellerStore', async ({storeData,token}) => {
    const response = await api.post(`/reseller/stores`,storeData, {

        headers: {
            "Content-Type":"application/json",
            "Authorization": `Bearer ${token}}`
        }

    });

    if(response.status === 200){
        toast.success("Product added successfully to your store");
    }
    
    return response.data;
});

// get store By reseller by reseller id
export const getStoreByResellerId = createAsyncThunk('resellerStore/getStoreByResellerId', async ({resellerId,token,page=1,limit=10}) => {
    const response = await api.get(`/reseller/stores/by-reseller/${resellerId}?page=${page}&limit=${limit}`,{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    return response.data;
});

// remove product from reseller store
export const removeProductFromResellerStore = createAsyncThunk('resellerStore/removeProductFromResellerStore', async ({productId,storeId,token}) => {
    const response = await api.delete(`/reseller/stores/${storeId}/products/${productId}`,{
        headers: {
            "Authorization": `Bearer ${token}`
        }
    });
    if(response.status === 200){
        toast.success("Product removed successfully from your store");
    }
    return response.data;
})

// update reseller store
export const updateResellerStore = createAsyncThunk('resellerStore/updateResellerStore', async ({storeId,storeData,token}) => {
    const response = await api.put(`/reseller/stores/${storeId}`,storeData, {
        headers: {
            "Content-Type":"application/json",
            "Authorization": `Bearer ${token}`
        }
    });
    if(response.status === 200){
        toast.success("Store updated successfully");
    }
    return response.data;
})

// get recommendation products from reseller store
export const getRecommendationProductsFromResellerStore = createAsyncThunk('resellerStore/getRecommendationProductsFromResellerStore', async ({category,productId, storeId}) => {
    const response = await api.get(`/reseller/stores/${storeId}/recommended-products?categoryIds=${category}&productId=${productId}&storeId=${storeId}`);
    return response.data;
})


const resellerStoreSlice = createSlice({
    name: 'resellerStore',
    initialState: {
        store: null,
        products: [],
        singleProductOfStore: null,
        recommendedProducts: null,
        hasMore:false,
        pagination: {},
        loading: false,
        error: null,
    },
    reducers: { 
        resetProducts: (state) => {
            state.products = []; // Clear products
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(getResellerStore.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(getResellerStore.fulfilled, (state, action) => {
            state.store = action.payload.resellerStore;
            state.products = [...state.products, ...action.payload.resellerStore?.products];
            state.hasMore = action.payload.pagination?.next > action.payload.pagination?.page;
            state.loading = false;
            state.error = null;
        })
        .addCase(getResellerStore.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(getSingleProductFromResellerStore.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(getSingleProductFromResellerStore.fulfilled, (state, action) => {
            state.singleProductOfStore = action.payload;
            state.loading = false;
            state.error = null;
        })
        .addCase(getSingleProductFromResellerStore.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(addProductToResellerStore.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(addProductToResellerStore.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            // state.store = action.payload;
        })
        .addCase(addProductToResellerStore.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(getStoreByResellerId.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(getStoreByResellerId.fulfilled, (state, action) => {
            state.store = action.payload.resellerStore;
            state.pagination = action.payload.pagination;
            state.loading = false;
            state.error = null;
        })
        .addCase(getStoreByResellerId.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(removeProductFromResellerStore.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(removeProductFromResellerStore.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        })
        .addCase(removeProductFromResellerStore.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(updateResellerStore.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(updateResellerStore.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
        })
        .addCase(updateResellerStore.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })
        .addCase(getRecommendationProductsFromResellerStore.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
        .addCase(getRecommendationProductsFromResellerStore.fulfilled, (state, action) => {
            state.recommendedProducts = action.payload;
            state.loading = false;
            state.error = null;
        })
        .addCase(getRecommendationProductsFromResellerStore.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        })

    },
});
export const { resetProducts } = resellerStoreSlice.actions;
export default resellerStoreSlice.reducer;