
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import productsReducer from '../features/products/productsSlice';
import authReducer from '../features/auth/authSlice';
import userReducer from '../features/user/userSlice';
import categoryReducer from '../features/category/categorySlice';
import subcategoryReducer from '../features/subcategory/subcategorySlice';
import executiveReducer from '../features/executive/executiveSlice';
import dashboardReducer from '../features/dashboard/dashboardSlice';
import orderReducer from '../features/orders/ordersSlice';
import affiliateReducer from '../features/affiliate/affiliateSlice';
import withdrawalMethodReducer from '../features/withdrawalMethod/withdrawalSlice';
import packageReducer from '../features/package/packageSlice';
import subscriptionOrderReducer from '../features/subscriptionOrder/subscriptionOrderSlice';
import resellerProductReducer from '../features/resellerProducts/resellerProductSlice';
import metaDataReducer from '../features/metaData/metaDataSlice';
import videoCourseReducer from '../features/videoCourse/videoCourseSlice';
import resellerStoreReducer from '../features/resellerStore/resellerStoreSlice';
import contactSlice from '../features/contact/contactSlice';
import orderBonusReducer from '../features/orderBonus/orderBonusSlice';
import headlineReducer from '../features/headline/headlineSlice';
import sliderImageReducer from '../features/sliderImage/sliderImageSlice';
import referAmountReducer from '../features/referAmount/referAmountSlice';
import ServiceChargeReducer from '../features/serviceCharge/serviceChargeSlice';
import withdrawalRequestReducer from '../features/withdrawalRequest/withdrawalRequestSlice';
import productRequestReducer from '../features/productRequest/productRequestSlice';
import allReferralReducer from '../features/allReferrals/allReferralSlice';
import forgotPasswordReducer from '../features/forgotPassword/forgotPasswordSlice';
import reviewReducer from '../features/reviews/reviewSlice';
import paymentOptionsReducer from '../features/paymentOptions/paymentOptionSlice';
import offerReducer from '../features/offers/offerSlice';
import uploadImageReducer from '../features/uploadImages/uploadImageSlice';
import bonusReducer from '../features/bonus/bonusSlice';
import phoneOrderDetailsReducer from '../features/phoneOrderDetails/phoneOrderSlice';
import navbarReducer from '../features/navbar/navbarSlice';

// Combine all reducers
export const rootReducer = combineReducers({
  products: productsReducer,
  auth: authReducer,
  user: userReducer,
  category: categoryReducer,
  subcategory: subcategoryReducer,
  executive: executiveReducer,
  dashboard: dashboardReducer,
  orders: orderReducer,
  affiliate: affiliateReducer,
  withdrawal: withdrawalMethodReducer,
  withdrawalRequest: withdrawalRequestReducer,
  packages: packageReducer,
  subscriptionOrders: subscriptionOrderReducer,
  resellerProducts: resellerProductReducer,
  metaData: metaDataReducer,
  videoCourse: videoCourseReducer,
  resellerStore: resellerStoreReducer,
  contact: contactSlice,
  orderBonus: orderBonusReducer,
  headline: headlineReducer,
  sliderImage: sliderImageReducer,
  referAmount: referAmountReducer,
  serviceCharge: ServiceChargeReducer,
  productRequest: productRequestReducer,
  allReferral: allReferralReducer,
  forgotPassword: forgotPasswordReducer,
  review: reviewReducer,
  paymentOptions: paymentOptionsReducer,
  offer: offerReducer,
  images: uploadImageReducer,
  navbar: navbarReducer,
  bonus: bonusReducer,
  phoneOrderDetails: phoneOrderDetailsReducer,

});

// create a custom noop storage
// const createNoopStorage = () => {
//   return {
//     getItem: (_key) => Promise.resolve(null),
//     setItem: (_key, _value) => Promise.resolve(),
//     removeItem: (_key) => Promise.resolve(),
//   };
// };

// Create a noop storage for development
const isServer = typeof window === "undefined";

// const storageEngine = isServer ? createNoopStorage() : storage;

// Create a persist config for the root reducer
const persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['auth',], // Specify only the auth slice to be persisted
};

// Wrap the root reducer with persistReducer
export const persistedReducer = persistReducer(persistConfig, rootReducer);



// Create a non-persistent store for the server
export const setupStore = (preloadedState={}) =>
  configureStore({
    reducer: isServer ? rootReducer : persistedReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
        },
      }),
    preloadedState,
  });

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE'],
      },
    }),
});

// Create persistor
export const persistor = isServer ? null : persistStore(store);



