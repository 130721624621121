import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { createOrder,createOrderByReseller } from "../features/orders/ordersSlice";
import { useDispatch, useSelector } from "react-redux";
import { resetSuccess } from "../features/orders/ordersSlice";
import { getCurrentServiceCharge } from "../features/serviceCharge/serviceChargeSlice";
import LoaderComponent from "../components/LoaderComponent";
import { toast } from "react-toastify";

// Validation Schema
const CheckoutSchema = Yup.object().shape({
  full_name: Yup.string().required("Full Name is required"),
  phone: Yup.string().required("Phone is required"),
  region: Yup.string().required("Region is required"),
  city: Yup.string().required("City is required"),
  postal_code: Yup.string().required("Postal Code is required"),
  zone: Yup.string().required("Zone is required"),
  address: Yup.string().required("Address is required"),
  paymentMethod: Yup.string().required("Please choose a payment option"),
});

const Checkout = () => {
  const { loading, createdOrder: orderResponse } = useSelector((state) => state.orders);
  const { currentServiceCharge, loading: serviceChargeLoading } = useSelector((state) => state.serviceCharge);
  const [useBillingAsShipping, setUseBillingAsShipping] = useState(true);
  const [selectedArea, setSelectedArea] = useState(null);
  const [serviceCharge, setServiceCharge] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { products, totalPrice, affiliateCode, reseller,orderByReseller } = location.state || {};
  
  // fetch service charge
  useEffect(() => {
    dispatch(getCurrentServiceCharge());
  }, [dispatch]);

  // if the user didn't come from the cart page or single product page , redirect them to the products page
  useEffect(() => {
    if (!products) {
      navigate("/shop/products");
    }
  }, [products, navigate]);

  useEffect(() => {
    if (orderResponse) {
      // remove ref code
      localStorage.removeItem("ref_code");
      // remove cart items
      localStorage.removeItem("cart");
      // If the order is created successfully, redirect to the order details page
      dispatch(resetSuccess());
      if(orderByReseller){
        navigate(`/reseller/dashboard`, { state: { active: "orders" } });
      } else {
        navigate(`/user/dashboard`, { state: { active: "orders" } });
      }
    }
  }, [orderResponse, navigate, dispatch, orderByReseller]);

  const initialValues = {
    full_name: "",
    phone: "",
    region: "",
    city: "",
    postal_code: "",
    zone: "",
    address: "",
    paymentMethod: "",
    shippingFullName: "",
    shippingPhone: "",
    shippingRegion: "",
    shippingCity: "",
    shippingPostalCode: "",
    shippingZone: "",
    shippingAddress: "",
  };

   // Handle area selection
   const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const selected = currentServiceCharge.find((area) => area._id === selectedId);
    setSelectedArea(selected?.name || null);
    setServiceCharge(selected?.amount || 0);
  };

  const handleSubmit = async (values, { resetForm }) => {
    const orderDetails = {
      products,
      billingAddress: {
        fullName: values.full_name,
        phone: values.phone,
        region: values.region,
        city: values.city,
        postalCode: values.postal_code,
        zone: values.zone,
        address: values.address,
      },
      shippingAddress: useBillingAsShipping
        ? { fullName: values.full_name,
          phone: values.phone,
          region: values.region,
          city: values.city,
          postalCode: values.postal_code,
          zone: values.zone,
          address: values.address, }
        : {
            fullName: values.shippingFullName,
            phone: values.shippingPhone,
            region: values.shippingRegion,
            city: values.shippingCity,
            postalCode: values.shippingPostalCode,
            zone: values.shippingZone,
            address: values.shippingAddress,
          },
      paymentMethod: values.paymentMethod,
      totalPrice: totalPrice,
      deliveryCharge: serviceCharge,
    };

    if (affiliateCode) {
      orderDetails.affiliateCode = affiliateCode;
    }

    if (reseller) {
      orderDetails.reseller = reseller;
    }

    if (orderByReseller) {
      orderDetails.orderByReseller = orderByReseller;
    }

    // Process this in your backend to create an order
    try {
      if(orderByReseller){
        await dispatch(createOrderByReseller({ order: orderDetails, token: JSON.parse(localStorage.getItem("token")) })).unwrap(); 
        resetForm();
      }else {

        await dispatch(createOrder({ order: orderDetails, token: JSON.parse(localStorage.getItem("token")) })).unwrap();
        resetForm();
        
      }
    } catch (error) {
      console.error("Failed to create order:", error); // Log any error
      toast.error("Failed to create order. Please try again.");
    }
  };
  if (serviceChargeLoading) return <LoaderComponent />
  return (
    <div className="max-w-4xl mx-auto p-8 rounded-xl shadow-xl">
      <h2 className="text-3xl font-semibold text-center text-gray-800 mb-8">Checkout</h2>

      {/* Product Details Section */}
      <div className="bg-gray-50 p-6 rounded-lg shadow-md mb-6">
        <h3 className="text-2xl font-medium text-gray-700 mb-4">Order Summary</h3>
        {products &&
          products.map((product) => (
            <div key={product.id} className="py-2 border-b border-gray-300">
              <h3 className="text-gray-600">{product.title}</h3>
              <h3 className="text-gray-800 font-semibold">
                {product.quantity} x TK. {product.price} = TK. {product.price * product.quantity}
              </h3>
            </div>
          ))}
        <p className="sm:text-sm md:text-xl font-semibold mt-4 text-gray-800">Product-Total: TK. {totalPrice}</p>
        {
          serviceCharge && (
          <>
          <p className="sm:text-sm md:text-xl font-semibold mt-4 text-blue-600">Service-Charge: TK. {serviceCharge}</p>
        <p className="text-xl font-semibold mt-4 text-gray-800">Total: TK. {totalPrice + serviceCharge}</p>
        </>
          )
        }

      </div>
      <div className="bg-gray-50 p-6 rounded-lg shadow-md mb-6">
        <h1 className="text-2xl font-bold mb-4 text-center">
        Select Your Area
      </h1>
      <div className="mb-4">
        <label
          htmlFor="area"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Area Name
        </label>
        <select
          id="area"
          className="w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 p-2"
          onChange={handleSelectChange}
        >
          <option value="">Select an area</option>
          {currentServiceCharge?.map((area) => (
            <option key={area._id} value={area._id}>
              {area.name}
            </option>
          ))}
        </select>
      </div>
      {selectedArea && (
        <div className="mt-4 p-4 bg-blue-50 border border-blue-200 rounded-md">
          <h2 className="text-xl font-semibold">
            Selected Area: <span className="text-blue-600">{selectedArea}</span>
          </h2>
          <p className="mt-2 text-lg">
            Service Charge: <span className="font-bold">TK.{serviceCharge}</span>
          </p>
        </div>
      )}
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={CheckoutSchema}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue }) => (
          <Form>
            {/* Billing Address */}
            <div className="bg-gray-50 p-6 rounded-lg shadow-md mb-6">
              <h3 className="text-2xl font-medium text-gray-700 mb-6">Billing Address</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block mb-1 font-medium text-gray-600">Full Name</label>
                  <Field
                    name="full_name"
                    type="text"
                    className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <ErrorMessage name="full_name" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                <div>
                  <label className="block mb-1 font-medium text-gray-600">Phone</label>
                  <Field
                    name="phone"
                    type="text"
                    className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <ErrorMessage name="phone" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                <div>
                  <label className="block mb-1 font-medium text-gray-600">Region/Country</label>
                  <Field
                    name="region"
                    type="text"
                    className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <ErrorMessage name="region" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                <div>
                  <label className="block mb-1 font-medium text-gray-600">City/District</label>
                  <Field
                    name="city"
                    type="text"
                    className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <ErrorMessage name="city" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                <div>
                  <label className="block mb-1 font-medium text-gray-600">Postal Code</label>
                  <Field
                    name="postal_code"
                    type="text"
                    className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <ErrorMessage name="postal_code" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                <div>
                  <label className="block mb-1 font-medium text-gray-600">Zone/Thana</label>
                  <Field
                    name="zone"
                    type="text"
                    className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <ErrorMessage name="zone" component="div" className="text-red-500 text-sm mt-1" />
                </div>

                <div className="col-span-2">
                  <label className="block mb-1 font-medium text-gray-600">Address</label>
                  <Field
                    name="address"
                    type="text"
                    className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <ErrorMessage name="address" component="div" className="text-red-500 text-sm mt-1" />
                </div>
              </div>
            </div>

            {/* Shipping Address */}
            <div className="bg-gray-50 p-6 rounded-lg shadow-md mb-6">
              <div className="flex items-center mb-4">
                <h3 className="text-2xl font-medium text-gray-700 flex-grow">Shipping Address</h3>
                <label className="flex items-center text-sm font-medium text-gray-600">
                  <input
                    type="checkbox"
                    checked={useBillingAsShipping}
                    onChange={() => {
                      setUseBillingAsShipping(!useBillingAsShipping);
                      if (!useBillingAsShipping) {
                        setFieldValue("shippingFullName", values.full_name);
                        setFieldValue("shippingPhone", values.phone);
                        setFieldValue("shippingRegion", values.region);
                        setFieldValue("shippingCity", values.city);
                        setFieldValue("shippingPostalCode", values.postal_code);
                        setFieldValue("shippingZone", values.zone);
                        setFieldValue("shippingAddress", values.address);
                      }
                    }}
                    className="mr-2"
                  />
                  Use billing address
                </label>
              </div>

              {!useBillingAsShipping && (
                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block mb-1 font-medium text-gray-600">Full Name</label>
                    <Field
                      name="shippingFullName"
                      type="text"
                      className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>

                  <div>
                    <label className="block mb-1 font-medium text-gray-600">Phone</label>
                    <Field
                      name="shippingPhone"
                      type="text"
                      className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>

                  <div>
                    <label className="block mb-1 font-medium text-gray-600">Region/Country</label>
                    <Field
                      name="shippingRegion"
                      type="text"
                      className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>

                  <div>
                    <label className="block mb-1 font-medium text-gray-600">City/District</label>
                    <Field
                      name="shippingCity"
                      type="text"
                      className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>

                  <div>
                    <label className="block mb-1 font-medium text-gray-600">Postal Code</label>
                    <Field
                      name="shippingPostalCode"
                      type="text"
                      className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>

                  <div>
                    <label className="block mb-1 font-medium text-gray-600">Zone/Thana</label>
                    <Field
                      name="shippingZone"
                      type="text"
                      className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>

                  <div className="col-span-2">
                    <label className="block mb-1 font-medium text-gray-600">Address</label>
                    <Field
                      name="shippingAddress"
                      type="text"
                      className="w-full p-3 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                </div>
              )}
            </div>

            {/* Payment Method */}
            <div className="mb-6">
              <h3 className="text-2xl font-medium text-gray-700 mb-4">Payment Method</h3>
              <div className="flex gap-4">
                <label>
                  <Field
                    type="radio"
                    name="paymentMethod"
                    value="cash-on-delivery"
                    className="mr-2"
                  />
                  Cash on Delivery
                </label>
                <label>
                  <Field
                    disabled
                    type="radio"
                    name="paymentMethod"
                    value="online-payment"
                    className="mr-2"
                  />
                  Online Payment ( this feature is not available yet )
                </label>
              </div>
              <ErrorMessage name="paymentMethod" component="div" className="text-red-500 text-sm mt-1" />
            </div>

            {/* Submit Button */}
            <div className="text-center">
              <button
                type="submit"
                className="w-full py-3 px-6 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700 transition-colors"
                disabled={loading}
              >
                {loading ? "Processing..." : "Place Order"}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Checkout;





















// import React, { useEffect, useState } from "react";
// import { Formik, Form, Field, ErrorMessage } from "formik";
// import * as Yup from "yup";
// import { useLocation, useNavigate } from "react-router-dom";
// import { createOrder } from "../features/orders/ordersSlice";
// import { useDispatch, useSelector } from "react-redux";

// // Validation Schema
// const CheckoutSchema = Yup.object().shape({
//   full_name: Yup.string().required("Full Name is required"),
//   phone: Yup.string().required("Phone is required"),
//   region: Yup.string().required("Region is required"),
//   city: Yup.string().required("City is required"),
//   postal_code: Yup.string().required("Postal Code is required"),
//   zone: Yup.string().required("Zone is required"),
//   address: Yup.string().required("Address is required"),
//   paymentMethod: Yup.string().required("Please choose a payment option"),
// });

// const Checkout = () => {

//   const {loading, createdOrder: orderResponse} = useSelector((state) => state.orders);
//   const [useBillingAsShipping, setUseBillingAsShipping] = useState(true);
//   const location = useLocation();
//   const navigate = useNavigate();
//   const  dispatch = useDispatch();


//   const { products, total_price, affiliate_code, reseller } = location.state || {};

//   useEffect(() => {
//     if (!products) {
//       navigate("/products");
//     }
//   }, [products, navigate]);

//   //@TODo . remove useEffect or add logic if the payment option is online banking
//   useEffect(() => {
//     if (orderResponse) {
//       // remove ref code
//       localStorage.removeItem('ref_code');
//       // remove cart items
//       localStorage.removeItem('cart');
//       // If the order is created successfully, redirect to the order details page
//       navigate(`/users/profile`, { state: {active: 'orders'} });
//     }
//   }, [orderResponse, navigate]);

  

//   const initialValues = {
//     full_name: "",
//     phone: "",
//     region: "",
//     city: "",
//     postal_code: "",
//     zone: "",
//     address: "",
//     paymentMethod: "",
//     shippingFullName: "",
//     shippingPhone: "",
//     shippingRegion: "",
//     shippingCity: "",
//     shippingPostalCode: "",
//     shippingZone: "",
//     shippingAddress: "",
//   };

//   const handleSubmit = async (values,{ resetForm }) => {
//     const orderDetails = {
//       products,
//       billing_address: {
//         full_name: values.full_name,
//         phone: values.phone,
//         region: values.region,
//         city: values.city,
//         postal_code: values.postal_code,
//         zone: values.zone,
//         address: values.address,
//       },
//       shipping_address: useBillingAsShipping
//         ? { ...values }
//         : {
//             full_name: values.shippingFullName,
//             phone: values.shippingPhone,
//             region: values.shippingRegion,
//             city: values.shippingCity,
//             postal_code: values.shippingPostalCode,
//             zone: values.shippingZone,
//             address: values.shippingAddress,
//           },
//       payment_method: values.paymentMethod,
//       total_price
//     };

//     if (affiliate_code) {
//       orderDetails.affiliate_code = affiliate_code;
//     }

//     if (reseller) {
//       orderDetails.reseller = reseller;
//     }

//      // Process this in your backend to create an order
//     try {
//       await dispatch(createOrder({order:orderDetails,token:JSON.parse(localStorage.getItem("token"))})).unwrap(); // Assuming createOrder returns a promise
//       resetForm();
      
//     } catch (error) {
//       console.error("Failed to create order:", error); // Log any error
//     }

//   };

//   return (
//     <div className="max-w-4xl mx-auto p-6 bg-gray-100 rounded-lg shadow-lg">
//       <h2 className="text-2xl font-bold mb-6">Checkout</h2>

//       {/* Product Details Section */}
//       <div className="bg-white p-6 rounded-lg shadow-md mb-4">
//         <h3 className="text-xl font-semibold mb-4">Order Summary</h3>
//         {products &&
//           products.map((product) => (
//             <div
//               key={product.id}
//               className="flex justify-between border-b pb-2 mb-2"
//             >
//               <span>
//                 {product.price} x {product.quantity}
//               </span>
//               <span>product-total: ${product.price * product.quantity}</span>
//             </div>
//           ))}
//         <p className="text-lg font-semibold">Total: ${total_price}</p>
//       </div>

//       <Formik
//         initialValues={initialValues}
//         validationSchema={CheckoutSchema}
//         onSubmit={handleSubmit}
//       >
//         {({ values, setFieldValue }) => (
//           <Form>
//             {/* Billing Address */}
//             <div className="bg-white p-6 rounded-lg shadow-md mb-4">
//               <h3 className="text-xl font-semibold mb-4">Billing Address</h3>
//               <div className="grid grid-cols-2 gap-4">
//                 <div>
//                   <label className="block mb-1 font-medium">Full Name</label>
//                   <Field
//                     name="full_name"
//                     type="text"
//                     className="w-full p-2 border rounded"
//                   />
//                   <ErrorMessage
//                     name="full_name"
//                     component="div"
//                     className="text-red-500 text-sm"
//                   />
//                 </div>

//                 <div>
//                   <label className="block mb-1 font-medium">Phone</label>
//                   <Field
//                     name="phone"
//                     type="text"
//                     className="w-full p-2 border rounded"
//                   />
//                   <ErrorMessage
//                     name="phone"
//                     component="div"
//                     className="text-red-500 text-sm"
//                   />
//                 </div>

//                 {/* Other billing fields */}
//                 <div>
//                   <label className="block mb-1 font-medium">Region</label>
//                   <Field
//                     name="region"
//                     type="text"
//                     className="w-full p-2 border rounded"
//                   />
//                   <ErrorMessage
//                     name="region"
//                     component="div"
//                     className="text-red-500 text-sm"
//                   />
//                 </div>

//                 <div>
//                   <label className="block mb-1 font-medium">City</label>
//                   <Field
//                     name="city"
//                     type="text"
//                     className="w-full p-2 border rounded"
//                   />
//                   <ErrorMessage
//                     name="city"
//                     component="div"
//                     className="text-red-500 text-sm"
//                   />
//                 </div>

//                 <div>
//                   <label className="block mb-1 font-medium">Postal Code</label>
//                   <Field
//                     name="postal_code"
//                     type="text"
//                     className="w-full p-2 border rounded"
//                   />
//                   <ErrorMessage
//                     name="postal_code"
//                     component="div"
//                     className="text-red-500 text-sm"
//                   />
//                 </div>

//                 <div>
//                   <label className="block mb-1 font-medium">Zone</label>
//                   <Field
//                     name="zone"
//                     type="text"
//                     className="w-full p-2 border rounded"
//                   />
//                   <ErrorMessage
//                     name="zone"
//                     component="div"
//                     className="text-red-500 text-sm"
//                   />
//                 </div>

//                 <div className="col-span-2">
//                   <label className="block mb-1 font-medium">Address</label>
//                   <Field
//                     name="address"
//                     type="text"
//                     className="w-full p-2 border rounded"
//                   />
//                   <ErrorMessage
//                     name="address"
//                     component="div"
//                     className="text-red-500 text-sm"
//                   />
//                 </div>
//               </div>
//             </div>

//             {/* Shipping Address */}
//             <div className="bg-white p-6 rounded-lg shadow-md mb-4">
//               <div className="flex items-center">
//                 <h3 className="text-xl font-semibold mb-4 flex-grow">
//                   Shipping Address
//                 </h3>
//                 <label className="flex items-center text-sm font-medium">
//                   <input
//                     type="checkbox"
//                     checked={useBillingAsShipping}
//                     onChange={() => {
//                       setUseBillingAsShipping(!useBillingAsShipping);
//                       if (!useBillingAsShipping) {
//                         setFieldValue("shippingFullName", values.full_name);
//                         setFieldValue("shippingPhone", values.phone);
//                         setFieldValue("shippingRegion", values.region);
//                         setFieldValue("shippingCity", values.city);
//                         setFieldValue("shippingPostalCode", values.postal_code);
//                         setFieldValue("shippingZone", values.zone);
//                         setFieldValue("shippingAddress", values.address);
//                       }
//                     }}
//                   />
//                   <span className="ml-2">Same as billing address</span>
//                 </label>
//               </div>
//               <div className="grid grid-cols-2 gap-4">
//                 {/* Shipping form fields with conditional disabling */}
//                 <div>
//                   <label className="block mb-1 font-medium">Full Name</label>
//                   <Field
//                     name="shippingFullName"
//                     type="text"
//                     className="w-full p-2 border rounded"
//                     disabled={useBillingAsShipping}
//                   />
//                   <ErrorMessage
//                     name="shippingFullName"
//                     component="div"
//                     className="text-red-500 text-sm"
//                   />
//                 </div>

//                 <div>
//                   <label className="block mb-1 font-medium">Phone</label>
//                   <Field
//                     name="shippingPhone"
//                     type="text"
//                     className="w-full p-2 border rounded"
//                     disabled={useBillingAsShipping}
//                   />
//                   <ErrorMessage
//                     name="shippingPhone"
//                     component="div"
//                     className="text-red-500 text-sm"
//                   />
//                 </div>

//                 {/* Other shipping fields */}
//                 <div>
//                   <label className="block mb-1 font-medium">Region</label>
//                   <Field
//                     name="shippingRegion"
//                     type="text"
//                     className="w-full p-2 border rounded"
//                     disabled={useBillingAsShipping}
//                   />
//                   <ErrorMessage
//                     name="shippingRegion"
//                     component="div"
//                     className="text-red-500 text-sm"
//                   />
//                 </div>

//                 <div>
//                   <label className="block mb-1 font-medium">City</label>
//                   <Field
//                     name="shippingCity"
//                     type="text"
//                     className="w-full p-2 border rounded"
//                     disabled={useBillingAsShipping}
//                   />
//                   <ErrorMessage
//                     name="shippingCity"
//                     component="div"
//                     className="text-red-500 text-sm"
//                   />
//                 </div>

//                 <div>
//                   <label className="block mb-1 font-medium">Postal Code</label>
//                   <Field
//                     name="shippingPostalCode"
//                     type="text"
//                     className="w-full p-2 border rounded"
//                     disabled={useBillingAsShipping}
//                   />
//                   <ErrorMessage
//                     name="shippingPostalCode"
//                     component="div"
//                     className="text-red-500 text-sm"
//                   />
//                 </div>

//                 <div>
//                   <label className="block mb-1 font-medium">Zone</label>
//                   <Field
//                     name="shippingZone"
//                     type="text"
//                     className="w-full p-2 border rounded"
//                     disabled={useBillingAsShipping}
//                   />
//                   <ErrorMessage
//                     name="shippingZone"
//                     component="div"
//                     className="text-red-500 text-sm"
//                   />
//                 </div>

//                 <div className="col-span-2">
//                   <label className="block mb-1 font-medium">Address</label>
//                   <Field
//                     name="shippingAddress"
//                     type="text"
//                     className="w-full p-2 border rounded"
//                     disabled={useBillingAsShipping}
//                   />
//                   <ErrorMessage
//                     name="shippingAddress"
//                     component="div"
//                     className="text-red-500 text-sm"
//                   />
//                 </div>
//               </div>
//             </div>

//             {/* Payment Method */}
//             <div className="bg-white p-6 rounded-lg shadow-md mb-4">
//               <h3 className="text-xl font-semibold mb-4">Payment Method</h3>
//               <div className="flex items-center">
//                 <label className="flex items-center mr-4">
//                   <Field
//                     type="radio"
//                     name="paymentMethod"
//                     value="Cash on Delivery"
//                     className="mr-2"
//                   />
//                   Cash on Delivery
//                 </label>
//                 <label className="flex items-center">
//                   <Field
//                     type="radio"
//                     name="paymentMethod"
//                     value="Online Payment"
//                     className="mr-2"
//                   />
//                   Online Payment
//                 </label>
//               </div>
//               <ErrorMessage
//                 name="paymentMethod"
//                 component="div"
//                 className="text-red-500 text-sm mt-2"
//               />
//             </div>

//             <button
//               type="submit"
//               className="w-full p-3 bg-blue-600 text-white rounded mt-4"
//             >
//               {loading ? "Loading..." : "Place Order"}
//             </button>
//           </Form>
//         )}
//       </Formik>
//     </div>
//   );
// };

// export default Checkout;
