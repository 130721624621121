import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrdersByReseller } from "../../features/orders/ordersSlice";
import LoaderComponent from "../LoaderComponent";
import Modal from "../common/Modal";
import AddReview from "../AddReview";
import Pagination from "../Pagination";

const ResellerOrders = () => {
  const { orders, pagination, loading } = useSelector((state) => state.orders);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("All");
  const [bonusFilter, setBonusFilter] = useState("all");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState({
    filterType: bonusFilter,
    limit,
  });

  useEffect(() => {
    dispatch(getOrdersByReseller({ page, query }));
  }, [dispatch, page, query]);

  const applyBonusFilter = () => {
    if (bonusFilter === "all" && limit) {
      setQuery({ filterType: bonusFilter, limit });
    } else if (bonusFilter === "today" && limit) {
      setQuery({ filterType: bonusFilter, limit });
    } else if (bonusFilter === "thisMonth" && limit) {
      setQuery({ filterType: bonusFilter, limit });
    } else if (bonusFilter === "custom" && startDate && endDate) {
      setQuery({
        filterType: bonusFilter,
        startDate: new Date(startDate).toISOString(),
        endDate: new Date(endDate).toISOString(),
        limit,
      });
    }
  };

  const filteredOrders =
    filter === "All"
      ? orders
      : orders.filter((order) => order.status === filter);

  if (loading) return <LoaderComponent message="Loading orders..." />;
  if (orders.length === 0)
    return (
      <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">
        You have no orders yet.
      </h2>
    );

  return (
    <div className="container mx-auto p-6 bg-gradient-to-r from-blue-50 to-gray-100  h-screen">
      <h2 className="text-3xl font-bold mb-6 text-center text-blue-600">
        My Orders
      </h2>

      <h2 className="text-2xl font-bold text-center text-gray-700 mb-6">
        Total Orders: {pagination?.totalItems}
      </h2>

      {/* Status Filter Buttons */}
      <div className="flex flex-wrap justify-center gap-3 mb-8">
        {[
          "All",
          "pending",
          "processing",
          "shipped",
          "completed",
          "delivered",
        ].map((status) => (
          <button
            key={status}
            onClick={() => setFilter(status)}
            className={`px-5 py-2 rounded-full text-sm font-semibold shadow-md transition-colors ${
              filter === status
                ? "bg-blue-500 text-white"
                : "bg-gray-200 text-gray-700 hover:bg-blue-400 hover:text-white"
            }`}
          >
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </button>
        ))}
      </div>

      <div className="flex flex-col md:flex-row items-center gap-6 p-6 bg-gray-50 rounded-lg shadow-lg">
        {/* Date Filter Section */}
        <div className="flex flex-col sm:flex-row items-center gap-6">
          <div className="flex flex-col">
            <label
              htmlFor="bonusFilter"
              className="text-sm font-medium text-gray-700 mb-1"
            >
              Bonus Filter
            </label>
            <select
              id="bonusFilter"
              value={bonusFilter}
              onChange={(e) => setBonusFilter(e.target.value)}
              className="px-4 py-2 rounded-lg border border-gray-300 bg-white shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
            >
              <option value="all">All</option>
              <option value="today">Today</option>
              <option value="thisMonth">This Month</option>
              <option value="custom">Custom</option>
            </select>
          </div>

          {bonusFilter === "custom" && (
            <div className="flex flex-col md:flex-row gap-6 items-center">
              {/* Start Date */}
              <div className="flex flex-col">
                <label
                  htmlFor="startDate"
                  className="text-sm font-medium text-gray-700 mb-1"
                >
                  Start Date
                </label>
                <input
                  type="date"
                  id="startDate"
                  value={startDate || ""}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="px-4 py-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
                />
              </div>

              {/* End Date */}
              <div className="flex flex-col">
                <label
                  htmlFor="endDate"
                  className="text-sm font-medium text-gray-700 mb-1"
                >
                  End Date
                </label>
                <input
                  type="date"
                  id="endDate"
                  value={endDate || ""}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="px-4 py-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
                />
              </div>
            </div>
          )}
        </div>

        {/* Limit Section */}
        <div className="flex flex-col">
          <label className="text-sm font-medium text-gray-700 mb-1">
            Set Limit
          </label>
          <input
            type="number"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
            className="px-4 py-2 rounded-lg border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 focus:border-blue-400"
          />
        </div>

        {/* Apply Button */}
        <button
          onClick={applyBonusFilter}
          className="px-6 py-2 rounded-full text-sm font-semibold shadow-md transition-colors bg-blue-500 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2"
        >
          Apply
        </button>
      </div>

      {/* Display Filtered Orders */}
      {filteredOrders.map((order) => (
        <OrderCard key={order._id} order={order} />
      ))}
      {/* Pagination */}
      <Pagination
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onPageChange={setPage}
      />
    </div>
  );
};

// Single Order Card Component
const OrderCard = ({ order }) => {
  const [showProducts, setShowProducts] = useState(false);

  const [modal, setModal] = useState(false);
  const [productId, setProductId] = useState(null);
  const [user, setUser] = useState(null);

  const toggleModal = (productId, userId) => {
    setModal(!modal);
    setProductId(productId);
    setUser(userId);
  };

  return (
    <div className="mb-4 p-6 bg-white rounded-lg shadow-lg transition-transform transform hover:-translate-y-1">
      <div className="flex flex-row justify-between mb-4">
        <div>
          <p className="text-sm text-gray-500">Order ID:</p>
          <p className="sm:text-sm md:text-xl font-semibold text-gray-800">
            {order.orderId}
          </p>
        </div>
        <button
          onClick={() => setShowProducts(!showProducts)}
          className="text-blue-500 hover:underline focus:outline-none"
        >
          {showProducts ? "Hide Details" : "View Details"}
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
        <div className="flex flex-row items-center">
          <p className="text-sm text-gray-500">Order Status:</p>
          <p
            className={`text-lg font-semibold ${
              order.status === "completed"
                ? "text-green-500"
                : order.status === "processing"
                ? "text-yellow-500"
                : "text-gray-700"
            }`}
          >
            {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
          </p>
        </div>
        <div className="flex flex-row items-center">
          <p className="text-sm text-gray-500">Payment Status:</p>
          <p
            className={`text-lg font-semibold ${
              order.paymentStatus === "Paid" ? "text-green-500" : "text-red-500"
            }`}
          >
            {order.paymentStatus}
          </p>
        </div>
        <div className="flex flex-row items-center">
          <p className="text-sm text-gray-500">Total Price:</p>
          <p className="text-lg font-semibold text-blue-500">
            Tk. {order.totalPrice + order.deliveryCharge}
          </p>
        </div>
      </div>

      {/* Products List - Collapsible */}
      {showProducts && (
        <div className="mt-4 border-t pt-4 mb-6">
          <h3 className="text-lg font-semibold mb-3 text-gray-700">
            Products:
          </h3>
          {order.products.map((product) => (
            <div
              key={product.id}
              className="flex flex-col md:flex-row items-center justify-between mb-4 p-3 bg-gray-50 rounded-lg shadow-inner"
            >
              <div className="flex flex-col md:flex-row items-center mb-6">
                <img
                  src={product.image}
                  alt={product.title}
                  className="w-full h-full md:w-12 h-12 rounded mr-4 object-cover shadow-md"
                />
                <div className="flex flex-col ">
                  <p className="text-gray-800 font-medium">{product.title}</p>
                  <p className="text-sm text-gray-500">
                    Quantity: {product.quantity}
                  </p>
                  <p className="text-sm text-gray-500">
                    Price: TK. {product?.soldPrice.toFixed(2)}
                  </p>
                  {product.color && (
                    <p className="text-sm text-gray-500">
                      Color: {product.color}
                    </p>
                  )}
                  {product.size && (
                    <p className="text-sm text-gray-500">
                      Size: {product.size}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-col item-center mb-6">
                <h2 className="text-lg font-semibold text-gray-800">
                  Shipping Address
                </h2>
                <p className="text-sm md:text-lg font-semibold text-gray-800">
                  {" "}
                  Name: {order.shippingAddress?.fullName}
                </p>
                <p className="text-sm md:text-lg font-semibold text-gray-800">
                  phone: {order.shippingAddress?.phone}
                </p>
                <p className="text-sm md:text-lg font-semibold text-gray-800">
                  region: {order.shippingAddress?.region}
                </p>
                <p className="text-sm md:text-lg font-semibold text-gray-800">
                  city: {order.shippingAddress?.city}
                </p>
                <p className="text-sm md:text-lg font-semibold text-gray-800">
                  postalCode:{order.shippingAddress?.postalCode}
                </p>
                <p className="text-sm md:text-lg font-semibold text-gray-800">
                  {" "}
                  zone: {order.shippingAddress?.zone}
                </p>
                <p className="text-sm md:text-lg font-semibold text-gray-800">
                  address: {order.shippingAddress?.address}
                </p>
              </div>
              <div className="flex flex-col items-center">
                <p className="text-sm text-gray-500">
                  Subtotal: TK. {product?.soldPrice * product.quantity}
                </p>
                <p>deliverCharge: TK. {order?.deliveryCharge}</p>
                <p className="text-lg font-semibold mr-4 text-gray-800">
                  Total Tk.{order?.totalPrice + order?.deliveryCharge}
                </p>
                {order.status === "completed" && (
                  <button
                    onClick={() =>
                      toggleModal(product.id, order.orderByReseller)
                    }
                    className="text-blue-500 text-sm underline hover:text-blue-700"
                  >
                    Add Review
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
      {modal && (
        <Modal title="Add Review" isOpen={modal} onClose={toggleModal}>
          <AddReview userId={user} productId={productId} />
        </Modal>
      )}
    </div>
  );
};

export default ResellerOrders;
