import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api/api";
import { toast } from "react-toastify";

// get all payment options
export const getPaymentOptions = createAsyncThunk(
  "paymentOption/getPaymentOptions",
  async () => {
    const response = await api.get("/payment-options");
    return response.data;
  }
);

// create payment option
export const createPaymentOption = createAsyncThunk(
  "paymentOption/createPaymentOption",
  async ({ paymentOptionData, token }) => {
    const response = await api.post("/payment-options", paymentOptionData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 201) {
      toast.success("payment option created successfully");
    }
    return response.data;
  }
);

// delete payment option
export const deletePaymentOption = createAsyncThunk(
  "paymentOption/deletePaymentOption",
  async ({ paymentOptionId, token }) => {
    const response = await api.delete(`/payment-options/${paymentOptionId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      toast.success("payment option deleted successfully");
    }
    return response.data;
  }
);

// update payment options
export const updatePaymentOption = createAsyncThunk(
  "paymentOption/updatePaymentOption",
  async ({ paymentOptionId, paymentOptionData, token }) => {
    const response = await api.put(
      `/payment-options/${paymentOptionId}`,
      paymentOptionData,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (response.status === 200) {
      toast.success("payment option updated successfully");
    }
    return response.data;
  }
);

const paymentOptionSlice = createSlice({
  name: "paymentOption",
  initialState: {
    paymentOptions: [],
    loading: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPaymentOptions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPaymentOptions.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentOptions = action.payload;
      })
      .addCase(getPaymentOptions.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createPaymentOption.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPaymentOption.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentOptions.push(action.payload);
      })
      .addCase(createPaymentOption.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updatePaymentOption.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePaymentOption.fulfilled, (state, action) => {
        state.loading = false;
        state.paymentOptions = state.paymentOptions.map((item) =>
          item._id === action.payload._id ? action.payload : item
        );
      })
      .addCase(updatePaymentOption.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default paymentOptionSlice.reducer;
