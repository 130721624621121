import React, { useState } from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppContact = () => {
  const [showToast, setShowToast] = useState(false);
  const phoneNumber = '1745075455'; // Replace with actual phone number
  const message = 'Hello, I would like to contact you.';

  return (
    <div className="fixed bottom-6 right-6 flex flex-col items-center space-y-2 z-50">
      <a
        href={`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`}
        target="_blank"
        rel="noopener noreferrer"
        className="relative w-16 h-16 bg-green-500 text-white rounded-full flex items-center justify-center shadow-lg transition-transform duration-300 hover:scale-110"
        onMouseEnter={() => setShowToast(true)}
        onMouseLeave={() => setShowToast(false)}
      >
        <FaWhatsapp size={28} />
        {showToast && (
          <div className="absolute -top-12 bg-gray-800 text-white text-sm p-2 rounded-md shadow-lg animate-fade-in">
            Contact us on WhatsApp!
          </div>
        )}
      </a>
    </div>
  );
};

export default WhatsAppContact;
