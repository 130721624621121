import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../api/api";
import { toast } from "react-toastify";

export const getReferAmounts = createAsyncThunk(
  "referAmount/getReferAmounts",
  async ({ token }) => {
    const response = await api.get("/refer-amounts", {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data;
  }
);

export const addReferAmount = createAsyncThunk(
  "referAmount/addReferAmount",
  async ({ referAmountForAffiliate, referAmountForReseller, token }) => {
    const response = await api.post(
      "/refer-amounts",
      { referAmountForAffiliate, referAmountForReseller },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    if (response.status === 201) {
      toast.success("refer amount created successfully");
    }
    return response.data;
  }
);

export const deleteReferAmount = createAsyncThunk(
  "referAmount/deleteReferAmount",
  async ({ referAmountId, token }) => {
    await api.delete(`/refer-amounts/${referAmountId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return referAmountId;
  }
);

// update refer amount
export const updateReferAmount = createAsyncThunk(
  "referAmount/updateReferAmount",
  async ({ id, referAmount, token }) => {
    const response = await api.put(`/refer-amounts/${id}`, referAmount, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (response.status === 200) {
      toast.success("refer amount updated successfully");
    }
    return response.data;
  }
);

const referAmountSlice = createSlice({
  name: "referAmount",
  initialState: {
    referAmounts: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getReferAmounts.pending, (state) => {
        state.loading = true;
      })
      .addCase(getReferAmounts.fulfilled, (state, action) => {
        state.loading = false;
        state.referAmounts = action.payload;
      })
      .addCase(getReferAmounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(addReferAmount.fulfilled, (state, action) => {
        state.referAmounts.push(action.payload);
      })
      .addCase(deleteReferAmount.fulfilled, (state, action) => {
        state.referAmounts = state.referAmounts.filter(
          (item) => item._id !== action.payload
        );
      })
      .addCase(updateReferAmount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateReferAmount.fulfilled, (state, action) => {

        state.referAmounts = state.referAmounts.map((item) => {
          if (item._id === action.payload._id) {
            return action.payload;
          }
          return item;
        });
        state.loading = false;
        state.error = null;
      })
      .addCase(addReferAmount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default referAmountSlice.reducer;
