import React, { useState, useEffect } from 'react';

const CountdownTimer = ({ endDate }) => {
  const [timeLeft, setTimeLeft] = useState(0);
  

  useEffect(() => {
    // Convert endDate string to Date object
    const targetDate = new Date(endDate);

    // Update the countdown every second
    const countdownInterval = setInterval(() => {
      const now = new Date();
      const timeDifference = targetDate - now; // Get the remaining time in milliseconds

      if (timeDifference <= 0) {
        clearInterval(countdownInterval); // Clear the interval when the time is up
        setTimeLeft(0); // Set timeLeft to 0 when the offer ends
      } else {
        setTimeLeft(timeDifference); // Update the remaining time
      }
    }, 1000); // Update every second

    // Clean up the interval when the component unmounts
    return () => clearInterval(countdownInterval);
  }, [endDate]); // Re-run the effect if endDate changes

  // If there's time left, calculate days, hours, minutes, and seconds
  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return (
    <div className="flex justify-center items-center h-full">
  {timeLeft > 0 ? (
    <div className="text-center bg-red-500 text-white font-bold p-4 rounded-lg shadow-lg ">
      <p className="text-xl md:text-2xl lg:text-3xl">
        Campaign Time Remaining
      </p>
      <div className="text-lg md:text-xl lg:text-2xl">
        {days}
        <span className="text-pink-300"> Days</span> {hours}
        <span className="text-pink-300"> Hours</span> {minutes}
        <span className="text-pink-300"> Minutes</span> {seconds}
        <span className="text-pink-300"> Seconds</span>
      </div>
    </div>
  ) : (
    <div className="text-center bg-gray-300 text-gray-800 font-bold p-4 rounded-lg shadow-lg">
      <p className="text-xl md:text-2xl lg:text-3xl">Offer Expired</p>
    </div>
  )}
</div>
  );
};

export default CountdownTimer;
