import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {FaEye, FaPlus, FaDownload,FaEdit } from 'react-icons/fa';
import { getAllProducts, resetProducts, setFilter, setPage,downloadTemplate } from '../../features/products/productsSlice';
import LoaderComponent from '../LoaderComponent';
import ProductDetails from './ProductDetails';
import ShowCreateFormModal from './ShowCreateFormModal';
import Pagination from '../Pagination';
import Modal from '../common/Modal';
import ImageUploader from './ImageUploader';
import UploadFile from './UploadFile';
import { debounce } from "lodash";
import Search from '../Search';
import EditProductForm from './EditProductForm';
import StockDetailsAndEdit from './StockDetailsAndEdit';
import { toast } from 'react-toastify';

const ProductsList = () => {
  const { products,pagination, loading, filters, page } = useSelector((state) => state.products);
  const dispatch = useDispatch();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showImageUploader, setShowImageUploader] = useState(false);
  const [fileUpload, setFileUpload] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  const [showStockDetails, setShowStockDetails] = useState(false);

  useEffect(() => {
    dispatch(resetProducts());
    dispatch(getAllProducts({ filters, page }));
  }, [dispatch, filters, page]);

  useEffect(() => {
    return () => {
      dispatch(
        setFilter({
          category: "",
          minPrice: 0,
          maxPrice: 100000,
          inStock: false,
          rating: 0,
          search: "",
        })
      );
      dispatch(resetProducts());
    };
  }, [dispatch]);

  // const handleDelete = (productId) => {
  //   if (window.confirm('Are you sure you want to delete this product?')) {
  //     dispatch(deleteProduct(productId)); 
  //   }
  // };

  // pagination handler
  const handlePageChange = (newPage) => {
    dispatch(resetProducts());
    dispatch(setPage(newPage));; 
  };

  const handleViewDetails = (product) => {
    setSelectedProduct(product);
    setShowDetails(true);
  };



  const handleEdit = (product) => {
    setIsEditing(!isEditing);
    setSelectedProduct(product);
  }

  // Debounced search handler
  // eslint-disable-next-line react-hooks/exhaustive-deps
   const debouncedSearch = useCallback(
    debounce((query) => {
      // If the search query is empty, reset the search filter
      if (!query.trim()) {
        dispatch(setFilter({ search: "" })); // Clear the search filter
        dispatch(resetProducts());
        dispatch(getAllProducts({ filters: {}, page: 1 })); // Fetch full data
      } else {
        // Otherwise, update the search filter
        dispatch(setFilter({ search: query }));
      }
    },20000),
    [dispatch]
  );



  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    debouncedSearch(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    dispatch(setFilter({ search: searchQuery }));
    
  };

  // download template
  // const handleDownloadTemplate = async () => {
  //   try {
  //     // Dispatch the thunk and unwrap the Blob response
  //     const blob = await dispatch(downloadTemplate()).unwrap();
  
  //     // Create a download link
  //     const url = window.URL.createObjectURL(blob);
  //     const link = document.createElement('a');
  //     link.href = url;
  //     link.setAttribute('download', 'product_template.xlsx');
  //     document.body.appendChild(link);
  //     link.click();
  
  //     // Clean up
  //     link.parentNode.removeChild(link);
  //   } catch (error) {
  //     console.error('Error downloading template:', error);
  //   }
  // };

  const handleDownloadTemplate = async () => {
    try {
      await dispatch(downloadTemplate()).unwrap();
      toast.success("Template downloaded successfully");
    } catch (error) {
      toast.error(error.message || "Failed to download template");
    }
  };
  
  // calculate total stock -- as only one variant is available so we can calculate total stock by both and addition them
  const calculateTotalStock = (product) => {
    const colorSizeStock = product.colorSizeVariants.reduce(
      (total, variant) => total + variant.sizes.reduce((sum, size) => sum + size.stock, 0),
      0
    );
    const sizeStock = product.sizeVariants.reduce((total, variant) => total + variant.stock, 0);
    return colorSizeStock + sizeStock;
  };
  
  const handleStockDetailsAndEdit = (product) => {
    setSelectedProduct(product);
    setShowStockDetails(true);
  };


  if (loading) return <LoaderComponent />;

 

  return (
    <div className="container mx-auto p-6 mb-8 h-screen">
      <div className="flex flex-col  md:flex-row justify-between items-center mb-6">
        <h2 className="text-2xl font-semibold text-gray-800">Products List</h2>
        <button
          onClick={() => setShowCreateForm(true)}
          className="bg-blue-600 text-white p-2 rounded-lg shadow-lg hover:bg-blue-700 transition-all flex items-center mb-4"
        >
          <FaPlus className="mr-2" /> Create Product
        </button>
        <button
          onClick={() => setShowImageUploader(true)}
          className="bg-blue-600 text-white p-2 rounded-lg shadow-lg hover:bg-blue-700 transition-all flex items-center mb-4"
        >
          <FaPlus className="mr-2" /> image upload
        </button>
        <button
          onClick={() => setFileUpload(true)}
          className="bg-blue-600 text-white p-2 rounded-lg shadow-lg hover:bg-blue-700 transition-all flex items-center mb-4"
        >
          <FaPlus className="mr-2" /> Bulk upload
        </button>
        <button
          onClick={handleDownloadTemplate}
          className="bg-blue-600 text-white p-2 rounded-lg shadow-lg hover:bg-blue-700 transition-all flex items-center mb-4"
        >
          <FaDownload className="mr-2" /> Download Xl template
        </button>
      </div>

      {/* Search Bar */}
      <Search handleSearchSubmit={handleSearchSubmit} handleSearchChange={handleSearchChange} searchQuery={searchQuery} placeholder={"Search by product title"} />

      <div className="overflow-x-auto shadow-lg ">
        <table className="min-w-full bg-white text-center border-collapse border border-gray-200">
          <thead className="bg-gray-200 text-gray-700">
            <tr>
              <th className="border border-gray-400 py-3 px-4 text-sm font-medium">SL No</th>
              <th className="border border-gray-400 py-3 px-4 text-sm font-medium">Product Code</th>
              <th className="border border-gray-400 py-3 px-4 text-sm font-medium">Product Name</th>
              <th className="border border-gray-400 py-3 px-4 text-sm font-medium">Category</th>
              <th className="border border-gray-400 py-3 px-4 text-sm font-medium">Subcategory</th>
              <th className='border border-gray-400 py-3 px-4 text-sm font-medium'>Stock Status</th>
              <th className="border border-gray-400 py-3 px-4 text-sm font-medium">Stock Balance</th> 
              <th className="border border-gray-400 py-3 px-4 text-sm font-medium">Actions</th>
            </tr>
          </thead>
          <tbody>
            {products.length > 0 ? (
              products.map((product, index) => (
                <tr key={product._id} className="border-b hover:bg-gray-100">
                  <td className="border border-gray-400 py-3 px-4 text-gray-600">{index + 1}</td>
                  <td className="border border-gray-400 py-3 px-4 text-gray-600">{product.productCode || "-"}</td>
                  <td className="border border-gray-400 py-3 px-4 text-gray-800 font-semibold">{product.title}</td>
                  
                  <td className="border border-gray-400 py-3 px-4 text-gray-600">{product.category?.map((cat) => cat.name).join(', ')}</td>
                  <td className="border border-gray-400 py-3 px-4 text-gray-600">{product.subcategory?.map((cat) => cat.name).join(', ')}</td>
                  <td className="border border-gray-400 py-3 px-4 text-gray-600">{calculateTotalStock(product) > 0 ? 'In Stock' : 'Out of Stock'}</td>
                  <td className="border border-gray-400 py-3 px-4 text-gray-600">
                    {calculateTotalStock(product)}
                    <br />
                    <button onClick={() => handleStockDetailsAndEdit(product)} className='text-blue-600 p-2'>View/Edit Details</button>
                    </td>
                 
                  <td className=" py-3 px-4 flex justify-center gap-2">
                    <button
                      onClick={() => handleViewDetails(product)}
                      className="bg-blue-500 text-white p-2 rounded-full shadow-md hover:bg-blue-600 transition-all"
                    >
                      <FaEye />
                    </button>
                    <button
                      onClick={() => handleEdit(product)}
                      className="bg-green-500 text-white p-2 rounded-full shadow-md hover:bg-green-600 transition-all"
                    >
                      <FaEdit />
                    </button>
                    {/* <button
                      onClick={() => handleDelete(product._id)}
                      className="bg-red-500 text-white p-2 rounded-full shadow-md hover:bg-red-600 transition-all"
                    >
                      <FaTrash />
                    </button> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="py-4 text-gray-500">
                  No products found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      <Pagination
        currentPage={pagination?.page}
        totalPages={pagination?.totalPages}
        onPageChange={handlePageChange}
      />

      {/* Modal for Product Details */}
      {showDetails && selectedProduct && (
        <ProductDetails selectedProduct={selectedProduct} setShowDetails={setShowDetails} />
      )}

      {/* Modal for Create Form */}
      {showCreateForm && (
        <ShowCreateFormModal setShowCreateForm={setShowCreateForm} />
      )}
      {/* modal for bulk image upload */}
      {
        showImageUploader && (
          <Modal title="Image Upload" isOpen={showImageUploader} onClose={() => setShowImageUploader(false)}>
            <ImageUploader />
          </Modal>
        )
      }

      {/* modal for excel file upload */}

      {
        fileUpload && (
          <Modal title="File Upload" isOpen={fileUpload} onClose={() => setFileUpload(false)}>
            <UploadFile />
          </Modal>
        )
      }

      {/* modal for edit product form */}

      {
        isEditing && (
          <Modal title="Edit Product" isOpen={isEditing} onClose={() => setIsEditing(false)}>
            <EditProductForm product={selectedProduct} />
          </Modal>
        )
      }

      {/* modal for stock details and edit */}
      {
        showStockDetails && (
          <Modal title="Stock Details" isOpen={showStockDetails} onClose={() => setShowStockDetails(false)}>
            <StockDetailsAndEdit product={selectedProduct} />
          </Modal>
        )
      }
     
    </div>
  );
};

export default ProductsList;
