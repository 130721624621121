import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  createOfferByCategory,
  createOfferAllProducts,
  createOfferSpecificProducts,
  stopOffer,
} from "../../features/offers/offerSlice";
import { fetchCategories } from "../../features/category/categorySlice";
import { fetchProductsForOffer } from "../../features/products/productsSlice";
import LoaderComponent from "../LoaderComponent";

const Offer = () => {
  const dispatch = useDispatch();
  const { productsAvailableForOffer, loading } = useSelector(
    (state) => state.products
  );
  const { categories } = useSelector((state) => state.category);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [formData, setFormData] = useState({
    isActive: false,
    discountType: "percentage",
    discountValue: 0,
    startDate: "",
    endDate: "",
  });
  const [successMessage, setSuccessMessage] = useState("");
  const [toggleCategoryOffer, setToggleCategoryOffer] = useState(false);
  const [toggleAllProductsOffer, setToggleAllProductsOffer] = useState(false);
  const [toggleSpecificProductsOffer, setToggleSpecificProductsOffer] =
    useState(false);

  useEffect(() => {
    dispatch(fetchProductsForOffer());
    dispatch(fetchCategories());
  }, [dispatch]);

  const handleSuccess = (message) => {
    setSuccessMessage(message);
    setTimeout(() => setSuccessMessage(""), 3000); // Clear message after 3 seconds
  };

  const handleOfferByCategory = (e) => {
    e.preventDefault();
    dispatch(
      createOfferByCategory({
        category: selectedCategory.value,
        ...formData,
      })
    ).then(() => {
      handleSuccess("Offer created for the selected category!");
      setSelectedCategory(null); // Reset form
    });
  };

  const handleOfferAllProducts = (e) => {
    e.preventDefault();
    dispatch(createOfferAllProducts(formData)).then(() =>
      handleSuccess("Offer created for all products!")
    );
  };

  const handleOfferSpecificProducts = (e) => {
    e.preventDefault();
    const productIds = selectedProducts.map((product) => product.value);
    dispatch(
      createOfferSpecificProducts({
        productIds,
        ...formData,
        discountValue: Number(formData.discountValue),
        startDate: new Date(formData.startDate),
        endDate: new Date(formData.endDate),
      })
    ).then(() => {
      handleSuccess("Offer created for the selected products!");
      setSelectedProducts([]); // Reset form
    });
  };

  const handleStopOffer = () => {
    dispatch(stopOffer()).then(() => handleSuccess("All offers stopped!"));
  };

  const handleToggleCategoryOffer = () => {
    setToggleCategoryOffer(!toggleCategoryOffer);
  };

  const handleToggleAllProductsOffer = () => {
    setToggleAllProductsOffer(!toggleAllProductsOffer);
  };

  const handleToggleSpecificProductsOffer = () => {
    setToggleSpecificProductsOffer(!toggleSpecificProductsOffer);
  };

  if (loading) return <LoaderComponent />;

  return (
    <div className="p-6 max-w-4xl mx-auto">
      <h1 className="text-3xl font-bold text-center mb-8">Manage Campaign</h1>

      {successMessage && (
        <div className="bg-green-100 text-green-800 p-4 rounded mb-6">
          {successMessage}
        </div>
      )}
      {/* Category Offer */}
      <div className="mb-6 p-6 bg-white shadow-md rounded-lg">
        <h2
          onClick={handleToggleCategoryOffer}
          className="text-2xl font-semibold mb-4 text-gray-800 hover:text-blue-600 cursor-pointer"
        >
          Click to Create Campaign by Category++
        </h2>
        {toggleCategoryOffer && (
          <form onSubmit={handleOfferByCategory} className="space-y-4">
            {/* Category Selector */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Select Category
              </label>
              <Select
                options={categories.map((cat) => ({
                  value: cat._id,
                  label: cat.name,
                }))}
                value={selectedCategory}
                onChange={(selectedOption) =>
                  setSelectedCategory(selectedOption || null)
                }
                placeholder="Select a Category"
                className="border rounded-lg shadow-sm w-full"
              />
            </div>

            {/* Activate Offer */}
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={formData.isActive}
                onChange={(e) =>
                  setFormData({ ...formData, isActive: e.target.checked })
                }
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <label className="text-sm font-medium text-gray-700">
                Activate Campaign
              </label>
            </div>

            {/* Discount Type */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Discount Type
              </label>
              <select
                value={formData.discountType}
                onChange={(e) =>
                  setFormData({ ...formData, discountType: e.target.value })
                }
                className="w-full border rounded-lg shadow-sm px-4 py-2"
              >
                <option value="percentage">Percentage</option>
                <option value="flat">Flat</option>
              </select>
            </div>

            {/* Discount Value */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Discount Value
              </label>
              <input
                type="number"
                value={formData.discountValue}
                onChange={(e) =>
                  setFormData({ ...formData, discountValue: e.target.value })
                }
                className="w-full border rounded-lg shadow-sm px-4 py-2"
                placeholder="Enter discount value"
              />
            </div>

            {/* Start Date */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Start Date
              </label>
              <input
                type="date"
                value={formData.startDate}
                onChange={(e) =>
                  setFormData({ ...formData, startDate: e.target.value })
                }
                className="w-full border rounded-lg shadow-sm px-4 py-2"
              />
            </div>

            {/* End Date */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                End Date
              </label>
              <input
                type="date"
                value={formData.endDate}
                onChange={(e) =>
                  setFormData({ ...formData, endDate: e.target.value })
                }
                className="w-full border rounded-lg shadow-sm px-4 py-2"
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className={`w-full bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed`}
              disabled={!selectedCategory}
            >
              Create Campaign by Category
            </button>
          </form>
        )}
      </div>
      {/* All Products Offer */}

      <div className="mb-6 p-6 bg-white shadow-md rounded-lg">
        <h2
          onClick={handleToggleAllProductsOffer}
          className="text-2xl font-semibold mb-4 text-gray-800 hover:text-blue-600 cursor-pointer"
        >
          Click to Create Campaign for All Products++
        </h2>

        {toggleAllProductsOffer && (
          <form onSubmit={handleOfferAllProducts} className="space-y-4">
            {/* Activate Offer */}
            <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                checked={formData.isActive}
                onChange={(e) =>
                  setFormData({ ...formData, isActive: e.target.checked })
                }
                className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
              />
              <label className="text-sm font-medium text-gray-700">
                Activate Campaign
              </label>
            </div>

            {/* Discount Type */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Discount Type
              </label>
              <select
                value={formData.discountType}
                onChange={(e) =>
                  setFormData({ ...formData, discountType: e.target.value })
                }
                className="w-full border rounded-lg shadow-sm px-4 py-2"
              >
                <option value="percentage">Percentage</option>
                <option value="flat">Flat</option>
              </select>
            </div>

            {/* Discount Value */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Discount Value
              </label>
              <input
                type="number"
                value={formData.discountValue}
                onChange={(e) =>
                  setFormData({ ...formData, discountValue: e.target.value })
                }
                className="w-full border rounded-lg shadow-sm px-4 py-2"
                placeholder="Enter discount value"
              />
            </div>

            {/* Start Date */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Start Date
              </label>
              <input
                type="date"
                value={formData.startDate}
                onChange={(e) =>
                  setFormData({ ...formData, startDate: e.target.value })
                }
                className="w-full border rounded-lg shadow-sm px-4 py-2"
              />
            </div>

            {/* End Date */}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                End Date
              </label>
              <input
                type="date"
                value={formData.endDate}
                onChange={(e) =>
                  setFormData({ ...formData, endDate: e.target.value })
                }
                className="w-full border rounded-lg shadow-sm px-4 py-2"
              />
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className={`w-full bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed`}
            >
              Create Campaign to All Products
            </button>
          </form>
        )}
      </div>

      {/* {productsAvailableForOffer?.length > 0 && (
        <div className="mb-6">
          <h2 className="text-xl font-semibold mb-2">
            Offer for Specific Products
          </h2>
          <Select
            options={productsAvailableForOffer.map((prod) => ({
              value: prod._id,
              label: prod.title,
            }))}
            value={selectedProducts}
            onChange={(selectedOptions) =>
              setSelectedProducts(selectedOptions || [])
            }
            isMulti
            placeholder="Select Products"
            className="mb-4"
          />
          <button
            onClick={handleOfferSpecificProducts}
            className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
            disabled={selectedProducts.length === 0}
          >
            Create Offer for Specific Products
          </button>
        </div>
      )} */}

      {/* create offer for specific products */}
      {productsAvailableForOffer?.length > 0 && (
        <div className="mb-6 p-6 bg-white shadow-md rounded-lg">
          <h2
            onClick={handleToggleSpecificProductsOffer}
            className="text-2xl font-semibold mb-4 text-gray-800 hover:text-blue-600 cursor-pointer"
          >
            Click to Create Campaign by Specific Products++
          </h2>
          {toggleSpecificProductsOffer && (
            <form onSubmit={handleOfferSpecificProducts} className="space-y-4">
              {/* Category Selector */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Select products
                </label>
                <Select
                  options={productsAvailableForOffer.map((prod) => ({
                    value: prod._id,
                    label: prod.title,
                  }))}
                  value={selectedProducts}
                  onChange={(selectedOptions) =>
                    setSelectedProducts(selectedOptions || [])
                  }
                  isMulti
                  placeholder="Select Products"
                  className="mb-4"
                />
              </div>

              {/* Activate Offer */}
              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  checked={formData.isActive}
                  onChange={(e) =>
                    setFormData({ ...formData, isActive: e.target.checked })
                  }
                  className="rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                />
                <label className="text-sm font-medium text-gray-700">
                  Activate Campaign
                </label>
              </div>

              {/* Discount Type */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Discount Type
                </label>
                <select
                  value={formData.discountType}
                  onChange={(e) =>
                    setFormData({ ...formData, discountType: e.target.value })
                  }
                  className="w-full border rounded-lg shadow-sm px-4 py-2"
                >
                  <option value="percentage">Percentage</option>
                  <option value="flat">Flat</option>
                </select>
              </div>

              {/* Discount Value */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Discount Value
                </label>
                <input
                  type="number"
                  value={formData.discountValue}
                  onChange={(e) =>
                    setFormData({ ...formData, discountValue: e.target.value })
                  }
                  className="w-full border rounded-lg shadow-sm px-4 py-2"
                  placeholder="Enter discount value"
                />
              </div>

              {/* Start Date */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Start Date
                </label>
                <input
                  type="date"
                  value={formData.startDate}
                  onChange={(e) =>
                    setFormData({ ...formData, startDate: e.target.value })
                  }
                  className="w-full border rounded-lg shadow-sm px-4 py-2"
                />
              </div>

              {/* End Date */}
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  End Date
                </label>
                <input
                  type="date"
                  value={formData.endDate}
                  onChange={(e) =>
                    setFormData({ ...formData, endDate: e.target.value })
                  }
                  className="w-full border rounded-lg shadow-sm px-4 py-2"
                />
              </div>

              {/* Submit Button */}
              <button
                type="submit"
                className={`w-full bg-blue-500 text-white px-4 py-2 rounded-lg shadow-md hover:bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed`}
              >
                Create Campaign by Category
              </button>
            </form>
          )}
        </div>
      )}

      <div className="mb-6">
        <h2 className="text-xl font-semibold mb-2">Stop Offer</h2>
        <button
          onClick={handleStopOffer}
          className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
        >
          Stop  Campaign
        </button>
      </div>
    </div>
  );
};

export default Offer;
